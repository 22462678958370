// SucessoMsg.tsx
import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';

interface SucessoMsgProps {
  onAnother?: () => void;
  onCancel?: () => void;
  message?:  string;
}

const SucessoMsg: React.FC<SucessoMsgProps> = ({ onAnother, message, onCancel }) => {
  return (
    <div
      id="popup-modal"
      tabIndex={-1}
      className="overflow-y-auto overflow-x-hidden backdrop-blur-sm fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="p-4 md:p-5 text-center">
            <svg
              className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                {message}
            </h3>

            <div className='flex justify-between w-full'>
                <button
                  onClick={onCancel}
                  data-modal-hide="popup-modal"
                  type="button"
                  className="closeButton gap-2"
                >
                    <IoClose className='h-5 w-5'/>
                  Não
                </button>
                
                    <button
                      onClick={onAnother}
                      data-modal-hide="popup-modal"
                      type="button"
                      className="confirmButton gap-2"
                    >
                        <FaCheck/>
                      Sim
                    </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SucessoMsg;
