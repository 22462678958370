import React from 'react'

type Props = {}

const IncriçãoMunicipalHelp = ({close}: any) => {
  return (
    <div className='w-full h-screen fixed z-20'>

            <div id="static-modal" data-modal-backdrop="static" tabIndex={-1}  className=" overflow-y-auto overflow-x-hidden z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Cadastro municipal/ estadual e outras informações
                            </h3>
                            <button onClick={close} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Visite o site do orgão responssável no seu município para encontrar informações referentes a inscrição municipal da sua empresa.
                            </p>
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Caso a empresa possua sede em goiânia visite o site abaixo, informando o seu cnpj é possível obter o nº da inscrição municipal, natureaza jurídica, data de abertura e outras informações.
                            </p>
                            <a href="https://www.goiania.go.gov.br/sistemas/sccae/asp/sccae00010r0.asp">https://www.goiania.go.gov.br/sistemas/sccae/asp/sccae00010r0.asp</a>

                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Os dados referentes a inscrição estadual podem ser obtidos através do orgão responssável no estado ao qual a sua empresa está vinculada.
                            </p>
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Para empresas registradas no estado de Goiás, o site abaixo fornece estas informções, basta selecionar o tipo de entrada (cnpj, cce ou cpf).
                            </p>
                            <a href="http://appasp.sefaz.go.gov.br/Sintegra/Consulta/consultar.asp">http://appasp.sefaz.go.gov.br/Sintegra/Consulta/consultar.asp</a>
                        </div>
        
                    </div>
                </div>
            </div>
    </div>
     

  )
}

export default IncriçãoMunicipalHelp