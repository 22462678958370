import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DisplayMiddle } from '../../Utils/types/PortalTypes'; // Certifique-se de ajustar o caminho para onde o enum DisplayMiddle está definido
import ProcessosFerramentas from './ProcessosFerramentas';
import { Bs0CircleFill, BsChat, BsFillInfoCircleFill, BsPerson, BsSearch } from 'react-icons/bs';
import { CategoriasPadrão, TCategoriaPastas } from '../../Utils/DevUtils/CategoriasFake';
import { useQuery } from '@tanstack/react-query';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import { PiCaretDownThin, PiCaretUpThin } from 'react-icons/pi';
import { RxCaretLeft, RxCaretRight } from "react-icons/rx";
import { useInView } from 'react-intersection-observer';
import ItensProcessos from '../ItensComponents/ItensProcessos';

const TopProcessos = ({ processo, filtroMiddle, displayFiltroMiddle }: any) => {



  const token = localStorage.getItem('token');
  const empresaSelecionada = localStorage.getItem('empresaSelecionada');
  const { configCategoria, getCategorias } = useReactQueryEmpresas()

  const { data: categoriasData } = useQuery({
    queryFn: () => getCategorias(token as string, empresaSelecionada as string),
    queryKey: ['rqCategorias', empresaSelecionada]
  })

  const [categoriaAtiva, setCategoriaAtiva] = useState<TCategoriaPastas | undefined>(undefined);

  const [categoria, setCategoria] = useState<TCategoriaPastas>({ value: 'Documentos', label: 'Documentos' });
  const [abrirListaCategorias, setAbrirListaCategorias] = useState<boolean>(false);

  const [buscarFiltro, setBuscarFiltro] = useState<string>('');

  const [categorias, setCategorias] = useState<any | undefined>(undefined);

  const handleCategoria = (categoria: TCategoriaPastas) => {
    console.log('Categoria: ', categoria)
    setCategoria(categoria);
    filtroMiddle(categoria.label)
    setAbrirListaCategorias(false)
  }


  useEffect(() => {
    if (displayFiltroMiddle === DisplayMiddle.RESUMO || displayFiltroMiddle === DisplayMiddle.VERMAISCPH) {
      setAbrirListaCategorias(false);
      setCategoria({ value: 'Documentos', label: 'Documentos' })

    }
  }, [displayFiltroMiddle])

  useLayoutEffect(() => {

    if (categoriasData) {
      const tempCategorias = categoriasData.categorias
      setCategorias(tempCategorias)
    }

  }, [])
  useLayoutEffect(() => {

    if (categoriasData && buscarFiltro.length <= 0) {
      const tempCategorias = categoriasData.categorias
      setCategorias(tempCategorias)
    }

  }, [buscarFiltro])

  const handleSearch = (e: any) => {


    setBuscarFiltro(e.target.value);
    if (buscarFiltro || buscarFiltro.length > 1) {
      const filteredData: [TCategoriaPastas] = categorias.filter((item: TCategoriaPastas) => {

        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(buscarFiltro.toLowerCase());
      });
      setCategorias(filteredData);
    } else {
      setCategorias(categoriasData.categorias);
    }
  };


  const [abrirCategorias, setAbrirCategorias] = useState<boolean>(false);
  const fechar = () => {
    setAbrirCategorias(false);
  }

  const [ref1, inView1] = useInView()
  const [ref2, inView2] = useInView()
  const scrollRef = useRef<HTMLElement | any>(null)


  const scrollFunction = (direction: 'left' | 'right') => {


    switch (true) {
      case direction === 'right':
        scrollRef.current?.scrollBy({
          left: scrollRef.current.clientWidth,
          top: 0,
          behavior: 'smooth',
        })
        break;
      case direction === 'left':
        scrollRef.current?.scrollBy({
          left: -scrollRef.current.clientWidth,
          top: 0,
          behavior: 'smooth',
        })

    }

  }

 


  return (
    <div >
      <div className='w-full flex flex-row h-fit gap-4 '>
        <ProcessosFerramentas processo={processo} filtroMiddle={filtroMiddle} displayFiltroMiddle={displayFiltroMiddle} />
        <div className='flex flex-row gap-4'>
          <label
            className={displayFiltroMiddle === DisplayMiddle.RESUMO ? 'processoActiveTab' : 'processoIdleTab'}
            onClick={() => filtroMiddle(DisplayMiddle.RESUMO)}
          >
            Resumo
          </label>
          <label
            className={displayFiltroMiddle === DisplayMiddle.VERMAISCPH ?'processoActiveTab' : 'processoIdleTab'}
            onClick={() => filtroMiddle(DisplayMiddle.VERMAISCPH)}

          >
            C.P.H
          </label>

          <div className='relative flex flex-row justify-between '>
            <label
              onClick={() => filtroMiddle(categoria.value)}
              className={
                displayFiltroMiddle !== DisplayMiddle.RESUMO &&
                  displayFiltroMiddle !== DisplayMiddle.CONTATO &&
                  displayFiltroMiddle !== DisplayMiddle.CHAT &&
                  displayFiltroMiddle !== DisplayMiddle.VERMAISCPH
                  ? 'processoActiveTab  flex flex-row relative'
                  : 'processoIdleTab    flex flex-row relative'
              }> <span className='capitalize w-full'>{displayFiltroMiddle === DisplayMiddle.RESUMO || displayFiltroMiddle === DisplayMiddle.VERMAISCPH ? categoria.label.slice(0, 10) : displayFiltroMiddle.slice(0, 10)}</span>  <span onClick={() => setAbrirListaCategorias(current => !current)}>{abrirListaCategorias ? <PiCaretUpThin className='mt-1 font-semibold opacity-75 cursor-pointer' /> : <PiCaretDownThin className='mt-1 font-semibold opacity-75 cursor-pointer' />}</span></label>
            <div className='rounded-lg absolute top-[100%] right-0 z-50 w-full'>
              {
                abrirListaCategorias &&
                <div className='flex flex-col gap-2 min-h-14 max-h-64 min-w-44 overflow-x-hidden overflow-y-auto rounded-lg bg-white chart shadow-xl mt-2'>
                  <label className='w-full inline-flex max-h-11 outline-none border rounded-lgflex flex-row gap-1 sticky top-0 bg-white z-50 mx-2 rounded-lg'>
                    <span className='mt-2'>
                      <BsSearch className='text-sm mt-2 ml-2 opacity-55' />
                    </span>
                    <input type="text" className='border-none bg-none outline-none focus:ring-0 max-w-fit placeholder:opacity-55 text-xsm opacity-70' onChange={handleSearch} placeholder='Buscar' />
                  </label>
                  <label className='border-b border-opacity-75 hover:text-white text-sm cursor-pointer opacity-75 hover:opacity-100 hover:bg-orange-500 w-full px-5' onClick={() => { handleCategoria({ value: 'Documentos', label: 'Documentos' }) }}>
                    Documentos
                  </label>
                  {
                    categorias && categorias.sort((a: any, b: any) => a.label.localeCompare(b.label)).map((catgoriaMap: TCategoriaPastas) => (
                      <label className='selectTabOption' onClick={() => { handleCategoria(catgoriaMap) }}>
                        {catgoriaMap.label}
                      </label>
                    ))
                  }
                </div>
              }
            </div>
          </div>
          
        </div>



        {
          displayFiltroMiddle !== DisplayMiddle.VERMAISCPH && displayFiltroMiddle !== DisplayMiddle.RESUMO && displayFiltroMiddle !== DisplayMiddle.CONTATO && displayFiltroMiddle !== DisplayMiddle.CHAT &&

          <>
            {
              !inView1 &&
              <label className='text-xl mt-2 opacity-75 w-fit cursor-pointer hover:opacity-100' onClick={() => scrollFunction('left')}>
                <RxCaretLeft />
              </label>
            }


            <div className='relative flex flex-row w-full justify-start gap-2 overflow-x-auto  my-1 no-scrollbar' ref={scrollRef}>


              <div ref={ref1}></div>
              {
                categoriasData && categoriasData.filtros.map((categoria: TCategoriaPastas) => (
                  <label key={categoria._id} className={displayFiltroMiddle === categoria.label ? `selectedTab min-w-36 max-w-36` : `idleTab max-w-36 min-w-36`} onClick={() => filtroMiddle(categoria.label)}>{categoria.label.length >= 10
                    ? `${categoria.label.slice(0, 15)}...` : categoria.label} </label>
                ))
              }
              <div ref={ref2}></div>


            </div>
            {
              !inView2 &&
              <label className='text-xl mt-2 opacity-75 w-fit cursor-pointer hover:opacity-100' onClick={() => scrollFunction('right')}>
                <RxCaretRight />
              </label>
            }

          </>
        }



      </div>






    </div>
  );
}

export default TopProcessos;
