import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import { BsBellFill } from 'react-icons/bs';
import NotificacoesCard from '../Notificações/NotificacoesCard';
import { useReactQueryDashboard } from '../../Utils/ReactQuery/getDashboardUtils';
import { useQuery } from '@tanstack/react-query';
import { useUserContext } from '../../Context/useUserContext';
import { TNotifiicacoes } from '../../Utils/types/PortalTypes';

interface NotificaçõesComponentProps {
  isOpen: boolean;
}

const NotificaçõesComponent: React.FC<NotificaçõesComponentProps> = ({ isOpen }) => {
  const [showNotificações, setShowNotificações] = useState<boolean>(false);
  const [userCntext] = useUserContext();
  const notificacoesRef = useRef<HTMLDivElement>(null);

  const empresaPadrão = localStorage.getItem('empresaPadrão');
  const token = localStorage.getItem('token');
  const { getNotificações } = useReactQueryDashboard();

  const { data: notificaçõesData } = useQuery({
    queryFn: () => getNotificações(empresaPadrão as string, token as string),
    queryKey: ['rqNotificações', empresaPadrão],
    staleTime: 1000 * 60 * 3,
  });

  const notificationCount =
    notificaçõesData?.notificações?.filter((i: TNotifiicacoes) =>
      i.usuarios.includes(userCntext.User.id)
    ).length || 0;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (notificacoesRef.current && !notificacoesRef.current.contains(event.target as Node)) {
        setShowNotificações(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [notificacoesRef]);

  return (
    <div className="relative" ref={notificacoesRef}>
      <button
        className={`flex items-center w-full p-2 text-gray-700 rounded-lg hover:bg-gray-100 ${
          !isOpen ? 'justify-center' : ''
        }`}
        onClick={() => setShowNotificações((current) => !current)}
      >
        <div className="relative">
          <BsBellFill className="w-5 h-5 text-gray-500" />
          {notificationCount > 0 && (
            <span
              className={`absolute -top-1 left-4 w-4 h-4 rounded-full flex items-center justify-center bg-red-600 text-white text-xs`}
            >
              {notificationCount}
            </span>
          )}
        </div>
        {isOpen && <span className="ml-3">Notificações</span>}
      </button>

      {/* Exibir o cartão de notificações se estiver aberto */}
      {notificaçõesData && showNotificações && (
        <div className="absolute left-0 mt-2 w-64 bg-white rounded-md shadow-lg z-50">
          <NotificacoesCard
            notificaçõesData={notificaçõesData.notificações || []}
          />
        </div>
      )}
    </div>
  );
};

export default NotificaçõesComponent;
