import { useState, useEffect, useRef } from 'react'
import { BsEye, BsEyeSlash, BsQuestionCircle, BsX } from 'react-icons/bs'
import {IMaskInput } from 'react-imask';
import {checkCpfCnpj} from '../../Utils/checkCpfCnpj'
import { formatCpfCnpj } from '../../Utils/formatCpfCnpj';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {useReactQueryEmpresas} from '../../Utils/ReactQuery/getEmpresas'
import AlertaCadastro from '../Alertas';
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser';
import AcessoHelpModal from '../RegisterComponents/AcessoHelpModal';
import { UserUpdateStatus } from '../../Utils/types/userType';
import userEvent from '@testing-library/user-event';
import { cnpj } from 'cpf-cnpj-validator';
import { toast } from 'react-toastify';
import { useGetAcessos } from '../../Utils/ReactQuery/getAcessos';
import LoadingPage from '../LoadingPage';
import { Link } from 'react-router-dom';



const NovoAcessoAssociarEmpresa = ({user, acessos, close}: any) => {

    const queryClient = useQueryClient();
    const {getEmpresas} = useReactQueryEmpresas();
    const {configurarAcesso} = useGetAcessos();
    
    // const { } = useReactQueryUsers();

    const formRef  = useRef<any>(null);
    const [acessoAtual, setAcessoAtual] = useState<String>('')
    const [buscarEmpresa, setBuscarEmpresa] = useState<string>('');
    const [empresa, setEmpresa] = useState<any>('');
    const [cnpjErro, setCnpjErro] = useState<Boolean>(false);
    const [empresaErro, setEmpresaErro] = useState<Boolean>(false);
    const [cnpjErroMsg, setCnpjMsgError]= useState<String>('');
    const [acesso, setAcesso] = useState<String>('');
    const [acessosState, setAcessosState] = useState<[String]>(['']);
    const [acessoErro, setAcessoErro] = useState<Boolean>(false);
    const [acessoErroMsg, setAcessoErroMsg] = useState<string>('');

  // states auxiliares do react query -----------------
    const [criarAcessoStatus, setCriarAcessoStatus] = useState<UserUpdateStatus>(UserUpdateStatus.IDLE)

  // states auxiliares do react query -----------------


  const {data, isLoading} = useQuery({queryFn: getEmpresas, queryKey: ['rqTodasAsEmpresas']});

  // Tailwind classes --------------------------------------------------------------------------
  const inputFiled: string =` w-full
  block py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer
  `

  const company = `
  text-xl h-35 md:text:md
  border-t-2 border-solid 
    mb-4 text-gray-500 dark:text-gray-400
  `

  const inactiveButton =`disabled text-white bg-blue-400 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center opacity-75 dark:focus:ring-blue-200 my-6`;
  const activeButton =`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 my-6`;
// Tailwind classes --------------------------------------------------------------------------


  useEffect(()=>{
    const acessoAt = user.Acessos;
    setAcessoAtual(acessoAt.TipoAcesso);
    if(acessos){
      const arrAcesso = acessos.Acessos;
      const flatAcessos = arrAcesso.flatMap((i: any)=>{return i.Acessos});
      const arrayObj = flatAcessos.flatMap((i:any)=>{return i.TipoAcesso});
      setAcessosState(arrayObj);
    }
  },[user, acessos])


  const verificarAcesso = (e: any)=>{

    if(acessosState.includes(acesso)){
      setAcessoErro(true);
      setAcessoErroMsg('O usuário já possui o acesso selecionado!');
      return;
    }else{
      setAcessoErro(false);
      setAcessoErroMsg('');
      return;
    }
  }



  const pesquisarEmpresa =(e: any)=>{
      e.preventDefault();
      const empresaData = data.empresas.find((i: any)=>{
          return i.CnpjEmpresa === cnpj.format(buscarEmpresa as string);
      })
      setEmpresa(empresaData);
      setEmpresaErro(false)
      if(!empresaData){
          toast.error('A empresa não foi encontrada!')
          setEmpresaErro(true);
          return;
      }
  }



    // React query ==> Mutação  -----------------------------------
    const novoAcessoMutation = useMutation({
      mutationFn: configurarAcesso,
      onMutate:(config: any): any=>{
        setCriarAcessoStatus(UserUpdateStatus.LOADING)
        // console.log('New empresa mutation iniciada!');
        queryClient.invalidateQueries({queryKey: ['rqUser']});
        queryClient.refetchQueries({queryKey:[ 'rqUser']});
        queryClient.invalidateQueries({queryKey: ['rqAcessos']});
        queryClient.refetchQueries({queryKey:    ['rqAcessos']});

      },
       onSuccess:async(response)=>{
          // console.log('Mutação bem sucedida!: ', response);

          if(response.status === 1){
            setCriarAcessoStatus(UserUpdateStatus.SUCCESS);
            toast.success(`${response.msg}`);
          }else{
            setCriarAcessoStatus(UserUpdateStatus.ERROR);
            toast.error(`${response.msg}`);
          }
      },
       onError: async(response)=>{
          const err = await response;
          // console.log('Erro: ', err);
          setCriarAcessoStatus(UserUpdateStatus.ERROR)
          toast.error(`Erro interno`)

      },
       onSettled: async(response)=>{
            const res = await response;
            // console.log('Mutação concluída!: ', res);
            setCriarAcessoStatus(UserUpdateStatus.IDLE)
            return;
      }   
     });

     // React query ==> Mutação  -----------------------------------

        const handleNovoAcesso= async(e:any)=>{
            e.preventDefault();

            const empresaId = empresa._id
            const token = localStorage.getItem('token')
            novoAcessoMutation.mutate({token: token as string, Action:'novo-cadastro-associar-empresa' ,Empresa: {
                empresaId,
                NovoAcesso: acesso as string
            }})
        }


    if(criarAcessoStatus === UserUpdateStatus.LOADING){
        return(
            <LoadingPage/>
        )
    }

    if(criarAcessoStatus === UserUpdateStatus.SUCCESS){
      return(
        <div className='bg-white rounded-lg shadow dark:bg-gray-700 h-fit p-5 flex flex-col '>

          <div className='w-full text-right '>
            <button onClick={close} className='w-fit cursor-pointer opacity-80 hover:opacity-100 transition-opacity'> <BsX className='text-xl '/> </button>
          </div>

          <div className="relative z-0 w-full  group text-left">
              <div className="max-w-md flex flex-col gap-4 justify-center items-center">
                  <h3 className='text-3xl'> Novo acesso registrado com sucesso! </h3>
                  <Link className='w-fit p-5  bg-white shadow dark:bg-gray-800 rounded-md opacity-80 hover:opacity-100 transition-opacity' to={'/portal/acessos'}> Retornar a página de acessos</Link>
              </div>
          </div>
        </div>
      )
    }


      

  return (
    <form className="w-2/5 mx-auto  flex flex-col px-5 gap-5 " onSubmit={handleNovoAcesso} ref ={formRef}>


      <div className='bg-white rounded-lg shadow dark:bg-gray-700 h-fit p-5 flex flex-col '>

      <div className='w-full text-right '>
        <button onClick={close} className='w-fit cursor-pointer opacity-80 hover:opacity-100 transition-opacity'> <BsX className='text-2xl '/> </button>
      </div>

          <div className="relative z-0 w-full  group text-left">
                <div className="max-w-md">
                    <h3 className='text-xl'> Criar novo acesso para : <span className='font-semibold'>{user.Nome}</span></h3>

                    {acessoErro && <p className='text-red-500'> {acessoErroMsg} </p>}
                    <select onChange={(e)=> setAcesso(e.target.value)} onBlur={verificarAcesso} className='text-black dark:text-black dark:focus:text-black rounded-lg' required  name='Acesso' >
          
                        {acessoAtual !== "Empresa" && <option value={"Empresa"} className='text-black dark:text-black '>  Empresa</option>}
                        <option value={"Administrador"} className='text-black dark:text-black '>  Administrador</option>
                        {acessoAtual !== "Assessoria" && <option value={"Assessoria"} className='text-black dark:text-black '>  Assessoria</option>
                        }
          
                        {acessoAtual !== "Governo" && <option value={"Governo"} className='text-black dark:text-black '>  Governo</option> }
          
                        {acessoAtual !== "Banco" && <option value={"Banco"} className='text-black dark:text-black '>  Banco</option>}
                    </select>
                </div>
            </div>
          
            <div className={`${company}`}>
              <h3 > Informações da empresa </h3>
            </div>
              <div className="relative z-0 w-full mb-5 group text-left ">
                <div className="max-w-md">
                  <div className="mb-2 block">
                      <label className={`text-gray-500 dark:text-gray-40`}>Informe o CNPJ da empresa para solicitar o acesso</label>
                  </div>
                  <div className="relative z-0 w-full mb-5 group text-left ">
                    <IMaskInput
                    name='CnpjEmpresa'
                    mask="00.000.000/0000-00"
                    onAccept={(value, mask)=> setBuscarEmpresa(value)} 
                    className={`${inputFiled}`} placeholder="Cnpj da empresa"  />
                    <button onClick={pesquisarEmpresa}> Pesquisar </button>
                  </div>
                </div>
            </div>
          
            {
            empresa &&
            <div className='bg-gray-600 rounded-md p-4'>
                <div className='w-12 h-12 rounded-full object-contain'>
                    <img className='rounded-full' src={empresa.LogoEmpresa} alt="empresa" />
                </div>
                <h3> Informações da empresa</h3>
                <p> Nome da empresa: {empresa.NomeEmpresa}</p>
                <p> Cnpj: {empresa.CnpjEmpresa}</p>
          
            </div>
            }

            {
              acessoErro || empresaErro ?
              <button type="submit" className={`${inactiveButton}`} disabled={true}>Criar acesso</button>

              :            
              <button type="submit" className={`${activeButton}`}>Criar acesso</button>

            }            
      </div>

  </form>
  )
}

export default NovoAcessoAssociarEmpresa
