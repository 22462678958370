export const formatarData = (date: string): string=>{

    let dias: any = new Date(date).getDate();
    if(dias < 10){
      dias = '0' + dias
    }

    let mes: any = new Date(date).getMonth() + 1;
    if(mes < 10){
      mes = '0' + mes;
    }

    let ano = new Date(date).getFullYear();

    let horas: any = new Date(date).getHours();
    if(horas < 10){
      horas = '0' + horas;
    }
    let minutos: any = new Date(date).getMinutes();
    if(minutos < 10){
      minutos = '0' + minutos;
    }
    
    const dataFormatada = dias + '/' + mes + '/' + ano + ' - ' + horas + ':' + minutos+'h';

    if( isNaN(dias as any) || isNaN(mes as any) ){
      return '---'
    }

    return dataFormatada as string
  }