import React, { useEffect, useLayoutEffect, useState } from 'react';
import CaptacaoFiltro from './CaptacaoFiltro';
import CaptacaoDisplay from './CaptacaoDisplay';
import NovoProcesso from '../ProcessosComponents/NovoProcesso';
import { BsPencil, BsQuestionCircle } from 'react-icons/bs';
import { useUserContext } from '../../Context/useUserContext';
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import LoadingPage from '../LoadingPage';
import { useInView } from 'react-intersection-observer';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import { toast } from 'react-toastify';
import CaptaçãoAcessoAviso from './CaptaçãoAcessoAviso';
import LoadingSmall from '../LoadingSmall';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';

const Captacao = () => {
  const [captacaoManual, setCaptacaoManual] = useState<boolean>(false);
  const [pagina, setPagina] = useState<number>(1);
  const [itensPorPagina, setItensPorPagina] = useState<number>(20);
  const [totalItens, setTotalItens] = useState<number>(0);
  const [permitirCalendario, setPermetirCalendario] = useState<boolean>(false);

  const permissaoCalendario = () => {
    setPermetirCalendario (current => !current);
  }

  // Paginator state variables
  const [first, setFirst] = useState<number>((pagina - 1) * itensPorPagina);
  const [rows, setRows] = useState<number>(itensPorPagina);

  const { ref, inView } = useInView();

  const token = localStorage.getItem('token');
  const empresaSelecionada = localStorage.getItem('empresaSelecionada');
  const { getArmazenamentoEmpresa, alterarEmpresa, selecionarEmpresa } =
    useReactQueryEmpresas();

  const { data: armazenamentoData, isLoading } = useQuery({
    queryFn: () =>
      getArmazenamentoEmpresa(token as string, empresaSelecionada as string),
    queryKey: ['rqArmazenamentoEmpresa'],
    refetchOnWindowFocus: false,
  });

  const fechar = () => {
    setCaptacaoManual(false);
  };

  const [showTooltip, setShowTooltip] = useState(false);

  const [userContext] = useUserContext();
  const cnaePrincipal = localStorage.getItem('cnaePrincipal');
  const [filtroAtual, setFiltroAtual] = useState<string>(
    encodeURI(cnaePrincipal as string) || ''
  );

  const { getCaptacaoAutomatica } = useReactQueryCaptacao();

  const {
    data: captacaoData,
    isLoading: captacaoIsLoading,
    refetch,
  } = useQuery({
    queryFn: () =>
      getCaptacaoAutomatica({
        descrição: filtroAtual as string,
        pagina: pagina,
        tamanhoPagina: itensPorPagina,
      }),
    queryKey: ['rqCaptacao', filtroAtual, pagina, itensPorPagina],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
  });

  const {
    data: filtroData,
    isLoading: filtroisLoading,
  } = useQuery({
    queryFn: () =>
      selecionarEmpresa({
        empresaid: empresaSelecionada as string,
        token: token as string,
      }),
    queryKey: ['rqFiltroCaptacao'],
  });

  useEffect(() => {
    if (captacaoData) {
      setTotalItens(captacaoData.total);
    }
  }, [captacaoData]);

  useLayoutEffect(() => {
    const filtroEmpresa = localStorage.getItem(`${empresaSelecionada}-filtro`);

    if (filtroEmpresa) {
      setFiltroAtual(filtroEmpresa);
    } else {
      setFiltroAtual(cnaePrincipal as string);
    }
  }, []);

  // Mutation for altering filter
  const queryClient = useQueryClient();
  const alterarFiltroMutaton = useMutation({
    mutationFn: getCaptacaoAutomatica,
    onMutate: (config) => {},
    onSuccess: async (response) => {
      // console.log('Response da atualização do filtro: ', response);
      queryClient.invalidateQueries({ queryKey: ['rqCaptacao'] });
      queryClient.refetchQueries({ queryKey: ['rqCaptacao'] });
    },
    onError: (error: any) => {
      console.error(error.message);
    },
    onSettled: (data) => {},
  });

  const alterarFiltro = (filtro: string, save: false) => {
    // console.log('Save: ', save);

    if (save) {
      localStorage.setItem(`${empresaSelecionada}-filtro`, filtro);
    }

    if (filtro && filtro.length > 1) {
      setFiltroAtual(filtro);
    } else {
      setFiltroAtual(cnaePrincipal as string);
    }
  };

  // Paginator onPageChange handler with explicit event type
  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFirst(event.first);
    setRows(event.rows);
    setPagina(Math.floor(event.first / event.rows) + 1);
    setItensPorPagina(event.rows);
  };

  // Tailwind CSS classes
  const activePage =
    'w-full min-h-screen grid grid-cols-[300px_minmax(400px,_1fr)]';
  const inactivePage =
    'w-full min-h-screen grid grid-cols-[300px_minmax(400px,_1fr)] blur-sm';

  if (armazenamentoData && armazenamentoData.planoDescrição === 'inativo') {
    return <CaptaçãoAcessoAviso />;
  }
  if (armazenamentoData && armazenamentoData.planoDescrição === 'visitante') {
    return <CaptaçãoAcessoAviso />;
  }

  return (
    <div className='w-full h-fit bg-white border-r border-l '>
      
      {captacaoManual && <NovoProcesso fechar={fechar} />}

      <div className={captacaoManual ? inactivePage : activePage}>
        <div className='flex flex-col gap-2 '>
          <div className='w-full flex flex-col pl-6 pr-4 mb-1 py-2'>
            <div className='flex justify-center'>
              <h3 className='text-xl font-extrabold text-gray-500 pr-4 dark:text-white'>
                Captação automática
              </h3>
              <div className='flex items-center pt-2'>
                <BsQuestionCircle
                  className='text-gray-700 dark:text-gray-400 cursor-pointer'
                  onClick={() => setShowTooltip((current) => !current)}
                />
                {showTooltip && (
                  <div className='absolute top-44 left-32 mt-2 z-50 max-w-xs w-48'>
                    <div className='bg-gray-100 dark:bg-gray-800 rounded-lg p-2 shadow-lg'>
                      <p className='text-[0.7rem] font-semibold text-gray-700 dark:text-gray-300'>
                        Processos encontrados no PNCP de acordo com a atividade
                        econômica primária da empresa (CNAE). Total de editais
                        encontrados: {totalItens}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='flex flex-col items-start justify-center mr-11 ml-8 '>
            <label
              className='capBuscarButton'
              onClick={() => setCaptacaoManual(true)}
            >
              <BsPencil className='mr-2' />
              Registro Manual
            </label>
          </div>
          <CaptacaoFiltro alterarFiltro={alterarFiltro} permissaoCalendario={permissaoCalendario}/>
        </div>

        <div className='flex flex-col gap-2 mr-8'>
          <div className='overflow-y-auto flex flex-col relative chart h-[45.5em] '>
            {captacaoData && !isLoading ? (
              <div className='flex flex-col justify-between '>
                <CaptacaoDisplay permitirCalendario={permitirCalendario} editais={captacaoData} />
              </div>
            ) : (
              <div className='flex flex-col items-center'>
                <div className='text-center text-sm text-gray-500 mb-2'>
                  Carregando mais processos...
                </div>
                <i
                  className='pi pi-spin pi-cog text-orange-400'
                  style={{ fontSize: '2rem' }}
                ></i>
              </div>
            )}
            {captacaoData && captacaoData.items.length <= 1 && (
              <h3> Nenhum edital encontrado na busca!</h3>
            )}
          </div>
          {/* Paginator Component with updated template */}
          <Paginator
            first={first}
            rows={rows}
            totalRecords={totalItens}
            onPageChange={onPageChange}
            rowsPerPageOptions={[10, 20, 50]}
            template='PrevPageLink PageLinks NextPageLink RowsPerPageDropdown'
          />
        </div>
      </div>
    </div>
  );
};

export default Captacao;
