import React, { Suspense } from 'react'
import Login from '../Components/HomeComponents/Login'

type Props = {}

const Home = (props: Props) => {

  return (
    <div className='homePage bg-gray-50 dark:bg-gray-900 h-screen min-h-dvh'>

        <Suspense fallback ={<p> Carregando ...</p>}>
          <Login />
        </Suspense>

    </div>
  )
}

export default Home