import React, { useState } from 'react'
import { BsQuestionCircle } from 'react-icons/bs';
import AcessosHelp from '../Components/AcessosComponents/AcessosHelp';
import NovoAcessoAssociarEmpresa from '../Components/AcessosComponents/NovoAcessoAssociarEmpresa'
import NovoAcessoCriarEmpresa from '../Components/AcessosComponents/NovoAcessoCriarEmpresa';
import AcessoHelpModal from '../Components/RegisterComponents/AcessoHelpModal';
import { useQuery } from '@tanstack/react-query';
import { useGetAcessos } from '../Utils/ReactQuery/getAcessos';

type Props = {}

const CriarAcesso = ({user}: any) => {

  const {listarAcessos} = useGetAcessos();

  const {data: acessos, isLoading: isLoadingAcessos} = useQuery({
      queryFn: ()=> listarAcessos(user.CpfCnpj),
      queryKey: ["rqAcessos", user]
  })

  enum CriarAcesso{
    NOVAEMPRESA = 'novaempresa',
    ASSOCIAR = 'associar',
    IDLE = 'idle'
  } 
  
  const [criarcessoTipo, setCriarAcessoTipo] = useState<CriarAcesso>(CriarAcesso.IDLE);
  const [acessoAjuda, setAcessoAjuda] = useState<boolean>(false);

  const close = (e: any)=>{
    e.preventDefault()  
    setCriarAcessoTipo(CriarAcesso.IDLE)
  
  }

  const closeHelp = (e: any)=>{
    e.preventDefault();
    setAcessoAjuda(false);
  }

  return (
    <div>

      {
        criarcessoTipo === CriarAcesso.IDLE &&

        <div className='className="overflow-y-auto overflow-x-hidden  w-full md:inset-0 h-screen max-h-full flex flex-col justify-center items-center '>
        <div className='w-3/4 flex flex-col justify-around gap-8 bg-white rounded-lg shadow dark:bg-gray-700 min-h-56 p-5 max-w-lg'>
          
          <h3 className='text-3xl'> Selecione como deseja criar a nova forma de acesso </h3>

          <div className='flex flex-col gap-5 items-center justify-center '>
            <button onClick={()=> setCriarAcessoTipo(CriarAcesso.ASSOCIAR)} className=" max-w-md inline-flex items-center justify-between z-10 w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"> Associar o novo acesso a uma empresa já existente </button>
            <button onClick={()=> setCriarAcessoTipo(CriarAcesso.NOVAEMPRESA)} className=" max-w-md inline-flex items-center justify-between z-10 w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"> Criar uma nova empresa para o novo acesso </button>
          </div>
          
          <span className='text-lg flex flex-row gap-4 opacity-50 hover:opacity-100 transition-opacity w-fit cursor-pointer' onClick={()=> setAcessoAjuda(current => !current)}> <BsQuestionCircle className='mt-1'/> Ajuda </span>
        </div>

        {
          acessoAjuda && <AcessosHelp close ={closeHelp}/>
        }

      </div>
      }

        
      {
          criarcessoTipo === CriarAcesso.ASSOCIAR &&
          <div className='w-full min-h-screen flex flex-col justify-around items-center'>
        {
            <NovoAcessoAssociarEmpresa user ={user} acessos ={acessos} close ={close}/>
        }
        </div>
      }
      
      {
        criarcessoTipo === CriarAcesso.NOVAEMPRESA  &&
        <div className='w-full min-h-screen flex flex-col justify-around items-center'>
          {
              <NovoAcessoCriarEmpresa user ={user} acessos ={acessos} close ={close}/>
          }
        </div>
      }
    </div>
  )
}

export default CriarAcesso