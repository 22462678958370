import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import ErrorPage from '../Pages/ErrorPage';
import { useReactQueryUsers } from '../Utils/ReactQuery/getUser';
import AlertaCadastro from './Alertas';
import LoadingPage from './LoadingPage';

type Props = {}

const PrivateRoute = (props: Props) => {
    const {getUser} = useReactQueryUsers()
    const token = localStorage.getItem("token");
    const {data, isLoading, isError, isSuccess} = useQuery({queryFn: ()=> getUser(token as string), queryKey: ['rqUser'], staleTime: 1000*60*60});


    if( !data && isLoading){
        return(
            <LoadingPage/>
        )
    }

    return (
        data && data.user ? <Outlet/>: 
        <>
         <AlertaCadastro message={data ? data.msg: ''} state ='error'/>
         <ErrorPage message={data ? data.msg : ''}/>    
        </>
    )
    
}

export default PrivateRoute