import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { BsDash, BsFileEarmarkSpreadsheet, BsNodeMinus, BsPencil, BsPlus, BsQuestionCircle, BsX } from 'react-icons/bs';
import { IMaskInput } from 'react-imask';
import { toast } from 'react-toastify';

import { useUserContext } from '../../Context/useUserContext';
import { getCnpjData } from '../../Utils/cnpjAutocomplete';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';

import { InputUpdateStatus, UserUpdateStatus } from '../../Utils/types/userType';
import LoadingPage from '../LoadingPage';
import IncriçãoMunicipalHelp from './IncriçãoMunicipalHelp';
import styles from './Perfil.module.css'
import AlterarLogoEmpresa from './AlterarLogoEmpresa';
import { TAtividadeEconomicaS, TAtividaEconomicaP } from '../../Utils/types/empresaTypes';
import { useGetPerissions } from '../../Utils/verificarAcesso';


const EmpresaConfig = ({ user }: any) => {

  const [userContext, setUserContext] = useUserContext();
  const solicitação = userContext.Solicitações;
  const permissão = userContext.userContext;
  const [admin, setAdmin] = useState<Boolean | null>(false)
  const [solicitado, setSolicitado] = useState<Boolean | null>(null);
  const [acessoAtual, setAcessoAtual] = useState<string>('')


  const { verifyAdm } = useGetPerissions(userContext);
  const result = verifyAdm();


  const [editar, setEditar] = useState<boolean>(false);

  useEffect(() => {

    if (solicitação) {
      setSolicitado(solicitação)
    }
  })

  // Acesso

  const [acesso, setAcesso] = useState<String>(user.Acessos.TipoAcesso || '');
  useEffect(() => {
    const acessoData = user.Acessos.TipoAcesso;
    setAcesso(acessoData);
    setAcessoAtual(user.AcessoAtual)

  }, [user])


  const { selecionarEmpresa } = useReactQueryEmpresas()

  const empresaPadrão = localStorage.getItem('empresaPadrão');
  const token = localStorage.getItem('token');
  const { data: empresaData, isLoading: empresaIsLoading } = useQuery({
    queryFn: () => selecionarEmpresa({ empresaid: empresaPadrão as string, token: token as string }),
    queryKey: ['rqEmpresaInfo']
  })

  if (empresaData)
    console.log('Empresa data: ', empresaData)

  const { alterarEmpresa } = useReactQueryEmpresas();
  const [loadingAutocomplete, setLoadingAutoComplete] = useState<Boolean>(false);

  //--- Validações padrão-------
  const [cnpj, setCnpj] = useState<String>('');
  const [helpIncriçãoMunicipal, setHelpInscriçãoMunicipal] = useState<Boolean>(false);
  const [alterarLogoEmpresa, setAlterarLogoEmpresa] = useState<boolean>(false);
  const [updateStatus, setUpdateStatus] = useState<InputUpdateStatus>(InputUpdateStatus.IDLE);
  const [cnpjData, setCnpjData] = useState<any>()
  const [razaoSocial, setRazaoSocial] = useState<String>('');
  const [nomeFantasia, setNomeFantasia] = useState<String>('');
  const [nire, setNire] = useState<String>('');
  const [naturezaJuridica, setNaturezaJuridica] = useState<String>('');
  const [mei, setMEI] = useState<Boolean>(false);
  const [portedaEmpresa, setPortedaEmpresa] = useState<String>('');

  const [capitalSocial, setCapitalSocial] = useState<String>('');
  const [inscriçãoMunicipal, setInscriçãoMunicipal] = useState<String>('');
  const [inscriçãoEstadual, setInscriçãoEstadual] = useState<String>('');
  const [dataAbertura, setDataAbertura] = useState<String>('');
  const [endereçoEmpresa, setEndereçoEmpresa] = useState<String>('');
  const [complementoEmpresa, setComplementoEmpresa] = useState<String>();
  const [cepEmpresa, setCepEmpresa] = useState<String>('');
  const [foneEmpresa, setFoneEmpresa] = useState<String>('');
  const [emailEmpresa, setEmailEmpresa] = useState<String>('');
  const [siteEmpresa, setSiteEmpresa] = useState<String>('');


  enum StatusTabs {
    IDLE = 'inalterado',
    ALTERADO = 'alterado',
    ERRO = 'erro'
  }
  enum SituaçãoFinanceira {
    POSITIVO = 'Positivo',
    NEGATIVO = 'Negativo',
    IDLE = ''
  }
  const [situaçãoFinanceira, setSituaçãoFinanceira] = useState<SituaçãoFinanceira>(SituaçãoFinanceira.IDLE);

  const [ativEconSCodigoTemp, setAtivEconSCodigoTemp] = useState<string>('');
  const [ativEconSDescTemp, setAtivEconSDescTemp] = useState<string>('');
  const [ativEconPCodigoTemp, setAtivEconPCodigoTemp] = useState<string>('');
  const [ativEconPDescTemp, setAtivEconPDescTemp] = useState<string>('');
  const [atividadeEconômicaP, setAtividadeEconômicaP] = useState<TAtividaEconomicaP>();
  const [atividadeEconômicaS, setAtividadeEconômicaS] = useState<any>(['']);
  const [ativEconSSelec, setAtivEconSSelec] = useState<string>();

  const atvEconSTempCodRef = useRef<HTMLInputElement | any>(null);
  const atvEconSTempDescRef = useRef<HTMLInputElement | any>(null);


  const inserirAtividadeEconomicaS = (e: any) => {

    e.preventDefault();

    if (!ativEconSCodigoTemp ||
      !ativEconSDescTemp) {
      toast.error("E necessário inserir um codigo e uma descrição para adicionar um CNAE")
      return;
    }

    const checkAtv = atividadeEconômicaS.filter((i: any) => {
      return i.codigo === ativEconSCodigoTemp
    })

    if (checkAtv.length >= 1) {
      toast.error('CNAE ja cadastrado!');
      return;
    }

    const atividadeEconomicaSTemp = {
      codigo: ativEconSCodigoTemp,
      descricao: ativEconSDescTemp
    }


    setAtividadeEconômicaS([...atividadeEconômicaS, atividadeEconomicaSTemp as any])
    atvEconSTempCodRef.current.value = '';
    atvEconSTempDescRef.current.value = '';
    setAtivEconSCodigoTemp('');
    setAtivEconSDescTemp('');

    return;
  }



  const inserirAtivEconP = (e: any) => {
    e.preventDefault();

    if (!ativEconPCodigoTemp ||
      !ativEconPDescTemp) {
      toast.error("E necessário inserir um codigo e uma descrição para adicionar um CNAE")
      return;
    }

    setAtividadeEconômicaP({
      codigo: ativEconPCodigoTemp,
      descricao: ativEconPDescTemp
    })

    setAtivEconPCodigoTemp('');
    setAtivEconPDescTemp('');
  }


  const removerAtvEconS = (e: any) => {

    e.preventDefault();
    if (!ativEconSSelec) {
      return
    }

    const atividadesEconSTemp = atividadeEconômicaS.filter((i: any) => {
      return i.codigo !== ativEconSSelec
    })

    setAtividadeEconômicaS(atividadesEconSTemp);
    return;
  }


  //---- Mutação ---------------------
  const queryClient = useQueryClient();
  const updateEmpresaMutation = useMutation({
    mutationFn: alterarEmpresa,
    onMutate: async (config) => {
      setUpdateStatus(InputUpdateStatus.LOADING);
    },
    onSuccess: async (response) => {
      queryClient.invalidateQueries({ queryKey: ['rqEmpresa'] });
      queryClient.refetchQueries({ queryKey: ['rqEmpresa'] });
      if (response.status === 1) {

        setUpdateStatus(InputUpdateStatus.SUCCESS);
        toast.success(response.msg, { autoClose: 3000 });
      }
      if (response.status === 3) {
        setUpdateStatus(InputUpdateStatus.ERROR);
        toast.error(response.msg, { autoClose: 3000 })
      }
    },
    onError: (error) => {
      console.error(error);
      setUpdateStatus(InputUpdateStatus.ERROR);
      toast.error('Erro interno!', { autoClose: 5000 })
    },
    onSettled: async (response) => {
      // console.log('Mutação concluída!')
    }
  })

  //---- Mutação ---------------------]



  // Funções utilitárias ------------------------------------------------------------------

  const handleUserUpdate = (e: any) => {
    e.preventDefault();


    const formData = new FormData(e.currentTarget);
    const updateData = {
      CnpjEmpresa: formData.get('CnpjEmpresa'),
      NomeEmpresa: formData.get('NomeEmpresa'),
      RazãoSocial: formData.get('RazãoSocial'),
      NomeFantasia: formData.get('NomeFantasia'),
      LogoEmpresa: formData.get('LogoEmpresa'),
      Nire: formData.get('Nire'),
      NaturezaJuridica: formData.get('NaturezaJuridica'),
      PorteEmpresa: formData.get('PorteEmpresa'),
      CapitalSocial: formData.get('CapitalSocial'),
      SituaçãoFinanceira: formData.get('SituaçãoFinanceira'),
      InscriçãoMunicipal: formData.get('InscriçãoMunicipal'),
      InscriçãoEstadual: formData.get('InscriçãoEstadual'),
      Site: formData.get('Site'),
      DataAbertura: formData.get('DataAbertura'),
      MEI: mei,
      Fone: formData.get('FoneEmpresa'),
      Cep: formData.get('Cep'),
      Complemento: formData.get('Complemento'),
      Endereço: formData.get('Endereço'),
      AtividadeEconômicaP: atividadeEconômicaP,
      AtividadeEconômicaS: atividadeEconômicaS,

    }

    const dataLength = Object.values(updateData).filter((i: any) => i !== "").length

    if (dataLength <= 0) {
      setUpdateStatus(InputUpdateStatus.ERROR);
      toast.error('Não são permitidos envios vazios!')
      return;
    }

    const filteredEmpresa = Object.fromEntries(Object.entries(updateData).filter(([key, value]) => value !== "" && value !== null));



    const token = localStorage.getItem('token')
    updateEmpresaMutation.mutate({ token: token as string, data: filteredEmpresa, empresaId: empresaPadrão as string })

  }
  //-------------------------
  const inputField = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
  const inactiveField = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-none block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
  const lowKeyButton = `text-md h-35 md:text:sm
    mb-4 text-gray-500 dark:text-gray-400 opacity-80 hover:opacity-100 transition-opacity`


  // Tailwind -----------------

  // //~~MANUTENÇÃO~~//
  // useEffect(()=>{
  //   console.log('uuser atual: ',acessoAtual)
  // },[acessoAtual])
  // //~~MANUTENÇÃO~~//

  useEffect(() => {
    if (empresaData) {


      setAtividadeEconômicaS(empresaData.empresa.AtividadeEconômicaS);

    }
  }, [empresaData])


  const verifyCnpj = async (e: any) => {
    e.preventDefault();

    if (cnpj.length < 14) {
      setLoadingAutoComplete(false);
      return;
    }

    setLoadingAutoComplete(true);
    setUpdateStatus(InputUpdateStatus.LOADING)


    const res = await getCnpjData(cnpj as string);

    if (res === null) {
      setLoadingAutoComplete(false);
      return;
    }

    setCnpjData(res);
    setNaturezaJuridica(res.natureza_juridica);
    setCapitalSocial(res.capital_social);
    setRazaoSocial(res.razao_social);
    setNomeFantasia(res.nome_fantasia);
    setDataAbertura(res.data_inicio_atividade);
    setEndereçoEmpresa(`${res.logradouro}, ${res.municipio}, ${res.uf}`);
    setComplementoEmpresa(res.complemento);
    setCepEmpresa(res.cep);
    setFoneEmpresa(res.ddd_telefone_1);
    setPortedaEmpresa(res.porte)
    setMEI(res.data_opcao_pelo_mei)
    setLoadingAutoComplete(false)
    setUpdateStatus(InputUpdateStatus.IDLE)



    setAtividadeEconômicaP({
      codigo: res.cnae_fiscal,
      descricao: res.cnae_fiscal_descricao
    })

    setAtividadeEconômicaS(res.cnaes_secundarios)
    return;



  }

  if (updateStatus === InputUpdateStatus.LOADING) {
    return (
      <LoadingPage />
    )
  }


  const close = (e: any) => {
    e.preventDefault()
    setHelpInscriçãoMunicipal(false)
    setAlterarLogoEmpresa(false)
  }


  if (solicitado) {
    return (
      <h3> Aguarde...</h3>
    )
  }

  return (
    <div className='w-full flex flex-col bg-white rounded-xl p-5 mb-5 shadow-xl'>

      {
        alterarLogoEmpresa && <AlterarLogoEmpresa empresa={empresaPadrão} fechar={close} />
      }

      {
        helpIncriçãoMunicipal && <IncriçãoMunicipalHelp close={close} />
      }



      <form onSubmit={handleUserUpdate} className='border-b-2 border-white border-solid' >

        <div className={`${styles.inputField}`}>
          <div>
            <div className='w-20 h-20 rounded-full object-contain'>
              <img src={empresaData && empresaData.empresa.LogoEmpresa ? empresaData.empresa.LogoEmpresa : 'https://portalglc.s3.sa-east-1.amazonaws.com/res/empresa.jpg'} alt="Perfil..." className='w-full h-auto rounded-full' />

            </div>
            {editar && <label className='text-xl pt-2 opacity-80 hover:opacity-100 transition-opacity cursor-pointer' onClick={() => setAlterarLogoEmpresa(true)}> Alterar Logo da empresa</label>}
          </div>



        </div>

        <div className={`grid sm:grid-cols-2 grid-cols gap-4 my-4 h-fit`}>

          <div className={`${styles.inputField}`} >
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome da empresa</label>
            <input type="text" name="NomeEmpresa" className={`${inputField}`} placeholder={empresaData && `${empresaData.empresa.NomeEmpresa}`} readOnly={!editar ? true : false} />
          </div>

          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cnpj da empresa</label>
            <input type="text" name="CnpjEmpresa" className={`${inputField}`} placeholder={empresaData && `${empresaData.empresa.CnpjEmpresa}`} readOnly={!editar || loadingAutocomplete ? true : false} onChange={(e) => setCnpj(e.target.value)} />
            <div className="relative z-0 w-full mb-5 group text-left ">
              <button className={`${lowKeyButton} mt-2`} onClick={verifyCnpj}> Buscar dados do CNPJ </button>
            </div>
            {loadingAutocomplete && <p className='text-white'> Aguarde, buscando dados ...</p>}
          </div>

          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Razão social</label>
            <input type="text" name="RazãoSocial" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.RazãoSocial ? `${empresaData.empresa.RazãoSocial}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={razaoSocial as string} onChange={(e) => setRazaoSocial(e.target.value)} />
          </div>

          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Telefone da empresa </label>

            <IMaskInput type="text" name="FoneEmpresa" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.Fone ? `${empresaData.empresa.Fone}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={foneEmpresa as string}
              mask={'(00)-00000000'}
              onAccept={(value, mask) => setFoneEmpresa(value)}
            />
            <small> Informe o ddd, ex: (62)1234567</small>
          </div>

          {
            acessoAtual === "Empresa" || acessoAtual === "Administrador" || acessoAtual === "Assessoria" || acessoAtual == "Acessoria" ?

              <>
                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Natureza jurídica</label>
                  <input type="text" name="NaturezaJuridica" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.NaturezaJuridica ? `${empresaData.empresa.NaturezaJuridica}` : 'Campo incompleto!'} readOnly={user.Cadastro === "Colaborador" ? true : false} value={naturezaJuridica as string} onChange={(e) => setNaturezaJuridica(e.target.value)} />
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Capital social</label>
                  <input type="text" name="CapitalSocial" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.CapitalSocial ? `${empresaData.empresa.CapitalSocial}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={capitalSocial as string} onChange={(e) => setCapitalSocial(e.target.value)} />
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome fantasia</label>
                  <input type="text" name="NomeFantasia" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.NomeFantasia ? `${empresaData.empresa.NomeFantasia}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={nomeFantasia as string} onChange={(e) => setNomeFantasia(e.target.value)} />
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Porte</label>
                  <input type="text" name="PorteEmpresa" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.PorteEmpresa ? `${empresaData.empresa.PorteEmpresa}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={portedaEmpresa as string} onChange={(e) => setPortedaEmpresa(e.target.value)} />
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data da abertura</label>
                  <input type="text" name="DataAbertura" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.DataAbertura ? `${empresaData.empresa.DataAbertura}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={dataAbertura as string} onChange={(e) => setDataAbertura(e.target.value)} />
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Situação financeira  </label>
                  <select className='text-black dark:text-black rounded-md bg-white  cursor-pointer opacity-80 hover:opacity-100 transition-opacity' name="SituaçãoFinanceira" onChange={(e) => setSituaçãoFinanceira(e.target.value as SituaçãoFinanceira)} disabled={editar ? false : true}>
                    <option className='rounded-sm' defaultChecked={true} value={SituaçãoFinanceira.POSITIVO}>Positivo </option>
                    <option className='rounded-sm' value={SituaçãoFinanceira.NEGATIVO}>Negativo </option>
                  </select>
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Inscrição municipal  </label>
                  <input type="text" name="InscriçãoMunicipal" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.InscriçãoMunicipal ? `${empresaData.empresa.InscriçãoMunicipal}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={inscriçãoMunicipal as string} onChange={(e) => setInscriçãoMunicipal(e.target.value)} />
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Inscrição estadual  </label>
                  <input type="text" name="InscriçãoEstadual" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.InscriçãoEstadual ? `${empresaData.empresa.InscriçãoEstadual}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={inscriçãoEstadual as string} onChange={(e) => setInscriçãoEstadual(e.target.value)} />
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> NIRE  </label>
                  <input type="text" name="Nire" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.Nire ? `${empresaData.empresa.Nire}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={nire as string} onChange={(e) => setNire(e.target.value)} minLength={11} maxLength={11} />
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cep</label>

                  <IMaskInput
                    mask={'000.000-00'}
                    value={cepEmpresa as string}
                    onAccept={(value, mask) => setCepEmpresa(value)}
                    name="Cep" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.Cep ? `${empresaData.empresa.Cep}` : 'Campo incompleto!'} readOnly={!editar ? true : false}
                  />
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Endereço</label>
                  <input type="text" name="Endereço" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.Endereço ? `${empresaData.empresa.Endereço}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={endereçoEmpresa as string} onChange={(e) => setEndereçoEmpresa(e.target.value)} />
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Complemento </label>
                  <input type="text" name="Complemento" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.Complemento ? `${empresaData.empresa.Complemento}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={complementoEmpresa as string} onChange={(e) => setComplementoEmpresa(e.target.value)} />
                </div>

                <div className={`${styles.inputField}`} >
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">  Registrado como Mei </label>


                  {
                    editar ?
                      <div className='flex flex-row gap-2'>
                        <div className='flex flex-row gap-2'>
                          <label htmlFor='meiSim' className="cursor-pointer block mb-2 text-sm font-medium text-gray-900 dark:text-white">  Sim</label>
                          <input className='cursor-pointer' type="radio" name="Mei" id="meiSim" onChange={(e) => {
                            !e.target.checked ? setMEI(false) : setMEI(true)
                          }} defaultChecked={empresaData ? empresaData.empresa.MEI : false} />
                        </div>
                        <div className='flex flex-row gap-2'>

                          <label htmlFor='meiNão' className="cursor-pointer block mb-2 text-sm font-medium text-gray-900 dark:text-white" >  Não</label>
                          <input className='cursor-pointer' type="radio" name="Mei" id="meiNão" defaultChecked={true} onChange={(e) => {
                            e.target.checked ? setMEI(false) : setMEI(true)
                          }} />

                        </div>
                      </div>
                      :
                      <div>
                        {empresaData && empresaData.empresa.MEI ? <p>Sim</p> : <p>Não</p>}
                      </div>
                  }

                </div>
              </>
              :
              <></>
          }

          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Site da empresa  </label>
            <input type="text" name="Site" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.Site ? `${empresaData.empresa.Site}` : 'Campo incompleto!'} readOnly={!editar ? true : false} value={siteEmpresa as string} onChange={(e) => setSiteEmpresa(e.target.value)} />
          </div>

        </div>

        <div className='grid grid-cols-1 md:grid-cols-2  text-sm font-medium text-gray-900 dark:text-white'>
          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Atividade econômica primária </label>

            {
              empresaData && empresaData.empresa.AtividadeEconômicaP ?
                <label className={inputField}> {empresaData && empresaData.empresa.AtividadeEconômicaP.descricao} ({empresaData.empresa.AtividadeEconômicaP.codigo}) </label> :
                <label className={`${inputField}`}> Não foi informado nenhuma atividade econômica primária!</label>
            }

            {
              editar &&
              <>
                <label htmlFor=""> Código</label>
                <input type="number" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.AtividadeEconômicaP ? `${empresaData.empresa.AtividadeEconômicaP.codigo}` : 'Campo incompleto!'} readOnly={!editar ? true : false} onChange={(e) => setAtivEconPCodigoTemp(e.target.value)} />


                <label htmlFor=""> Descrição</label>
                <input type="text" className={`${inputField}`} placeholder={empresaData && empresaData.empresa.AtividadeEconômicaP ? `${empresaData.empresa.AtividadeEconômicaP.descricao}` : 'Campo incompleto!'} readOnly={user.Cadastro === "Colaborador" ? true : false} onChange={(e) => setAtivEconPDescTemp(e.target.value)} />
              </>
            }


            {
              editar &&
              <div className='flex flex-col'>
                <button className='inline-flex opacity-80 hover:opacity-100 cursor-pointer transition-opacity' onClick={inserirAtivEconP}><BsPlus className='text-xl mr-2' /> Alterar atividade econômica principal</button>
                <small> Este campo altera a busca automatica de editais!</small>
              </div>

            }

          </div>



          <div className={`${styles.inputField}`} >
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Atividade econômica Secundária </label>

            <select className={inputField} onChange={(e) => setAtivEconSSelec(e.target.value)} disabled={editar ? false : true}>
              <option value="" className={inputField}> Lista de atividades econômicas secundárias (cnaes)</option>

              {
                atividadeEconômicaS && atividadeEconômicaS.map((atividade: TAtividaEconomicaP) => (
                  <option value={atividade.codigo} className='inline-flex'>{atividade.descricao} ({atividade.codigo})</option>
                ))
              }

            </select>




            {
              editar &&
              <>
                <label htmlFor=""> Código</label>
                <input type="number" className={`${inputField}`} onChange={(e) => setAtivEconSCodigoTemp(e.target.value)} ref={atvEconSTempCodRef} />

                <label htmlFor=""> Descrição</label>
                <input type="text" className={`${inputField}`} onChange={(e) => setAtivEconSDescTemp(e.target.value)} ref={atvEconSTempDescRef} />

                <button className='inline-flex opacity-80 hover:opacity-100 cursor-pointer transition-opacity' onClick={inserirAtividadeEconomicaS}><BsPlus className='text-xl mr-2' /> Adicionar atividade economica secundaria</button>

                {
                  ativEconSSelec && ativEconSSelec.length >= 1 &&
                  <button className='inline-flex opacity-80 hover:opacity-100 cursor-pointer transition-opacity' onClick={removerAtvEconS} ><BsDash className='text-xl mr-2' /> Remover atividade economica secundaria</button>
                }
              </>
            }

          </div>


        </div>

        <div className='w-fit max-w-xl'>

          {
            result ? !editar ?
              <button className="confirmButton my-2" onClick={() => setEditar(current => !current)}>
                <BsPencil className='text-3md mr-4' /> <span className='text-md'>Alterar informações</span>
              </button>
              :

              <div className='flex flex-row gap-4 my-2'>
                <button type="submit" className="confirmButton">
                  Alterar informações do usuário
                </button>

                <button className="closeButton" onClick={() => setEditar(current => !current)}>
                  <BsX className='text-xl mr-4' /> <span> Cancelar</span>
                </button>
              </div>
              :
              <label className='mt-2'> Somente administradores da empresa podem alterar estas infrmações.</label>


          }


        </div>




      
      </form>

    </div>
  )
}


export default EmpresaConfig