import { url } from "inspector";
import { TItensData } from "../types/PortalTypes";

export const useReactQueryCaptacao = () => {
  const pncpBaseUrl = "https://pncp.gov.br/api/search";
  const pncpBaseApiUrl = "https://pncp.gov.br/api/pncp/v1/orgaos";

  const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL;

  type TCaptacaoFiltros = {};

  type TCaptacaooAutomatica = {
    descrição: string;
    url?: string;
    filros?: TCaptacaoFiltros;
    pagina: number;
    tamanhoPagina: number;
  };

  const getCaptacaoAutomatica = async (data: TCaptacaooAutomatica) => {
    try {
      if (data.url) {
        const res = await fetch(data.url);
        const response = await res.json();
        return response;
      }

      const url = `${pncpBaseUrl}/?q=${data.descrição}&tipos_documento=edital&ordenacao=-data&pagina=${data.pagina}&tam_pagina=${data.tamanhoPagina}&status=recebendo_proposta`;

      const res = await fetch(url);
      const response = await res.json();

      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getCaptacaoArquivos = async (url: string) => {
    try {
      const res = await fetch(url);
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getCaptacaoItens = async (url: string) => {
    try {
      const res = await fetch(url);
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const getCaptacaoPortais = async (url: string) => {
    try {
      const res = await fetch(url);
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getProcessoItens = async (token: string, processoId: string) => {
    const url = `${baseUrl}/api/itens/get-itens?token=${token}&processoId=${processoId}`;

    try {
      const res = await fetch(url);
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  type TCriarItem = {
    processoId: string;
    token: string;
    data: TItensData;
  };

  const createProcessoItens = async (data: TCriarItem) => {
    const url = `${baseUrl}/api/itens/criar-itens?token=${data.token}&processoId=${data.processoId}`;

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data.data),
      });
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  type TEditarItem = {
    itemid: string;
    token: string;
    data: {
      Numero?: string;
      Descrição?: string;
      Quantidade?: string;
      MarcaModel?: string;
      ValorUnitarioEstimado?: string;
      ValorTotalEstimado?: string;
    };
  };

  const editarCaptacaoItens = async (data: TEditarItem) => {
    const url = `${baseUrl}/api/itens/editar-itens?token=${data.token}&itemid=${data.itemid}`;

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data.data),
      });

      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  type TApagarItem = {
    token: string;
    itemid: string;
  };

  const apagarCaptacaoItens = async (data: TApagarItem) => {
    const url = `${baseUrl}/api/itens/apagar-itens?token=${data.token}&itemid=${data.itemid}`;
    try {
      const res = await fetch(url);
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return {
    getCaptacaoAutomatica,
    getCaptacaoArquivos,
    getCaptacaoItens,
    getCaptacaoPortais,
    getProcessoItens,
    createProcessoItens,
    editarCaptacaoItens,
    apagarCaptacaoItens,
  };
};
