import React, { useState } from 'react';
import {
  BsDownload,
  BsEye,
  BsFilePlus,
  BsInfo,
  BsPencil,
  BsTrash,
} from 'react-icons/bs';
import { formatarData } from '../../Utils/formatarData';
import { DisplayPastas, ModoAbrirArquivo } from '../../Utils/types/pastasTypes';
import ArquivoModalPastas from '../ArquivosComponents.tsx/ArquivosModalPastas';
import ApagarArquivo from './ApagarArquivo';
import NovoArquivo from './NovoArquivo';
import PastaInfo from './PastaInfo';
import { useQuery } from '@tanstack/react-query';
import { useArquivosUtils } from '../../Utils/ReactQuery/getArquivos';
import LoadingSmall from '../LoadingSmall';
import EditarArquivo from '../ArquivosComponents.tsx/EditarArquivo';

const PastaConteudo = ({ pasta, displayPastas }: any) => {
  const token = localStorage.getItem('token');
  const { getArquivos } = useArquivosUtils();
  const { data: arquivosData, isLoading: arquivosIsLoading } = useQuery({
    queryFn: () => getArquivos(token as string, pasta._id),
    queryKey: ['rqPastaArquivos', pasta._id],
  });

  const [pastaInfo, setPastaInfo] = useState<boolean>(false);
  const [arquivoSelecionado, setArquivoSelecionado] = useState<any>();
  const [apagarArquivo, setApagarArquivo] = useState<boolean>(false);
  const [arquivoAberto, setArquivoAberto] = useState<boolean>(false);
  const [novoArquivo, setNovoArquivo] = useState<boolean>(false);
  const [editarArquivo, setEditarArquivo] = useState<boolean>(false);
  const [displayArquivo, setDisplayArquivo] = useState<ModoAbrirArquivo>(
    ModoAbrirArquivo.EDITAR
  );
  const changeDisplayArquivo = (data: ModoAbrirArquivo) => {
    setDisplayArquivo(data);
  }
  
  const [abrirArquivoDetalhe, setAbrirArquivoDetalhe] = useState<boolean>(false);

  const fecharPastaInfo = (e: any) => {
    e.preventDefault();
    setPastaInfo(false);
  };

  const handleApagarArquivo = (e: any, arquivo: any) => {
    e.preventDefault();
    setApagarArquivo(true);
    setArquivoSelecionado(arquivo);
    return;
  };

  const handleAbrirArquivo = (e: any, arquivo: any) => {
    e.preventDefault();
    setArquivoSelecionado(arquivo);
    setArquivoAberto(true);
  };

  const fecharArquivo = (e: any) => {
    setApagarArquivo(false);
    setArquivoAberto(false);
    setNovoArquivo(false);
    setEditarArquivo(false);
    setAbrirArquivoDetalhe(false);
    return;
  };

  const handleEditarArquivo = (e: any, arquivo: any) => {
    e.preventDefault();
    setArquivoSelecionado(arquivo);
    setEditarArquivo(true);
    setDisplayArquivo(ModoAbrirArquivo.EDITAR);
    return;
  };

  const handleAbrirArquivoDetalhe = (e: any, arquivo: any) => {
    e.preventDefault();
    setAbrirArquivoDetalhe(true);
    setArquivoSelecionado(arquivo);
    setDisplayArquivo(ModoAbrirArquivo.VISUALIZAR);
    return;
  };

  const dowloadFile = (
    e: any,
    fileUrl: string,
    fileName: string,
    arquivoPath: string
  ) => {
    e.preventDefault();
    try {
      let contentUrl = '';
      if (fileUrl) {
        contentUrl = fileUrl;
      }
      if (arquivoPath) {
        contentUrl = `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivoPath}`;
      }
      fetch(contentUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${fileName}`;

          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const findArquivoLink = (arquivo: any) => {
    switch (true) {
      case arquivo.Cnpj === '19001876000180':
        return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/${arquivo.ArquivoPath}`;
      case arquivo.LinkAws !== undefined:
        return arquivo.LinkAws;
      case arquivo.ArquivoPath !== undefined:
        return `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivo.ArquivoPath}`;
      default:
        return '#';
    }
  };

  if (arquivosIsLoading) {
    return (
      <tr>
        <td colSpan={7}>
          <LoadingSmall />
        </td>
      </tr>
    );
  }

  return (
    <>
      {/* Modais e outros componentes */}
      {pastaInfo && <PastaInfo fechar={fecharPastaInfo} pasta={pasta} />}
      {apagarArquivo && (
        <ApagarArquivo fechar={fecharArquivo} arquivo={arquivoSelecionado} />
      )}
      {arquivoAberto && (
        <ArquivoModalPastas arquivo={arquivoSelecionado} fechar={fecharArquivo} />
      )}
      {novoArquivo && <NovoArquivo fechar={fecharArquivo} pastaid={pasta._id} />}
      {editarArquivo && (
        <EditarArquivo
          arquivo={arquivoSelecionado}
          fechar={fecharArquivo}
          displayArquivo={displayArquivo}
          changeDisplayArquivo={changeDisplayArquivo}
        />
      )}
      {abrirArquivoDetalhe && (
        <EditarArquivo
          arquivo={arquivoSelecionado}
          fechar={fecharArquivo}
          displayArquivo={displayArquivo}
          changeDisplayArquivo={changeDisplayArquivo}
        />
      )}

      {/* Linha de botões */}
      <tr className="bg-gray-100">
        <td colSpan={7}>
          <div className="flex justify-between items-center my-1 pl-4">
            <button
              onClick={() => setNovoArquivo((current) => !current)}
              className="flex items-center gap-2 text-green-500 hover:text-green-700"
            >
              <BsFilePlus className="text-lg" /> Novo arquivo
            </button>
            <button
              onClick={() => setPastaInfo((current) => !current)}
              className="flex items-center bg-gray-200 rounded-md shadow-lg p-1 hover:bg-gray-250 hover:text-blue-500"
            >
              <BsInfo className="text-lg" /> Informações da pasta
            </button>
          </div>
        </td>
      </tr>

      {/* Renderização das linhas de arquivos */}
      {arquivosData &&
        arquivosData.arquivos.map((arquivo: any) => (
          <tr
            key={arquivo._id}
            className="border-b dark:border-gray-600 bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <td className="w-28">
              <button
                className="text-blue-500 hover:underline pl-4"
                onClick={(e) => handleAbrirArquivoDetalhe(e, arquivo)}
              >
                {arquivo.NomeArquivo?.length >= 50 ? `${arquivo.NomeArquivo.slice(0, 50)}...` : arquivo.NomeArquivo}
              </button>
            </td>
            <td className="w-36 flex flex-row justify-center  gap-1">
              {/* Ícones de opções */}
              <a
                href={findArquivoLink(arquivo)}
                target="_blank"
                rel="noopener noreferrer"
                className="iconPastaTable"
              >
                <BsEye />
              </a>
              <button
                onClick={(e) =>
                  dowloadFile(
                    e,
                    arquivo.LinkAws,
                    arquivo.NomeArquivo,
                    arquivo.ArquivoPath
                  )
                }
                className="iconPastaTable"
              >
                <BsDownload />
              </button>
              <button
                onClick={(e) => handleEditarArquivo(e, arquivo)}
                className="iconPastaTable"
              >
                <BsPencil />
              </button>
              <button
                onClick={(e) => handleApagarArquivo(e, arquivo)}
                className="iconPastaTable"
              >
                <BsTrash />
              </button>
            </td>
            <td className="w-36 text-center">{arquivo.Categoria || '---'}</td>
            <td className="w-36 text-center">
              {arquivo.DataEmissao ? formatarData(arquivo.DataEmissao) : '---'}
            </td>
            <td className="w-36 text-center">
              {arquivo.DataVencimento ? formatarData(arquivo.DataVencimento) : '---'}
            </td>
            <td className="w-36 text-center">{arquivo.Valor || '---'}</td>
            <td className="w-36 text-center">{arquivo.Status || '---'}</td>
            
          </tr>
        ))}
    </>
  );
};

export default PastaConteudo;
