import React, { useEffect, useState } from 'react'
import LoadingPage from '../LoadingPage'
import { BsPencil, BsPlus, BsX } from 'react-icons/bs';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { error } from 'console';
import { DisplayMiddle } from '../../Utils/types/PortalTypes';
import AlertaAssinatura from '../AlertaAssinatura';
import { TArquivosUsuario } from '../../Utils/types/userType';
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser';
import { useReactQueryTimeline } from '../../Utils/ReactQuery/getArquivosTimeline';
import { CategoriasPadrão, TCategoriaPastas } from '../../Utils/DevUtils/CategoriasFake';
import CategoriasDropDown from '../../Utils/CategoriasDropDown';
import {FileTypeResult, fileTypeFromBlob, fileTypeFromBuffer, fileTypeFromFile, fileTypeFromStream} from 'file-type';
import LoadingUploadArquivo from '../LoadingUploadArquivo';
import SucessoMsg from '../SucessoMsg';

const NovoArquivoProcesso = ({ processo, pasta, editar, arquivo, fechar, modalidade, user }: any) => {

    // //~~MANUTENÇÃO~~// 
    // useEffect(() => {
    //     console.log('Pasta:', arquivo)
    // }, [arquivo])
    // //~~MANUTENÇÃO~~// 

    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false);
    const [file, setFile] = useState<any>('');
    const [fileName, setFilename] = useState<string>('');
    const [fileType, setFileType] = useState<FileTypeResult>({mime: 'application/pdf', ext: 'pdf'})
    const [fileValidator, setFileValidator] = useState<boolean>(true)

    const [dataInicioEditar, setDataInicioEditar] = useState<'text' | 'datetime-local'>('text')
    const [dataVencimentoEditar, setDataVencimentoEditar] = useState<'text' | 'datetime-local'>('text')

    const [dataVencimento, setDataVencimento] = useState<string>('');
    const [dataEmissão, setDataEmissão] = useState<string>('')

    const [fileAlerta, setFileAlerta] = useState<boolean>(false);

    const [categoria, setCategoria] = useState<TCategoriaPastas>()
    const handleCategoria = (data: TCategoriaPastas)=>{
        
        setCategoria(data)
    }

    useEffect(() => {

    


        const allowedTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/x-rar-compressed"]

        const getFileTipe = async()=>{
            const fileTipe = await fileTypeFromBlob(file)
            
            setFileType(fileTipe as FileTypeResult);

            const validName = file.name.replace(fileTipe?.ext, fileTipe?.ext)
            console.log('Vald name: ', validName)

            if(!allowedTypes.includes(fileTipe?.mime as string)){
                setFileValidator(false)
            }else{
                setFileValidator(true)
            }

            return fileTipe
        }

        getFileTipe().catch((data)=>{
            console.error(data);
            return
        })

        if (file && file.size > 4872861 && user) {
            toast.error('Os arquivos do usuário não podém exeder a 5mb cada.');
            setFileAlerta(true)
        }

        if (file && file.size < 4872861 && user) {
            setFileAlerta(false)
        }

        if(!fileValidator){
            setFileAlerta(true)
        }
        if(fileValidator){
            setFileAlerta(false)
        }

    }, [file])

    const [planos, setPlanos] = useState<boolean>(false)

    // MUTAÇÃO ----------------------------------
    const token = localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada');
    const { salavarArquivoProcesso, editarArquivo, fileUpload } = useReactQueryProcessos();
    const queryClient = useQueryClient();

    const salvarArquivoMutation = useMutation({
        mutationFn: salavarArquivoProcesso,
        onMutate: async (config) => {
            // console.log('Mutação iniciada!');
            setLoading(true);
        },
        onSuccess: async (response) => {
            // console.log('Mutação bem sucedida: ', response);
            queryClient.invalidateQueries({ queryKey: ['rqPastasProcesso'] });
            queryClient.fetchQuery({ queryKey: ['rqPastasProcesso'] });
            queryClient.refetchQueries({ queryKey: ['rqPastasProcesso'] });

            if (response.status === 1) {
                toast.success(response.msg);
                setSucesso(true)
            }

            if (response.status === 4) {
                toast.warn(response.msg);
                setPlanos(true)
            }
            if (response.status === 3) {
                toast.error(response.msg);
            }
        },
        onError: async (error) => {
            console.error(error.message);
            toast.error(error.message)
        },
        onSettled(data, error, variables, context) {
            // console.log('Mutação concluída!');
            setLoading(false);
        },
    })


    const { salvarArquivoUser } = useReactQueryUsers();
    const { salvarArquivosTimeline } = useReactQueryTimeline()

    const arquivoPerfilMutation = useMutation({
        mutationFn: salvarArquivoUser,
        onMutate: async (config) => {
            // console.log('Mutação iniciada!');
            setLoading(true);
        },
        onSuccess: async (response) => {
            // console.log('Mutação bem sucedida: ', response);
            queryClient.invalidateQueries({ queryKey: ['rqArquivosUser'] });
            queryClient.fetchQuery({ queryKey: ['rqArquivosUser'] });
            queryClient.refetchQueries({ queryKey: ['rqArquivosUser'] });

            if (response.status === 1) {
                toast.success(response.msg);
                setSucesso(true)
            }
            if (response.status === 4) {
                toast.warn(response.msg);
                setPlanos(true)
            }

            if (response.status === 3) {
                toast.error(response.msg);
            }
        },
        onError: async (error) => {
            console.error(error.message);
            toast.error(error.message)
        },
        onSettled(data, error, variables, context) {
            // console.log('Mutação concluída!');
            setLoading(false);
        },
    })


    const salvarTimelineMutation = useMutation({
        mutationFn: salvarArquivosTimeline,
        onMutate: async (config) => {
            // console.log('Mutação iniciada!');
            setLoading(true);
        },
        onSuccess: async (response) => {
            // console.log('Mutação bem sucedida: ', response);
            queryClient.invalidateQueries({ queryKey: ['rqTimeline'] });
            queryClient.fetchQuery({ queryKey: ['rqTimeline'] });
            queryClient.refetchQueries({ queryKey: ['rqTimeline'] });

            if (response.status === 1) {
                toast.success(response.msg);
                setSucesso(true)
            }
            if (response.status === 4) {
                toast.warn(response.msg);
                setPlanos(true)
            }

            if (response.status === 3) {
                toast.error(response.msg);
            }
        },
        onError: async (error) => {
            console.error(error.message);
            toast.error(error.message)
        },
        onSettled(data, error, variables, context) {
            // console.log('Mutação concluída!');
            setLoading(false);
        },
    })


    const editarArquivoMutaton = useMutation({
        mutationFn: editarArquivo,
        onMutate: async (data) => {
            setLoading(true);
        },
        onSuccess: async (response) => {

            queryClient.invalidateQueries({ queryKey: ['rqPastasProcesso'] });
            queryClient.fetchQuery({ queryKey: ['rqPastasProcesso'] });
            queryClient.refetchQueries({ queryKey: ['rqPastasProcesso'] });

            if (response.status === 1) {
                toast.success(response.msg);
                setSucesso(true);
                fechar()
            }

            if (response.status === 3) {
                toast.error(response.msg);
            }

        },
        onError: async (err) => {
            toast.error(err.message)

        },
        onSettled: async (data) => {
            setLoading(false)
        }
    })


    const empresaPadrão = localStorage.getItem('empresaPadrão');
    // MUTAÇÃO ----------------------------------

    const handleNovoArquivo = (e: any) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData(e.currentTarget);
        formData.append('file', file)


        const arquivoProcessoData = {
            nomeDoArquivo: formData.get('nomeDoArquivo') ? `${formData.get('nomeDoArquivo')}.${fileType.ext}` : file.name,
            dataInicio: formData.get('dataInicio'),
            dataVencimento: formData.get('dataVencimento'),
            categoria: categoria?.label,
            valor: formData.get('valor'),
            Size: file.size || 0,
            status: formData.get('status') || 'Nenhum',
            mimetype: fileType.mime || file.type,
            exttype: fileType.ext || 'pdf'
        }

    
        
        if (editar) {

            editarArquivoMutaton.mutate({
                token: token as string,
                processoid: processo._id,
                action: 'editar',
                data: {
                    nomeDoArquivo: arquivoProcessoData.nomeDoArquivo as string,
                    dataInicio: dataEmissão,
                    dataVencimento: dataVencimento,
                    categoria: arquivoProcessoData.categoria as string,
                    valor: arquivoProcessoData.valor as string,
                    processoId: processo._id,
                    empresaid: processo.empresaSelecionada
                },
                Arquivo: '',
                arquivoid: arquivo._id
            })

            return;
        }


        // Arquivos do usuário
        if (user) {

            if (fileAlerta) {
                return
            }

           

            arquivoPerfilMutation.mutate({
                resumo: {
                    NomeDoArquivo: arquivoProcessoData.nomeDoArquivo as string,
                    dataInicio: arquivoProcessoData.dataInicio as string,
                    dataVencimento: arquivoProcessoData.dataVencimento as string,
                    categoria: arquivoProcessoData.categoria as string,
                    valor: arquivoProcessoData.valor as string,
                    Size: arquivoProcessoData.Size as number,
                    empresaid: empresaPadrão as string,
                    Userid: user.id,
                    status: arquivoProcessoData.status as string
                }, token: token as string, Arquivo: formData
            });

            return;
        }

        //Arquivos  C.P.H.
        if (modalidade) {

            salvarTimelineMutation.mutate({
                resumo: {
                    NomeDoArquivo: arquivoProcessoData.nomeDoArquivo as string,
                    dataInicio: arquivoProcessoData.dataInicio as string,
                    dataVencimento: arquivoProcessoData.dataVencimento as string,
                    categoria: arquivoProcessoData.categoria as string,
                    valor: arquivoProcessoData.valor as string,
                    Size: arquivoProcessoData.Size as number,
                    Processoid: processo._id,
                    Modalidade: modalidade as string,
                    empresaid: processo.empresaPadrão as string,
                    status: arquivoProcessoData.status as string
                }, token: token as string, Arquivo: formData, empresaid: processo.empresaSelecionada
            });

            return;
        }


        // Arquivo solto no processo, sem pasta
        if (!pasta || pasta === undefined) {
            salvarArquivoMutation.mutate({
                action: 'novo',
                processoid: processo._id,
                token: token as string,
                Arquivo: formData,
                data: {
                    nomeDoArquivo: arquivoProcessoData.nomeDoArquivo as string,
                    dataInicio: arquivoProcessoData.dataInicio as string,
                    dataVencimento: arquivoProcessoData.dataVencimento as string,
                    categoria: arquivoProcessoData.categoria as string,
                    valor: arquivoProcessoData.valor as string,
                    Size: arquivoProcessoData.Size as number,
                    processoId: processo._id,
                    empresaid: processo.empresaSelecionada
                },
                mimetype: arquivoProcessoData.mimetype,
                exttype:arquivoProcessoData.exttype
            })

            return;
        }

        // Arquivo em pasta
        salvarArquivoMutation.mutate({
            action: 'novo-pasta',
            processoid: processo._id,
            pastaid: pasta._id,
            token: token as string,
            Arquivo: formData,
            data: {
                nomeDoArquivo: arquivoProcessoData.nomeDoArquivo as string,
                dataInicio: arquivoProcessoData.dataInicio as string,
                dataVencimento: arquivoProcessoData.dataVencimento as string,
                categoria: pasta.categoria as string,
                valor: arquivoProcessoData.valor as string,
                Size: arquivoProcessoData.Size as number,
                processoId: processo._id,
                empresaid: processo.empresaSelecionada
            },
            mimetype: arquivoProcessoData.mimetype,
            exttype:arquivoProcessoData.exttype
           
        })

        return
    }

    const anexarOutro = () => {
        setSucesso(false);
    };


    // Tailwind --------------------------------------------------
    const selectInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
    const optionInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
    // Tailwind --------------------------------------------------

    const fecharAlerta = () => {
        setPlanos(false)
    }

    if (planos) {
        return (
            <>
                <AlertaAssinatura fechar={fecharAlerta} tipo={'arquivo'} />
            </>
        )
    }


    if (sucesso) {
        return <SucessoMsg onAnother={anexarOutro} onCancel={fechar} message={"Deseja anexar outro arquivo?"}/>;
      }


    return (
        <div>

            <div id="crud-modal" tabIndex={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">


                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {
                            loading ? <div className='max-h-screen overflow-y-hidden'><LoadingUploadArquivo value = {fileUpload}/> </div>
                                :

                                <form className="p-4 md:p-5" onSubmit={handleNovoArquivo}>

                                    {!editar &&
                                        <div>

                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Anexar arquivo </label>
                                            <input className='rounded-xl' type="file" accept="application/pdf xls,xlsx rar" name="file" onChange={(e: any) => setFile(e.target.files[0])} required={true} />
                                            <br />

                                            
                                            {
                                                !fileValidator &&
                                                <label className='text-red-500' htmlFor="">Tipo de arquvo não suportado, selecione apenas arquivos ".pdf", ".docx", "xls/xlsx" e ".rar</label>
                                            }
                                           
                                        </div>
                                    }

                                    <div className="grid gap-4 mb-4 grid-cols-2">


                                        <div className="col-span-2">
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome do arquivo </label>
                                            <input type="text" name="nomeDoArquivo" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={editar ? arquivo.nomeDoArquivo : file ? file.name : 'Nome do arquivo'} />

                                        </div>

                                        <div className="col-span-2">
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Valor </label>
                                            <input type="number" name="valor" step={"0.01"} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={editar ? arquivo.valor : "Valor"} />
                                        </div>

                                        <div className="col-span-2">
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data inicio </label>

                                            {
                                                editar ?
                                                    <>
                                                        <input type={dataInicioEditar} onFocus={() => setDataInicioEditar('datetime-local')} name="dataInicio" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" value={dataEmissão ? dataEmissão : arquivo.dataInicio} placeholder={arquivo.dataInicio ? arquivo.dataInicio : 'Nenhum valor informado!'} onChange={(e) => setDataEmissão(e.target.value)} />

                                                        {
                                                            dataInicioEditar === 'text' ?

                                                                <BsPencil className='cursor-pointer opacity-75 hover:opacity-100 mt-2' onClick={() => setDataInicioEditar('datetime-local')} />
                                                                :
                                                                <BsX className='cursor-pointer opacity-75 hover:opacity-100 text-xl mt-2' onClick={() => setDataInicioEditar('text')} />}
                                                    </>
                                                    :
                                                    <input type={"datetime-local"} name="dataInicio" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" />
                                            }


                                        </div>

                                        <div className="col-span-2">
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data vencimento </label>

                                            {
                                                editar ?
                                                    <>
                                                        <input type={dataVencimentoEditar} onFocus={() => setDataVencimentoEditar('datetime-local')} name="dataVencimento" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" value={dataVencimento ? dataVencimento : arquivo.dataVencimento} placeholder={arquivo.dataVencimento ? arquivo.dataVencimento : 'Nenhum valor informado!'} onChange={(e) => setDataVencimento(e.target.value)} />

                                                        {
                                                            dataVencimentoEditar === 'text' ?

                                                                <BsPencil className='cursor-pointer opacity-75 mt-2 hover:opacity-100' onClick={() => setDataVencimentoEditar('datetime-local')} />
                                                                :
                                                                <BsX className='cursor-pointer opacity-75 mt-2 hover:opacity-100 text-xl' onClick={() => setDataVencimentoEditar('text')} />}
                                                    </>
                                                    :
                                                    <input type="datetime-local" name="dataVencimento" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" />
                                            }

                                        </div>


                                        <div >
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status </label>
                                            <select name="status" id="status" className={`${selectInput}`} required={true}>
                                                <option className={`${optionInput}`} selected={editar && arquivo.status === "nenhum" ? true : false} value="nenhum"> Nenhum</option>
                                                <option className={`${optionInput}`} selected={editar && arquivo.status === "entregue" ? true : false} value="entregue"  >Entregue</option>
                                                <option className={`${optionInput}`} selected={editar && arquivo.status === "Distratado" ? true : false} value="Distratado">Distratado</option>
                                                <option className={`${optionInput}`} selected={editar && arquivo.status === "encerrado" ? true : false} value="encerrado">Encerrado</option>
                                            </select>
                                        </div>

                                        {

                                            !modalidade && !pasta &&

                                            <div>
                                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Categoria </label>
                                            <CategoriasDropDown padrao={{label: 'Documentos', value: 'Documentos'}} options={{drection: 'up'}} changeCategoria={handleCategoria}/>
                                        </div>

                                        }

                                    </div>


                                    <div className='flex flex-row gap-2'>


                                        <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" disabled={fileAlerta || !fileValidator}>
                                            <BsPlus className='text-xl' />
                                            {editar ? 'Editar arquivo' : 'Criar arquivo'}
                                        </button>
                                        
                                        <label onClick={fechar} className="text-white inline-flex items-center cursor-pointer bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                                            <BsX className='text-xl' />
                                            Cancelar
                                        </label>
                                    </div>



                                </form>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NovoArquivoProcesso
