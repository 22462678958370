import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { useReactQueryEmpresas } from '../Utils/ReactQuery/getEmpresas';
import LoadingComponent from './LoaadingComponent';
import { useReactQueryProcessos } from '../Utils/ReactQuery/getProcessosUtils';
import { SituaçãoInterna } from '../Utils/types/PortalTypes';
import { BsFolder } from 'react-icons/bs';
import LoadingPage from './LoadingPage';
import { toast } from 'react-toastify';
import { PiFolderFill, PiFolderOpenFill } from 'react-icons/pi';

const SelecionarEmpresa = ({ abrirPasta }: any) => {


    const [loading, setLoading] = useState<boolean>(false);

    const scrollRef = useRef<HTMLElement | any>(null);
    const { empresasPermitidas, verifcarEmpresa } = useReactQueryEmpresas();
    const token = localStorage.getItem('token');

    const { data: empresasDisponiveis, isLoading: empresasDisponiveisLoading } = useQuery({
        queryFn: () => empresasPermitidas(token as string),
        queryKey: ['rqEmpresasPermitidas'],
        staleTime: 1000 * 60 * 2,
    });


    //-- Pastas e portais vencidos
    const empresaPadrão = localStorage.getItem("empresaPadrão")

    const { data: verEmpData, isLoading: verIsLoading } = useQuery({
        queryFn: () => verifcarEmpresa(empresaPadrão as string, token as string),
        queryKey: ['rqVerificarEmpresa'],
        refetchOnWindowFocus: false,
        staleTime: Infinity
    })



    const verificarEmpresa = (empresaid: string, style: 'open' | 'close') => {

        try {
            if (verEmpData) {

                const assessoriaTotalVencidos = verEmpData.pastasVencidas.length + verEmpData.portaisVencidos.length;
                const assessoriaTotalAVencer = verEmpData.portaisAVencer.length + verEmpData.pastasAVencer.length;

                if (empresaid === empresaPadrão) {
                    return <div className={style === 'open' ? 'flex items-center flex-col relative' : `flex items-center justify-center relative mt-1`}>

                        {
                            assessoriaTotalVencidos > 0 &&
                            <span className={`badge-red ${style === 'open' ?'absolute -top-[15%] left-5' :'absolute  -left-[100%] ml-1'}`} title={`${verEmpData.pastasVencidas.length} pastas e ${verEmpData.portaisVencidos.length} assinaturas de portais vencidas`}> {assessoriaTotalVencidos} </span>
                        }

                        {
                            assessoriaTotalAVencer > 0 &&
                            <span className={`badge-yellow  ${style === 'open'? 'absolute -top-[15%] ' : 'absolute top-{100%} -right-[100%] mr-1'} `} title={`${verEmpData.pastasAVencer.length} pastas e ${verEmpData.portaisAVencer.length} assinaturas que vencem em 7 dias`}> {assessoriaTotalAVencer} </span>
                        }

                    </div>
                }

                const pastasVencidas = verEmpData.pastasVencidas.filter((pastas: any) => {
                    return pastas.EmpresaAfiliada === empresaid
                })
                const portaisVencidos = verEmpData.portaisVencidos.filter((pastas: any) => {
                    return pastas.EmpresaPortal === empresaid
                })

                const pastasAVencer = verEmpData.pastasAVencer.filter((pastas: any) => {
                    return pastas.EmpresaAfiliada === empresaid
                })
                const portaisAVencer = verEmpData.portaisAVencer.filter((pastas: any) => {
                    return pastas.EmpresaPortal === empresaid
                })


                const totalVencido = pastasVencidas.length + portaisVencidos.length;
                const totalAVencer = pastasAVencer.length + portaisAVencer.length;

                return <div className={style === 'open' ? 'flex items-center flex-col relative' : `flex items-center justify-center relative mt-1`}>
                    {
                        totalVencido > 0 &&
                        <span className={`badge-red ${style === 'open' ?'absolute -top-[15%] left-5' :'absolute  -left-[100%] ml-1'}`} title={`${pastasVencidas.length} pastas e ${portaisVencidos.length} assinaturas de portais vencidas`}> {totalVencido} </span>
                    }

                    {totalAVencer > 0 &&
                        <span className={`badge-yellow  ${style === 'open'? 'absolute -top-[15%] ' : 'absolute top-{100%} -right-[100%] mr-1'} `} title={`${pastasAVencer.length} pastas e ${portaisAVencer.length} assinaturas que vencem em 7 dias`}> {totalAVencer} </span>
                    }
                </div>

            }
        } catch (error) {
            console.error(error)
            return <div className='flex flex-col'>
                <span className='badge'> 0 </span>

                <span className='badge'> 0 </span>
            </div>

        }
    }

    //-- Pastas e portais vencidos

    type TEmpresa = {
        AtividadeEconômicaP?: {
            _id?: string;
            descricao?: string;
        };
        CnpjEmpresa?: string;
        LogoEmpresa?: string;
        NomeEmpresa: string;
        _id?: string;
    };

    const queryClient = useQueryClient();
    const { listarProcessos } = useReactQueryProcessos();

    const empresaSelecionada: any = localStorage.getItem('empresaSelecionada');

    const selecionarEmpresaMutation = useMutation({
        mutationFn: listarProcessos,
        onMutate: async (data) => {
            setLoading(true);
        },
        onSuccess: async (response) => {
            
            queryClient.invalidateQueries({ queryKey: ['rqProcessos'] });
            queryClient.refetchQueries({ queryKey: ['rqProcessos'] });
        },
        onSettled: () => {
            window.location.reload();
            setLoading(false);
        },
    });

    const dispararAlterarEmpresa = async (
        e: any,
        empresaid: string,
        cnaePrincipal: any
    ) => {
        e.preventDefault();

        if (empresaid) {
            selecionarEmpresaMutation.mutate({
                empresaid,
                pageParam: 1,
                filtro: SituaçãoInterna.REGISTRAR,
            });
            localStorage.setItem('empresaSelecionada', empresaid);
            localStorage.setItem('cnaePrincipal', cnaePrincipal.descricao);
        }
    };

    // State to manage open folders
    const [folderOpen, setFolderOpen] = useState<{ [key: string]: boolean }>({});

    // Function to handle opening folders
    const abrirPastaEmpresa = (e: any, empresaId: string) => {
        e.stopPropagation();
        setFolderOpen((prev) => ({
            ...prev,
            [empresaId]: !prev[empresaId],
        }));
        abrirPasta();
    };

    return (
        <div className="w-full pb-2 ">
            <div className="w-full flex flex-row items-center">
                <div
                    className="flex flex-row justify-between items-center w-full max-w-4xl overflow-x-auto h-full snap-x no-scrollbar pl-2"
                    ref={scrollRef}
                >
                    <div className="flex flex-row pb-1 gap-4 justify-around items-center w-fit">
                        {empresasDisponiveis?.empresas &&
                            empresasDisponiveis.empresas.map((empresa: TEmpresa) => {
                                const isSelected = empresaSelecionada === empresa._id;
                                const isFolderOpen = folderOpen[empresa._id as string];

                                return (
                                    <div
                                        key={empresa._id}
                                        id={empresa._id}
                                        onClick={(e) =>
                                            !isSelected &&
                                            dispararAlterarEmpresa(
                                                e,
                                                empresa._id as string,
                                                empresa.AtividadeEconômicaP as any
                                            )
                                        }
                                        className="cursor-pointer flex flex-col justify-center items-center"
                                    >

                                        {isSelected ? (
                                            <div className="px-1 border border-gray-200 rounded-lg flex items-center bg-white dark:bg-gray-800 relative mt-1">

                                                

                                                <div className='flex flex-row mr-2 relative'>

                                                    {verificarEmpresa(empresa._id as string, 'open')}


                                                    {empresa.LogoEmpresa ? (
                                                        <img
                                                            src={`${empresa.LogoEmpresa}`}
                                                            alt="Empresa"
                                                            className="rounded-full w-10 h-10 object-contain"
                                                        />
                                                    ) : (
                                                        <div className="rounded-full w-10 h-10 flex justify-center items-center p-1 bg-gray-100 dark:bg-gray-700 mr-2 ">
                                                            <span className="text-xs font-semibold text-gray-900 dark:text-white">
                                                                {empresa.NomeEmpresa.slice(0, 2)}
                                                            </span>
                                                        </div>
                                                    )}
                                               


                                                </div>



                                                {isFolderOpen ? (
                                                    <PiFolderOpenFill
                                                        className="h-5 w-5 mr-2 text-gray-500"
                                                        onClick={(e) => abrirPastaEmpresa(e, empresa._id as string)}
                                                    />
                                                ) : (
                                                    <PiFolderFill
                                                        className="h-5 w-5 mr-2 text-gray-500"
                                                        onClick={(e) => abrirPastaEmpresa(e, empresa._id as string)}
                                                    />
                                                )}

                                                <div>
                                                    <h3 className="text-xs font-bold text-gray-500 dark:text-white">
                                                        {empresa.NomeEmpresa}
                                                    </h3>
                                                    <div className="flex">
                                                        <p className="text-xs text-gray-500 dark:text-gray-300 ">
                                                            {empresa.CnpjEmpresa}
                                                        </p>
                                                    </div>
                                                </div>




                                            </div>
                                        ) : (
                                            // Render the default display for unselected companies without CNPJ
                                            <div>
                                                {empresa.LogoEmpresa ? (

                                                    <div className='flex flex-col items-center'>

                                                        {verificarEmpresa(empresa._id as string, 'close')}

                                                        <img
                                                            src={`${empresa.LogoEmpresa}`}
                                                            alt="Empresa"
                                                            className={`rounded-full w-9 h-9 hover:opacity-100 transition- object-contain border border-gray-300 ${loading ? 'opacity-55' : 'opacity-100'
                                                                }`}
                                                        />


                                                    </div>

                                                ) : (
                                                    <div
                                                        className={`rounded-full w-10 h-10 flex justify-center items-center p-1 bg-gray-100 dark:bg-gray-70 ${loading ? 'opacity-55' : 'opacity-100'
                                                            }`}
                                                    >
                                                        <span className="text-xs font-semibold text-gray-900 dark:text-white">
                                                            {empresa.NomeEmpresa.slice(0, 2)}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelecionarEmpresa;
