import { useEffect, useLayoutEffect, useState } from "react";
import { useUserContext } from "../Context/useUserContext";
import { typeCadastroState } from "./types/cadastroTypes";


export const usePreChecagem =(empresaData: any, user: any, userEmpresaPadrão: any)=>{

    const [userContext, setUserContext] = useUserContext();

    const [cadastroState, setCadastroState] = useState<typeCadastroState>({empresaComplete: 0, pagamentoComplete: 0, userComplete: 0});
    const [permissõesTemp, setPermissõesTemp] = useState<String>('');


    useLayoutEffect(()=>{
      setUserContext({
        User: user,
        empresaPadrão: userEmpresaPadrão 
      })
    },[user])

    
    useEffect(()=>{
     
      try {
        
     
        let acessoEmpty: number = 0;
        if(user.Acessos.TipoAcesso === "Governo" || user.Acessos.TipoAcesso === "Governo"){
            acessoEmpty = 24;
        }
          
        if(empresaData && user){
  
          const empresaPadrão = empresaData.empresas.find((i: any)=>{return i.CnpjEmpresa === user.Afiliação});
    
          const empresasKeys: number = Object.keys(empresaPadrão).length - acessoEmpty;
          const empresasEmptyValues: number = Object.values(empresaPadrão).filter((i: any)=>
          {return i === ""}).length;
          const empresaComplete: number = 100 - Math.trunc((empresasEmptyValues/empresasKeys)*100);
    
          const emptyUserValue: number = Object.values(user).filter((i: any)=>
          {return i === ""}).length;
          const userKeys: number = Object.keys(user).length
          const userComplete: number = 100 - Math.trunc((emptyUserValue/userKeys) *100);

      
          setUserContext({
            EmpresaPadrão: empresaPadrão,
            User: user
          })




          const acessoMap = empresaPadrão.Empresa_Solicitações.flatMap((i: any)=> {
            return i.AcessoUserId
          } )
         
          // if(acessoMap.includes(user.id)){
          //   console.log('Em solicitação!')
          //   setPermissões({
          //     Solicitações: false
          //   })
          // }
          

          setCadastroState({
              empresaComplete,
              userComplete,
              pagamentoComplete: 100
          })

        } 
      
      } catch (error) {
        console.error(error);
      }

             
      },[empresaData, user])

      return {
        cadastroState,
        userContext,
        permissõesTemp
    }
}