export enum FiltroSelecionado{
  DESAPROVADO    = 'Desaprovado',
  APROVADO       = 'Aprovado',
  FAZENDO        = 'Fazendo',
  FEITO          = 'Feito',
  REVOGADA       = 'Revogada',
  ORÇAMENTO      = 'Orçamento',
  SESSAODIA      = 'Sessões do Dia',
  ACOMPANHAMENTO = 'Acompanhamento',
  PERDIDA        = 'Perdida',
  GANHA          = 'Ganha',
  ENTRAGANDO     = 'Entregando',
  OCORRENCIA     = 'Ocorrencia',
  EXECUÇÃO       = 'Execução',
  ENCERRADO      = 'Encerrado',
  RESUMO         = 'Resumo'

}

export enum InicialTab{
  FILTRO = 'filtro',
  PASTA = 'pasta',
  IDLE = 'idle'

}

export enum RegistrarProcesso{
  INFOLICITANTE = 'infolicitante',
  INFOPROCESSO ='infoprocesso',
  INFODATAS ='infodatas',
  CONTATOS ='contatos'
}


export enum DisplayMiddle{
  RESUMO = 'resumo',
  ARP ='Ata Registro Preços',
  DOCUMENTOS ='Documentos',
  CONTRATOS = 'Contrato',
  OBJETO ='Proposta Readequada',
  OS ="Ordem Serviço'ᴮ",
  FATURAMENTO = 'Faturamento',
  ACT = 'A.C.T',
  EMPENHO = 'empenho',
  CONTATO = 'Contato',
  CHAT ='Chat',
  ESCLARECIMENTO = 'esclarecimento',
  MANDATOS =      'Mandatos',
  DILIGENCIA =    'Diligencia',
  ADJUDICAÇÃO =   'Adjudicação',
  REGISTROPROP =  'Registroprop',
  APRESENTAÇÃO =  'Apresentação',
  JULGAMENTOR =   'Julgamentor',
  PUBLICAÇÃO =    'Publicação',
  IMPUGNAÇÃO =    'Impugnação',
  CPH = 'C.P.H',
  ATR = 'Ata Reealização',
  IRECURSO =      'Irecurso',
  RECURSO =       'Recurso',
  CONTRARRAZÃO =  'Contrarrazão',
  HOMOLOGAÇÃO =   'Homologação',
  SANSÕES= 'sansões',
  VERMAISCPH= 'vermaiscph',
 




}


export type TArquivosProcessos={
filtro:
"Orçamento"|                 
"Públicação"                |    
"Edital/Anexos"             |    
"Recibo Edital"             |    
"Cotação"                   |    
"C.P.H"                     |    
"Esclarecimento"            |    
"Impugnação"                |    
"Decisão Impugnação"        |    
"Vistoria"                  |    
"V.D.C"                     |    
"Intenção Recuso"           |    
"Recurso"                   |    
"Contrarrazão"              |    
"Parecer Jurídico"          |    
"Decisão Final Recurso"     |    
"Ata Realização"            |    
"Proposta Readequada"       |    
"Amostra"                   |    
"Homologação"               |    
"Ajdudicação"               |    
"Ata Registro Preços"       |    
"Contrato"                  |    
"Seguro Garantia Contratual"|    
"Empenho"                   |    
"Ordem Serviço'ᴮ"           |    
"Comprovante Entrega"       |    
"Nota Fiscal"               |    
"Boleto"                    |    
"Comprovante Pagamento"     |    
"Adesão"                    |    
"Ofício's"                  |    
"A.C.T"                     |    
"Outros"
    
}


export type TListarProcessos= {
empresaid: string, pageParam: number, 
filtro?:SituaçãoInterna 
}

export enum SituaçãoInterna {
RESUMO = 'Resumo',
REGISTRAR     ="Registrar",
DIÁRIO        ="Diário",
DESAPROVADO   ="Desaprovado",
APROVADO      ="Aprovado",
FAZENDO       ="Fazendo",
FEITO         ="Feito",
REVOGADA      ="Revogada",
ORÇAMENTO     ="Orçamento",
SESSÕES_DO_DIA="Sessões do Dia",
ACOMPANHAMENTO="Acompanhamento",
PERDIDA       ="Perdida",
GANHA         ="Ganha",
EXECUÇÃO      ="Execução",
ENCERRADO     ="Encerrado",
ENTREGANDO    ="Entregando",
OCORRÊNCIA    ="Ocorrência",
CAPTACAO      ='Captação'

}


export type TNotifiicacoes ={
tipo: 'Processo' | 'Pasta' | 'Portal' |  'Usuario',
foto: string,
nome: string,
descricao: 'Adicionou um novo processo' | 'Adicionou uma nova pasta na empresa' |'Adicionou um portal licitatorio' | 'Foi adicionado a equipe',
empresaid: string,
item?: string,
_id: string,
createdAt: any,
usuarios: string[]
}





export type TArquivosExtra ={
_id?: string | number,
NomeDoArquivo: string,
Descrição?: string, 
createdAt?: string,
updatedAt?: string,
Modalidade?: string,
Processoid: string,
LinkAws?: string,
Size?: number | string,
status: string,
empresaid: string,
dataInicio:     string, 
dataVencimento: string, 
categoria:      string, 
valor:          string, 
arquivoPath?:    string, 
file?: HTMLFormElement | any,


}

export enum AbaTipo {
ARQUIVOS = 'arquivos',
ITENS = 'itens',
ADICIONARITEM = 'adicionaritem',
IDLE = 'idle'
}






export enum ArquivosProcessoExtra{
CREDENCIAMENTO ='Credenciamento',
HABILITACAOJURIDICA ='Habilitação jurídica',
PROPOSTA ='Proposta',
SEGURIDADEFET ='Seguridade Fiscal e Trabalhista',
REGULARIDADEFET ='Regularidade Fiscal e Trabalhista',
QUALIFICACAOT ='Qualficação Técnica',
QUALIFICACAOEF ='Qualificação econômica financeira',
DECLARACAO ='Declaração',
OUTROS ='Outros',
IDLE = 'idle',
ITEM = 'item',
ADICIONARITEM = 'adicionar item'

}


export type typeContatosProcesso ={
contatoNome: string,
contatoEmpresa: string,
contatoDepartamento: string,
contatoCargoFuncao: string,
contatoEmail: string,
contatoTelefone: string,
contatoStatus: string,

}

export type typeProcessoData = {
usuarioNome?: string,
linkSistemaOrigem?: string
link_pncp?: string,
id_pncp?: string,
orgaopublicoLicitante: { type: String },
CnpjLicitante: string,
logoLicitante: string,
empresaSelecionada: string,
usuarioSelecionado: string,
enderecoLicitante: string,
cidadeLicitante: string,
cepLicitante: string,
foneLicitante: string,
emailLicitante: string,
siteLicitante: string,
modalidade: string,
numero  : string,
processo: string,
tipo: string,
srp: string,
objeto: string,
valorestimado: string,
LocalsessaoPublica: string,
portalSelecionado: string,
situacaoInterna: string,
exclusividade: string,
importante: boolean,
DataHoraPublicacao: string,
DataHoraAdjucacao: string,
DataHoraLimiteEsclarecimento    : string,
DataHoraImpugnacao              : string,
DataHoraContraProposta          : string,
DataHoraAberturaLicitacao       : string,
DataHoraContinuacaoLicitacao    : string,
DataHoraIntencaoRecurso         : string,
DataHoraRecurso                 : string,
DataHoraLimiteContrarrazao      : string,
DataHoraLimiteJulgamentoRecurso : string,
DataHoraHomologacao             : string,
DataHoraAdjudicação             : string,
DataHoraDiligencia              : string,
DataHoraApresentação            : string,
DataMandatoS                    : string,
file?: any,
contatos?: any,
captacaoPncp?: boolean,
LinkArquivosPncp?: string,
LinkItensPncp?: string

}

export type typeRegistarProcesso = {
token: string,
empresaid?: string,
data: typeProcessoData,
Arquivo?: any,
contatos?: any,
processoid?: any
empresaSelecionada?: any,
usuarioSelecionado? : any,
portalSelecionado?: string,
File? : boolean,
tipo?: string
}

type TCaptacaoItens={
  Numero: string
  Descrição: string
  Quantidade: string
  ValorUnitarioEstimado: string | number
  ValorTotalEstimado: string | number
}

export type typeRegistarProcessoDireto = typeRegistarProcesso & {
  itens?: [TCaptacaoItens],
}


export type typeProcessoContato ={
token: string,
action: 'novo' | 'editar' |'apagar',
contato?: typeContatosProcesso,
processoid: string
}

export type TCategoriaPastas={
_id?: string,
label: string,
value: string

}

export type TCustonDropdown = {
selecionado?: TCategoriaPastas,
data?: [TCategoriaPastas],
padrao: TCategoriaPastas,
options?: {
    drection: 'up' | 'dn'
},
changeCategoria?: any

}

export type TItem = {
numeroItem: number;
descricao: string;
quantidade: number;
valorUnitarioEstimado: number | null;
valorTotal: number | null;

};

export type TItensData = {
  _id?: string,
  processoId?: string,
  Numero: string,
  Descrição: string,
  Quantidade: string,
  ValorUnitarioEstimado: string | number,
  ValorTotalEstimado: string | number,
  MarcaModelo: string
}
