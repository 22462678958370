
export const useReacttQueryPagamentos =()=>{

    const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL;

    const getPagamentos = async(empresaid: string, token: string)=>{
        const url = `${baseUrl}/api/pagamentos/get-pagamentos?empresaid=${empresaid}&token=${token}`;

        try {

            const res = await fetch(url);
            const response = await res.json();
            return response;
            
        } catch (error) {
            console.error(error);
            return [''];            
        }


    }


    return {
        getPagamentos,
    }

}
