import { cnpj } from 'cpf-cnpj-validator'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoadingPage from '../LoadingPage'
import { useUserContext } from '../../Context/useUserContext'
import TopProcessos from './TopProcessos'
import { DisplayMiddle } from '../../Utils/types/PortalTypes'
import ProcessosPastas from './ProcessosPastas'
import ContatosProcessos from './ContatosProcessos'
import { useQuery } from '@tanstack/react-query'
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser'
import BottonProcessos from './BottonProcessos'
import BottonProcessoExtras from './BottonProcessosExtra'
import ProcessosChat from '../ProcessosChat/ProcessosChat'


const MiddleProcessos = ({ processo, empresa, portais, selecionarPortal, globalFilter, usuarios, empresas }: any) => {



    // ~~MANUTENÇÃO~~// 
    // useEffect(() => {
    //     console.log('Empresas -------->: ', empresas)
    // }, [empresas])
    // // ~~MANUTENÇÃO~~// 

    const [showObj, setShowObj] = useState<boolean>(false);

    const findLogoEmpresa = (empresaSeleconada: string) => {
        try {
            const empresaProcesso = empresas.empresas.find((i: any) => {
                return i._id === empresaSeleconada
            })

            return empresaProcesso.LogoEmpresa
        } catch (error) {
            return ''
        }
    }

    //Find  empresa -----------------

    // ~~MANUTENÇÃO~~// 
    // useEffect(()=>{
    //     console.log('User data---------->: ', userData)
    // },[userData])
    // ~~MANUTENÇÃO~~// 

    const findProcessoUsuario = (usuario: string): string => {

        try {
            const tempUser = usuarios.find((i: any) => {
                return i._id === usuario
            })
            if (tempUser.Foto) {
                return tempUser.Foto
            } else {
                return `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/avatar2.png`
            }
        } catch (error) {
            console.error(error);
            return `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/avatar2.png`
        }
    }


    const findPortal = (portalSelecionado: string) => {

        try {

            if (!portalSelecionado) {
                return `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/portal.jpg`
            }

            if (portais) {
                const portalEncontrado = portais.find((i: any) => {
                    return i._id === portalSelecionado
                });


                switch (true) {
                    case portalEncontrado.EmpresaPortal === "65aace13036becbc9a90ab6c" && portalEncontrado.LogoPortal.length <= 28:
                        return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/logoPortais/${portalEncontrado.LogoPortal}`
                        break;
                    case portalEncontrado && portalEncontrado.LogoPortal.length <= 28:
                        return `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/logoPortais/${portalEncontrado.LogoPortal}`
                        break;
                    case portalEncontrado && portalEncontrado.LogoPortal.length > 20:
                        return portalEncontrado.LogoPortal;
                        break;
                }
            } else {
                return false
            }
        } catch (error) {

            return `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/portal.jpg`
        }
    }


    const findLogoLicitante = (logoLicitante: string) => {

        switch (true) {
            case !logoLicitante:
                return `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/empresa.jpg`;
                break
            case logoLicitante && logoLicitante.length <= 28:
                return `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/processos/logosLicitante/${logoLicitante}`
                break;
            case logoLicitante && logoLicitante.length > 28:
                return logoLicitante;
                break;
        }
    }

    const findLogoLictanteDescartMed = (logoLicitante: string) => {
        switch (true) {
            case !logoLicitante:
                return `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/empresa.jpg`;
                break
            case logoLicitante && logoLicitante.length <= 28:
                return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/processos/logosLicitante/${logoLicitante}`
                break;
            case logoLicitante && logoLicitante.length > 28:
                return logoLicitante;
                break;
        }
    }


    const handleLocalSp = (localSp: string) => {
        switch (true) {
            case localSp.includes("http") || localSp.includes("https"):
                return (<a target='_blank' href={localSp} className='underline '> Local sp</a>)
                break;
            case !localSp.includes("http") && !localSp.includes("https"):
                return (<a href={`http://www.google.com/search?q=${localSp}`} target='_blank' className='underline'>Local Sp </a>)
        }
    }

    const handlePortalOpen = (portalSelecionado: string) => {
        if (portais) {
            const portalEncontrado = portais.find((i: any) => {
                return i._id === portalSelecionado
            });
            selecionarPortal(portalEncontrado)
            if (portalEncontrado) {
                return portalEncontrado
            }
        } else {
            return false
        }
    }


    const [displayMiddle, setDisplayMiddle] = useState<DisplayMiddle>(globalFilter);

    useLayoutEffect(() => {
        setDisplayMiddle(globalFilter)
    }, [globalFilter])

    const changeMiddleDisplay = (filtroMiddle: DisplayMiddle) => {
        setDisplayMiddle(filtroMiddle);
    }

    if (!processo || !empresa || !portais) {
        return (
            <LoadingPage />
        )
    }


    const formatCep = (cep: string) => {
        return cep ? cep.replace(/(\d{5})(\d{3})/, "$1-$2") : '';
    };

    const formatTelefone = (telefone: string) => {
        return telefone ? telefone.replace(/(\d{2})(\d{4,5})(\d{4})/, "($1) $2-$3") : '';
    };

    return (

        <div>

            <TopProcessos processo={processo} filtroMiddle={changeMiddleDisplay} displayFiltroMiddle={displayMiddle} />

            {displayMiddle !== DisplayMiddle.VERMAISCPH && displayMiddle !== DisplayMiddle.RESUMO && displayMiddle !== DisplayMiddle.CONTATO && displayMiddle !== DisplayMiddle.CHAT &&
                <ProcessosPastas filtro={displayMiddle} processo={processo} changeMiddleDisplay={changeMiddleDisplay} />
            }
            {
                displayMiddle === DisplayMiddle.VERMAISCPH && <BottonProcessoExtras processo={processo} />
            }



            {displayMiddle === DisplayMiddle.CONTATO && <ContatosProcessos processo={processo} />}

            {displayMiddle === DisplayMiddle.CHAT && <ProcessosChat processo={processo} />}

            {displayMiddle === DisplayMiddle.RESUMO &&

                <div className='flex flex-col justify-center items-center p-0 m-0 py-2 text-sm'>
                    <div className='h-full flex flex-row justify-between dark:bg-gray-600 w-full'>


                        {/* Coluna 1 */}
                        <div className='flex flex-row w-1/3 p-4 items-start max-w-full'>
                            <div className='flex flex-col justify-start items-center mr-4'>
                                <div className='w-16 h-16 object-contain rounded-full mb-2'>

                                    {
                                        processo.empresaSelecionada === '65aace13036becbc9a90ab6c' ?
                                            <img src={findLogoLictanteDescartMed(processo.logoLicitante)} className='w-full h-full rounded-full' alt="logo" />
                                            :
                                            <img src={findLogoLicitante(processo.logoLicitante)} className='w-full h-full rounded-full' alt="logo" />
                                    }

                                </div>
                                <div className='w-16 h-16 object-contain rounded-full'>

                                    <img src={`${findPortal(processo.portalSelecionado)}`} className='w-full h-full rounded-full cursor-pointer opacity-80 hover:opacity-100' alt="logo" onClick={() => handlePortalOpen(processo.portalSelecionado)} />


                                </div>


                            </div>

                            <div className='flex flex-col'>
                                <label><span className='font-bold'>{processo.orgaopublicoLicitante}</span></label>
                                <label><span className='font-semibold'>CNPJ:</span> {cnpj.format(processo.CnpjLicitante)}</label>
                                <label><span className='font-semibold'>Endereço:</span> {processo.enderecoLicitante}</label>
                                <label><span className='font-semibold'>Cidade/UF:</span> {processo.cidadeLicitante}</label>
                                <div className='flex items-center'>
                                    <label className='flex items-center mr-4'><span className='font-semibold'>Fone: </span> {formatTelefone(processo.foneLicitante)}</label>
                                    <label className='flex items-center'><span className='font-semibold'>CEP: </span>{formatCep(processo.cepLicitante)}</label>
                                </div>
                                <Link to={`mailto:${processo.emailLicitante}`}>
                                    <span className='font-semibold'>E-mail:</span>
                                    <span className='underline underline-offset-1 opacity-80 hover:opacity-100 cursor-pointer pl-2'>{processo.emailLicitante}</span>
                                </Link>

                                <Link to={processo.siteLicitante}>
                                    <span className='font-semibold'>Site:</span>
                                    <span className='underline underline-offset-1 opacity-80 hover:opacity-100 cursor-pointer pl-2'>

                                        {processo.siteLicitante && processo.siteLicitante.length >= 30 ? `${processo.siteLicitante.slice(0, 25)} ...` : processo.siteLicitante}

                                    </span>
                                </Link>

                            </div>
                        </div>

                        {/* Coluna 2 */}
                        <div className='flex flex-col w-1/4 p-4 mt-5'>
                            <div className='flex flex-col justify-start items-start'>
                                <label><span className='font-semibold'>Modalidade:</span> {processo.modalidade}</label>
                                <label><span className='font-semibold'>Processo:</span> {processo.processo}</label>

                                {
                                    processo.usuarioNome &&
                                    <label>
                                        <span className='font-semibold'>Portal: </span>

                                        {
                                            processo.linkSistemaOrigem && processo.linkSistemaOrigem.length > 1 ?
                                                <a href={processo.linkSistemaOrigem
                                                } className='text-blue-500 underline'> {processo.usuarioNome}</a>
                                                :
                                                <label className='cursor-pointer' title='Não foi fornecido link para o portal da compra!'>{processo.usuarioNome}</label>
                                        }

                                    </label>

                                }
                                <label><span className='font-semibold'>Local S.P:</span> {handleLocalSp(processo.LocalsessaoPublica)}</label>
                                <label><span className='font-semibold'>Situação:</span> {processo.situacaoInterna}</label>

                                <label><span className='font-semibold'>Objeto:</span>
                                    {!showObj && processo.objeto && processo.objeto.length >= 30 ? `${processo.objeto.slice(0, 30)}...` : processo.objeto}
                                    {processo.objeto && processo.objeto.length >= 30 && (
                                        <span className='font-semibold cursor-pointer' onClick={() => setShowObj(current => !current)}>
                                            {showObj ? "[-]" : "[+]"}
                                        </span>
                                    )}
                                </label>
                            </div>
                        </div>

                        {/* Coluna 3 */}
                        <div className='flex flex-col w-2/12 p-4 mt-5'>
                            <div className='flex flex-col justify-center w-full'>
                                <label><span className='font-semibold'>Nº:</span> {processo.numero}</label>
                                <label><span className='font-semibold'>Tipo:</span> {processo.tipo}</label>
                                <label><span className='font-semibold'>S.R.P:</span> {processo.srp}</label>
                                <label><span className='font-semibold'>V.T.E:</span> {processo.valorestimado === "sigiloso" ? "sigiloso" : `R$ ${processo.valorestimado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}</label>
                                <label><span className='font-semibold'>Exclusividade:</span> {processo.exclusividade}</label>

                                {
                                    processo.link_pncp &&
                                    <a href={processo.link_pncp} target='_blank' className='font-semibold  opacity-85 hover:opacity-100 cursor-pointer underline w-fit'>PNCP</a>}

                            </div>
                        </div>

                        {/* Coluna 4 */}
                        <div className='flex flex-row w-1/3 p-4 items-start'>
                            <div className='flex flex-col justify-start items-center mr-4'>
                                {/* Imagem da logo da empresa */}
                                <div className='w-16 h-16 object-contain rounded-full mb-2'>
                                    {empresa.LogoEmpresa ? (
                                        <img src={`${findLogoEmpresa(processo.empresaSelecionada)}`} className='w-full h-full rounded-full origin-center' alt="logo" />
                                    ) : (
                                        <div className='w-16 h-16 rounded-full flex items-center justify-center border'>
                                            <span>{empresa.NomeEmpresa && empresa.NomeEmpresa.slice(0, 2)}</span>
                                        </div>
                                    )}
                                </div>

                                {/* Imagem do avatar do usuário */}
                                <div className='w-16 h-16 object-contain rounded-full'>
                                    <img src={processo.usuarioSelecionado ? `${findProcessoUsuario(processo.usuarioSelecionado)}` : 'https://portalglc.s3.sa-east-1.amazonaws.com/res/avatar.png'} className='w-full h-full rounded-full origin-center' alt="avatar" />
                                </div>
                            </div>

                            {/* Informações da empresa */}
                            <div className='flex flex-col'>

                                <label><span className='font-semibold'> Nome: </span> {empresa.NomeEmpresa}</label>
                                <label><span className='font-semibold'>CNPJ:</span> {cnpj.format(empresa.CnpjEmpresa)}</label>
                                <label><span className='font-semibold'>Endereço:</span> {empresa.Endereço}</label>
                                <label><span className='font-semibold'>Cidade/UF:</span> {' '}</label>
                                <div className='flex items-center'>
                                    <label className='flex items-center mr-4'><span className='font-semibold'>Fone:</span> {formatTelefone(empresa.Fone)}</label>
                                    <label className='flex items-center'><span className='font-semibold'>CEP: </span>{formatCep(empresa.Cep)}</label>
                                </div>
                                <Link to={`mailto:${empresa.Email}`}>
                                    <span className='font-semibold'>E-mail:</span>
                                    <span className='underline underline-offset-1 opacity-80 hover:opacity-100 cursor-pointer pl-2'>{empresa.Email}</span>
                                </Link>
                                <Link to={empresa.Site}>
                                    <span className='font-semibold'>Site:</span>
                                    <span className='underline underline-offset-1 opacity-80 hover:opacity-100 cursor-pointer pl-2'>{empresa.Site}</span>
                                </Link>

                            </div>
                        </div>


                    </div>
                </div>
            }

            {
                displayMiddle === DisplayMiddle.RESUMO &&
                <>
                    <BottonProcessos processo={processo} />

                </>
            }

        </div>
    )
}

export default MiddleProcessos