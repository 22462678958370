import { cnpj, cpf } from 'cpf-cnpj-validator';

export const checkCpfCnpj = (data: string): boolean => {
    const onlyDigits = data.replace(/\D/g, '');

    if (cpf.isValid(onlyDigits)) {
        return true;
    } else if (cnpj.isValid(onlyDigits)) {
        return true;
    } else {
        console.error('Erro');
        return false;
    }
};