export const useReactQueryDashboard = ()=>{

    const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL;

    type TDashboardData={
        token: string,
        empresaid: string
    }
    const getDashboardData = async(data: TDashboardData)=>{
        const url = `${baseUrl}/api/dashboard/get-chartdata?token=${data.token}&empresaid=${data.empresaid}`

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
            
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const getNotificações = async(empresaid: string, token: string)=>{
        const url = `${baseUrl}/api/dashboard/get-notificacoes?empresaid=${empresaid}&token=${token}`

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
            
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    type TApagarNotificacao ={
        token: string,
        notificacaoid: string
    }

    const removerNotificações = async(data: TApagarNotificacao)=>{
        const url = `${baseUrl}/api/dashboard/apagar-notificacoes?token=${data.token}&notificacaoid=${data.notificacaoid}`;

        try {
             const res = await fetch(url);
             const response = res.json();
             return response
        } catch (error) {
            console.error(error)
            return null
        }
    }

   

    return {
        getDashboardData,
        getNotificações,
        removerNotificações
    }
}