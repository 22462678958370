import axios from "axios";
import { useEffect, useState } from "react";

export const useArquivosUtils =()=>{

    const [fileUpload, setFileUpload] = useState<any>('');

    const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL



    type typeArquivo={
        NomeArquivo?: string,
        CriadoPor? : string,
        Descrição? : string,
        LinkAws?: string,
        ArquivoId?: string
    }

    type typeSalvarArquivo = {
        token: string, 
        empresaid?: string,
        pastaid?: string,
        Arquivo: typeArquivo
    }

    type typeSavlvarArquivoAWS={
        token: string, 
        empresaid?: string,
        Arquivo: HTMLFormElement | any,
        pastaid: string,
        nomeArquivo: string,
        descrição?: string, 
        Size?: number | string
    }


    

    type resType={
        status: number,
        msg: string
    }
    
    const [response, setResponse] = useState<any>('');

    const getArquivos = async(token: string, pastaid: string)=>{

        const url = `${baseUrl}/api/arquivos/get-arquivos?token=${token}&pastaid=${pastaid}`
        try {
            const res= await fetch(url);
            const response = await res.json();
            return response;
            
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const salvarArquivoV2 =async(data: typeSavlvarArquivoAWS)=>{
        const url = `${baseUrl}/api/arquivos/salvar-arquivo?token=${data.token}&&pastaid=${data.pastaid}&&nomeArquivo=${data.nomeArquivo}&&descrição=${data.descrição}&Size=${data.Size}&empresaid=${data.empresaid}`;

       try{
        
        const res: any = await axios({
       
        url: url,
        method: 'POST',
        headers:{
            'Content-Type': 'multipart/form-data'
        },
        data: data.Arquivo,
        onUploadProgress: (p: any)=>{
            const completedUpload = p.loaded/p.total ;
            setFileUpload(completedUpload)
        }
        });

        const response = await res.data;

       return response;

        }
        catch(err){
            console.error(err);
            return null

        }
        

    
    }


    const editarArquivo = async(data: typeSalvarArquivo)=>{
        const url = `${baseUrl}/api/arquivos/editar-arquivo?token=${data.token}` ;

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers:{ 
                    'content-type':'application/json'
                },
                body: JSON.stringify(data)
            });
            const response = await res.json();
            return response;
            
        } catch (error) {
            console.error(error);
            return null;
        }

    }

    type typeApagarArquivo ={
        token: string,
        Arquivo: typeArquivo

    }

    const apagarArquivo = async(data: typeApagarArquivo)=>{
        const url = `${baseUrl}/api/arquivos/apagar-arquivo?token=${data.token}`;

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }

    }


    return{
        getArquivos,
        fileUpload,
        editarArquivo,
        apagarArquivo,
        salvarArquivoV2
    }

}

