
export const useReactQueryComentariosUtils =()=>{

    const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL;

    type TchatUsuario ={
        id            : string | number,
        nome          : string,
        cargoFuncao   : string,
        foto          : string
    }

    type TchatMessage ={
        token: string,
        processoId      : string | number,
        texto           : string,
        usuario: TchatUsuario
    }

    const getMessages = async(token: string, processoid: string)=>{
        const url = `${baseUrl}/api/processos-comentarios/get-comentarios?token=${token}&processoid=${processoid}`;

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }


    }

    const postMessage = async( data: TchatMessage)=>{

        const url = `${baseUrl}/api/processos-comentarios/post-comentarios?token=${data.token}&processoid=${data.processoId}`;

        try {
            
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const response = await res.json();
            return response;

        } catch (error) {
            console.error(error);
            return null;
        }
    }


    type TEdtarComentario = {
        comentarioid: string,
        token: string,
        texto?: string
    }

    const editarComentario = async(data: TEdtarComentario)=>{

        const url = `${baseUrl}/api/processos-comentarios/editar-comentarios?token=${data.token}&comentarioid=${data.comentarioid}`;

        try {
            
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type': 'application/json'
                },
                body: JSON.stringify({texto: data.texto})
            });

            const response = await res.json();
            return response;

        } catch (error) {
            console.error(error);
            return null;
        }
    

    }
    const apagarComentaro = async(data: TEdtarComentario)=>{

        const url = `${baseUrl}/api/processos-comentarios/apagar-comentarios?token=${data.token}&comentarioid=${data.comentarioid}`;

        try {
            
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type': 'application/json'
                }
              
            });

            const response = await res.json();
            return response;

        } catch (error) {
            console.error(error);
            return null;
        }
    

    }

    return{
        postMessage,
        getMessages, 
        editarComentario,
        apagarComentaro
    }

}