import moment from 'moment';
import React, { useEffect, useState } from 'react'




// console.log(  'Diferença definitiva até as 23h: ', tempoDiferença )

const CountTime = ({ dataFinal, pasta }: any) => {

  const [tempoRestante, setTempoRestante] = useState<any>('');
  const [aoVivo, setAoVivo] = useState<boolean>(false);

  const dataAtual = moment();




  const prazoFinal = moment(dataFinal);
  const dataFinal24h = moment().set({ h: 20, minute: 59 });

  useEffect(() => {

    const contagemDoDia = () => {
      const dataAtualAtualizada = moment();
      const diferença2 = moment(prazoFinal).diff(dataAtualAtualizada);

      const dias: any = moment.duration(diferença2).days();
      let horas: any = moment.duration(diferença2).hours();
      let minutos: any = moment.duration(diferença2).minutes();
      let segundos: any = moment.duration(diferença2).seconds();

      if (horas < 10) {
        horas = '0' + horas
      }
      if (minutos < 10) {
        minutos = '0' + minutos
      }
      if (segundos < 10) {
        segundos = '0' + segundos
      }

      const tempoDiferença = `${dias}d ${horas}h ${minutos}m ${segundos}s`

      setTempoRestante(tempoDiferença);

    }

    const diferença = moment(prazoFinal).diff(dataAtual)
    switch (true) {
      case diferença < 0 && !dataAtual.isSame(moment(dataFinal), 'day'):
        return setTempoRestante('Expirado')
        break;
      case diferença > 0 && !(dataAtual.isSame(moment(dataFinal), 'day')):
        contagemDoDia()
        break;
      case (dataAtual.isSame(moment(dataFinal), 'day') && !pasta):
        return setTempoRestante('Ao vivo');
        break;
      case moment.duration(diferença).days() <= 90:
        return setTempoRestante(`${moment.duration(diferença).days()}`)
    }




    const timer = setInterval(() => {
      contagemDoDia()
    }, 1000)

    return () => clearInterval(timer);



  }, [dataFinal])




  return (
    <div>
      {
        tempoRestante === 'Expirado' && <p className='text-red-500 text-xs'> {tempoRestante}</p>
      }

      {
        tempoRestante !== 'Expirado' && tempoRestante !== 'Ao vivo' && <p className='bg-yellow-300 text-xs'>{tempoRestante} </p>
      }

      {
        tempoRestante === 'Ao vivo' && <p className='pulse-animation text-xs'> {tempoRestante} </p>
      }


    </div>
  )
}

export default CountTime