import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react'
import { BsX } from 'react-icons/bs'
import { toast } from 'react-toastify';
import { usePastasUtils } from '../../Utils/ReactQuery/getPastasUtils';
import LoadingPage from '../LoadingPage';
import { Link } from 'react-router-dom';

type Props = {}

const ApagarPasta = ({pasta, fechar}: any) => {


    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false);


    //Mutação ---------------------------
    const {apagarPasta} = usePastasUtils();
    const queryClient = useQueryClient();
    const empresaid = localStorage.getItem('empresaSelecionada');
    const token = localStorage.getItem('token')


    const apagarPastaMutation = useMutation({
        mutationFn: apagarPasta,
        onMutate: async(config)=>{
            setLoading(true);
            // console.log('Mutação iniciada!');
        },
        onSuccess: async(response)=>{
            // console.log("Mutação bem sucedida!", response);
            if(response.status === 1){
                toast.success(response.msg);
                setSucesso(true);
                queryClient.invalidateQueries({queryKey: ["rqPastas"]});
                queryClient.refetchQueries({queryKey: ["rqPastas"]});                
            }else{
                toast.error(response.msg);
                setError(true);
            }

        },
        onSettled: async(reesponse)=>{
            // console.log('Mutação bem sucedida');
            setLoading(false); 
        }

    })

    //Mutação ---------------------------

    const hanldeApagarPasta =(e: any)=>{
        e.preventDefault();
        apagarPastaMutation.mutate({token: token as string, body:{empresaid: empresaid as string, pastaid: pasta._id as string }});
        return;
    }

    if(loading){
        return(
            <div id="crud-modal" tabIndex ={-1} aria-hidden="true" className="overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 ">
                <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <LoadingPage/>
                </div>
                </div>
            </div>
        )
    }
    if(sucesso){
        return(
        <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Pasta apagada com sucesso!</h3>

                        <button onClick={fechar} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Voltar </button>

                    </div>
                </div>
            </div>
        </div>

        )
    }

  return (
    <div>
        
    <div id="popup-modal" tabIndex={1} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative p-4 w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                <div className="p-4 md:p-5 text-center">
                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Deseja apagar esta pasta?</h3>
                    <p>
                        Todos os conteúdos desta pasta serão apagados permanentemente.
                    </p>
                    <div className='flex flex-row justify-between'>
                        <button data-modal-hide="popup-modal" type="button" className="closeButton" onClick={fechar}> 
                            <BsX className='text-xl'/>
                            Cancelar</button>
                        <button data-modal-hide="popup-modal" type="button" className="closeButton" onClick={hanldeApagarPasta}>
                            Apagar pasta
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
  )
}

export default ApagarPasta