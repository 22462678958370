import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { BsPlus, BsX } from 'react-icons/bs'
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { toast } from 'react-toastify';
import LoadingPage from '../LoadingPage';
import { DisplayMiddle } from '../../Utils/types/PortalTypes';
import { CategoriasPadrão, TCategoriaPastas } from '../../Utils/DevUtils/CategoriasFake';
import PastasProcessoCatgorias from './PastasProcessoCatgorias';
import CategoriasDropDown  from '../../Utils/CategoriasDropDown';

const NovaPastaProcesso = ({ processo, fechar, editar, pasta }: any) => {


    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false);

    const [abrirNovaCategoria, setAbrirNovaCategoria] = useState<boolean>(false)


    // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('Processo nova pasta: ', processo)
    //     console.log('Processo nova pasta: ', pasta)
    // },[processo])
    // //~~MANUTENÇÃO~~//

    const [categoria, setCategoria] = useState<TCategoriaPastas>()
    const handleCategoria = (data: TCategoriaPastas)=>{
        
        setCategoria(data)
    }

    const [categoriaModal, setCategoriaModal] = useState<boolean>(false)
    const fecharModal = () => {
        setCategoriaModal(false)
    }



    // Mutation
    const token = localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada')

    const queryClient = useQueryClient();
    const { processosPastas } = useReactQueryProcessos();

    const pastaProcessoMutation = useMutation({
        mutationFn: processosPastas,
        onMutate: async (config) => {
            // console.log('Mutação iniciada!')
        },
        onSuccess: async (response) => {

            // console.log('Mutação bem sucedida: ', response)

            queryClient.invalidateQueries({ queryKey: ['rqPastasProcesso'] })
            queryClient.fetchQuery({ queryKey: ['rqPastasProcesso'] })
            queryClient.refetchQueries({ queryKey: ['rqPastasProcesso'] })

            if (response.status === 1) {
                toast.success(response.msg)
                setLoading(false);
                setSucesso(true);
            }
            if (response.status === 3) {
                toast.error(response.msg)
            }
        },
        onError: async (err) => {
            console.error(err.message);

        }, onSettled: async (data) => {
            // console.log('Mutação concluida!');
            setLoading(false)
            fechar()
        }
    })


    // Mutation
    const handleNovaPasta = (e: any) => {

        e.preventDefault()
        const formData = new FormData(e.currentTarget);

        const pastaData = {
            nomePasta: formData.get('nomePasta'),
            dataInicio: formData.get('dataInicio'),
            dataVencimento: formData.get('dataVencimento'),
            categoria: categoria?.label,
            valor: formData.get('valor'),
            status: formData.get('status'),
        }

        pastaProcessoMutation.mutate({
            empresaid: processo.empresaSelecionada as string,
            token: token as string,
            action: 'nova',
            processoid: processo._id,
            data: {
                nomePasta: pastaData.nomePasta as string || "",
                categoria: pastaData.categoria as string || "",
                valor: pastaData.valor as string || "",
                status: pastaData.status as string || "",
                processoId: processo._id,
                dataInicio: pastaData.dataInicio as string || "",
                dataVencimento: pastaData.dataVencimento as string || "",

            }
        })
    }


    const handleEditarPasta = (e: any) => {
        e.preventDefault()
        const formData = new FormData(e.currentTarget);


        const pastaData = {
            nomePasta: formData.get('nomePasta') || pasta.nomePasta,
            dataInicio: formData.get('dataInicio') || pasta.dataEmissao,
            dataVencimento: formData.get('dataVencimento') || pasta.dtaVencimento,
            categoria: categoria?.value || pasta.ategoria,
            valor: formData.get('valor') || pasta.valor,
            status: formData.get('status') || pasta.status,
        }

        pastaProcessoMutation.mutate({
            empresaid: processo.empresaSelecionada as string,
            token: token as string,
            action: 'editar',
            processoid: processo._id,
            pastaid: pasta._id,
            data: {
                nomePasta: pastaData.nomePasta as string || pasta.nomePasta,
                categoria: pastaData.categoria as string || pasta.categoria,
                valor: pastaData.valor as string || pasta.valor,
                status: pastaData.status as string || pasta.status,
                processoId: processo._id,
                dataInicio: pastaData.dataInicio as string || pasta.dataInicio,
                dataVencimento: pastaData.dataVencimento as string || pasta.dataVencimento,

            }

        })
    }


    const fecharNovaPasta = (e: any) => {
        setSucesso(false)
        fechar(e)
    }

    // Tailwind -------------------------------------------------

    const selectInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
    const optionInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";

    // Tailwind -------------------------------------------------


    if (sucesso) {
        return (
            <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden bg-black bg-opacity-30 fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-md max-h-full">

                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Pasta criada com sucesso!</h3>

                            <div className='flex flex-row justify-center'><button onClick={fecharNovaPasta} data-modal-hide="popup-modal" type="button" className="closeButton ">Fechar</button></div>

                        </div>
                    </div>
                </div>
            </div>

        )
    }

    return (
        <div>
            <div id="crud-modal" tabIndex={-1} className="overflow-y-auto bg-black bg-opacity-30 overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">

                {
                    categoriaModal
                    &&
                    <PastasProcessoCatgorias novaCat={true} fechar={fecharModal}
                    />
                }

                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                        {
                            loading && <LoadingPage />
                        }


                        <form className="p-4 md:p-5" onSubmit={editar ? handleEditarPasta : handleNovaPasta}>
                            <div className="grid gap-4 mb-4 grid-cols-2">


                                <div className="col-span-2">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome da pasta </label>
                                    <input required={editar ? false : true} type="text" name="nomePasta" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={editar ? pasta.nomePasta : "Nome da pasta"} />
                                </div>

                                <div className="col-span-2">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Valor </label>
                                    <input type="number" step={"0.01"} name="valor" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={editar ? pasta.valor : "Valor"} />
                                </div>

                                <div className="col-span-2">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data inicio </label>

                                    <input type="datetime-local" name="dataInicio" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required={true} />

                                </div>

                                <div className="col-span-2">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data vencimento </label>
                                    <input type="datetime-local" name="dataVencimento" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Categoria" />
                                </div>

                                <div >
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Status </label>
                                    <select name="status" id="status" className={`${selectInput}`} required={editar ? false : true}>
                                        <option className={`${optionInput}`} selected={editar && pasta.status === "Nenhum" ? true : false} value="Nenhum"> Nenhum</option>
                                        <option className={`${optionInput}`} selected={editar && pasta.status === "Entregue" ? true : false} value="Entregue"  >Entregue</option>
                                        <option className={`${optionInput}`} selected={editar && pasta.status === "Distratado" ? true : false} value="Distratado">Distratado</option>
                                        <option className={`${optionInput}`} selected={editar && pasta.status === "Encerrado" ? true : false} value="Encerrado">Encerrado</option>

                                        <option className={`${optionInput}`} selected={editar && pasta.status === "Deferido" ? true : false} value="Deferido">Deferido</option>
                                        <option className={`${optionInput}`} selected={editar && pasta.status === "Indeferido" ? true : false} value="Indeferido">Indeferido</option>

                                    </select>
                                </div>

                                <div>

                                    <div className='flex flex-row justify-between'>
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Categoria </label>

                                        <label className='text-sm flex font-medium flex-row text-blue-600 opacity-60 hover:opacity-100 hover:underline' onClick={() => setCategoriaModal(prev => !prev)}>
                                            <BsPlus className='mt-1' />Adicionar</label>


                                    </div>
                                    <div>
                                        <CategoriasDropDown padrao={{label: 'Documentos', value: 'Documentos'}} options={{drection: 'up'}} changeCategoria={handleCategoria}/>
                                    </div>




                                </div>

                            </div>

                            <div className='flex flex-row justify-between'>
                                <button type='button' onClick={fechar} className="closeButton">
                                    <BsX className='text-xl' />
                                    Cancelar
                                </button>
                                <button type="submit" className="confirmButton">
                                    <BsPlus className='text-xl' />
                                    {editar ? 'Salvar alterações' : 'Criar pasta'}
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NovaPastaProcesso