import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import LoadingPage from '../LoadingPage';
import SolicitarAcesso from './SolicitarAcesso';


const VerificarAcesso = (Props: { close: any, acessos: any, acessoLogIn: any, loading: boolean, success: boolean, empresas: any, usuario: any }) => {



    const [acessos, setAcessos] = useState<[String]>(['']);
    const [nome, setNome] = useState<String>('Aguarde ...');
    const [empresas, setEmpresas] = useState<[string]>(['']);

    const [solictarNovoAcesso, setSolicitarNovoAcesso] = useState<boolean>(false);

    const handleLoginParent = (e: any, afiliação: string) => {
        e.preventDefault();
        Props.acessoLogIn(e.target.id, afiliação);
        return;
    }

    useEffect(() => {
        if (Props.acessos) {
            const acessoArr = Props.acessos.Acessos
            const nomeArr = Props.acessos.Nome
            setAcessos(acessoArr);
            setNome(nomeArr);
            setEmpresas(Props.empresas)
        }
    }, [Props.acessos]);

    const findEmpresa = (cnpj: string) => {
        const nomeEmpresa: any = empresas.find((i: any) => {
            return i.CnpjEmpresa === cnpj
        })

        return nomeEmpresa.NomeEmpresa;

    }

    if (Props.success) {
        return (
            <div id="select-modal" tabIndex={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex flex-col justify-center items-center">
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <LoadingPage />
                </div>
            </div>
        )

    }

    if (Props.loading) {
        return (
            <div id="select-modal" tabIndex={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex flex-col justify-center items-center">
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <LoadingPage />
                </div>
            </div>
        )
    }

    const fecharSolicitação = () => {
        setSolicitarNovoAcesso(false)
    }

    return (

        <div id="select-modal" tabIndex={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex flex-col justify-center items-center">
            <div className="relative p-4 w-full max-w-md max-h-full">

                {
                    solictarNovoAcesso &&
                    <SolicitarAcesso usuario={Props.usuario} fechar={fecharSolicitação} />

                }

                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        {
                            acessos.length > 0 ?
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-400">
                                    Acessos disponíveis para <span className='text-black dark:text-white font-semibold'> {Props.acessos && nome}</span>
                                </h3>
                                :
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-400"> </h3>
                        }
                        <button onClick={Props.close} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="select-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>

                        </button>
                    </div>

                    <div className="p-4 md:p-5">
                        <p className="text-gray-500 dark:text-gray-400 mb-4">Selecione um acesso disponível:</p>
                        <ul className="space-y-4 mb-4">
                            {

                                acessos && acessos.length > 0 ? acessos.map((ac: any) => (
                                    <li key={ac.TipoAcesso as string} id={`${ac.TipoAcesso}`} onClick={(e) => handleLoginParent(e, ac.Afiliação)} className=" z-10 w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500"  >
                                        <label className="w-full pointer-events-none inline-flex items-center justify-between">
                                            <div className="block">
                                                <div className="w-full text-lg font-semibold">{ac.TipoAcesso}</div>
                                                <div className="w-full text-gray-500 dark:text-gray-400">{findEmpresa(ac.Afiliação as string)}</div>
                                            </div>

                                            <svg className="w-4 h-4 ms-3 rtl:rotate-180 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" /></svg>
                                        </label>
                                    </li>
                                ))
                                    :
                                    <div>
                                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                            O Cpf/Cnpj informado não possui nenhum cadastro!
                                        </p>

                                        <Link className="cursor-pointer text-base leading-relaxed text-gray-400 hover:text-gray-500 dark:text-gray-300 hover:dark:text-gray-300 opacity-80 hover:opacity-100  transition-opacity" to={'/registrar'}> Registre-se</Link>
                                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400"> ou </p>
                                        <Link className="cursor-pointer text-base leading-relaxed text-gray-400 hover:text-gray-500 dark:text-gray-300 hover:dark:text-gray-300 opacity-80 hover:opacity-100  transition-opacity" to={'/registrar'}> Solicite outra forma de acesso</Link>
                                    </div>
                            }

                            <div className='border border-opacity-95 rounded-lg w-fit cursor-pointer opacity-90 hover:opacity-100 p-2' onClick={() => setSolicitarNovoAcesso(true)}>
                                Solicitar acesso a outra empresa
                            </div>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerificarAcesso