import { useQuery } from '@tanstack/react-query';
import React, { Suspense, useEffect, useState } from 'react';
import AlertaCadastro from '../Components/Alertas';
import {useReactQueryUsers} from '../Utils/ReactQuery/getUser'
import {useReactQueryEmpresas}  from '../Utils/ReactQuery/getEmpresas'
import { Route, Routes } from 'react-router-dom';
import Perfil from './Perfil';
import { toast } from 'react-toastify';
import Acessos from './Acessos';
import CriarAcesso from './CriarAcesso';
import { useGetAcessos } from '../Utils/ReactQuery/getAcessos';
import LoadingPage from '../Components/LoadingPage';
import { usePreChecagem } from '../Utils/usePreChecagem';
import Administrador from './Administrador';
import { usePortaisUtils } from '../Utils/ReactQuery/getPortais';
import PortaisListar from './PortaisListar';
import Pastas from './Pastas';
import DashboardPage from './DashbardPage';
import BemVindo from './BemVindo';
import Home from '../Components/PortalComponents/Home';
import AdminRoute from '../Components/AdminRoute';


const Portal = () => {

  
  const [error, setError] = useState<Boolean>(false);
  const [errorMsg, setErrorMsg] = useState<String>('');


    const {getUser} = useReactQueryUsers();
    const {listarEmpresas} = useReactQueryEmpresas();
    const {getPortais} = usePortaisUtils();

    const token = localStorage.getItem("token");
    const {data, isLoading, isError, isSuccess} = useQuery({queryFn: ()=> getUser(token as string), queryKey: ['rqUser']});
   
    const user = data.user || null;


    const userEmpresaPadrão = data.empresaPadrão || null;
    
    const {data: empresaData, isLoading: empresaisLoading, isError: empresaIsError, isSuccess: empresaIsSuccess} = useQuery({queryFn: ()=> listarEmpresas(user.Acessos.Empresas), queryKey: ['rqEmpresa'], staleTime: 60000});

  


  const {cadastroState} = usePreChecagem(empresaData, user, userEmpresaPadrão)



  const cadastroForm = {
    userComplete: cadastroState.userComplete,
    empresaComplete: cadastroState.empresaComplete,
    pagamentoComplete: cadastroState.pagamentoComplete
  }

 // Abstrair???-------------------------------------------
 


 // Abstrair???-------------------------------------------

   useEffect(()=>{
    if(data && data.status === 7){

      setError(true);
      setErrorMsg(data.msg as string);

      setTimeout(()=>{
        window.location.href = '/'
      },3000)
    
    }
   },[data])

  return (
    <div className='min-h-dvh flex flex-col bg-slate-100'>
      {
        error && <AlertaCadastro message={`${errorMsg}`} state ={'error'}/>
      }
      
    
      <Suspense fallback ={<LoadingPage/>}>
      <Routes>
        <Route path='/' element={<Home empresaData ={empresaData} />}/>
        <Route path='/bem-vindo' element={<BemVindo/>}/>
        <Route path='/perfil' element ={<Perfil user={data.user} cadastroState ={cadastroForm}/>}/>
        <Route path='/dashboard' element ={<DashboardPage/>}/>
        <Route path ='/acessos' element={<Acessos user ={user} empresaData={empresaData} />}/>
        <Route path='/acessos/criar-acesso' element ={<CriarAcesso user={user} />}/>

       
        <Route path ='/admin' element={<Administrador user = {user}/>}/>
        
        
        <Route path='/portais/:portalid' element ={<PortaisListar empresaData = {empresaData}/>}/>
        <Route path='/pastas/:pastaid' element ={empresaisLoading ?   <LoadingPage/> : <Pastas empresaData ={empresaData}/> }/>
      </Routes>
      </Suspense>
      
      
    </div>
  )
}

export default Portal