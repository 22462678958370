import { useQuery } from '@tanstack/react-query';
import React, { useLayoutEffect, useState } from 'react';
import { BsFolder, BsFolderPlus, BsPencil, BsTrash } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import LoadingComponent from '../LoaadingComponent';
import styles from '../PastasComponents/Pastas.module.css';
import PastaConteudo from '../PastasComponents/PastaConteudo';
import { DisplayPastas, typePasta } from '../../Utils/types/pastasTypes';
import ApagarPasta from '../PastasComponents/ApagarPasta';
import PastaInfo from '../PastasComponents/PastaInfo';
import { formatarData } from '../../Utils/formatarData';
import NovaPasta from '../PastasComponents/NovaPasta';
import { MdLink, MdLinkOff } from 'react-icons/md';
import { converterValor } from '../../Utils/ConverterValor';
import { useUserContext } from '../../Context/useUserContext';
import { useGetPerissions } from '../../Utils/verificarAcesso';
import { toast } from 'react-toastify';
import moment from 'moment';

const PastasTable = ({ pastasData }: any) => {
  const [pasta, setPasta] = useState<any>();
  const [empresaSelecionada, setEmpresaSelecionada] = useState<string | null>(
    localStorage.getItem('empresaSelecionada') || null
  );

  const [userContext] = useUserContext();
  const { verifyAdm } = useGetPerissions(userContext);
  const result = verifyAdm();

  useLayoutEffect(() => {
    setEmpresaSelecionada(localStorage.getItem('empresaSelecionada'));
  });

  const token = localStorage.getItem('token');
  const { selecionarEmpresa } = useReactQueryEmpresas();

  const { data: empresaData, isLoading: empresaIsLoading } = useQuery({
    queryKey: ['rqEmpresaSelecionada'],
    queryFn: () =>
      selecionarEmpresa({
        token: token as string,
        empresaid: empresaSelecionada as string,
      }),
  });

  const [editando, setEditando] = useState<boolean>(false);
  const [apagando, setApagando] = useState<boolean>(false);
  const [criarPasta, setCriarPasta] = useState<boolean>(false);

  const [expandedPastaId, setExpandedPastaId] = useState<string | null>(null);

  const handleAbrirPasta = (e: any, pasta: any) => {
    e.preventDefault();
    setExpandedPastaId((prevId) => (prevId === pasta._id ? null : pasta._id));
  };

  const handleEditarPasta = (pasta: typePasta) => {
    setPasta(pasta);
    setEditando(true);
  };

  const hanldeApagarPasta = (pasta: typePasta) => {
    setPasta(pasta);
    setApagando(true);
  };

  const fechar = (e: any) => {
    setEditando(false);
    setApagando(false);
    setCriarPasta(false);
    setPasta('');
    return;
  };

  const pastaStatus = (pasta: any) => {

    const dataVencimento = Date.parse(pasta.DataVencimento);
    const dataAtual: any = new Date().getTime();
    const statusNumber: any = (
      (dataVencimento - dataAtual) /
      (1000 * 60 * 60 * 24)
    ).toFixed(0);

    switch (true) {
      case statusNumber > 0 && statusNumber > 7:
        return 'Vigente';
      case statusNumber <= 0:
        return 'Vencido';
      case statusNumber <= 7 && statusNumber < 0:
        return `Vencendo`;
      default:
        return 'Vigente';
    }

  };

  const verficarVencimento = (dataVencimento: string)=>{
    const dataAtual = moment();
    const dataFinal = moment(dataVencimento);
    const dif = dataFinal.diff(dataAtual, 'days');

    if(!dataVencimento){
      return 'SineDie'
    }


    switch(true){
      case dif < 0 && dif > -90:
        return `Vencidoa a ${Math.abs(dif)} dias`
      case dif < 0 && dif < 90:
        return 'Vencido'
      case dif > 0 && dif < 7:
        return `Vence em ${Math.abs(dif)} dias`;
      case dif > 7:
        return 'Vigente'
      default:
        return'SineDie'
    }

  }


  // Classes Tailwind CSS para estilização do status
  const statusVigente = 'text-green-500 font-semibold';
  const statusVencido = 'text-red-500 font-semibold';
  const statusVencimentoProximo = 'text-yellow-500 font-semibold';

  if (!pastasData) {
    return (
      <div className={`w-full dark:bg-gray-600 shadow-2xl ${styles.dashboardPastas}`}>
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className={`w-full dark:bg-gray-600 h-fit`}>
      {apagando && <ApagarPasta pasta={pasta} fechar={fechar} />}

      {editando && <PastaInfo pasta={pasta} fechar={fechar} editando={true} />}

      {criarPasta && <NovaPasta fechar={fechar} />}

      <div className={`flex flex-col ${styles.dashboardPastas} h-52`}>
        <div className="relative overflow-x-auto">
          <table className="table-fixed w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className='bg-gray-100'>
                <th
                  scope="col"
                  className="w-28 cursor-pointer items-center flex my-2 pl-2"
                  onClick={result ? () => setCriarPasta((current) => !current) : () => toast.error("Usuário não autorizado!")}
                >
                  <BsFolderPlus className="mr-1 cursor-pointer" />
                  Nova pasta
                </th>
                <th scope="col" className="w-36 text-center">Ação</th>
                <th scope="col" className="w-36 text-center">Categoria</th>
                <th scope="col" className="w-36 text-center">Emissão</th>
                <th scope="col" className="w-36 text-center">Vencimento</th>
                <th scope="col" className="w-36 text-center">Valor</th>
                <th scope="col" className="w-36 text-center">Status</th>
                
              </tr>
            </thead>
            <tbody>
              {pastasData &&
                pastasData.map((pasta: typePasta) => (
                  <React.Fragment key={pasta._id}>
                    <tr className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${pastaStatus(pasta) === 'Vencido' ? 'text-red-700': pastaStatus(pasta) === 'Vencendo'?'text-yellow-400':  'text-black'} `}>
                      <th
                        scope="row"
                        className="w-28 font-medium underline underline-offset-2 whitespace-nowrap dark:text-white"
                      >
                        <Link
                          to={`/portal/pastas/${pasta.NomePasta}`}
                          className="cursor-pointer w-fit"
                        >
                          {pasta.NomePasta.replace(/_/g, " ")?.length >= 55
                            ? `${pasta.NomePasta.replace(/_/g, " ").slice(0, 55)}...`
                            : pasta.NomePasta.replace(/_/g, " ")}
                        </Link>

                      </th>

                      <td className="w-36 inline-flex gap-1 justify-center">
                        {pasta.LinkOEA ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={pasta.LinkOEA}
                            className="iconPastaTable"
                          >
                            <MdLink />
                          </a>
                        ) : (
                          <span className="iconPastaTable">
                            <MdLinkOff />
                          </span>
                        )}

                        <BsFolder
                          className={`iconPastaTable ${expandedPastaId === pasta._id ? 'text-blue-500' : ''
                            }`}
                          onClick={(e) => handleAbrirPasta(e, pasta)}
                        />
                        <BsPencil
                          className="iconPastaTable"
                          onClick={() => handleEditarPasta(pasta)}
                        />
                        <BsTrash
                          className="iconPastaTable"
                          onClick={() => hanldeApagarPasta(pasta)}
                        />
                      </td>


                      <td className="w-36 text-center">{pasta.Categoria}</td>

                      <td className="w-36 text-center">
                        {formatarData(pasta.DataEmissao as string)}
                      </td>
                      <td className="w-36 text-center">
                        {pasta.DataVencimento === "" || !pasta.DataVencimento ? 'SineDie' : formatarData(pasta.DataVencimento as string)}
                      </td>

                      <td className="w-36 text-center">
                        {converterValor(pasta.Valor)}
                      </td>

                      <td className="w-36 text-center">
                        <span
                          className={
                            pastaStatus(pasta) === 'Vigente'
                              ? statusVigente
                              : pastaStatus(pasta) === 'Vencido'
                                ? statusVencido
                                : statusVencimentoProximo
                          }
                        >
                          {verficarVencimento(pasta.DataVencimento as string)}
                        </span>
                      </td>
                      
                    </tr>
                    {/* Conteúdo expandido */}
                    {expandedPastaId === pasta._id && (
                      <PastaConteudo pasta={pasta} displayPastas={DisplayPastas.DETAILS} />
                    )}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PastasTable;
