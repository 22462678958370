import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BsDownload, BsEye, BsFilePlus, BsPencil, BsShare, BsTrash } from 'react-icons/bs'
import { FaFolderOpen } from 'react-icons/fa'
import { TArquivosPortais, TPastasPortais } from '../../Utils/types/portaisTypes'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { formatarData } from '../../Utils/formatarData'
import { toast } from 'react-toastify'
import { RiShare2Fill } from "react-icons/ri";
import { SlShareAlt } from "react-icons/sl";
import { TArquivosUsuario, TPastasUsuario } from '../../Utils/types/userType'
import NovoArquivoPerfil from './NovoArquivoPerfil'
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser'
import { countFolderSize } from '../../Utils/CountFileSize'
import NovoArquivoProcesso from '../ProcessosComponents/NovoArquivoProcesso'
import { converterValor } from '../../Utils/ConverterValor'
import moment from 'moment'
import LoadingUploadArquivo from '../LoadingUploadArquivo'
import LoadingSmall from '../LoadingSmall'



const PerfilArquivos = ({user, admin}: any) => {

    const [novoArquivoPortal, setNovoArquivoPortal] = useState<Boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false);
    const [editar, setEditar] = useState<boolean>(false);
    const [arquivoSelecionado, setArquivoSelecionado] = useState<TArquivosUsuario | null>(null);
    const [confirmarApagarArquivo, setConfirmarApagar] = useState<boolean>(false);
    
    let userid ='';
    if(admin){
        userid =user._id
    }else{
        userid = user.id
    }

    const empresaPadrão = localStorage.getItem('empresaPadrão');
    const {getUserArquivos, apagarUserArquivo} = useReactQueryUsers();
    const token = localStorage.getItem('token');
    const {data: arquivosUserData, isLoading} = useQuery({
        queryFn: ()=> getUserArquivos(token as string, userid, empresaPadrão as string),
        queryKey: ["rqArquivosUser", token, user, userid]
    });

    const empresaSelecionada = localStorage.getItem('empresaSelecionada')
    const queryClient =useQueryClient();

    const apagarArquivoMutation = useMutation({
        mutationFn: apagarUserArquivo,
        onMutate: async(config)=>{
            // console.log('Mutação iniciada!');
            setLoading(true);
        },
        onSuccess: async(response)=>{
            // console.log('Mutação bem sucedida: ', response);
            queryClient.invalidateQueries({queryKey: ['rqArquivosUser']});
            queryClient.fetchQuery({queryKey: ['rqArquivosUser']});
            queryClient.refetchQueries({queryKey: ['rqArquivosUser']});

            if(response.status === 1){
                toast.success(response.msg);
                setSucesso(true)
            }else{
                toast.error(response.msg);
            }
        },
        onError: async(error)=>{
            console.error(error.message);
            toast.error(error.message)
        },
        onSettled(data, error, variables, context) {
            // console.log('Mutação concluída!');
            setLoading(false);
        },
    })


    const fechar =()=>{
        setNovoArquivoPortal(false);
        setEditar(false);
        setArquivoSelecionado(null)
        setConfirmarApagar(false);
        setArquivoSelecionado(null)
    }

    const apagarArquivo =(arquivo: TArquivosUsuario)=>{
        setArquivoSelecionado(arquivo)
        setConfirmarApagar(true)
        
    }
    const handleApagarArquivo =(e: any)=>{
        e.preventDefault();
        apagarArquivoMutation.mutate({token: token as string, arquivoid: arquivoSelecionado?._id as string, empresaid: empresaSelecionada as string});
    }

    const dowloadFile = (e: any, fileUrl: string, fileName: string)=>{
        e.preventDefault();
        const nomeValido = `${fileName.replaceAll(/\s/g, '_')}.pdf`

        try {
            fetch(fileUrl).then((response)=> response.blob()).then((blob)=> {
                const url = window.URL.createObjectURL( new Blob([blob]));
                const link = document.createElement('a');
                link.href =url;
                link.download = `${nomeValido}`
    
                document.body.appendChild(link);
    
                link.click();
                
                link.parentNode?.removeChild(link);
                window.URL.revokeObjectURL(fileUrl)
    
            });
        } catch (error) {
            console.error(error);
            toast.error('Erro no dowload!');
            return;
            
        }
    }


    const ajustarCategoria =(categoria : string)=>{

        switch(true){
            case categoria === 'os':
                return `Ordem Serviço'ᴮ'`
                break;
            case categoria === 'act':
                return 'Atestdo de capacidade técnica'
                break
            case categoria === 'objeto':
                return 'Proposta readequada'
                break
            case categoria === 'arp':
                return 'Ata registros preços'
                break
            default: return categoria
        }

    }

    const dataAtual = moment();

    const checkStatus =(dataVencimento: string, status: string)=>{


        if(!dataVencimento && status === 'Nenhum'){
            return <span className=' text-green-500'> --- </span>
        }
     
        if(!dataVencimento && !status){
            return <span className=' text-green-500'> --- </span>
        }

        const dataFinal = moment(dataVencimento);
        const dif = moment(dataFinal).diff(dataAtual, 'days');
        const hours = moment(dataFinal).diff(dataAtual, 'hours');


        switch(true){
            case status === 'Deferido':
                return <span className=' text-green-500'> Deferido </span>
                break;
            case status === 'Indeferido':
                return <span className=' text-red-500'> Indeferido </span>
                break;
            case dif === 0 && status ==='Nenhum' || dif === 0 && !status :
                return <span className=' text-yellow-500'> Vence hoje</span>
            case dif < 90 && dif > 0 &&status ==='Nenhum' || dif < 90 && dif> 0 && !status :
                return <span className=' text-yellow-500'> Vence em {dif} dias</span>
                break;
            case dif > 0 && status ==='Nenhum' || dif > 0 && !status:
                return <span className=' text-green-500'> Vigente</span>
                break;
            case dif < 0 && status === 'Nenhum' || dif < 0 && !status:
                return <span className=' text-red-500'> Vencido </span>
                break;
            case status === 'entregue':
                return <span className=' text-green-500'> Entregue </span>
                break;
            case status === 'distratado':
                return <span className=' text-red-500'> Distratado </span>
                break;
            case status === 'encerrado':
                return <span className=' text-red-500'> Encerrado </span>
                break;
        }
    }
   

    // //~~MANUTENÇO~~//
    // useEffect(()=>{
        
    // console.log('User----------->', user)
    // },[user])
    // //~~MANUTENÇO~~//

    if(sucesso){
        return(
        <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
 
                    
                        {confirmarApagarArquivo ? 
                       <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Arquivo inserido com sucesso!</h3>
                       :
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Arquivo inserido com sucesso!</h3>
                        }
                        <button onClick={()=> setSucesso(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Fechar</button>

                        

                    </div>
                </div>
            </div>
        </div>

        )
    }
    


  return (
    <div className='overflow-y-auto max-h-96 h-96 max-w-6xl' >
        <h3> Armazenamento total:  {arquivosUserData && countFolderSize(arquivosUserData.arquivos) } </h3>


        {
            confirmarApagarArquivo &&
            <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Deseja apagar este arquivo? </h3>

                        <div>
                            <label> {arquivoSelecionado?.NomeDoArquivo}</label>
                            <small> Este arquivo será apagado permanentemente</small>
                        </div>

                        <button onClick={handleApagarArquivo} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Confirmar</button>

                        <button onClick={fechar} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Fechar</button>

                    </div>
                </div>
            </div>
        </div>

        }
            {
                // novoArquivoPortal && <NovoArquivoPerfil fechar ={fechar} user ={user} editar={false} />
            }


            {
                novoArquivoPortal && <NovoArquivoProcesso user ={user} fechar={fechar} editar={false}/>
            }

        <div className='fadeIn '>

           { 
           !admin &&
            <div className='inline-flex cursor-pointer opacity-80 hover:opacity-100 transition-opacity w-fit' onClick={()=> setNovoArquivoPortal(current => !current)}>
                <RiShare2Fill className='text-xl mr-2'/> 
                <span className=''> Inserir arquivo </span>

            </div>
            }
            
            { arquivosUserData && arquivosUserData.arquivos.length >= 1 ?
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                    <tr>

                            <th scope="col" className="w-64 pr-6">
                                Nome do arquivo
                            </th>
                            <th scope="col" className="w-48">
                                Opção
                            </th>
                            <th scope="col" className="w-48">
                                Categoria
                            </th>
                            <th scope="col" className="w-48">
                                Emissão
                            </th>
                            <th scope="col" className="w-48">
                                Vencimento
                            </th>
                            <th scope="col" className="w-48">
                                Valor
                            </th>
                            <th scope="col" className="w-48">
                                Status
                            </th>
                  
                    </tr>
                </thead>
                <tbody >
                    {
                        arquivosUserData && arquivosUserData.arquivos.map((arquivo: TArquivosUsuario)=>(
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        
                                <td className="px-6 py-4">
                                <a href={arquivo.LinkAws} target='_blank'>{arquivo.NomeDoArquivo && arquivo.NomeDoArquivo.length > 30 ? `${arquivo.NomeDoArquivo.slice(0, 30)}...`: arquivo.NomeDoArquivo }</a>
                                </td>


                                {/* <td className="px-6 py-4">
                                {arquivo.Descrição && arquivo.Descrição.length > 30 ? `${arquivo.Descrição.slice(0, 30)}...`: arquivo.Descrição }
                                </td> */}

                                <td className="px-6 py-4 flex flex-row gap-2">
                                    <a href={arquivo.LinkAws} target='_blank'>
                                        <SlShareAlt className='cursor-pointer opacity-80 hover:opacity-100' title='Link'/>
                                    </a>
                                    <BsDownload className='cursor-pointer opacity-80 hover:opacity-100' title='Baixar' onClick={(e)=> dowloadFile(e, arquivo.LinkAws as string, arquivo.NomeDoArquivo)}/>

                                    <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100' title='Apagar' onClick={()=> apagarArquivo(arquivo)} />
                                </td>
            
                                {/* <td className="px-6 py-4">
                                    {formatarData(arquivo.createdAt as string)}
                                </td> */}



                                <td className=" ">
                                    {arquivo.categoria ? ajustarCategoria(arquivo.categoria).toUpperCase() : '---'}
                                </td>
                                <td className=" ">
                                    {arquivo.dataInicio ? formatarData(arquivo.dataInicio): "Sine Die"}
                                </td>
                                <td className=" ">
                                    {arquivo.dataVencimento ? formatarData(arquivo.dataVencimento) : "Sine Die"}
                                </td>
                                <td className=" ">
                                    {converterValor(arquivo.valor)}
                                </td>
                           
                                <td className="  inline-flex text-black dark:text-gray-300  gap-4">
                                    {checkStatus(arquivo.dataVencimento, arquivo.status)}
                                </td>

                            </tr>
                        ))
                    }
                    
            
                </tbody>
            </table>
            :
            <>
            <br/>
            <label htmlFor=""> Nenhum arquivo anexado!</label>
            </>
            }

        </div>
    </div>
  )
}

export default PerfilArquivos