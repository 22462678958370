import React from 'react';
import Center from './Center';
import SideNavWrapper from './SideNavWrapper'; // Importando o wrapper

const Home = ({ empresaData }: any) => {
  return (
    <div>
      <div className='grid grid-cols-[50px_minmax(900px,_1fr)_50px]'>
        <SideNavWrapper />
        <Center empresas={empresaData ? empresaData.empresas : null} />
      </div>
    </div>
  );
};

export default Home;
