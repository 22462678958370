import React, { useState } from 'react'
import LoadingPage from '../LoadingPage'
import { BsPlus, BsX } from 'react-icons/bs';
import LoadingComponent from '../LoaadingComponent';
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { usePortaisUtils } from '../../Utils/ReactQuery/getPortais';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';


const AlterarLogoEmpresa = ({empresa, fechar}: any) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [file, setFile] = useState<string>('');
  const [filePreview, setFilePreview] = useState<string>('')

  const handlePreview = (e: any)=>{
    setFilePreview(URL.createObjectURL(e.target.files[0])); 
    setFile(e.target.files[0]);
  }

  //MUTAÇÃO------------------------
  const token = localStorage.getItem('token');
  const empresaPadrão = localStorage.getItem("empresaPadrão")
  const {alterarLogoEmpresa} = useReactQueryEmpresas()

  const queryClient = useQueryClient(); 
  const empresaLogoMutation = (useMutation({
    mutationFn: alterarLogoEmpresa,
    onMutate: async()=>{
      // console.log('Mutação iniciada!');
      setLoading(true);
    },
    onSuccess: async(response)=>{
        // console.log('Mutação bem sucedida: ', response);

        queryClient.invalidateQueries({queryKey: ['rqEmpresa']});
        queryClient.refetchQueries({queryKey: ['rqEmpresa']});

        if(response.status === 1){
          toast.success(response.msg);
          setLoading(false);
          setSuccess(true)
        }
        if(response.status === 3){
          toast.error(response.msg)
        }
      },onError: async(err)=>{
        console.error(err.message);
        toast.error(err.message);

      },
      onSettled: async(data)=>{
        // console.log('Mutação cncuída!');
        setLoading(false);
        window.location.reload()
      }
  }))
  
  
  //MUTAÇÃO------------------------

  const handleAlterarLogoEmpresa =(e: any)=>{
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    formData.append('file', file);

    empresaLogoMutation.mutate({token: token as string, empresaid: empresaPadrão as string, Arquivo: formData})
  }


if(loading){
  return(
    <div id="popup-modal" tabIndex={1} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative p-4 w-full max-w-md ">
            
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col gap-4 p-4 min-h-56">
              <LoadingComponent/>
            </div>
        </div>
    </div>
  )
}

if(success){
  return(
    <div id="popup-modal" tabIndex={1} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative p-4 w-full max-w-md max-h-full flex flex-col justify-center items-center">
            
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col gap-4 p-4 max-h-56">
              <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
              </svg>
             <label htmlFor=""> Logo da empresa alterada com seucesso!</label>

             <button  type="submit" className="closeButton" onClick={fechar}> fechar</button>
            </div>


            
        </div>
    </div>)
  
}

  return (

    <div>
      <div id="popup-modal" tabIndex={1} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative p-4 w-full max-w-md max-h-full">
            
            <form className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col gap-4 p-4" onSubmit={handleAlterarLogoEmpresa}>
             
                <div className='flex flex-row justify-between'>
                    <div className='w-16 h-16 rounded-full p-2 object-contain origin-center mb-3'>
                      
                       
                    {
                        filePreview ?
                        <img src={filePreview } alt={empresa.NomeEmpresa.slice(0, 1)}  className='w-12 h-12 rounded-full' />
                        :<img src={empresa.LogoEmpresa } alt={empresa.NomeEmpresa.slice(0, 1)}  className='w-12 h-12 rounded-full' />

                    }

                      
                    </div>
                    <span className='p-0 m-0'> <BsX className='text-3xl w-fit cursor-pointer opacity-80 hover:opacity-100 transition-opacity' onClick={fechar}/></span>
                </div>
              
              <input type="file" name="file" id='fileInput' onChange={handlePreview}/>
              <small> São aceitos somente arquivos .jpg e .png com menos de 5mb </small>

              <button  type="submit" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" > Alterar imagem</button>
               
            </form>
         
        </div>
      </div>
    </div>
  )
}

export default AlterarLogoEmpresa