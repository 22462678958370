import React from 'react'
import RecuperarSenhaForm from '../Components/RecuperarSenhaComponents/RecuperarSenhaForm'

type Props = {}

const RecuperarSenha = (props: Props) => {
  return (
    <div className='min-h-dvh w-full'>

       <RecuperarSenhaForm/>
    </div>
  )
}

export default RecuperarSenha