import { useLayoutEffect, useState } from 'react';
import { BsEye, BsEyeSlash, BsX } from 'react-icons/bs';
import { GrFormCheckmark } from 'react-icons/gr';
import { checkCpfCnpj } from '../../Utils/checkCpfCnpj';
import { formatCpfCnpj } from '../../Utils/formatCpfCnpj';
import { IMaskInput } from 'react-imask';
import { NivelHierarquicoRegistro, RegistroEmpresa } from '../../Utils/types/cadastroTypes';
import { getCnpjData } from '../../Utils/cnpjAutocomplete';
import { cnpj } from 'cpf-cnpj-validator';
import TermosRegistro from './TermosRegistro';
import { toast } from 'react-toastify';
import { useGetAcessos } from '../../Utils/ReactQuery/getAcessos';
import { useMutation, useQuery } from '@tanstack/react-query';
import AlertaCadastroExistente from './AlertaCadastroExistente';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { NivelHierarquico } from '../../Utils/types/userType';


const EmpresaFormEtapaUm = ({ fechar, registrar, acesso }: any) => {
    const [cpfCnpj, setCpfCnpj] = useState<string>('');
    const [cpfCnpjVerify, setCpfCnpjVerify] = useState<boolean | any>(true);
    const [passWordError, setPasswordError] = useState<boolean>(false);
    const [passwordErrorrMesg, setPasswordErrorMsg] = useState<string>('');
    const [checkPassword, setCheckPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [repetirSenha, setRepetirSenha] = useState<string>('');
    const [nivelHierarquico, setNivelHierarquico] = useState<NivelHierarquicoRegistro | null>(null);

    const [cnpjEmpresa, setCnpjEmpresa] = useState<string>('');

    const [razaoSocial, setRazaoSocial] = useState<string>('');
    const [nomeFantasia, setNomeFantasia] = useState<string>('');
    const [naturezaJuridica, setNaturezaJuridica] = useState<string>('');
    const [mei, setMEI] = useState<Boolean>(false);
    const [portedaEmpresa, setPortedaEmpresa] = useState<string>('');
    const [atividadeEconômicaP, setAtividadeEconômicaP] = useState<any>([]);
    const [atividadeEconômicaS, setAtividadeEconômicaS] = useState<any>([]);
    const [capitalSocial, setCapitalSocial] = useState<string>('');
    const [dataAbertura, setDataAbertura] = useState<string>('');
    const [endereçoEmpresa, setEndereçoEmpresa] = useState<string>('');
    const [complementoEmpresa, setComplementoEmpresa] = useState<string>();
    const [cepEmpresa, setCepEmpresa] = useState<string>('');
    const [foneEmpresa, setFoneEmpresa] = useState<string>('');
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [senha, setSenha] = useState<string>('')

    const [condiçõesLegais, setCondiçõesLegais] = useState<boolean>(false);
    const [politicaPrivacidade, setPoliticaPrivacidade] = useState<boolean>(false);
    const [termoAceito, setTermoAceito] = useState<boolean>(false)

    const [cadastroExistente, setCadastroExistente] = useState<boolean>(false);
    const [empresaData, setEmpresaData] = useState<any>({});
    const [verificaçãoCadastro, setVerificaçãoCadasto] = useState<boolean>(false);


    const fecharCadastro = () => {
        setCadastroExistente(false);
        setEmpresaData('');
    }



    // Verificar se usuário já possui acesso
    const { verificarCadastro } = useGetAcessos();
    const { data: verCadData, isLoading, refetch } = useQuery({
        queryFn: () => verificarCadastro(cpfCnpj),
        queryKey: ['rqVerificarCadastro'],
    });

    useLayoutEffect(() => {
        if (verCadData && verCadData.status === 4) {
            setVerificaçãoCadasto(true);
        }
        if (verCadData && verCadData.status === 1) {
            setVerificaçãoCadasto(false);
        }
    }, [verCadData]);

    const verificarCpfCnpj = () => {
        setCpfCnpjVerify(checkCpfCnpj(cpfCnpj));

        if (checkCpfCnpj(cpfCnpj)) {
            refetch();

            if (verCadData?.status === 4) {
                setVerificaçãoCadasto(true);
            }
            if (verCadData?.status === 1) {
                setVerificaçãoCadasto(false);
            }
        }
    }

    const verificarCaddastroMation = useMutation({
        mutationFn: verificarCadastro,
        onSuccess: (response) => {
            if (response.status === 4) {
                setCadastroExistente(true);
            }
            if (response.status === 1) {
                setCadastroExistente(false);
            }
        },
        onError: (error: any) => {
            console.error(error.message);
        }
    });


    const checkPass = (e: any) => {

        e.preventDefault();
        if (senha.length > 0 && repetirSenha.length < 8) {
            setPasswordError(true);
            setPasswordErrorMsg('A senha deve conter mais de 8 dígtos');
            return;
        }

        if (repetirSenha.length >= 1 && repetirSenha !== senha) {
            setPasswordError(true);
            setPasswordErrorMsg('As senhas digitadas não coincidem!');
            return;
        }
        setPasswordError(false);

    };


    const handleEtapa = (e: any) => {
        e.preventDefault();

        if (!termoAceito) {
            toast.error("É necessário aceitar os termos de consentimento para registrar-se no portal!", { autoClose: 3000 });
            return;
        }

        const formData = new FormData(e.currentTarget);

        searchCnpj(cnpjEmpresa);

        const dadosEtapa = {
            Nome: formData.get('Nome'),
            Email: formData.get('Email'),
            Senha: formData.get('Senha'),
            CpfCnpj: formatCpfCnpj(formData.get('CpfCnpj') as string),
            CnpjEmpresa: formatCpfCnpj(formData.get('CnpjEmpresa') as string),
            Telefone: formData.get("Telefone"),
            CargoFuncao: formData.get('CargoFuncao'),
            RazãoSocial: formData.get('RazãoSocial'),
            Acesso: acesso,
            NaturezaJuridica: naturezaJuridica,
            CapitalSocial: capitalSocial,
            RazaoSocial: razaoSocial,
            NomeFantasia: nomeFantasia,
            DataAbertura: dataAbertura,
            EndereçoEmpresa: endereçoEmpresa,
            ComplementoEmpresa: complementoEmpresa,
            CepEmpresa: cepEmpresa,
            Fone: foneEmpresa,
            PortedaEmpresa: portedaEmpresa,
            MEI: mei,
            AtividadeEconômicaP: atividadeEconômicaP,
            AtividadeEconômicaS: atividadeEconômicaS,
            Afiliação: cnpjEmpresa,
            Cadastro: "Empresa",
            NivelHierarquico: {
                tipo: 'Presidente',
                nivel: 2
            }
        }

        setEmpresaData(dadosEtapa);
        verificarCaddastroMation.mutate(cpfCnpj);

        console.log("teste", dadosEtapa);
        if (verCadData && verCadData.status !== 4) {
            registrar(dadosEtapa);
        }
    };




    const searchCnpj = async (cnpj: string) => {
        if (cnpj.length < 14) {
            return;
        }

        try {
            const res = await getCnpjData(cnpj as string);

            setNaturezaJuridica(res.natureza_juridica);
            setCapitalSocial(res.capital_social);
            setRazaoSocial(res.razao_social);
            setNomeFantasia(res.nome_fantasia);
            setDataAbertura(res.data_inicio_atividade);
            setEndereçoEmpresa(`${res.logradouro}, ${res.municipio}, ${res.uf}`);
            setComplementoEmpresa(res.complemento);
            setCepEmpresa(res.cep);
            setFoneEmpresa(res.ddd_telefone_1);
            setPortedaEmpresa(res.porte);
            setMEI(res.opcao_pelo_mei);
            setAtividadeEconômicaP({
                codigo: res.cnae_fiscal || '',
                descricao: res.cnae_fiscal_descricao || ''
            });
            setAtividadeEconômicaS(res.cnaes_secundarios);
        } catch (error) {
            console.error('Erro ao buscar dados do CNPJ:', error);
            return;
        }
    }


    const fecharTermoModal = () => {
        setPoliticaPrivacidade(false);
        setCondiçõesLegais(false);
    }

    return (

        <div className="flex flex-col md:flex-row bg-gradient-to-br from-slate-50 to-white h-screen overflow-hidden">
            {
                cadastroExistente &&
                <AlertaCadastroExistente data={empresaData} fechar={fecharCadastro} />
            }

            {
                politicaPrivacidade && <TermosRegistro showTermsModal={false} showPrivacyModal={true} fechar={fecharTermoModal} />
            }

            {
                condiçõesLegais && <TermosRegistro showTermsModal={true} showPrivacyModal={false} fechar={fecharTermoModal} />
            }

            <div className="bg-gradient-to-br from-slate-50 to-white dark:bg-gray-900 w-full flex justify-center items-center overflow-auto">
                <div className="relative bg-white flex flex-col lg:flex-row h-full w-full">
                    {/* Metade Esquerda: Imagem */}
                    <div className="w-1/2 hidden lg:flex">
                        <img
                            src="./design.png"
                            alt="Descrição da imagem"
                            className="w-full h-full object-cover"
                        />
                    </div>

                    {/* Metade Direita: Formulário */}
                    <div className="w-full lg:w-1/2 p-8 flex flex-col justify-center overflow-auto">
                        <div className="flex mb-4 items-center">
                            <button
                                type="button"
                                onClick={fechar}
                                className="text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
                            >
                                <IoIosArrowRoundBack className="w-6 h-6" />
                            </button>
                            <h3 className=" dark:text-gray-400 text-xl pl-36 font-bold text-orange-500">
                                {acesso === "Assessoria" ? "Cadastrar como Assessoria" : "Cadastrar como Empresa"}
                            </h3>
                        </div>

                        <div className='mb-4'>
                            <h3 className="text-md pl-6 text-orange-500 dark:text-gray-200 font-semibold">Insira os dados da empresa</h3>
                            <hr className="mx-4 mt-2 border-gray-300 dark:border-gray-600" />
                        </div>

                        <form onSubmit={handleEtapa} className="space-y-6">
                            <div className="flex flex-col md:flex-row md:gap-4">



                                {/* CNPJ da Empresa */}
                                <div className="flex flex-col flex-1 relative mb-4 mt-4 md:mt-0">
                                    <div className="flex items-center ">

                                        {cnpjEmpresa && cnpjEmpresa.length > 2 && !cnpj.isValid(cnpjEmpresa) && (
                                            <p id="cnpj-empresa-error" className='text-xs text-orange-400 ml-2'>
                                                CNPJ inválido.
                                            </p>
                                        )}
                                    </div>
                                    <IMaskInput
                                        mask="00.000.000/0000-00"
                                        type="text"
                                        name="CnpjEmpresa"
                                        id="CnpjEmpresa"
                                        aria-describedby={cnpjEmpresa && cnpjEmpresa.length > 2 && !cnpj.isValid(cnpjEmpresa) ? "cnpj-empresa-error" : undefined}
                                        className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pl-4 pr-4 py-2.5"
                                        placeholder="Digite o CNPJ da empresa"
                                        required
                                        value={cnpjEmpresa}
                                        onAccept={(value) => setCnpjEmpresa(value)}
                                        onBlur={() => searchCnpj(cnpjEmpresa)}
                                    />
                                </div>


                                <div className="flex flex-col flex-1 relative mb-4">
                                    <input
                                        type="text"
                                        name="RazãoSocial"
                                        id="RazãoSocial"
                                        className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pl-4 pr-4 py-2.5"
                                        placeholder="Digite o nome da empresa"
                                        required
                                        value={razaoSocial}
                                        onChange={(e) => setRazaoSocial(e.target.value)}
                                    />
                                </div>


                            </div>

                            {/* Verificação de cadastro */}
                            {verificaçãoCadastro ? (
                                <div className="col-span-2">
                                    <p className='text-orange-400 pt-4'>Usuário já possui cadastro, utilize a senha atual para registrar uma nova empresa!</p>
                                </div>
                            ) : (
                                <>

                                    <div className='mb-4'>
                                        <h3 className="text-md pl-6 text-orange-500 dark:text-gray-200 font-semibold">Insira os dados do administrador da conta</h3>
                                        <hr className="mx-4 mt-2 border-gray-300 dark:border-gray-600" />
                                    </div>



                                    <div className="flex flex-col md:flex-row md:gap-4">

                                        {/* CPF/CNPJ do Usuário */}
                                        <div className="flex flex-col flex-1 relative mb-4">
                                            {/* Container para o rótulo e a mensagem de erro */}
                                            <div className="flex items-center ">

                                                {cpfCnpj && cpfCnpj.length > 2 && !cpfCnpjVerify && (
                                                    <p id="cpf-cnpj-error" className='text-xs text-orange-400 ml-2'>
                                                        *CPF/CNPJ Inválido!
                                                    </p>
                                                )}
                                            </div>
                                            <IMaskInput
                                                mask={[
                                                    {
                                                        mask: '000.000.000-00',
                                                        maxLength: 11, // CPF tem 11 dígitos
                                                    },
                                                    {
                                                        mask: '00.000.000/0000-00',
                                                        maxLength: 14, // CNPJ tem 14 dígitos
                                                    },
                                                ]}
                                                type="text"
                                                name="CpfCnpj"
                                                id="floating_cpfcnpj"
                                                aria-describedby={cpfCnpj && cpfCnpj.length > 2 && !cpfCnpjVerify ? "cpf-cnpj-error" : undefined}
                                                className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pl-4 pr-4 py-2.5 max-w-md"
                                                placeholder="Digite seu CPF ou CNPJ"
                                                required
                                                value={cpfCnpj}
                                                onAccept={(value) => setCpfCnpj(value)}
                                                onBlur={verificarCpfCnpj}
                                            />
                                        </div>




                                        {/* E-mail */}
                                        <div className="flex flex-col flex-1 relative mb-4 mt-4 md:mt-0">
                                            <div className="flex items-center ">


                                            </div>
                                            <input
                                                type="text"
                                                name="Nome"
                                                id="Nome"
                                                aria-describedby={/* emailError ? "email-error" : */ undefined}
                                                className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pl-4 pr-4 py-2.5"
                                                placeholder="Digite o nome do administrador"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col md:flex-row md:gap-4">



                                        <div className="flex flex-col flex-1 relative mb-4 mt-4 md:mt-0">

                                            <input
                                                type="email"
                                                name="Email"
                                                id="Email"
                                                aria-describedby={/* emailError ? "email-error" : */ undefined}
                                                className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pl-4 pr-4 py-2.5"
                                                placeholder="Digite o e-mail do administrador"
                                                required
                                            />
                                        </div>

                                        <div className="flex flex-col flex-1 relative mb-4 mt-4 md:mt-0">


                                            <IMaskInput
                                                mask="(00) 0000-0000"
                                                placeholder="Digite o telefone do admnistrador"
                                                type="text"
                                                name="Telefone"
                                                id="Telefone"
                                                onAccept={(value) => setFoneEmpresa(value)}
                                                value={foneEmpresa}
                                                className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pl-4 pr-4 py-2.5"
                                            />

                                        </div>

                                    </div>
                                </>
                            )}

                            {/* Senha e Confirmar Senha */}
                            <div className="flex flex-col md:flex-row md:gap-4">
                                {/* Senha */}
                                <div className="flex flex-col flex-1 relative mb-4">
                                    {/* Container para o rótulo e a mensagem de erro */}
                                    <div className="flex items-center absolute top-[100%]">

                                        {passWordError && (
                                            <p id="senha-error" className="text-xs text-orange-500 ml-2">
                                                {passwordErrorrMesg}
                                            </p>
                                        )}
                                    </div>
                                    <div className="relative">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            name="Senha"
                                            id="Senha"
                                            className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pr-10 pl-4 py-2.5"
                                            placeholder="Digite sua senha"
                                            required
                                            onBlur={checkPass}
                                            onChange={(e) => setSenha(e.target.value)}
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                            onClick={() => setShowPassword((current) => !current)}
                                        >
                                            {showPassword ? (
                                                <BsEyeSlash className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                            ) : (
                                                <BsEye className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                            )}
                                        </div>
                                    </div>

                                </div>

                                {/* Confirmar Senha */}
                                <div className="flex flex-col flex-1 relative mb-4">

                                    <div className="relative">
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name="repetirSenha"
                                            id="repetirSenha"
                                            aria-describedby={passWordError ? 'confirmar-senha-error' : undefined}
                                            className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pr-10 pl-4 py-2.5"
                                            placeholder="Confirme sua senha"
                                            required
                                            onBlur={checkPass}
                                            onChange={(e) => setRepetirSenha(e.target.value)}
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                            onClick={() => setShowConfirmPassword((current) => !current)}
                                        >
                                            {showConfirmPassword ? (
                                                <BsEyeSlash className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                            ) : (
                                                <BsEye className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <hr className="mx-4 mt-2 border-gray-300 dark:border-gray-600" />

                            {/* Termos e Condições */}
                            <div className="flex justify-between items-center pl-4">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="terms"
                                        id="terms"
                                        className="mr-2 h-4 w-4 text-green-500 bg-transparent border-gray-300 rounded focus:ring-green-300"
                                        onChange={(e) => {
                                            setTermoAceito(e.target.checked);
                                        }}
                                    />

                                    <label htmlFor="terms" className="text-xs font-medium text-gray-500 mr-4 dark:text-gray-300">
                                        Li e aceito as{' '}
                                        <span
                                            className="underline text-orange-400 cursor-pointer opacity-80 transition-opacity hover:opacity-100"

                                        >
                                            Condições do Contrato
                                        </span>{' '}
                                        e a{' '}
                                        <span
                                            className="underline text-orange-400 cursor-pointer opacity-80 transition-opacity hover:opacity-100"
                                            onClick={() => setPoliticaPrivacidade(true)}
                                        >
                                            Política de Privacidade
                                        </span>{' '}
                                        e as{' '}
                                        <span
                                            className="underline text-orange-400 cursor-pointer opacity-80 transition-opacity hover:opacity-100"

                                        >
                                            Condições Legais
                                        </span>{' '}
                                        do Portal GLC
                                    </label>
                                </div>
                                <div className="flex justify-end pr-10">
                                    <button type="submit" className="confirmButton flex items-center">
                                        Registrar <GrFormCheckmark className="text-2xl ml-2" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>







    );
};


export default EmpresaFormEtapaUm;
