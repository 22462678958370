export const checkAnexado = (id: string, processoPncp: any): boolean => {


    if (processoPncp && processoPncp.processos) {
        const processos = processoPncp.processos;

        if (processos.includes(id)) {
            return true
        } else {
            return false
        }
    }

    return false
}
