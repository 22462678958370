import { useState } from "react";
import { useUserContext } from "../Context/useUserContext"
import { TEmpresaPadrão } from "./types/empresaTypes";




export const useGetPerissions = (userContext: any) => {



    const user = userContext.User;
    const empresa: TEmpresaPadrão = userContext.EmpresaPadrão


    const getPermission = () => {

    }

    const verifyAdm = (): boolean | null => {
        try {
            switch (true) {
                case userContext && empresa.Empresa_Adm.includes(user.id) || empresa.Empresa_Presidencia.includes(user.id):
                    return true
                case userContext && !empresa.Empresa_Adm.includes(user.id) && !empresa.Empresa_Presidencia.includes(user.id):
                    return false
                default:
                    return true

            }
        }
        catch (error) {
            console.error(error);
            return null;
        }

    }

    return {
        getPermission,
        verifyAdm,

    }

}

