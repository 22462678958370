import React from 'react'


const SolicContextMenu = ({
    solicitação,
    positionX,
    positionY,
    isToggled,
    buttons,
    contextMenuRef
}: any) => {


    const menuContextActive = 'flex flex-col border-black shadow-2xl justify-center items-center w-20 rounded-md dark:bg-gray-700 bg-white';
    const menuContextInctive = 'hidden';


  return (
    <menu className={isToggled ? `${menuContextActive}`:`${menuContextInctive} `}
    style ={{
        position: 'absolute',
        top: positionY + 2 + 'px',
        left: positionX + 2 + 'px',
    }}
    ref = {contextMenuRef}
    >

        {buttons.map((button: any, index: any)=>{

            const handleClick =(e: any)=>{
                e.stopPropagation();
                button.onClick(e, solicitação)
            }

            return (
                <button onClick={handleClick} key={index} className =' h-10 text-sm dark:hover:bg-gray-800 hover:bg-gray-400 w-full rounded-md'>
                    <span>{button.text}</span>
                </button>
            )

        })

        }
    </menu>
  )
}

export default SolicContextMenu