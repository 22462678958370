import { Link } from 'react-router-dom';
import { BsEye, BsEyeSlash, BsWhatsapp } from 'react-icons/bs';
import { useMutation } from '@tanstack/react-query';
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser';
import { Suspense, useEffect, useState } from 'react';
import AlertaCadastro from '../Alertas';
import { checkCpfCnpj } from '../../Utils/checkCpfCnpj';
import { useGetAcessos } from '../../Utils/ReactQuery/getAcessos';
import VerificarAcesso from './VerificarAcesso';
import { formatCpfCnpj } from '../../Utils/formatCpfCnpj';
import ErrorPage from '../../Pages/ErrorPage';
import { toast } from 'react-toastify';
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";


const Login = () => {
    const { logIn } = useReactQueryUsers();
    const { listarAcessos } = useGetAcessos();

    const [Senha, setSenha] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);

    const [acessos, setAcessos] = useState<string[]>([]);
    const [empresas, setEmpresas] = useState<string[]>([]);
    const [acessosVerify, setAcessosVerify] = useState<boolean>(false);
    const [cpfCnpj, setCpfCnpj] = useState<string>('');
    const [cpfCnpjVerify, setCpfCnpjVerify] = useState<boolean | null>(null);
    const [acessoLogIn] = useState<string>('');

    const [errorPage, setErrorPage] = useState(false);
    const [errorPageMsg, setErrorPageMsg] = useState('');

    const close = (e: any) => {
        e.preventDefault();
        setError(false);
        setSuccess(false);
        setAcessosVerify(false);
        setCpfCnpjVerify(null);
        setErrorMsg('');
    };

    const logInMutation = useMutation({
        mutationFn: logIn,
        onMutate: (): any => {
            // console.log('Login mutation iniciada!');
            setLoading(true);
        },
        onSuccess: async (response) => {
            if (response.status === 3) {
                setAcessosVerify(false);
                setError(true);
                setErrorMsg(response.msg);
                return;
            }
            if (response.status === 5) {
                setErrorPage(true);
                setErrorPageMsg(response.msg);
            } else {
                setSuccess(true);
                localStorage.setItem('token', response.token);
                localStorage.setItem('empresaSelecionada', response.empresa);
                localStorage.setItem('empresaPadrão', response.empresa);
                window.location.href = '/portal/bem-vindo';
                setLoading(false);
            }
        },
        onError: async (response) => {
            const err = await response;
            // console.log('Erro: ', err);
            setLoading(false);
            setError(true);
        },
        onSettled: async (response) => {
            // console.log('Mutação concluída!: ', response);
            setLoading(false);
        }
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const formattedValue = formatCpfCnpj(value);
        setCpfCnpj(formattedValue);
    };

    const handleCadastoVerify = async (e: any) => {
        e.preventDefault();

        try {
            if (checkCpfCnpj(cpfCnpj as string)) {
                const res = await listarAcessos({
                    CpfCnpj: formatCpfCnpj(cpfCnpj as string),
                    Senha: Senha
                });

                if (res.status === 3) {
                    setErrorMsg(res.msg);
                    setError(true);
                    toast.error(res.msg);
                    return;
                }
                if (res.Acessos) {
                    setAcessosVerify(true);
                    setAcessos(res.Acessos);
                    setEmpresas(res.Empresas);
                    // console.log('Acesso: ', acessos);
                    return;
                }
            } else {
                setErrorMsg('CPF ou CNPJ inválido!');
            }
        } catch (error) {
            console.error(error);
            setAcessosVerify(false);
            return;
        }
    };



    const getAcessoLogIn = (acl: string, afiiliação: string) => {
        if (acl && acl.length > 1) {
            try {
                logInMutation.mutate({

                    CpfCnpj: formatCpfCnpj(cpfCnpj as string) as string,
                    Senha: Senha as string,
                    Acesso: acl as string,
                    Afiliação: afiiliação

                });
                return;
            } catch (error) {
                console.error(error);
                setError(true);
                return;
            }
        }
        return;
    };


    const handleCpfCnpjBlur = () => {
        if (cpfCnpj.length === 14 || cpfCnpj.length === 18) {
            setCpfCnpjVerify(checkCpfCnpj(cpfCnpj));
        } else {
            setCpfCnpjVerify(null);
        }
    };

    if (errorPage) {
        return <ErrorPage message={errorPageMsg} />;
    }

    return (
        <section>
            {acessosVerify && acessos ? (
                <Suspense fallback={<p>Carregando ...</p>}>
                    <VerificarAcesso
                        close={close}
                        acessos={acessos}
                        empresas={empresas}
                        acessoLogIn={getAcessoLogIn}
                        loading={loading}
                        success={success as boolean}
                        usuario={{ cpfCnpj, Senha }}
                    />
                </Suspense>
            ) : (
                <div className="flex flex-col md:flex-row bg-gradient-to-br from-slate-50 to-white h-screen overflow-y-hidden">
                    {/* Metade Esquerda: Área de Login */}
                    <div className="w-full md:w-1/2 flex items-center justify-center p-4">
                        <div className="w-full bg-gradient-to-br from-slate-50 to-white p-6 sm:p-10 sm:max-w-lg max-w-md dark:bg-gray-800 dark:border-gray-700">
                            <div className="p-6 space-y-2 md:space-y-4 sm:p-12">
                                {/* Logo */}
                                <img className="max-w-full h-auto px-4" src="./logooficial.webp" alt="logo" />

                                {/* Linha Horizontal */}
                                <hr className="my-2 mx-4 border-gray-300 dark:border-gray-600" />

                                {/* Formulário de Login */}
                                <form className="space-y-2 md:space-y-4" onSubmit={handleCadastoVerify}>
                                    {/* Campo CPF/CNPJ */}
                                    <div className="mb-4">
                                        <div className="flex items-center relative">
                                            <label htmlFor="cpf-cnpj" className="block ml-4 mb-1 text-sm font-medium text-gray-500">
                                                CPF/CNPJ
                                            </label>
                                            {cpfCnpjVerify === false && (
                                                <p id="cpf-cnpj-error" className="text-red-500 text-xs ml-4">
                                                    *CPF/CNPJ Inválido!
                                                </p>
                                            )}
                                        </div>

                                        <div className="relative flex-1">
                                            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                                                <FaUser className='w-4 h-4 text-gray-500' />
                                            </div>
                                            <input
                                                type="text"
                                                id="cpf-cnpj"
                                                aria-describedby={cpfCnpjVerify === false ? "cpf-cnpj-error" : undefined}
                                                className="border bg-gradient-to-br from-slate-50 to-white border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-300 focus:ring-green-300 focus:border-green-300 block w-full pl-10 pr-4 py-2.5"
                                                placeholder="Digite apenas os números"
                                                required
                                                value={cpfCnpj}
                                                onChange={handleInputChange}
                                                onBlur={handleCpfCnpjBlur}
                                                maxLength={18}
                                                name="CpfCnpj"
                                            />
                                        </div>
                                    </div>

                                    {/* Campo Senha */}
                                    <div className="relative mb-4">
                                        {/* Container para o rótulo e a mensagem de erro */}
                                        <div className="flex items-center mb-1">
                                            <label htmlFor="password" className="ml-4 text-md font-medium text-gray-500">Senha</label>
                                            {error && (
                                                <p id="password-error" className="text-red-500 text-xs ml-2">
                                                    {errorMsg}
                                                </p>
                                            )}
                                        </div>

                                        {/* Container do input */}
                                        <div className="relative">
                                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <FaLock className='w-4 h-4 text-gray-500' />
                                            </div>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                id="password"
                                                aria-describedby={error ? "password-error" : undefined}
                                                placeholder="**********"
                                                className="border bg-gradient-to-br from-slate-50 to-white border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-300 focus:ring-green-300 focus:border-green-300 block w-full pl-10 pr-10 py-2.5"
                                                required
                                                name="Senha"
                                                onChange={(e) => setSenha(e.target.value)}
                                                onFocus={() => setError(false)}
                                            />

                                            <div
                                                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                                onClick={() => setShowPassword((current) => !current)}
                                            >
                                                {showPassword ? (
                                                    <BsEyeSlash className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                                ) : (
                                                    <BsEye className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Link "Esqueci minha senha" */}
                                    <div className="flex items-center justify-center mt-2">
                                        <a
                                            href="/auth/recuperar-senha"
                                            className="text-sm font-medium text-gray-500 hover:underline dark:text-primary-500"
                                        >
                                            Esqueci minha senha
                                        </a>
                                    </div>
                                    {/* Botão "Entrar" */}
                                    {loading || !cpfCnpj || !Senha ? (
                                        <button
                                            type="submit"
                                            disabled={true}
                                            className="w-full text-white bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-2xl text-sm py-2.5 text-center dark:bg-primary-500 cursor-not-allowed"
                                        >
                                            {loading ? (
                                                <div role="status" className="flex flex-row justify-center gap-2 items-center">
                                                    <svg
                                                        aria-hidden="true"
                                                        className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                        viewBox="0 0 100 101"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                            fill="currentColor"
                                                        />
                                                        <path
                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                            fill="currentFill"
                                                        />
                                                    </svg>
                                                    <span className="sr-only">Loading...</span>
                                                    <span> Aguarde ...</span>
                                                </div>
                                            ) : (
                                                'Entrar'
                                            )}
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="w-full text-white bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-2xl text-sm px-5 py-2.5 text-center dark:bg-primary-500"
                                        >
                                            Entrar
                                        </button>
                                    )}
                                    {/* Link "Não possui uma conta?" */}
                                    <p className="justify-center flex text-sm font-light text-gray-500 dark:text-gray-400 mt-2">
                                        Não possui uma conta?
                                        <Link className="font-medium text-orange-500 hover:underline dark:text-primary-500 ml-1" to={'/registrar'}>
                                            Cadastre-se aqui!
                                        </Link>
                                    </p>
                                    <hr className="mx-4 my-2 border-gray-300 dark:border-gray-600" />
                                    {/* Seção WhatsApp */}
                                    <div className="text-center mt-2">
                                        <p className="text-sm font-semibold text-gray-700 dark:text-gray-300">Canal de Atendimento</p>
                                        <div className="flex justify-center space-x-2">
                                            <a
                                                href="https://wa.me/5562995642949"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center text-primary-500 dark:text-primary-500 hover:underline"
                                            >
                                                <BsWhatsapp className="mr-1" />
                                                WhatsApp
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* Metade Direita: Imagem */}
                    <div className="w-full md:w-1/2 hidden md:flex h-auto object-contain">
                        <img
                            src="./design.png"
                            alt="Descrição da imagem"
                            className="w-full h-full object-cover"
                        />
                    </div>
                </div>
            )}
        </section>

    );
};

export default Login;