import React from 'react'
import { BsX } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'



const AlertaAssinatura = ({fechar, tipo}: any) => {


  return (
    <div id="popup-modal" tabIndex={-1} className="fixed top-0 left-0 bg-black bg-opacity-30 overflow-y-auto overflow-x-hidden z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        
                        <div className='absolute right-0 top-0 w-fit cursor-pointer' onClick={fechar}>
                            <BsX className='text-3xl'/>
                        </div>


                        <div className="p-4 md:p-5 text-center flex flex-col gap-5">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Nnenhum plano contratado!</h3>
                            
                            { 
                                tipo === 'captação' &&
                                <p> Assine um plano para começar a utilizar a ferramenta de busca automática de editais!</p>
                            }
                            { 
                                tipo === 'arquivo' &&
                                <p> Assine um plano para salvar arquivos no portal!</p>
                            }
                            { 
                                tipo === 'pasta' &&
                                <p> Assine um plano para salvar pastas no portal!</p>
                            }
                            { 
                                tipo === 'portal' &&
                                <p> Assine um plano para salvar informações sobre portais licitatórios!</p>
                            }
                        
                        
    
                            <Link to={'/portal/perfil?area=pagamento'}  data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Ver planos disponíveis!</Link>
    
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default AlertaAssinatura