import React, { Suspense } from 'react'
import RegisterForm from '../Components/RegisterComponents/RegisterForm'
import styles from '../Components/RegisterComponents/Register.module.css'

type Props = {}

const Register = (props: Props) => {

  return (
    <div className='bg-gray-50 dark:bg-gray-900'>
        <RegisterForm/>
    </div>
  )
}

export default Register