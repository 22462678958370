import React, { useLayoutEffect, useState } from 'react'
import { TCategoriaPastas } from './DevUtils/CategoriasFake';
import { useQuery } from '@tanstack/react-query';
import { useReactQueryEmpresas } from './ReactQuery/getEmpresas';
import { BsSearch } from 'react-icons/bs';
import { PiCaretDownThin, PiCaretUpThin } from 'react-icons/pi';
import { TCustonDropdown } from './types/PortalTypes';



const CategoriasDropDown = ({ changeCategoria, selecionado, data, padrao, options }: TCustonDropdown) => {

    const [categoria, setCategoria] = useState<TCategoriaPastas>({ value: 'Documentos', label: 'Documentos' });
    const [abrirListaCategorias, setAbrirListaCategorias] = useState<boolean>(false)
    const [buscarFiltro, setBuscarFiltro] = useState<string>('');
    const [categorias, setCategorias] = useState<any | undefined>(undefined);
    const token = localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada');
    const { configCategoria, getCategorias } = useReactQueryEmpresas()

    const { data: categoriasData } = useQuery({
        queryFn: () => getCategorias(token as string, empresaSelecionada as string),
        queryKey: ['rqCategorias', empresaSelecionada]
    })


    const handleCategoria = (categoria: TCategoriaPastas) => {
        
        setCategoria(categoria);
        changeCategoria(categoria)
        setAbrirListaCategorias(false)
    }


    useLayoutEffect(() => {

        if (categoriasData) {
            const tempCategorias = categoriasData.categorias
            setCategorias(tempCategorias)
        }

    }, [])
    useLayoutEffect(() => {

        if (categoriasData && buscarFiltro.length <= 0) {
            const tempCategorias = categoriasData.categorias
            setCategorias(tempCategorias)
        }

    }, [buscarFiltro])

    const handleSearch = (e: any) => {


        setBuscarFiltro(e.target.value);
        if (buscarFiltro || buscarFiltro.length > 1) {
            const filteredData: [TCategoriaPastas] = categorias.filter((item: TCategoriaPastas) => {

                return Object.values(item)
                    .join('')
                    .toLowerCase()
                    .includes(buscarFiltro.toLowerCase());
            });
            setCategorias(filteredData);
        } else {
            setCategorias(categoriasData.categorias);
        }
    };

    return (
        <div className='selectInput'>
            <div className='relative flex flex-row justify-between '>

                <label className='flex flex-row justify-between w-full cursor-pointer' onClick={() => setAbrirListaCategorias(current => !current)}> <span className='capitalize '>{categoria ? categoria.label : padrao.label}</span>  <span >{abrirListaCategorias ? <PiCaretUpThin className='mt-1 font-semibold opacity-75 cursor-pointer' /> : <PiCaretDownThin className='mt-1 font-semibold opacity-75 cursor-pointer' />}</span></label>


                <div className={`rounded-lg absolute ${options?.drection === 'dn' ? 'top-[100%]': 'bottom-[100%]'} right-0 z-50 w-full`}>

                    {
                        abrirListaCategorias &&
                        <div className='flex flex-col gap-2 min-h-14 max-h-64 min-w-44 overflow-x-hidden overflow-y-auto rounded-lg bg-white chart shadow-xl mt-2 pp-2'>

                            <label className='w-full inline-flex max-h-11 outline-none border rounded-lgflex flex-row gap-1 sticky top-0 bg-white z-50 mx-2 rounded-lg'>
                                <span className='mt-2'>
                                    <BsSearch className='text-sm mt-2 ml-2 opacity-55' />
                                </span>
                                <input type="text" className='border-none bg-none outline-none focus:ring-0 max-w-fit placeholder:opacity-55 text-xsm opacity-70' onChange={handleSearch} placeholder='Buscar' />
                            </label>


                            <label className='border-b border-opacity-75 hover:text-white text-sm cursor-pointer opacity-75 hover:opacity-100 hover:bg-orange-500 w-full px-5' onClick={() => { handleCategoria({ value: 'Documentos', label: 'Documentos' }) }}>
                                Documentos
                            </label>

                            {
                                categorias && categorias.sort((a: any, b: any) => a.label.localeCompare(b.label)).map((catgoriaMap: TCategoriaPastas) => (
                                    <label className='selectTabOption' onClick={() => { handleCategoria(catgoriaMap) }}>
                                        {catgoriaMap.label}
                                    </label>
                                ))
                            }

                            {/* <Dropdown filter options={categorias} onChange={(e)=> setCategoria(e.target.value)} /> */}

                        </div>
                    }

                </div>


            </div>
        </div>
    )
}

export default CategoriasDropDown