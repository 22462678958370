import { BsX } from 'react-icons/bs'
import ErrorPage from '../../Pages/ErrorPage'
import LoadingPage from '../LoadingPage'



const ArquivoModalPastas = ({arquivo, fechar, processo}: any) => {

    const findArquivoLink = (arquivo: any)=>{
        switch(true){
            case arquivo.oldDescartmed === true :
                const link2 = `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/${arquivo.ArquivoPath}`;
                return link2
            case arquivo.LinkAws !== undefined:
                return arquivo.LinkAws
            case arquivo.ArquivoPath !== undefined:
                const link = `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivo.ArquivoPath}`
                return link    
        }
    }


  return (
    <div id="crud-modal" tabIndex ={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full mt-10">

  

            <div className="relative p-4 w-full max-w-7xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                
                    <div className='w-full inline-flex justify-end'>
                        <span onClick={fechar} className='w-fit inline-flex justify-end cursor-pointer' > <BsX className='text-3xl mr-2 mt-2 '/> </span>
                    </div>

                    <div className={`grid grid-cols-[minmax(550px,_1fr)_auto]`}>
                        
                        
                        <div className='h-screen min-h-screen w-full'>
                            {
                            arquivo.LinkAws || arquivo.ArquivoPath ? <iframe src={findArquivoLink(arquivo)}
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                            onError ={
                                ()=> <ErrorPage message={'Erro ao carregar o documento'}/>
                            }
                            onLoad={
                                () => <LoadingPage/>
                            }
                            
                            ></iframe>
                        
                             :
                            <LoadingPage/>
                            }
                        </div>

                 
                    </div>
                    
                </div>
            </div>
        </div> 
  )
}

export default ArquivoModalPastas