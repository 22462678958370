import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react'
import { formatarData } from '../../Utils/formatarData'
import { PiNavigationArrow } from "react-icons/pi";
import { useUserContext } from '../../Context/useUserContext';
import { TchatMessage, TchatUsuario } from '../../Utils/types/comentariosTypes';
import { socket } from '../../Utils/useSocketClient';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useReactQueryComentariosUtils } from '../../Utils/ReactQuery/getComentariosUtils';
import { BsCheck, BsPaperclip, BsPencil, BsTrash, BsX } from 'react-icons/bs';
import { MdOutlineSpeakerNotes } from 'react-icons/md';
import { toast } from 'react-toastify';
import { InputTextarea } from 'primereact/inputtextarea';
import LoadingSmall from '../LoadingSmall';

type TDisplayChat = {
    processo: any,
    tipo: 'arquivo' | 'processo'
}

const DisplayChat = forwardRef(({ processo, tipo }: TDisplayChat, ref) => {

    const bottonRef = useRef<any>();
    const listRef = useRef<any>();
    const arquivoListRef = useRef<any>();

    const [comentariosProcesso, setComentariosProcesso] = useState<any>([]);
    const [text, setText] = useState<string>('');
    const [userContext] = useUserContext();

    const [comentarioId, setComentarioId] = useState<string>('');
    const [editandoId, setEditandoId] = useState<string>('');
    const [editedText, setEditedText] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false)


    // Use query ----------------
    const queryCliient = useQueryClient();
    const { editarComentario, apagarComentaro } = useReactQueryComentariosUtils();

    const editarComentarioMutaton = useMutation({
        mutationFn: editarComentario,
        onMutate: (data) => {
            setLoading(true)

        },
        onSuccess: async (response) => {

            console.log('Reesponse: ', response)

            if (response.status === 1) {
                toast.success(response.msg)
                setEditandoId('');
                setEditandoId('');
                setComentarioId('')
            }

            if (response.status === 3) {
                toast.error(response.msg)
            }

            queryCliient.invalidateQueries({ queryKey: [`rqComentarios-${processo._id}`] });
            queryCliient.refetchQueries({ queryKey: [`rqComentarios-${processo._id}`] });

        },
        onError: async (error) => {
            toast.error(error.message);
            console.error(error.cause)
        },
        onSettled(data, error, variables, context) {
            setLoading(false)
        },
    })

    const apagarComentarioMutaton = useMutation({
        mutationFn: apagarComentaro,
        onMutate: (data) => {
            setLoading(true)

        },
        onSuccess: async (response) => {

            console.log('Reesponse: ', response)

            if (response.status === 1) {
                toast.success(response.msg)
                setEditandoId('');
                setEditandoId('');
                setComentarioId('')
            }

            if (response.status === 3) {
                toast.error(response.msg)
            }

            queryCliient.invalidateQueries({ queryKey: [`rqComentarios-${processo._id}`] });
            queryCliient.refetchQueries({ queryKey: [`rqComentarios-${processo._id}`] });

        },
        onError: async (error) => {
            toast.error(error.message);
            console.error(error.cause)
        },
        onSettled(data, error, variables, context) {
            setLoading(false)
        },
    })

    // Use query ----------------
    const handleOpenModal = (comentarioid: string) => {

        if (comentarioId === comentarioid) {
            setComentarioId('');
            return
        }
        setComentarioId(comentarioid)
    }


    const handleEditar = (comentarioid: string) => {

        if (editandoId === comentarioid) {
            setEditandoId('');
            return
        }
        setEditandoId(comentarioid)
    }

    const token = localStorage.getItem('token')

    const [userData, setUserData] = useState<TchatUsuario>({
        nome: userContext.User.Nome as string,
        cargoFuncao: `-${userContext.User.CargoFuncao}`,
        foto: userContext.User.Foto,
        id: userContext.User.id
    });

    const [message, setMessage] = useState<TchatMessage>(({
        processoId: processo._id,
        texto: '',
        token: token as string,
        usuario: userData
    }));


    const { getMessages } = useReactQueryComentariosUtils()
    const { data: comentariosData, isLoading: comentariosIsLoading, refetch } = useQuery({
        queryFn: () => getMessages(token as string, processo._id),
        queryKey: [`rqComentarios-${processo._id}`, processo]
    })

    useEffect(() => {
        if (comentariosData) {
            setComentariosProcesso(comentariosData.comentarios)
        }

        if(tipo === 'processo' && listRef.current){
            listRef.current.scrollTop = listRef.current.scrollHeight
        }
        if(tipo === 'arquivo'&& arquivoListRef.current){
            arquivoListRef.current.scrollTop = arquivoListRef.current.scrollHeight
        }

    }, [comentariosData])

   useEffect(()=>{
        if(tipo === 'processo' && listRef.current){
            listRef.current.scrollTop = listRef.current.scrollHeight
        }
        if(tipo === 'arquivo' && arquivoListRef.current){
            arquivoListRef.current.scrollTop = arquivoListRef.current.scrollHeight
        }
   },[processo])



    useEffect(() => {

        const updateComentarios = (data: any) => {
            setComentariosProcesso((comentariosProcesso: any) => {
                return [...comentariosProcesso, data.data]
            })

            refetch()
        }

        socket.on('comentarioAtualizado', updateComentarios)
        setText('')

        return () => {
            socket.off('comentarioAtualizado', updateComentarios)
        }
    }, [socket])


    const inputRef = useRef<HTMLInputElement | any>(null);

    const enviarComentario = async (e: any) => {

        if(!text){
            return;
        }

        e.preventDefault();

        socket.emit('enviarComentario', {
            data: {
                processoId: message.processoId,
                texto: text,
                token: message.token,
                usuario: message.usuario
            }
        })

        inputRef.current.value = '';
        setText('');
        // bottonRef.current?.scrollIntoView({ behavior: "smooth" })
        // listRef.current?.lastElementChild?.scrollIntoView()
        listRef.current.scrollTop = listRef.current.scrollHeight
    }

    const enviarComentarioDoParente = async (e: any, text: string) => {

        e.preventDefault();
        
        toast.success('Comentário anexado com sucesso!')
        socket.emit('enviarComentario', {
            data: {
                processoId: message.processoId,
                texto: text,
                token: message.token,
                usuario: message.usuario
            }
        })

        arquivoListRef.current.scrollTop = listRef.current.scrollHeight


    }

    const sendEditarComentario = (e: any, data: string) => {
        e.preventDefault();

        editarComentarioMutaton.mutate({ token: token as string, comentarioid: editandoId, texto: editedText });

    }

    const handleApagarComentario = (e: any) => {
        e.preventDefault()
        apagarComentarioMutaton.mutate({ token: token as string, comentarioid: comentarioId })
    }


    useImperativeHandle(ref, () => {
        return {
            enviarComentarioDoParente,
        }
    })


    //Tailwind ---------------------------------------
    const displayArquivo = 'w-full h-full dark:bg-gray-600 bg-gray-200 flex flex-col gap-6 pl-2 mt-4 pt-4  mx-2'
    const displayProcessos = 'w-full h-52 dark:bg-gray-700 bg-gray-200 flex flex-col gap-6 pl-2 rouded-t-xl pt-4 '

    //Tailwind ---------------------------------------



    const findUserAvatar = (user: any) => {
      
        try {
            switch (true) {
                case user.foto.includes("https"):
                    return user.foto
                case user.oldDescartMed && !user.foto.includes("amazonaws"):
                    return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/avatarUsers/${user.foto}`
                case user.foto && user.foto.includes('amazonaws'):
                    return user.foto;
                case user.foto && !user.foto.includes("amazonaws"):
                    return `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/avatarUsers/${user.foto}`
                default:
                    return `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/avatar2.png`
            }
        } catch (error) {
            console.error(error);
            return `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/avatar2.png`

        }

    }

    return (
        <div className={tipo === 'arquivo' ? 'h-full flex flex-col justify-end px-4 max-h-[65%] min-h-screen relative' : 'h-full'} >
            <div className={tipo === 'arquivo' ? `${displayArquivo} overflow-y-auto` : `${displayProcessos} `} ref ={arquivoListRef}>
                <div className={tipo === 'arquivo'  ?'max-h-96 '  :'max-h-96 overflow-y-auto'} ref={listRef} >

                    {
                        comentariosProcesso && comentariosProcesso.filter((i: any) => {
                            return i.processoId === processo._id
                        }).map((comentario: any) => (
                            <div className="flex items-start  gap-2.5" >



                                <div className={comentario.usuario.id === userContext.User.id ? 'w-12 h-12 origin-center object-contain rounded-full order-2' : 'w-12 h-12 origin-center object-contain rounded-full '} >
                                    {
                                        comentario.usuario !== undefined && comentario.usuario.foto ?
                                            <img className="w-full h-full rounded-full" src={findUserAvatar(comentario.usuario)} alt="Jese image" />
                                            :
                                            <div className='w-8 h-8 rounded-full flex justify-center items-center bg-green-500'>
                                                <span>{comentario.usuario.nome.slice(0, 1)}</span>
                                            </div>
                                    }
                                </div>


                                <div className=" flex flex-col justify-between min-w-[250px] max-w-[250px] h-auto min-h-24 leading-1.5 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-800 p-2 m-0 my-2">

                                    {
                                        comentario.usuario.id === userContext.User.id &&

                                        <label className={`w-full flex flex-row  ${comentario.usuario.id === userContext.User.id ? 'justify-start' : 'justify-end'} relative`}><span className='text-xl cursor-pointer w-fit opacity-75 hover:opacity-100 ' onClick={() => handleOpenModal(comentario._id)}> ...</span>
                                            {
                                                comentarioId === comentario._id &&

                                                <div className='absolute top-0 left-5 w-fit bg-gray-500 rounded-md'>
                                                    {
                                                        loading ? <div className='bg-white'><LoadingSmall /></div> :
                                                            <div className='flex flex-row gap-2 p-2 text-white'>
                                                                <span className='cursor-pointer opacity-75 hover:opacity-100 w-fit' onClick={() => handleEditar(comentario._id)}>

                                                                    {editandoId === comentario._id ? <BsX /> : <BsPencil />}

                                                                </span>
                                                                {
                                                                    editandoId === comentarioId &&
                                                                    <span className='cursor-pointer opacity-75 hover:opacity-100 w-fit' onClick={(e: any) => sendEditarComentario(e, e.target.value)}><BsCheck /></span>
                                                                }
                                                                <span className='cursor-pointer opacity-75 hover:opacity-100 w-fit' onClick={(e: any) => handleApagarComentario(e)}> <BsTrash /></span>
                                                            </div>

                                                    }


                                                </div>}
                                        </label>}

                                    <div className={`flex flex-row relative ${comentario.usuario.id === userContext.User.id ? 'justify-end text-right' : 'justify-between'}`}>
                                        <span className="text-xsm font-semibold text-gray-900 dark:text-white">{comentario.usuario.nome} <span> {comentario.usuario.cargoFuncao}</span></span>
                                    </div>

                                    <div className=' text-wrap h-fit max-w-sm break-words text-sm '>
                                        {
                                            editandoId === comentario._id ?
                                                <InputTextarea className='rounded-md ' autoResize onChange={(e) => setEditedText(e.target.value)} rows={3} cols={30} placeholder={comentario.texto} />
                                                :
                                                <span className="text-wrap  w-full h-full ">{comentario.texto}</span>
                                        }
                                    </div>


                                    <div className={`w-full  text-sm p-0 m-0 ${comentario.usuario.id === userContext.User.id ? 'justify-start items-end text-left' : 'justify-end items-end text-right'}`} >
                                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400 chatDate">{formatarData(comentario.createdAt)}</span>
                                    </div>

                                </div>

                            </div>
                            
                        ))
                        
                    }
                   
                  
               
                </div>

            </div>
            
           
            {
                tipo === 'processo' &&
                <div className='left-0 mb-5 bg-gray-100 w-full absolute bottom-0'>
                    <div className='flex flex-row justify-start' >

                        <input type="text" name='texto' className='bg:gray-200 dark:bg-gray-600 rounded-2xl max-w-md w-full' onChange={(e) => setText(e.target.value)} value={text} ref={inputRef} />
                        <button disabled={text ? false: true} type='submit'><PiNavigationArrow className={`rotate-diagonal text-4xl  ${ text ? 'opacity-80 cursor-pointer' : 'opacity-50 '} hover:opacity-100 transition-opacity`} onClick={enviarComentario} /></button></div>
                </div>
            }
        </div>
    )
})

export default DisplayChat