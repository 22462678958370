import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'
import EmpresaConfig from './EmpresaConfig'
import PagamentoConfig from './PagamentoConfig'
import styles from './Perfil.module.css'
import UserConfig from './UserConfig'
import ContaConfig from './ContaConfig'
import { AdminTab } from '../../Utils/types/adminTypes'
import { Link } from 'react-router-dom'
import { useUserContext } from '../../Context/useUserContext'
import { useGetPerissions } from '../../Utils/verificarAcesso'



const Config = ({ activeTab, user }: any) => {


  const [userContext] = useUserContext();
  const { verifyAdm } = useGetPerissions(userContext)
  const result = verifyAdm()


  const { listarEmpresas, empresasPermitidas } = useReactQueryEmpresas()
  const { data: empresaData, isLoading: empresaisLoading, isError: empresaIsError, isSuccess: empresaIsSuccess } = useQuery({ queryFn: () => listarEmpresas(user.Acessos.Empresas), queryKey: ['rqEmpresa', user], staleTime: 60000 });


  return (
    <div className='mt-20 grid justify-start grid-cols-[250px_auto]'>
      <div className='w-full'>
        <div className='fixed'>
          {activeTab === "empresaTab" && <label >Configurações / Empresa  </label>}
          {activeTab === "conta" && <label >Configurações / Plano  </label>}
          {activeTab === "pagamentoTab" && <label >Configurações / Pagamentos  </label>}
          <div className='flex flex-col pt-5 gap-1'>
            <Link to="/portal/perfil?area=empresa" className={activeTab === "empresaTab" ? 'opacity-80 hover:opacity-100 cursor-pointer font-semibold' : 'opacity-80 hover:opacity-100 cursor-pointer '} >Empresa</Link>

            {
              result &&
              <>
                <Link to="/portal/perfil?area=conta" className={activeTab === "conta" ? 'opacity-80 hover:opacity-100 cursor-pointer font-semibold' : 'opacity-80 hover:opacity-100 cursor-pointer'}>Plano</Link>

                <Link to="/portal/perfil?area=pagamento" className={activeTab === "pagamentoTab" ? 'opacity-80 hover:opacity-100 cursor-pointer font-semibold' : 'opacity-80 hover:opacity-100 cursor-pointer'}>Pagamentos</Link>
              </>
            }

          </div>
        </div>
      </div>

      <div className={`w-full flex flex-col  justify-center items-center`}>
        {(() => {
          switch (activeTab) {
            case 'userTab':
              return <UserConfig user={user || null} empresaData={empresaData} />
              break
            case 'empresaTab':
              return <EmpresaConfig empresas={empresaData || null} user={user || null} />
              break
            case 'pagamentoTab':
              return <PagamentoConfig isAdm = {result}/>
            case 'conta':
              return <ContaConfig     isAdm = {result}/>
            default:
              return <UserConfig />
          }
        })()}
      </div>

    </div>
  )
}

export default Config