// SideBar.tsx
import React, { useState, useEffect, useRef } from 'react';
import {
  BsDiagram3Fill,
  BsFillGridFill,
  BsGearFill,
} from 'react-icons/bs';
import { RiAdminFill, RiDashboard3Fill } from 'react-icons/ri';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser';
import NotificaçõesComponent from '../Notificações/NotificaçõesComponent';
import { IoLogOutSharp } from "react-icons/io5";
import { useUserContext } from '../../Context/useUserContext';
import { useGetPerissions } from '../../Utils/verificarAcesso';

interface SideBarProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideBar: React.FC<SideBarProps> = ({ isOpen, setIsOpen }) => {

// Verfcando permissões do usuário
  const [userContext] = useUserContext();
  const { verifyAdm } = useGetPerissions(userContext);
  const isAdmin = verifyAdm();
// Verfcando permissões do usuário


  const { getUser, logOut } = useReactQueryUsers();
  const token = localStorage.getItem('token');

  const { data: userData } = useQuery({
    queryFn: () => getUser(token as string),
    queryKey: ['userInfo', token],
    staleTime: Infinity,
  });

  const user = userData ? userData.user : null;

  //------------------------------------------





  //---------------------------------------



  const getUserInitials = (name: any) => {
    if (!name) return '';
    const words = name.trim().split(' ');
    if (words.length === 1) {
      return words[0].charAt(0).toUpperCase() + '.';
    }
    const firstInitial = words[0].charAt(0).toUpperCase();
    const lastInitial = words[words.length - 1].charAt(0).toUpperCase();
    return `${firstInitial}. ${lastInitial}.`;
  };

  const sidebarRef = useRef<HTMLElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsOpen]);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const logOutMutation = useMutation({
    mutationFn: logOut,
    onMutate: () => {
      queryClient.invalidateQueries({ queryKey: ['rqUser'] });
      queryClient.invalidateQueries({ queryKey: ['rqEmpresa'] });
      queryClient.clear();
    },
    onSuccess: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('empresaSelecionada');
      navigate('/');
    },
  });

  const handleLogOut = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    logOutMutation.mutate({ config: { token: token as string } });
  };

  return (
    <section>
      {/* Sidebar */}
      <aside
        ref={sidebarRef}
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 h-screen transition-width duration-300 ${isOpen ? 'w-56' : 'w-16'
          } bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700`}
        aria-label="Sidebar"
      >
        {/* Sidebar Toggle Button */}
        <div
          className="absolute top-10 -right-4 z-50 flex items-center justify-center w-8 h-8 bg-white border border-gray-200 rounded-full shadow cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            className="w-4 h-4 text-gray-500"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            {isOpen ? (
              <path
                fillRule="evenodd"
                d="M6 10a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            ) : (
              <path
                fillRule="evenodd"
                d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            )}
          </svg>
        </div>

        {/* Sidebar Content */}
        <div className="relative flex flex-col h-full px-3 pb-4 overflow-y-auto">
          {/* Logo */}
          <div className="flex flex-col items-center justify-center mt-4 mb-2">
            <Link to="/">
              <img
                src={
                  isOpen
                    ? 'https://s3.sa-east-1.amazonaws.com/portalglc1.0/res/logoglc1.png'
                    : 'https://s3.sa-east-1.amazonaws.com/portalglc1.0/res/logo3.jpg'
                }
                className="h-8"
                alt="Logo GLC"
              />
            </Link>
            {isOpen && (
              <h3 className="text-xs mt-3">
                Gestão em{' '}
                <span className="text-orange-400">Licitações</span> e Contratos
              </h3>
            )}
          </div>

          <hr className="border-gray-200 dark:border-gray-700 mb-1" />

          {/* Main Navigation */}
          <ul className="space-y-2 font-medium flex-1">
            
            <li>

              {

                isAdmin ? 
                <Link
                  to="/portal/admin?area=assessoriasolicitacoes&secao=assessorias"
                  className={`flex items-center p-2 text-gray-700 rounded-lg hover:bg-gray-100 ${!isOpen ? 'justify-center' : ''
                    }`}
                >
                  <BsFillGridFill className="w-5 h-5 text-gray-500" />
                  {isOpen && (
                    <span className="ml-3 text-sm">Assessores</span>
                  )}
                </Link>
                :
                <label
                  className={`flex items-center p-2 opacity-75 cursor-not-allowed text-gray-700 rounded-lg hover:bg-gray-100 ${!isOpen ? 'justify-center' : ''
                    }`}
                >
                  <BsFillGridFill className="w-5 h-5 text-gray-500" />
                  {isOpen && (
                    <span className="ml-3 text-sm">Assessores</span>
                  )}
                </label>

              }

            </li>

            {isOpen && (
              <hr className="border-gray-200 dark:border-gray-700 my-2" />
            )}

            {/* "Licitações e Contratos" Section */}
            {isOpen ? (
              <>
                <li>
                  <span className="flex items-center px-2 text-gray-500 uppercase text-xs">
                    Licitações e Contratos
                  </span>
                </li>
                <li>
                  <Link
                    to="/portal"
                    className="flex items-center p-2 text-gray-700 rounded-lg hover:bg-gray-100"
                  >
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 transform scale-x-[-1]"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 492.508 492.508"
                    >
                      <path d="M199.493,402.145c0-10.141-8.221-18.361-18.36-18.361H42.475c-10.139,0-18.36,8.221-18.36,18.361
                    c0,3.195,0.818,6.199,2.255,8.816H0v38.067h223.607v-38.067h-26.369C198.674,408.343,199.493,405.34,199.493,402.145z"/>
                      <path d="M175.898,88.224l117.157,74.396c9.111,4.643,20.43,1.678,26.021-7.129l5.622-8.85c5.938-9.354,3.171-21.75-6.182-27.69
                    L204.592,46.608c-9.352-5.939-21.748-3.172-27.688,6.182l-5.622,8.851C165.692,70.447,167.82,81.952,175.898,88.224z"/>
                      <path d="M492.456,372.433l-0.082-1.771l-0.146-1.672c-0.075-1.143-0.235-2.159-0.375-3.204c-0.562-4.177-1.521-7.731-2.693-10.946
                    c-2.377-6.386-5.738-11.222-9.866-14.845c-1.027-0.913-2.126-1.714-3.218-2.528l-3.271-2.443
                    c-2.172-1.643-4.387-3.218-6.587-4.815c-2.196-1.606-4.419-3.169-6.644-4.729c-2.218-1.571-4.445-3.125-6.691-4.651
                    c-4.468-3.089-8.983-6.101-13.51-9.103l-6.812-4.464l-6.85-4.405c-4.58-2.911-9.167-5.813-13.785-8.667
                    c-4.611-2.865-9.24-5.703-13.896-8.496l-13.979-8.363l-14.072-8.22l-14.149-8.096l-14.219-7.987l-14.287-7.882l-14.354-7.773
                    c-4.802-2.566-9.599-5.137-14.433-7.653c-4.822-2.529-9.641-5.071-14.498-7.548l-4.398,6.928l-22.17-10.449l24.781-39.026
                    l-117.156-74.395l-60.944,95.974l117.157,74.395l24.781-39.026l18.887,15.622l-4.399,6.929c4.309,3.343,8.657,6.619,12.998,9.91
                    c4.331,3.305,8.698,6.553,13.062,9.808l13.14,9.686l13.211,9.577l13.275,9.474l13.346,9.361l13.422,9.242l13.514,9.095
                    c4.51,3.026,9.045,6.009,13.602,8.964c4.547,2.967,9.123,5.882,13.707,8.792l6.898,4.324l6.936,4.266
                    c4.643,2.818,9.289,5.625,13.985,8.357c2.337,1.383,4.689,2.739,7.055,4.078c2.358,1.349,4.719,2.697,7.106,4
                    c2.383,1.312,4.75,2.646,7.159,3.912l3.603,1.922c1.201,0.64,2.394,1.296,3.657,1.837c5.036,2.194,10.841,3.18,17.63,2.614
                    c3.409-0.305,7.034-0.949,11.054-2.216c1.006-0.317,1.992-0.606,3.061-1.023l1.574-0.58l1.639-0.68
                    c2.185-0.91,4.523-2.063,7.059-3.522C492.513,377.405,492.561,374.799,492.456,372.433z"/>
                      <path d="M67.897,261.877l113.922,72.341c9.354,5.938,21.75,3.172,27.689-6.181l5.621-8.852c5.592-8.808,3.462-20.311-4.615-26.583 L93.358,218.207c-9.111-4.642-20.43-1.678-26.022,7.13l-5.62,8.85C55.775,243.541,58.543,255.938,67.897,261.877z" />
                    </svg>
                    {isOpen && (
                      <span className="ml-3 text-sm">Processos</span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/portal/dashboard"
                    className="flex items-center p-2 text-gray-700 rounded-lg hover:bg-gray-100"
                  >
                    <RiDashboard3Fill className="w-5 h-5 text-gray-500" />
                    {isOpen && (
                      <span className="ml-3 text-sm">Dashboard</span>
                    )}
                  </Link>
                </li>

                <hr className="border-gray-200 dark:border-gray-700 my-2" />
              </>
            ) : (
              <>
                <li>
                  <Link
                    to="/portal"
                    className="flex items-center p-2 text-gray-700 rounded-lg hover:bg-gray-100 justify-center"
                  >
                    {/* Icon */}
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 transform scale-x-[-1]"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 492.508 492.508"
                    >
                      <path d="M199.493,402.145c0-10.141-8.221-18.361-18.36-18.361H42.475c-10.139,0-18.36,8.221-18.36,18.361
                    c0,3.195,0.818,6.199,2.255,8.816H0v38.067h223.607v-38.067h-26.369C198.674,408.343,199.493,405.34,199.493,402.145z"/>
                      <path d="M175.898,88.224l117.157,74.396c9.111,4.643,20.43,1.678,26.021-7.129l5.622-8.85c5.938-9.354,3.171-21.75-6.182-27.69
                    L204.592,46.608c-9.352-5.939-21.748-3.172-27.688,6.182l-5.622,8.851C165.692,70.447,167.82,81.952,175.898,88.224z"/>
                      <path d="M492.456,372.433l-0.082-1.771l-0.146-1.672c-0.075-1.143-0.235-2.159-0.375-3.204c-0.562-4.177-1.521-7.731-2.693-10.946
                    c-2.377-6.386-5.738-11.222-9.866-14.845c-1.027-0.913-2.126-1.714-3.218-2.528l-3.271-2.443
                    c-2.172-1.643-4.387-3.218-6.587-4.815c-2.196-1.606-4.419-3.169-6.644-4.729c-2.218-1.571-4.445-3.125-6.691-4.651
                    c-4.468-3.089-8.983-6.101-13.51-9.103l-6.812-4.464l-6.85-4.405c-4.58-2.911-9.167-5.813-13.785-8.667
                    c-4.611-2.865-9.24-5.703-13.896-8.496l-13.979-8.363l-14.072-8.22l-14.149-8.096l-14.219-7.987l-14.287-7.882l-14.354-7.773
                    c-4.802-2.566-9.599-5.137-14.433-7.653c-4.822-2.529-9.641-5.071-14.498-7.548l-4.398,6.928l-22.17-10.449l24.781-39.026
                    l-117.156-74.395l-60.944,95.974l117.157,74.395l24.781-39.026l18.887,15.622l-4.399,6.929c4.309,3.343,8.657,6.619,12.998,9.91
                    c4.331,3.305,8.698,6.553,13.062,9.808l13.14,9.686l13.211,9.577l13.275,9.474l13.346,9.361l13.422,9.242l13.514,9.095
                    c4.51,3.026,9.045,6.009,13.602,8.964c4.547,2.967,9.123,5.882,13.707,8.792l6.898,4.324l6.936,4.266
                    c4.643,2.818,9.289,5.625,13.985,8.357c2.337,1.383,4.689,2.739,7.055,4.078c2.358,1.349,4.719,2.697,7.106,4
                    c2.383,1.312,4.75,2.646,7.159,3.912l3.603,1.922c1.201,0.64,2.394,1.296,3.657,1.837c5.036,2.194,10.841,3.18,17.63,2.614
                    c3.409-0.305,7.034-0.949,11.054-2.216c1.006-0.317,1.992-0.606,3.061-1.023l1.574-0.58l1.639-0.68
                    c2.185-0.91,4.523-2.063,7.059-3.522C492.513,377.405,492.561,374.799,492.456,372.433z"/>
                      <path d="M67.897,261.877l113.922,72.341c9.354,5.938,21.75,3.172,27.689-6.181l5.621-8.852c5.592-8.808,3.462-20.311-4.615-26.583 L93.358,218.207c-9.111-4.642-20.43-1.678-26.022,7.13l-5.62,8.85C55.775,243.541,58.543,255.938,67.897,261.877z" />
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/portal/dashboard"
                    className="flex items-center p-2 text-gray-700 rounded-lg hover:bg-gray-100 justify-center"
                  >
                    <RiDashboard3Fill className="w-5 h-5 text-gray-500" />
                  </Link>
                </li>
              </>
            )}

            <li>

              {
                isAdmin ?
                  <Link
                    to="/portal/admin?area=solicitações&secao=rhestrategico"
                    className={`flex items-center p-2 text-gray-700 rounded-lg hover:bg-gray-100 ${!isOpen ? 'justify-center' : ''
                      }`}
                  >
                    <RiAdminFill className="w-5 h-5 text-gray-500" />
                    {isOpen && (
                      <span className="ml-3 text-sm">RH Estratégico</span>
                    )}
                  </Link>
                  :
                  <label className={`cursor-not-allowed opacity-65 flex items-center p-2 text-gray-500 rounded-lg hover:bg-gray-100 ${!isOpen ? 'justify-center' : ''
                    }`}
                  >
                    <RiAdminFill className="w-5 h-5 text-gray-500" />
                    {isOpen && (
                      <span className="ml-3 text-sm">RH Estratégico</span>
                    )}
                  </label>

              }

            </li>
          </ul>

          <hr className="border-gray-200 dark:border-gray-700 my-1" />

          {/* Settings and Notifications */}
          <ul className="space-y-2 font-medium">
            {/* Settings */}
            <li>
              <Link
                to="/portal/perfil?area=empresa"
                onClick={() => setIsOpen(false)}
                className={`flex items-center p-2 text-gray-700 rounded-lg hover:bg-gray-100 ${!isOpen ? 'justify-center' : ''
                  }`}
              >
                <BsGearFill className="w-5 h-5 text-gray-500" />
                {isOpen && (
                  <span className="ml-3 text-sm">Configurações</span>
                )}
              </Link>
            </li>

            {/* Notifications */}
            <li>
              <NotificaçõesComponent isOpen={isOpen} />
            </li>
          </ul>

          <hr className="border-gray-200 my-2" />

          {/* User Profile Section */}
          <div className="font-medium">
            <div
              className={`flex ${isOpen ? 'flex-row items-center' : 'flex-col items-center'} ${!isOpen ? 'justify-center' : ''} p-2 text-gray-700 rounded-lg`}
            >
              <Link
                to="/portal/perfil?area=user"
                onClick={() => setIsOpen(false)}
                className={`flex items-center ${!isOpen ? '' : 'flex-grow'} hover:bg-gray-100 p-2 rounded-lg`}
              >
                {user && user.Foto ? (
                  <div className='w-8 h-8 object-cover origin-center flex justify-center items-center'>
                    <img
                      className="w-full h-full rounded-full"
                      src={user.Foto}
                      alt="User"
                    />
                  </div>
                ) : (
                  <div
                    className={`${isOpen ? 'w-6 h-6' : 'w-8 h-8'} rounded-full bg-green-500 flex items-center justify-center`}
                  >
                    <span
                      className={`text-white font-bold ${isOpen ? 'text-xs' : ''}`}
                    >
                      {user ? user.Nome.charAt(0) : ''}
                    </span>
                  </div>
                )}
                {isOpen && (
                  <span className="ml-3 text-sm">
                    {user ? getUserInitials(user.Nome) : 'Usuário'}
                  </span>
                )}
              </Link>

              {/* Botão "Sair" */}
              <button
                onClick={handleLogOut}
                className={`flex items-center text-gray-700 hover:text-orange-700 hover:bg-gray-100 px-2 py-1 rounded-lg ${isOpen ? 'ml-2' : 'mt-2'}`}
              >
                <IoLogOutSharp className="w-5 h-5 text-gray-500 rotate-180" />
                {isOpen && <span className="ml-1 text-sm">Sair</span>}
              </button>
            </div>
          </div>
        </div>
      </aside>
    </section>
  );
};

export default SideBar;
