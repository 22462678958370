import { useEffect, useRef, useState } from 'react'
import { BsFolder, BsFolder2Open, BsSearch } from 'react-icons/bs'
import { PiCaretCircleUpDown, PiFolderFill, PiFolderOpenFill } from "react-icons/pi";
import SelecionarEmpresa from '../SelecionarEmpresa'
import DisplayProcessos from '../ProcessosComponents/DisplayProcessos'
import Filtros from '../ProcessosComponents/Filtros'
import { DisplayMiddle, SituaçãoInterna } from '../../Utils/types/PortalTypes'
import PortalObj from '../PortaisComponents/PortalObj'
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import FiltroGlobal from '../ProcessosComponents/FiltroGlobal'
import { toast } from 'react-toastify'
import LoadingSmall from '../LoadingSmall'
import BuscarProcessos from '../BuscarProcessosComponents/BuscarProcessos'
import Captacao from '../CaptacaoAutomatica/Captacao'
import PortalPastas from '../HomeComponents/PortalPastas';
import PortalPortais from '../HomeComponents/PortalPortais';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import LoadingPage from '../LoadingPage';
import { BiSearchAlt } from "react-icons/bi";

enum InicialTab {
  FILTRO = 'filtro',
  PASTA = 'pasta',
  IDLE = 'idle'
}

const Center = ({ empresas }: any) => {
  const [empresaSelecionada, setEmpresaSelecionada] = useState<string>('');
  const [processos, setProcessos] = useState<any>('');
  const [portalSelecionado, setPortalSelecionado] = useState<string>('');
  const [ferramentaInicial, setFerramentaInicial] = useState<InicialTab>(InicialTab.FILTRO);
  const [filtroSelecionado, setFiltroSelecionado] = useState<SituaçãoInterna>(SituaçãoInterna.RESUMO);


  // filtro impoortante 
  const [filtrarImportante, setFiltrarImportante] = useState<boolean>(false);

  const alterarFiltroImportante = () => {
    setFiltrarImportante(current => !current)
  }


  // filtro impoortante 




  const { listarProcessos } = useReactQueryProcessos();
  const empresaSelecionadaLs = localStorage.getItem('empresaSelecionada');

  const [pageParam, setPageParam] = useState<number>(1);
  const { ref, inView } = useInView();
  const searchRef = useRef<HTMLInputElement | any>(null);



  const { data: processosData, isLoading: processosIsLoading, isFetching, isError } = useQuery({
    queryFn: () => listarProcessos({ empresaid: empresaSelecionadaLs as string, pageParam, filtro: filtroSelecionado }),
    queryKey: ["rqProcessos", empresaSelecionada],
    refetchOnWindowFocus: false,
    
  });


  useEffect(() => {
    if (processosData) {
      setProcessos(processosData.processos);

      if (filtroSelecionado && processos) {
        setPageParam(1);
        setProcessos(processosData.processos);
        paginationMutation.mutate({ empresaid: empresaSelecionada, pageParam, filtro: SituaçãoInterna.RESUMO });
      }

      if (filtrarImportante) {
        const processosImportante = processosData.processos.filter((i: any) => {
          return i.importante
        })

        setProcessos(processosImportante)
      }
    }
  }, [processosData, filtroSelecionado, filtrarImportante]);

  useEffect(() => {
    if (empresas) {
      const empresaLocal = localStorage.getItem('empresaAtual');
      if (empresaLocal) {
        setEmpresaSelecionada(empresas[0]);
      }
    }
  }, [empresas]);

  const selecionarPortal = (portal: any) => {
    setPortalSelecionado(portal);
  };

  const fechar = (e: any) => {
    e.preventDefault();
    setPortalSelecionado('');
  };


  const [globalFilter, setGlobalFilter] = useState<DisplayMiddle>(DisplayMiddle.RESUMO);

  const changeFiltroGlobal = (filtroMiddle: DisplayMiddle) => {
    setGlobalFilter(filtroMiddle);
  };

  const queryClient = useQueryClient();

  const paginationMutation = useMutation({
    mutationFn: listarProcessos,
    onMutate: async () => {
      // console.log('Mutação iniciada!');
      setPageParam((pageParam) => pageParam + 1);
    },
    onSuccess: async (response) => {
      // console.log('Mutação bem sucedida!', response);

      queryClient.invalidateQueries({ queryKey: ['rqProcessos'] });
      queryClient.refetchQueries({ queryKey: ['rqProcessos'] });

      if (response.status === 1) {
        // console.log('Carregando mais processos!');
      } else {
        console.error('Erro: ', response.msg);
      }
    },
    onError: async (error) => {
      console.error(error.message);
      toast.error(error.message);
    },
    onSettled: async () => {
      // console.log('Mutação concluida!');
    },
  });

  // const hanldePagination = () => {


  //   paginationMutation.mutate({ empresaid: empresaSelecionada as string, pageParam: pageParam });
  // };

  // useEffect(() => {
  //   if (inView) {
  //     hanldePagination();
  //   }
  // }, [inView]);

  const filtrarProcesso = (filtro: SituaçãoInterna) => {
    paginationMutation.mutate({ empresaid: empresaSelecionada, pageParam, filtro: filtro });
  };

  const alterarFiltro = (filtro: SituaçãoInterna) => {
    if (processosData) {
      setFiltroSelecionado(filtro);
      filtrarProcesso(filtro);
      setProcessos(processosData.processos);

      if (filtroSelecionado === SituaçãoInterna.RESUMO) {
        setProcessos(processosData.processos);
      }
    }
  };

  const [processosBuscados, setProcessosBuscados] = useState<any>('');
  const [prcessosBuscadosInput, setProcessosBuscadosInput] = useState<string>('');
  const [bucaShallow, setBuscShallow] = useState<boolean>(false);
  const [buscaDeep, setBuscaDeep] = useState<boolean>(false);

  const closeBusca = () => {
    setBuscaDeep(false);
  };

  // const clearSearch = () => {
  //   setProcessosBuscados('');
  //   setBuscShallow(false);
  //   searchRef.current.value = '';
  // };

  const buscarProcesso = (cnpj: string) => {
    setBuscShallow(true);
    try {
      const processoBuscadosTemp: any = processos.filter((i: any) => i.CnpjLicitante === cnpj);

      if (processoBuscadosTemp && processoBuscadosTemp.length >= 1) {
        setProcessosBuscados(processoBuscadosTemp);
        return;
      }

      setProcessosBuscados('');
      return;
    } catch (error) {
      return;
    }
  };


  const toggleFerramentaInicial = () => {
    setFerramentaInicial(ferramentaInicial === InicialTab.FILTRO ? InicialTab.PASTA : InicialTab.FILTRO);
  };





  // if(processosIsLoading || isFetching ){
  //   return(
  //     <LoadingPage/>
  //   )
  // }


  return (
    <div className='w-full ml-10 mt-2 pr-5'>

      <div className='flex flex-row w-full relative'>
        <div className='flex flex-row absolute right-2 gap-4'>
          <div className='relative flex items-center pt-1'>

            <BiSearchAlt onClick={() => setBuscaDeep(true)} className='h-10 w-10 mt-1 text-3xl cursor-pointer hover:opacity-100 transition-opacity duration-200 ease-in-out text-gray-500' />

          </div>

          {buscaDeep && <BuscarProcessos cancelar={closeBusca} selecionarPortal={selecionarPortal} filtroGlobal={globalFilter} />}

          {/* {bucaShallow && (
            <div className='absolute top-full right-0 max-h-60 min-h-60 min-w-64 max-w-md p-2 z-50 rounded-md shadow-md flex flex-col gap-2 overflow-y-auto overflow-x-hidden bg-white'>
              {processosBuscados ? (
                processosBuscados.map((processo: any) => (
                  <div
                    className='h-full cursor-pointer opacity-80 hover:opacity-100'
                    key={processo._id}
                    onClick={() => setProcessos([processo, ...processos])}
                  >
                    <div className='h-6 w-6 rounded-full object-contain origin-center'>
                      {processo.LogoLicitante ? (
                        <img src="https://s3.sa-east-1.amazonaws.com/portalglc1.0/res/empresa.jpg" alt="img" className='h-6 w-6 rounded-full' />
                      ) : (
                        <img src={processo.LogoLicitante} alt="img" className='h-6 w-6 rounded-full' />
                      )}
                    </div>
                    <p>{processo.orgaopublicoLicitante}</p>
                    <p>Processo: {processo.processo}</p>
                  </div>
                ))
              ) : (
                <div>
                  <label>Nenhum processo encontrado com o CNPJ informado!</label>
                </div>
              )}
              <label className='flex flex-row gap-2 bottom-0 absolute cursor-pointer w-full overflow-x-hidden' onClick={() => setBuscaDeep((current) => !current)}>
                <BsSearch />Buscar mais...
              </label>
            </div>
          )} */}


        </div>
        <SelecionarEmpresa abrirPasta={toggleFerramentaInicial} />
      </div>
      {portalSelecionado && (
        <div id="crud-modal" aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative p-4 w-full max-w-5xl max-h-full">
            <PortalObj portalSelecionado={portalSelecionado} fechar={fechar} />
          </div>
        </div>
      )}
      {ferramentaInicial === InicialTab.PASTA && (
        <>
          <div className='w-full -mt-1'>
            <PortalPastas />
            <div className='mt-1'>
              <PortalPortais empresaid={empresaSelecionada} portalDefinido={portalSelecionado} portalDoProcesso={selecionarPortal} />
            </div>
          </div>
        </>
      )}
      <div className='sticky-container rounded-sm'>
        {ferramentaInicial === InicialTab.FILTRO && processos && (
          <div className='w-full bg-white dark:bg-gray-800 rounded-t-lg border-t border-l border-r pt-2'>
            <Filtros alterarFiltro={alterarFiltro} FiltroSelecionado={filtroSelecionado} processos={processosData.processos} />
          </div>
        )}
        <div className=''>
          <FiltroGlobal filtroGlobal={globalFilter} changeFiltroGlobal={changeFiltroGlobal} alterarFiltroImportante={alterarFiltroImportante} importante={filtrarImportante} />
        </div>
      </div>

      {filtroSelecionado === SituaçãoInterna.CAPTACAO ? (
        <Captacao />
      ) :
        processosIsLoading ?
          <LoadingPage />
          :
          (
            processos &&
            processos.length >= 1 && (
              <div>
                <DisplayProcessos selecionarPortal={selecionarPortal} processos={processos} filtroSelecionado={filtroSelecionado} globalFilter={globalFilter} usuarios={processosData.usuarios ? processosData.usuarios : ['']} />
                {/* <div ref={ref}>
              {isFetching && <LoadingSmall />}
            </div> */}
              </div>
            )
          )}
    </div>
  );
};

export default Center;
