export const converterValor = (valor: any)=>{


    try {

        if(!valor){
            return '---'
        }

        const valorUm = Number(valor);
        const valorTemp: any = Number(valor).toLocaleString("pt-br", {style: "currency", currency: 'BRL'}); 

        if(isNaN(valorTemp) && valor.includes('R$') ){
          return `${valor}`;
        }
        return valorTemp;

    } catch (error) {

        if(valor.includes('R$')){
            return valor
        }else{
            return `R$ ${valor}`;
        }

            
    }
}