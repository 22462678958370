import React from 'react'
import { useParams } from 'react-router-dom'
import LoadingPage from '../Components/LoadingPage'
import PortaisCenter from '../Components/PortaisComponents/PortaisCenter'
import PortaisSideNav from '../Components/PortaisComponents/PortaisSideNav'
import SideNavWrapper from '../Components/PortalComponents/SideNavWrapper'


const PortaisListar = ({empresaData}: any) => {

    const {portalid} = useParams();

  return (
    <div>

      <div className='grid grid-cols-[150px_minmax(900px,_1fr)_100px]'>
        {/* <PortaisSideNav/> */}
        {<SideNavWrapper/>}
        {empresaData ? <PortaisCenter empresas = { empresaData}/> : <LoadingPage/>}
      </div>

    </div>
  )
}

export default PortaisListar