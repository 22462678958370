import { useState } from 'react'
import { BsArrowRight, BsX } from "react-icons/bs";
import { useQuery } from '@tanstack/react-query';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'
import NovoEmpresa from './NovaEmpresa';
import NovoColaborador from './NovoColaborador';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoIosArrowRoundBack } from "react-icons/io";
import { BsBuildingFillGear } from "react-icons/bs";
import { MdLeaderboard } from "react-icons/md";
import { GrUserWorker } from "react-icons/gr";
import { PiBankFill } from "react-icons/pi";

const Register = () => {

  const { getEmpresas } = useReactQueryEmpresas();
  const { data }: any = useQuery({ queryKey: ['rqEmpresas'], queryFn: getEmpresas, staleTime: Infinity });

  enum RegisterModel {
    COLABORADOR = 'colaborador',
    EMPRESA = 'empresa',
    IDLE = 'idle',
    ASSESSORIA = 'assessoria'
  }
  const [tipoFormulario, setTipoFormulario] = useState<RegisterModel>(RegisterModel.IDLE);
  const fechar = () => {
    setTipoFormulario(RegisterModel.IDLE)
  }

  if (tipoFormulario === RegisterModel.EMPRESA) {
    return (
      <NovoEmpresa fecharRegistro={fechar} acesso={"Empresa"} />
    )
  }
  if (tipoFormulario === RegisterModel.ASSESSORIA) {
    return (
      <NovoEmpresa fecharRegistro={fechar} acesso={"Assessoria"} />
    )
  }
  if (tipoFormulario === RegisterModel.COLABORADOR) {
    return (
      <NovoColaborador fecharRegistro={fechar} empresas={data.empresas} />
    )
  }

  const handleArrowClick = () => {
    window.location.href = '/';
  }

  const handleBancoInvestidorClick = () => {
    toast.info('Em breve!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }


  return (
    <div className='bg-gradient-to-br from-slate-50 to-white dark:bg-gray-900 w-full min-h-screen flex justify-center items-center overflow-hidden'>
      <div className="flex w-screen h-screen">
        {/* Metade Esquerda: Conteúdo do Formulário */}
        <div className="w-1/2 p-16">
  <div className='flex mb-6'>
    <Link to={'/'} className='text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center'>
      <IoIosArrowRoundBack className="w-6 h-6" />
    </Link>
    <p className="text-gray-500 dark:text-gray-400 text-xl pl-32 font-semibold">
      Selecione o tipo de registro
    </p>
  </div>
  <ul className="space-y-4 mb-4">
    <li onClick={() => setTipoFormulario(RegisterModel.EMPRESA)}>
      <label className="inline-flex justify-between items-center w-full p-5 h-24 text-gray-900 bg-gradient-to-br from-slate-50 to-white border border-gray-300 rounded-2xl cursor-pointer dark:bg-gray-600 dark:border-gray-500 dark:text-white hover:bg-gray-100 hover:shadow-lg dark:hover:bg-gray-500">
        <BsBuildingFillGear className='h-8 w-8 text-gray-500' />
        <div className="block text-center flex-grow pr-4">
          <div className="text-xl font-semibold text-gray-500">Empresa</div>
          <div className="text-xs text-gray-500 dark:text-gray-400">Gerencie sua empresa com facilidade.</div>
        </div>
        <BsArrowRight className="text-gray-500 dark:text-gray-400 w-5 h-5 flex-shrink-0" />
      </label>
    </li>

    <li onClick={() => setTipoFormulario(RegisterModel.ASSESSORIA)}>
      <label className="inline-flex justify-between items-center w-full p-5 h-24 text-gray-900 bg-gradient-to-br from-slate-50 to-white border border-gray-300 rounded-2xl cursor-pointer dark:bg-gray-600 dark:border-gray-500 dark:text-white hover:bg-gray-100 hover:shadow-lg dark:hover:bg-gray-500">
        <MdLeaderboard className='h-8 w-8 text-gray-500' />

        <div className="block text-center flex-grow pr-4">
          <div className="text-xl font-semibold text-gray-500">Assessoria</div>
          <div className="text-xs text-gray-500 dark:text-gray-400">Assessorie uma empresa já existente.</div>
        </div>
        <BsArrowRight className="text-gray-500 dark:text-gray-400 w-5 h-5 flex-shrink-0" />
      </label>
    </li>

    <li onClick={() => setTipoFormulario(RegisterModel.COLABORADOR)}>
      <label className="inline-flex justify-between items-center w-full p-5 h-24 text-gray-900 bg-gradient-to-br from-slate-50 to-white border border-gray-300 rounded-2xl cursor-pointer dark:bg-gray-600 dark:border-gray-500 dark:text-white hover:bg-gray-100 hover:shadow-lg dark:hover:bg-gray-500">
        <GrUserWorker className='h-8 w-8 text-gray-500' />

        <div className="block text-center flex-grow pr-4">
          <div className="text-xl font-semibold text-gray-500">Colaborador</div>
          <div className="text-xs text-gray-500 dark:text-gray-400">Exclusivo para funcionários de empresas ou assessorias registradas.</div>
        </div>
        <BsArrowRight className="text-gray-500 dark:text-gray-400 w-5 h-5 flex-shrink-0" />
      </label>
    </li>

    <li onClick={handleBancoInvestidorClick}>
      <label className="inline-flex justify-between items-center w-full p-5 h-24 text-gray-900 bg-gradient-to-br from-slate-50 to-white border border-gray-300 rounded-2xl cursor-pointer dark:bg-gray-600 dark:border-gray-500 dark:text-white hover:bg-gray-100 hover:shadow-lg dark:hover:bg-gray-500">
        <PiBankFill className='h-8 w-8 text-gray-500' />

        <div className="block text-center flex-grow pr-4">
          <div className="text-xl font-semibold text-gray-500">Banco Investidor</div>
          <div className="text-xs text-gray-500 dark:text-gray-400">Ofereça crédito e potencialize os negócios das empresas ou assessorias.</div>
        </div>
        <BsArrowRight className="text-gray-500 dark:text-gray-400 w-5 h-5 flex-shrink-0" />
      </label>
    </li>
  </ul>
</div>


        {/* Metade Direita: Imagem */}
        <div className="w-1/2 hidden lg:block box-border">
          <img
            src="./design.png"
            alt="Descrição da imagem"
            className="w-full h-full object-cover rounded-r-lg"
          />
        </div>
      </div>
    </div>




  )
}

export default Register
