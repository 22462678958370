import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cnpj } from 'cpf-cnpj-validator';
import React, { useEffect, useRef, useState } from 'react'
import { BsX } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetAcessos } from '../../Utils/ReactQuery/getAcessos';
import { UserUpdateStatus } from '../../Utils/types/userType';
import LoadingPage from '../LoadingPage';
import styles from './Acessos.module.css'


const NovoAcessoCriarEmpresa = ({user, acessos, close}: any) => {

  const {configurarAcesso} = useGetAcessos();
  const queryClient =  useQueryClient();

  const depRef = useRef<HTMLInputElement | any>();
  const carRef = useRef<HTMLInputElement | any>();

  const [departamentos, setDepartamentos] = useState<[String]>(['']);
  const [departamentosTmp, setDepartamentosTmp] = useState<String>('');
  const [cargos, setCargos] = useState<[String]>(['']);
  const [cargosTmp, setCargosTmp] = useState<String>('');
  const [acessoAtual, setAcessoAtual] = useState<String>('')
  const [acesso, setAcesso] = useState<String>('');
  const [acessosState, setAcessosState] = useState<[String]>(['']);
  const [acessoErro, setAcessoErro] = useState<Boolean>(false);
  const [acessoErroMsg, setAcessoErroMsg] = useState<String>('');
  const [empresaErro, setEmpresaErro] = useState<Boolean>(false);

  const [cnpjState, setCnpjState] = useState<String>('')
  const [cnpjErro, setCnpjErro] = useState<Boolean>(false);

  const [criarAcessoStatus, setCriarAcessoStatus] = useState<UserUpdateStatus>(UserUpdateStatus.IDLE);

  const handleDepartamento: any =(e: any)=>{
    e.preventDefault();     
    if(departamentos.includes(departamentosTmp)){
      toast.error("Departamento já inserido!", {autoClose: 3000})
      return;
    }
    if(departamentosTmp.length < 1 || departamentosTmp === ""){
      toast.error("Departamento inválido!", {autoClose: 3000})
      return;
    }
    setDepartamentos((): any=> [...departamentos, departamentosTmp]);
    depRef.current.value = '';
    return;
  }

  const handleCargos: any =(e: any)=>{
    e.preventDefault();
    if(cargos.includes(cargosTmp)){
      toast.error("Crago já inserido!", {autoClose: 3000})
      return;
    }
    if(cargosTmp === ""){
      toast.error("Cargo inválido!", {autoClose: 3000})
      return;
    }
    setCargos((): any=> [...cargos, cargosTmp]);
    carRef.current.value = '';
    return;
}

const removeDepartamento =(e:any)=>{
    e.preventDefault();
    const tmpRemoval = departamentos.filter(i=> i !== e.target.id)
    setDepartamentos(tmpRemoval as [String]);
    return;
}


const removeCargo =(e: any) =>{
    e.preventDefault();
    const tmpRemoval = cargos.filter(i=> i !== e.target.id)
    setCargos(tmpRemoval as [String]);
    return;
}

useEffect(()=>{
  const acessoAt = user.Acessos;
  setAcessoAtual(acessoAt.TipoAcesso);
  if(acessos){
    const arrAcesso = acessos.Acessos;
    const flatAcessos = arrAcesso.flatMap((i: any)=>{return i.Acessos});
    const arrayObj = flatAcessos.flatMap((i:any)=>{return i.TipoAcesso});
    setAcessosState(arrayObj);
  }
}, [user, acessos]);

const verificarAcesso = (e: any)=>{


  if(acessosState.includes(acesso)){
    setAcessoErro(true);
    setAcessoErroMsg('O usuário já possui o acesso selecionado!');
    return;
  }else{
    setAcessoErro(false);
    setAcessoErroMsg('');
    return;
  }
}

const verificarCnpj = (e: any)=>{
  e.preventDefault();

  if(!cnpj.isValid(cnpjState as string)){
    setCnpjErro(true);
  }else{
    setCnpjErro(false)
  }

}

  // Tailwind ---------------------------------------------------------
  const inputField = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";

  const lowKeyinputField =" w-full block py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

  const inactiveButton =`disabled text-white bg-blue-400 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center opacity-75 dark:focus:ring-blue-200 my-6`;

  const activeButton =`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 my-6`;

  const activeTable : string="flex flex-wrap text-sm font-medium text-center w-full rounded-md h-50 border-2 border-gray-500 border-solid h-32 text-gray-500 dark:text-gray-400";

  const errorTable  : string="flex flex-wrap h-32 text-sm font-medium text-center w-full rounded-md h-50 border-2 border-red-500 border-solid text-red-500 dark:text-red-400";

  const table: string = `flex flex-wrap text-sm h-32 font-medium text-center w-full rounded-md h-50 border-2 border-gray-500 border-solid text-gray-500 dark:text-gray-400 `;

  const emptyLabel = `text-xl h-35 md:text:md
    mb-4 text-gray-500 dark:text-gray-400`;

  const comonText = `text-md h-35 md:text:sm
    mb-4 text-gray-500 dark:text-gray-400`;

  const lowKeyButton =`text-md h-35 md:text:sm
      mb-4 text-gray-500 dark:text-gray-400 opacity-80 hover:opacity-100 transition-opacity`
  // Tailwind ---------------------------------------------------------




  // Mutação ----------------------------
  // React query ==> Mutação  -----------------------------------
  const novoAcessoMutation = useMutation({
    mutationFn: configurarAcesso,
    onMutate:(config: any): any=>{
      setCriarAcessoStatus(UserUpdateStatus.LOADING)
      // console.log('New empresa mutation iniciada!');
      queryClient.invalidateQueries({queryKey: ['rqUser']});
      queryClient.refetchQueries({queryKey:[ 'rqUser']});
    },
     onSuccess:async(response)=>{
        // console.log('Mutação bem sucedida!: ', response);

        if(response.status === 1){
          setCriarAcessoStatus(UserUpdateStatus.SUCCESS);
          toast.success(`${response.msg}`);
        }else{
          setCriarAcessoStatus(UserUpdateStatus.ERROR);
          toast.error(`${response.msg}`);
        }
    },
     onError: async(response)=>{
        const err = await response;
        console.error('Erro: ', err);
        setCriarAcessoStatus(UserUpdateStatus.ERROR)
        toast.error(`Erro interno`)

    },
     onSettled: async(response)=>{
          const res = await response;
          // console.log('Mutação concluída!: ', res);
          setCriarAcessoStatus(UserUpdateStatus.IDLE)
          return;
    }   
   });

   // React query ==> Mutação  -----------------------------------

      const handleNovoAcesso= async(e:any)=>{
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const CnpjEmpresa = formData.get('CnpjEmpresa');
          const NomeEmpresa = formData.get('NomeEmpresa');
          const token = localStorage.getItem('token')
          novoAcessoMutation.mutate({token: token as string, Action:'novo-cadastro-criar-empresa' ,Empresa: {
              NovoAcesso: acesso as string,
              CnpjEmpresa: CnpjEmpresa as string,
              NomeEmpresa: NomeEmpresa as string,
              Departamentos: departamentos as [string],
              Cargos: cargos as [string]
          }})
      }
  // Mutação --------------------------

  if(criarAcessoStatus === UserUpdateStatus.LOADING){
    return(
      <LoadingPage/>
    )
  }

  if(criarAcessoStatus === UserUpdateStatus.SUCCESS){
    return(
      <div className='bg-white rounded-lg shadow dark:bg-gray-700 h-fit p-5 flex flex-col '>

        <div className='w-full text-right '>
          <button onClick={close} className='w-fit cursor-pointer opacity-80 hover:opacity-100 transition-opacity'> <BsX className='text-xl '/> </button>
        </div>

        <div className="relative z-0 w-full  group text-left">
            <div className="max-w-md flex flex-col gap-4 justify-center items-center">
                <h3 className='text-3xl'> Novo acesso registrado com sucesso! </h3>
                <Link className='w-fit p-5  bg-white shadow dark:bg-gray-800 rounded-md opacity-80 hover:opacity-100 transition-opacity' to={'/portal/acessos'}> Retornar a página de acessos</Link>
            </div>
        </div>
      </div>
    )
  }

  return (
  <div className='w-full flex flex-col min-h-screen justify-around mt-40 max-w-5xl'>

    <div className='w-full text-right '>
      <button onClick={close} className='w-fit cursor-pointer opacity-80 hover:opacity-100 transition-opacity'> <BsX className='text-3xl '/> </button>
    </div>

              

    <h3 className='text-xl'> Criar novo acesso para : <span className='font-semibold'>{user.Nome}</span></h3>

    <form className='p-5' onSubmit={handleNovoAcesso}>
      
      <div className={`${styles.autoGridForm} gap-4 my-4 h-fit`}>

      <div className={`${styles.inputField}`}>
        <div className='w-20 h-20 rounded-full object-contain'>
            <img src ='https://i.pinimg.com/736x/ec/d9/c2/ecd9c2e8ed0dbbc96ac472a965e4afda.jpg' alt="Perfil..." className='w-full h-auto rounded-full'/>
        </div>          
        <input type="file" name="Foto" placeholder='Envia a nova foto' className=' mt-5 rounded-lg' />
        <small  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> São
        permitidos apenas arquivos .png, .jpg e .ico com tamanho máximo de 15mb 
        </small>
      </div>

      <div className="relative z-0 w-full  group text-left">
        {acessoErro && <p className='text-red-500'>  {acessoErroMsg}</p>}
        <div className="max-w-md">
          <p>Selecione o novo tipo de acesso</p>
          <select onChange={(e)=> setAcesso(e.target.value)}  className='text-black dark:text-black dark:focus:text-black rounded-lg' required  name='Acesso' onBlur={verificarAcesso} >
            <option value="Empresa" className='text-black dark:text-black '>  Empresa</option>
            <option value={"Administrador"} className='text-black dark:text-black '>  Administrador</option>
            <option value="Assessoria" className='text-black dark:text-black '>  Assessoria</option>
            <option value="Governo" className='text-black dark:text-black '>  Governo</option> 
            <option value="Banco" className='text-black dark:text-black '>  Banco</option>
          </select>
        </div>
      </div>

        <div className={`${styles.inputField}`} >
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome da empresa</label>
          <input type="text" name="NomeEmpresa" className={`${inputField}`} />
        </div>
        

        <div className={`${styles.inputField}`} >
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cnpj da empresa</label>
          <input type="text" name="CnpjEmpresa" className={`${inputField}`}  onChange={(e)=> setCnpjState(e.target.value)} onBlur={verificarCnpj}/>
          {cnpjErro && <p className='text-red-500'> O CNPJ inserido não é válido!</p>}
        </div>

        <div className={`max-w-sm`}>
          <label className={`${emptyLabel}`}> Departamentos </label>
          <p className={`${comonText}`}> Informe ao menos 3 departamentos da sua empresa</p>
          <ul className={`${activeTable}`} >
              {
                  departamentos.length > 0 || !departamentos.includes("")  ?
                  departamentos.filter((i: any)=> {return i !== ""}).map((dp: any, index: number)=>(
                      <li key={index} className="me-2 flex flex-row gap-1 bg-blue-500 h-fit rounded-md items-center justify-center text-white">
                        <span > {
                              dp
                          }
                          </span>
                          <BsX id={String(dp)} onClick ={removeDepartamento}/>
                      </li>
                  ))
                  :
                  <p> Nenhum departamento informado!</p>
              }
          </ul>
          <div className="relative z-0 w-full mb-5 group text-left ">       
              <input  type="text" name="Departamentos" className={`${lowKeyinputField}`}  placeholder="Departamento"  onChange={(e)=> setDepartamentosTmp(e.target.value)} ref = {depRef}/>  
              <button onClick={handleDepartamento} className ={`${lowKeyButton}`} > Inserir departamento </button>
          </div>
        </div>

        <div className= 'max-w-sm'>
          <label className={`${emptyLabel}`}> Cargos </label>
          <p className={`${comonText}`}> Informe ao menos 3 cargos da sua empresa</p>   
          <ul className={`${table} `} >
              {
                  cargos.length > 0 ?
                  cargos.filter((i: any)=> {return i !== ""}).map((cr, index)=>(
                      <li key ={String(index)} className="me-2 flex flex-row gap-1 bg-blue-500 h-fit rounded-md items-center justify-center text-white">
                        <span> {String(cr)}</span>
                          <BsX id={String(cr)} onClick ={removeCargo}/>
                      </li>
                  ))
                  :
                  <p> Nenhum cargo informado! </p>
              }
          </ul>
          <div className="relative z-0 w-full mb-5 group text-left ">       
              <input type="text" name="Cargos" className={`${lowKeyinputField}`}  placeholder=" Cargos"  onChange={(e)=> setCargosTmp(e.target.value)} ref={carRef}/>   
              <button onClick={handleCargos} className ={`${lowKeyButton}`}> Inserir cargo </button>    
          </div>
          
        </div>

      </div>
      <p> Outras informações serão requisitadas ao concluir o cadastro!</p>

      {
        acessoErro || empresaErro || cnpjErro || cargos.length < 3 || departamentos.length < 3?
        <button type="submit" className={`${inactiveButton}`} disabled={true}>Criar acesso</button>
        :            
        <button type="submit" className={`${activeButton}`}>Criar acesso</button>

      } 

    </form>

  </div>

  )
}

export default NovoAcessoCriarEmpresa