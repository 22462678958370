import React, { useState, useEffect, useRef } from 'react';
import { CategoriasPadrão, TCategoriaPastas } from '../../Utils/DevUtils/CategoriasFake';
import { BsCaretDown, BsCaretUp, BsExclamationCircle, BsPlusCircle, BsX } from 'react-icons/bs';
import { toast } from 'react-toastify';
import LoadingSmall from '../LoadingSmall';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MdCheck } from "react-icons/md";
import PastasProcessoCatgorias from '../ProcessosComponents/PastasProcessoCatgorias';



const FerramentasConfig = ({ fechar, categoriasData }: any) => {


    const [categoria, setCategoria] = useState<TCategoriaPastas | undefined>();
    const [inputType, setInputType] = useState<'lista' | 'criar'>('lista');
    const [error, setError] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false)

    const [abrirFavoritos, setAbrirFavoritos] = useState<boolean>(false);
    const [abrirCategorias, setAbrirCategorias] = useState<boolean>(false);
    const [removerFiltro, setRemoverFiltro] = useState<boolean>(false);
    const [removerCategoria, setRemoverCategoria] = useState<boolean>(false);

    const [novaCategoria, setNovaCategoria] = useState<boolean>(false);
    const [anexarCategoria, setAnexarCategoria] = useState<boolean>(false);

    const fecharAnexar = ()=>{
        setAnexarCategoria(false);
    } 

    // Mutação -------------------------------
    const { configCategoria } = useReactQueryEmpresas()
    const token = localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada');
    const queryClient = useQueryClient();

    const configCategoriaMutation = useMutation({
        mutationFn: configCategoria,
        onMutate: async (data) => {
            setLoading(true)
        },
        onSuccess: async (response) => {

            queryClient.invalidateQueries({ queryKey: ['rqCategorias'] });
            queryClient.refetchQueries({ queryKey: ['rqCategorias'] });

            if (response.status === 1) {
                toast.success(response.msg)
                setSuccess(true);
            }

            if (response.status === 3) {
                toast.error(response.msg)
            }
        },
        onError: async (error) => {
            console.error(error.message);
            toast.error(error.message);
        },
        onSettled: async (data) => {
            setLoading(false);
            fechar();
        }
    })

    // Mutação -------------------------------

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                fechar();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    const handleAnexarCategoria = (e: React.FormEvent, action: 'nova' | 'anexar') => {
        e.preventDefault();
        
        configCategoriaMutation.mutate({ action: 'nova', token: token as string, empresaid: empresaSelecionada as string, categoria: categoria as TCategoriaPastas });
    };

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            fechar()
        }
    };

    const handleRemoverFiltroCategoria = (categoria: TCategoriaPastas, tipo: 'desanexar' | 'remover') => {

        setCategoria(categoria);
        switch (tipo) {
            case 'desanexar':
                return setRemoverFiltro(true);
            case 'remover':
                return setRemoverCategoria(true)
        }

    }

    const fecharRemoção =()=>{
        setCategoria(undefined)
        setRemoverCategoria(false);
        setRemoverCategoria(false)
    }



    if (removerFiltro || removerCategoria) {
        return (
            <div id="popup-modal" tabIndex={-1} className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50" >
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col p-10 gap-5" >

                        <div className='w-full flex justify-center items-center'>
                            <BsExclamationCircle className='text-5xl' />
                        </div>

                        {
                            removerFiltro &&
                            <>
                                <h3>
                                    Remover a categora "{categoria?.label}" dos filtros rápidos <span className='font-semibold'></span>?
                                </h3>

                                <small>
                                    Esta categoria continuará disponível, mas não estará mas presente na área de fltros acima dos processos.
                                </small>
                            </>
                        }
                        {
                            removerCategoria &&
                            <>
                                <h3>
                                    Remover a categora "{categoria?.label}" permanentemente <span className='font-semibold'></span>?
                                </h3>

                                <small>
                                    Esta categoria não aparecerá mais ao adicionar novas pastas e arquivos ou nos filtros, os arquivios e passtas permanecerão na mesma categoria.
                                </small>
                            </>

                        }

                        <div className='w-full flex flex-row justify-between'>

                            <label onClick={fecharRemoção} className={loading ? "closeButton disabled cursor-pointer" : "closeButton cursor-pointer"}>
                                <BsX className='text-xl' />
                                Cancelar
                            </label>

                            {
                                removerFiltro &&
                                <button className="closeButton" disabled={loading}
                                onClick={() => configCategoriaMutation.mutate({ token: token as string, empresaid: empresaSelecionada as string, action: 'remover-filtro', categoria: categoria as TCategoriaPastas })}
                                >

                                {loading ? <LoadingSmall message={'Desvinculando categria...'} /> : 'Desvincular'}
                                </button>
                            }

                            {

                                removerCategoria &&
                                <button className="closeButton" disabled={loading}
                                onClick={() => configCategoriaMutation.mutate({ token: token as string, empresaid: empresaSelecionada as string, action: 'remover-categoria', categoria: categoria as TCategoriaPastas })}
                                >

                                {loading ? <LoadingSmall message={'Desvinculando categria...'} /> : 'Remover'}
                                </button>

                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50"
            onClick={handleOverlayClick}
        >

           {
            anexarCategoria &&
            <PastasProcessoCatgorias novaCat={false} categoriasData={categoriasData} fechar={fecharAnexar}/>}

            <div className="relative p-4 w-full max-w-md max-h-full chart">
                <div className="relative bg-white dark:bg-gray-700 rounded-lg shadow-lg">

                    <button
                        onClick={fechar}
                        className="absolute top-3 right-3 text-gray-500 hover:bg-gray-200 rounded-lg hover:text-gray-700 dark:text-gray-300 dark:hover:text-white"
                        aria-label="Fechar modal"
                    >
                        <BsX size={24} />
                    </button>

                    <div className="p-6 text-center" >
                        <h3 className="mb-4 text-lg font-semibold text-gray-700 dark:text-gray-200">
                            Personalizar ferramentas
                        </h3>

                        <div className='flex flex-col justify-start text-left'>

                            <h3 className='text-xl'> Categorias de pastas e arquivos</h3>

                            <small className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                                Adicione um atalho para filtrar pastas ou arquivos do processo através da sua categoria.
                            </small>

                            <label className='font-semibold border-b-2'>
                                Pastas e arquivos dos processos
                            </label>

                            <div>

                                <label htmlFor=""> Lista de categorias favoritadas</label>
                                <br />
                                <small> São permitidas somente 7 categorias para o acesso rápido</small>

                                <label className='inputField relative'>Documentos

                                    {

                                        abrirFavoritos ?

                                            <span className='absolute right-0 top-[25%] flex flex-row cursor-pointer opacity-75 hover:opacity-100' onClick={() => setAbrirFavoritos(current => !current)}> ver menos <BsCaretUp className='mt-1 ml-2 mr-2' /></span>
                                            :
                                            <span className='absolute right-0 top-[25%] flex flex-row cursor-pointer opacity-75 hover:opacity-100' onClick={() => setAbrirFavoritos(current => !current)}> ver mais <BsCaretDown className='mt-1 ml-2 mr-2' /></span>

                                    }

                                </label>

                                {
                                    abrirFavoritos &&
                                    <div className='bg-white h-fit flex flex-col border p-2 overflow-y-auto chart max-h-64'>
                                        {
                                            categoriasData && categoriasData.filtros.map((categoria: TCategoriaPastas) => (
                                                <label className=' relative' key={categoria._id}>{categoria.label} <span className='absolute right-0 top-[25%] flex flex-row cursor-pointer opacity-75 hover:opacity-100' onClick={() => { handleRemoverFiltroCategoria(categoria, 'desanexar') }}> remover - </span></label>
                                            ))
                                        }
                                    </div>
                                }

                            </div>

                            <button onClick={()=> setAnexarCategoria(current => !current)} className='text-blue-500 hover:underline w-fit mx-0'> Anexar categoria +</button>

                        </div>

                        <div className='text-left mt-5'>

                            <label> Lista geral das categorias</label>

                            <label className='inputField relative'>Documento
                                {
                                    abrirFavoritos ?
                                        <span className='absolute right-0 top-[25%] flex flex-row cursor-pointer opacity-75 hover:opacity-100' onClick={() => setAbrirCategorias(current => !current)}> ver menos <BsCaretUp className='mt-1 ml-2 mr-2' /></span>
                                        :
                                        <span className='absolute right-0 top-[25%] flex flex-row cursor-pointer opacity-75 hover:opacity-100' onClick={() => setAbrirCategorias(current => !current)}> ver mais <BsCaretDown className='mt-1 ml-2 mr-2' /></span>
                                }

                            </label>

                            {
                                abrirCategorias &&
                                <div className='bg-white h-fit flex flex-col border p-2 overflow-y-auto chart max-h-64'>
                                    {
                                        categoriasData && categoriasData.categorias.map((categoria: TCategoriaPastas) => (
                                            <label className=' relative' key={categoria._id}>{categoria.label} <span className='absolute right-0 top-[25%] flex flex-row cursor-pointer opacity-75 hover:opacity-100' onClick={() => handleRemoverFiltroCategoria(categoria, 'remover')}> remover - </span></label>
                                        ))
                                    }
                                </div>
                            }


                            <button onClick={() => setNovaCategoria(current => !current)} className='text-blue-500 hover:underline w-fit mx-0'> Adicionar categoria {novaCategoria ? '-' : '+'}</button>

                            {
                                novaCategoria &&
                                <div className='flex flex-col'>
                                    <label htmlFor=""> Nome da categoria</label>

                                    <div className='flex flex-row'>

                                        <input type="text" name="" id="" className='inputField max-w-60' onChange={(e)=> setCategoria({label: e.target.value, value: e.target.value})}/>
                                        <span className='text-2xl ml-2 mt-2 opacity-70 hover:opacity-100 cursor-pointer' onClick={(e) => handleAnexarCategoria(e, 'nova')}>

                                            {loading ? <LoadingSmall /> : success ? <span className='fadeIn'><MdCheck /></span> : <BsPlusCircle />}

                                        </span>
                                    </div>

                                </div>}

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FerramentasConfig;
