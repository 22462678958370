import React, { createContext, ReactNode, useMemo, useState } from 'react'

type UserContextType ={
    Permissões? : [string],
}

export const UserContext = createContext<any>(null);

export const UserContextProvider =({children} : any)=>{

    const [userContext, setUserContext] = useState({
        Permissões: [''],
        Solicitações: null,
        EmpresaPadrão: [''],
        EmpresaSelecionada: [''],
        User : ['']
    });

    const contextValue = useMemo<any>(()=>[userContext, setUserContext],[userContext, setUserContext]);
    
    return(
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    )
    



}
 