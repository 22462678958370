import { DisplayMiddle } from "../types/PortalTypes"


export type TCategoriaPastas = {
    _id?: string,
    label: string,
    value: string

}

export const CategoriasPadrão: TCategoriaPastas[] = [
    { value: DisplayMiddle.PUBLICAÇÃO, label: 'Publicação' },
    { value: "Edital/Anexos", label: 'Edital/Anexos' },
    { value: "Recibo Edital", label: 'Recibo Edital' },
    { value: "Cotação", label: 'Cotação' },
    { value: "C.P.H", label: 'C.P.H' },
    { value: DisplayMiddle.ESCLARECIMENTO, label: 'Esclarecimento' },
    { value: "Impugnação", label: 'Impugnação' },
    { value: "Decisão Impugnação", label: 'Decisão Impugnação' },
    { value: "Vistoria", label: 'Vistoria' },
    { value: "V.D.C", label: 'V.D.C' },
    { value: DisplayMiddle.IRECURSO, label: 'Intenção Recurso' },
    { value: DisplayMiddle.RECURSO, label: 'Recurso' },
    { value: DisplayMiddle.CONTRARRAZÃO, label: 'Contrarrazão' },
    { value: "Parecer Jurídico", label: 'Parecer Jurídico' },
    { value: "Decisão Final Recurso", label: 'Decisão Final Recurso' },
    { value: DisplayMiddle.ATR, label: 'Ata Realização' },
    { value: "Proposta Readequada", label: 'Proposta Readequada' },
    { value: "Amostra", label: 'Amostra' },
    { value: DisplayMiddle.HOMOLOGAÇÃO, label: 'Homologação' },
    { value: "Ajdudicação", label: 'Ajdudicação' },
    { value: "Ata Registro Preços", label: 'Ata Registro Preços' },
    { value: "Contrato", label: 'Contrato' },
    { value: "Seguro Garantia Contratual", label: 'Seguro Garantia Contratual' },
    { value: "Empenho", label: 'Empenho' },
    { value: DisplayMiddle.OS, label: "Ordem Serviço'ᴮ" },
    { value: "Comprovante Entrega", label: 'Comprovante Entrega' },
    { value: "Nota Fiscal", label: 'Nota Fiscal' },
    { value: "Boleto", label: 'Boleto' },
    { value: "Comprovante Pagamento", label: 'Comprovante Pagamento' },
    { value: "Adesão", label: 'Adesão' },
    { value: "Ofício's", label: "Ofício's" },
    { value: DisplayMiddle.ACT, label: 'A.C.T' },
    { value: DisplayMiddle.SANSÕES, label: 'Sansões' },
    { value: DisplayMiddle.DILIGENCIA, label: 'Diligência' },
    { value: DisplayMiddle.ADJUDICAÇÃO, label: 'Adjudicação' },
    { value: DisplayMiddle.REGISTROPROP, label: 'Registro proposta' },
    { value: DisplayMiddle.MANDATOS, label: 'Mandato de segurança' },
    { value: DisplayMiddle.APRESENTAÇÃO, label: 'Apresentação' },
    { value: DisplayMiddle.JULGAMENTOR, label: 'Julgamento recurso' },
    { value: 'Pedido Esclarecimento', label: 'Pedido Esclarecimento' },
    { value: 'Resposta Esclarecimento', label: 'Resposta Esclarecimento' },
    { value: 'Pedido Impugnação', label: 'Pedido impugnação' },
    { value: 'Resposta Impugnação', label: 'Resposta impugnação' },
    { value: "Solicitação Diligência", label: 'Solicitação Diligência ' },
    { value: "Resposta Diligência", label: 'Resposta Diligência' },
    { value: "Pedido Mandato.s", label: 'Pedido Mandato S. ' },
    { value: "Resposta Mandato.s", label: 'Resposta Mandato S.' },
    { value: "Publicação Aviso", label: 'Publicação Aviso' },
    { value: "Licitação", label: 'Licitação' },
    { value: "Edital/Anexos", label: 'Edital/Anexos' },
    { value: "Termo Referência", label: 'Termo Referência' },
    { value: "Recibo Edital", label: 'Recibo Edital' },
    { value: "Retificação", label: 'Retificação' },
    { value: "Rerratificação", label: 'Rerratificação  ' },
    { value: "Adiamento", label: 'Adiamento' },
    { value: "Cancelamento", label: 'Cancelamento' },
    { value: "Revogado", label: 'Revogado' },
    { value: "Suspenso", label: 'Suspenso' },
    { value: "Fracassado", label: 'Fracassado' },
    { value: "Deserto", label: 'Deserto' },
    { value: "Outros", label: 'Outros' },
]

