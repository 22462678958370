import React from 'react'
import { BsGrid, BsList, BsSearch } from 'react-icons/bs'
import { DisplayPastas } from '../../Utils/types/pastasTypes'
import { TbListDetails } from "react-icons/tb";



const PastasFerramentas = ({filtroPasta, displayPasta}: any) => {
  return (
    <div className='w-full  shadow-lg flex flex-row justify-between items-center'>
        
        <div className="flex p-0 m-0 flex-row justify-between bg-white dark:bg-gray-900">
            <label htmlFor="table-search" className="sr-only">Buscar pasta</label>
            <div className="relative p-0 m-0">
                <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                    <BsSearch/>
                </div>
                <input type="text" id="table-search" className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Buscar pasta por nome"/>
            </div>
        </div>

        <div className='inline-flex gap-5 pr-5'>
            <TbListDetails className='text-xl cursor-pointer opacity-80 hover:opacity-100' onClick={(e)=> displayPasta(e, DisplayPastas.DETAILS)}/>
            <BsGrid className='text-xl cursor-pointer opacity-80 hover:opacity-100' onClick={(e)=> displayPasta(e, DisplayPastas.GRID)}/>
            <BsList className='text-xl cursor-pointer opacity-80 hover:opacity-100' onClick={(e)=> displayPasta(e, DisplayPastas.LIST)}/>
        </div>

    </div>
  )
}

export default PastasFerramentas