import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react'
import LoadingSmall from '../LoadingSmall';
import AssessoriaPermissões from './AssessoriaPermissões';
import { useAdminUtils } from '../../Utils/ReactQuery/getAdminUtils';
import { toast } from 'react-toastify';

const EmpresaGerenciarAssessorias = () => {
    const [confirmarRevogarAssesso, setConfirmarRevogarAcesso] = useState<boolean>(false);

    const [loading, setLoading] = useState<Boolean>(false);
    const [error, setError] = useState<Boolean>(false);

    const empresaSelecionada = localStorage.getItem('empresaSelecionada');
    const token = localStorage.getItem('token')
    const { listarAssessoria } = useAdminUtils();

    // RQ e Mutação -----------------------------
    const { data: assessoriasData, isLoading: assessoriasIsLoading } = useQuery({ queryFn: () => listarAssessoria({ empresaid: empresaSelecionada as string, token: token as string }), queryKey: ["rqAssessorias"] })

    const [selecionarEmpresa, setSelecionarEmpresa] = useState<any>('')
    const [abrirPermissões, setAbrirPermissões] = useState<boolean>(false)
    const [fecharOpcoes, setFecharOpcoes] = useState<boolean>(false)

    const queryClient = useQueryClient()
    const { revogarAssessoria } = useAdminUtils()

    const revogarAssessoriaMutation = useMutation({
        mutationFn: revogarAssessoria,
        onMutate: async (data) => {
            setLoading(true)

        },
        onSuccess: async (response) => {



            queryClient.invalidateQueries({queryKey:['"rqAssessorias"']})
            queryClient.refetchQueries({queryKey:['"rqAssessorias"']})

            if(response.status === 1){
                toast.success(response.msg)
            }
            if(response.status === 3){
                toast.error(response.msg)
            }

        },
        onError: async (err) => {
            console.error(err.message);
            toast.error('Erro na solicitação!');
        },
        onSettled: async (data) => {
            setLoading(false);
            setConfirmarRevogarAcesso(false);
            setSelecionarEmpresa('')
        }
    })



    // RQ e Mutação -----------------------------

    const handleRevogarAssesso = (empresa: any) => {
        setSelecionarEmpresa(empresa);
        setConfirmarRevogarAcesso(true)
        return;
    }

    const fechar = () => {
        setAbrirPermissões(false)
        setFecharOpcoes(false)
    }

    // const handleAbrirPermissões = (empresa: any) => {
    //     setSelecionarEmpresa(empresa);
    //     setAbrirPermissões(true)
    // }




    //Tailwind --------------------------------------------------------
    const idleRow = 'bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600';


    //Tailwind --------------------------------------------------------


    return (
        <div>

            {
                abrirPermissões &&
                <AssessoriaPermissões fechar={fechar} empresa={selecionarEmpresa} />
            }

            {
                confirmarRevogarAssesso &&
                <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full bg-transparent">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative  rounded-lg shadow ">

                            <div className="p-4 md:p-5 text-center bg-gray-100 dark:bg-gray-600">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Revogar o assesso desta empresa? </h3>

                                <button data-modal-hide="popup-modal" type="button" className="confirmButton2" onClick={()=> revogarAssessoriaMutation.mutate({token: token as string, empresaid: empresaSelecionada as string, tipo: 'empresa', Cnpj: selecionarEmpresa.CnpjEmpresa})}> {loading ? <LoadingSmall message={"aguarde"} /> : "Confirmar"}</button>

                                <button data-modal-hide="popup-modal" type="button" className="closeButton2" onClick={() => setConfirmarRevogarAcesso(false)}>Cancelar</button>

                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="relative overflow-x-auto shadow-xl sm:rounded-lg bg-white dark:bg-gray-900 p-5 rounded-xl max-w-4xl">
                <h3 className="text-lg font-semibold mb-2 font-sans">Assessorias desta empresa</h3>
                <small className="block mb-4 font-sans text-gray-600 dark:text-gray-300">Estas são as empresas que atualemnte assessoram a sua empresa, ao selecionar a opção de "Revogar assesso" a empresa selecionada não terá mais acesso a esta empresa.</small>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-sans">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                        <tr>

                            <th scope="col" className="px-6 py-3">
                                Nome da empresa
                            </th>
                            <th scope="col" className="px-6 py-3">
                                CNPJ da empresa
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Contato
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opções
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            assessoriasData && assessoriasData.assessorias.map((empresa: any) => (
                                <tr key={empresa.CnpjEmpresa} id={`id-${empresa._id}`} className={`${idleRow}`}>

                                    <td className="px-6 py-4">
                                        {empresa.NomeEmpresa}
                                    </td>
                                    <td className="px-6 py-4">
                                        {empresa.CnpjEmpresa}
                                    </td>

                                    <td className="px-6 py-4 flex flex-col ">
                                        <span>{empresa.Email}</span>
                                        <span>{empresa.Fone}</span>
                                    </td>
                                    <td className='px-6 py-4'>
                                        <div className='flex flex-col'>
                                            <label htmlFor='assessoriaCb' className='text-red-500 font-semibold opacity-80 hover:opacity-100 cursor-pointer inline-flex' onClick={() => handleRevogarAssesso(empresa)}> Revogar acesso </label>
                                            {/* <label htmlFor='assessoriaCb' className='font-semibold opacity-80 hover:opacity-100 cursor-pointer inline-flex' onClick={() => handleAbrirPermissões(empresa)}> Permissões </label> */}
                                        </div>
                                    </td>
                                </tr>
                            )) 
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default EmpresaGerenciarAssessorias