import { cnpj, cpf } from 'cpf-cnpj-validator';

export const formatCpfCnpj = (value: string): string => {
    const onlyDigits = value.replace(/\D/g, '');

    if (onlyDigits.length <= 11) {
        // Format CPF
        if (onlyDigits.length <= 3) {
            return onlyDigits;
        } else if (onlyDigits.length <= 6) {
            return onlyDigits.replace(/(\d{3})(\d+)/, '$1.$2');
        } else if (onlyDigits.length <= 9) {
            return onlyDigits.replace(/(\d{3})(\d{3})(\d+)/, '$1.$2.$3');
        } else {
            return onlyDigits.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
        }
    } else {
        // Format CNPJ
        if (onlyDigits.length <= 2) {
            return onlyDigits;
        } else if (onlyDigits.length <= 5) {
            return onlyDigits.replace(/(\d{2})(\d+)/, '$1.$2');
        } else if (onlyDigits.length <= 8) {
            return onlyDigits.replace(/(\d{2})(\d{3})(\d+)/, '$1.$2.$3');
        } else if (onlyDigits.length <= 12) {
            return onlyDigits.replace(/(\d{2})(\d{3})(\d{3})(\d{1,4})/, '$1.$2.$3/$4');
        } else {
            return onlyDigits.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/, '$1.$2.$3/$4-$5');
        }
    }
};
