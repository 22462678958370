import React, { useEffect, useState } from 'react'
import { BsPencil, BsTrash } from 'react-icons/bs'
import { FaList, FaRegFile, FaRegFolder } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { formatarData } from '../../Utils/formatarData'
import {DisplayPastas, typePasta} from '../../Utils/types/pastasTypes'
import LoadingPage from '../LoadingPage'
import ApagarPasta from './ApagarPasta'
import PastaInfo from './PastaInfo'
import styles from './Pastas.module.css'
import { MdLink, MdLinkOff } from 'react-icons/md'
import { converterValor } from '../../Utils/ConverterValor'


const DisplayListarPastas = ({displayPastas, pathPasta, pastas}: any) => {

    

  const [editando, setEditando] = useState<boolean>(false);
  const [pasta, setPasta] = useState<any>('');
  const [apagando, setApagando] = useState<boolean>(false);
 
  const handleEditarPasta = (e: any, pastaid: string)=>{
    const pastaEditada = pastas.find((i: any)=>{
      return i._id === pastaid
    })
    setPasta(pastaEditada);
    setEditando(true);
  }

  const hanldeApagarPasta =(e: any, pastaid: string)=>{
    const pastaEditada = pastas.find((i: any)=>{
      return i._id === pastaid
    })
    setPasta(pastaEditada);
    setApagando(true);
  }

  const fechar =()=>{
    setEditando(false);
    setApagando(false)
    setPasta('');
    return;
  }




  return (
    <div  className='mt-5'>

      {
        editando && <PastaInfo pasta ={pasta} fechar = {fechar} editando={true}/>
      }
      {
        apagando && <ApagarPasta fechar ={fechar} pasta ={pasta}/>
      }
    
          {
            displayPastas === DisplayPastas.GRID &&            
            <div className={`${styles.displayPastas} `}>
                { 
                  pastas && pastas.map((pasta: any)=>(
                    <div  className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity w-fit' >
                        <label key={pasta._id} id={pasta._id} className ={`flex flex-col`} >
                          <Link to={`/portal/pastas/${pasta.NomePasta}`} className='w-fit h-fit'>
                            <FaRegFile className='text-7xl  cursor-pointer'/>
                          </Link>
                        <div className='felx flex-col gap-2 justify-center items-center'>
                        <span>{pasta.NomePasta}</span>    
                          <div className='flex flex-row gap-2 items-center justify-center'>
                            <BsPencil className='cursor-pointer opacity-80 hover:opacity-100' onClick={(e)=> handleEditarPasta(e, pasta._id)} /> 
                            <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100'onClick={(e)=> hanldeApagarPasta(e, pasta._id)}/>
                          </div>
                        </div> 

                        </label>
                    </div>
                  )) 
                } 
            </div> 
          }

        { 
          displayPastas === DisplayPastas.LIST &&
          pastas && pastas.map((pasta: any)=>(
               <div className='flex flex-row justify-between w-full pl-5'>
               <Link to={`/portal/pastas/${pasta.NomePasta}`}   className='underline cursor-pointer'>{pasta.NomePasta}</Link> 
               <div className='inline-flex gap-4 pr-5'>
               <BsPencil className='cursor-pointer opacity-80 hover:opacity-100' onClick={(e)=> handleEditarPasta(e, pasta._id)} /> 
               <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100'onClick={(e)=> hanldeApagarPasta(e, pasta._id)}/>
               </div>
           </div>
           ))  
        } 

    {
      displayPastas === DisplayPastas.DETAILS &&   
      <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Nome do arquivo
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Valor
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Emissão
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Vencimento
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opções
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            pastas.map((pasta: typePasta)=>(
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900
                                underline underline-offset-2 whitespace-nowrap dark:text-white">
                                    <Link to={`/portal/pastas/${pasta.NomePasta}`} className='cursor-pointer w-fit'> {pasta.NomePasta.replace("_", " ")}</Link> 
                                </th>
                                <td className="px-6 py-4">
                                    {converterValor(pasta.Valor)}
                                </td>
                                <td className="px-6 py-4">
                                    {formatarData(pasta.DataEmissao as string) }
                                </td>
                                <td className="px-6 py-4">
                                    {formatarData(pasta.DataVencimento as string)}
                                </td>
                                <td className="px-6 py-4 inline-flex text-black dark:text-gray-300  gap-4">
                                
                                {
                                  pasta.LinkOEA ?
                                   <Link to={pasta.LinkOEA}><MdLink/></Link>
                                  :
                                  <Link to={pasta.LinkOEA}><MdLinkOff/></Link>
                                }


                                <BsPencil className='cursor-pointer opacity-80 hover:opacity-100' onClick={(e)=> handleEditarPasta(e, pasta._id as string)} /> 
                                <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100' onClick={(e)=> hanldeApagarPasta(e, pasta._id as string)}/>
                                </td>
                                </tr>
                            ))
                        }
                        
                    </tbody>
                </table>
            </div>
    }


    </div> 
  )
}

export default DisplayListarPastas