import React, { useEffect, useLayoutEffect, useState } from 'react'
import PastasFerramentas from './PastasFerramentas'
import styles from './Pastas.module.css'
import { DisplayPastas } from '../../Utils/types/pastasTypes'
import { Link, useParams } from 'react-router-dom'
import PastaConteudo from './PastaConteudo'
import DisplayListarPastas from './DisplayListarPastas'

type Props = {}

const ListarPastas = ({pastas, arquivos, empresas, empresaPadrão}: any) => {


    // //~~MANUTENÇÃO~~// //
    // useEffect(()=>{
    //     console.log('Pastas: ', pastas);
    //     console.log('Arquivos: ', arquivos);
    //     console.log('Empresa padrão: ', empresaPadrão)

    // },[pastas, arquivos, empresaPadrão])
    // //~~MANUTENÇÃO~~// //



    const {pastaid} = useParams();

    useEffect(()=>{
        setPastaIdState(pastaid)

    },[pastaid])

    const [displayPastas, setDisplayPastas] = useState<DisplayPastas | string>(DisplayPastas.GRID);
    const [pathAtual, setPathAtual] = useState<string>('');
    const [pathPasta, setPathPasta] = useState<string>('');
    const [pathArquivo, setPathArquivo] = useState<string>('');
    const [folderOpen, setFolderOpen] = useState<string>('');
    const [pastaSelecionada, setPasatSelecionada] = useState<any>('')
    const [pastaidState, setPastaIdState] = useState<any>('0');
    const [arquivosSelecionados, setArquivosSelecionados ] = useState<any>([])

    // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('Empresa padrão: ', empresaPadrão)
    // },[empresaPadrão])

    // //~~MANUTENÇÃO~~//

    useEffect(()=>{
        setPathAtual(empresaPadrão.NomeEmpresa)
    },[empresaPadrão])

    const changePastDisplay =(e: any, setDisplay: DisplayPastas): void=>{
        e.preventDefault();
        localStorage.setItem('pastaDisplay', setDisplay as string) ;
        switch(setDisplay){
            case  DisplayPastas.GRID:
                setDisplayPastas(DisplayPastas.GRID);
                break;
            case  DisplayPastas.LIST:
                setDisplayPastas(DisplayPastas.LIST);
                break;
            case  DisplayPastas.DETAILS:
                setDisplayPastas(DisplayPastas.DETAILS);
                break;
            default: setDisplayPastas(DisplayPastas.GRID)
            
        }
        return;
    }

    useLayoutEffect(()=>{
        const display = localStorage.getItem('pastaDisplay');
        
        switch(display){
            case  DisplayPastas.GRID:
                setDisplayPastas(DisplayPastas.GRID);
                break;
            case  DisplayPastas.LIST:
                setDisplayPastas(DisplayPastas.LIST);
                break;
            case  DisplayPastas.DETAILS:
                setDisplayPastas(DisplayPastas.DETAILS);
                break;
            default: setDisplayPastas(DisplayPastas.GRID)
            
        }

        setPathPasta("/" + pastaid + '/');
        
        if(pastas && pastaid !== "0"){
            const pastaTemp = pastas.find((i: any)=>{
            return i.NomePasta === pastaid
        })
        
        setPasatSelecionada(pastaTemp); 



        }
       


    }, [pastaid, pastas])

  


    
    const selectPasta = (pasta: any)=>{
        setPathPasta("/" + pasta.NomePasta + '/');
        const pastaTemp = pastas.find((i: any)=>{
            return i._id === pasta._id
        })
        setPasatSelecionada(pastaTemp);

        const arquivosTemp = arquivos.find((i: any)=>{
            return i.PastaArquivo === pastaTemp._id
        })

        arquivosSelecionados(arquivosTemp)


        return;
    }
    
  return (

    <div className='w-full'>
        
        <label> <Link className='underline' to = '/portal/pastas/0'>{`${pathAtual}`} </Link>{`${pathPasta} ${pathArquivo}`}   </label> 
         
    

        <PastasFerramentas displayPasta ={changePastDisplay} />

        {pastaid && pastaid === '0'
        &&
        <DisplayListarPastas displayPastas ={displayPastas} pathPasta ={selectPasta} pastas ={pastas}/>
        }

        {
            pastaSelecionada && pastaid !== "0" && <PastaConteudo pasta ={pastaSelecionada} arquivos = {arquivos} displayPastas ={displayPastas} />
        }
         
    </div>
  )
}

export default ListarPastas