import React, { useEffect, useState } from 'react'
import { BsDownload, BsEye, BsEyeSlash, BsPencil, BsTrash } from 'react-icons/bs';
import { SlShareAlt } from 'react-icons/sl';
import { formatarData } from '../../Utils/formatarData';

import { useQuery } from '@tanstack/react-query';
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';
import { FaExclamationTriangle } from 'react-icons/fa';


const CaptacaoArquivosProcesso = ({ item, url, processo }: any) => {


    const [arquivos, setArquivos] = useState<any>(['']);

    const {getCaptacaoArquivos} = useReactQueryCaptacao()
    const {data: captacaoArquivosData, isLoading} = useQuery({
        queryFn: ()=> getCaptacaoArquivos(url as string),
        queryKey:[`${processo._id}-rqCaptcaoArquivos`, url]
    })

    const [abrirArquivo, setAbrirArquivo] = useState<boolean>(false);
    const [arquivoSelecionado, setArquivoSelecionado] = useState<any>('');

    const fechar = () => {
        setAbrirArquivo(false);
        setArquivoSelecionado('');
    }

    const abrirArquivoPncp = (arquivo: any) => {
        setArquivoSelecionado(arquivo);
        setAbrirArquivo(true);
    }

    if(captacaoArquivosData && captacaoArquivosData.error && captacaoArquivosData.status === 404){
        return(
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <td colSpan={7} className=" pl-4 w-full text-start text-gray-500 py-4">
                <div className='flex gap-2 flex-col'>
                    
                    <label className='inline-flex'><FaExclamationTriangle className='mt-0.5 mr-2' />Ocorreu um erro ao tentar se comunicar com o servidor do PNCP. </label>
                    <span> Ainda é possível adicionar arquivos!</span>
                    
                    <span>{captacaoArquivosData.message}</span>
                </div>
            </td>
        </tr>

        )
    }
    
    if(captacaoArquivosData && captacaoArquivosData.error && captacaoArquivosData.status === 500){
        return(
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <td colSpan={7} className=" pl-4 w-full text-start text-gray-500 py-4">
                <div className='flex gap-2 flex-col'>
                    
                    <label className='inline-flex'><FaExclamationTriangle className='mt-0.5 mr-2' />Ocorreu um erro ao tentar se comunicar com o servidor do PNCP. Aparentemente, há uma instabilidade no próprio servidor do PNCP. Por favor, tente novamente mais tarde.</label>
                    <span> Ainda é possível adicionar arquivos!</span>
                    
                    <span>{captacaoArquivosData.message}</span>
                </div>
            </td>
        </tr>

        )
    }


  return (
        <>

            {
                captacaoArquivosData && captacaoArquivosData.map((arquivo: any) => (

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                        <td className="" title={arquivo.titulo}>
                            {arquivo.titulo?.length >= 40 ? `${arquivo.titulo.slice(0, 40)}...` : arquivo.titulo}
                        </td>
                        <td className=" justify-center inline-flex text-black dark:text-gray-300  gap-4">
                            <label >
                                <BsEyeSlash className='cursor-pointer opacity-80 hover:opacity-100' />
                            </label>
                            <a href={arquivo.url} target='_blank'>
                                <BsDownload className='cursor-pointer opacity-80 hover:opacity-100' />
                            </a>
                        </td>
                        <td className=" text-center">
                            {arquivo.tipoDocumentoDescricao}
                        </td>
                        <td className=" text-center">
                            {formatarData(arquivo.dataPublicacaoPncp)}
                        </td>

                        <td className=" text-center">
                            ---
                        </td>
                        <td className="text-center ">
                            ---
                        </td>
                        <td className=" text-center">
                            ----
                        </td>
                        
                    </tr>
                ))
                


            }
        </>
    )
}

export default CaptacaoArquivosProcesso
