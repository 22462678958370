export  const countFolderSize =(arquivo: any)=>{

        
    const fileSizesTemp = arquivo.flatMap((i: any)=> i.Size).filter((i: any)=> {
        if(!isNaN(i)){
            return i
        }
    })

    if(fileSizesTemp.length === 0|| !fileSizesTemp){
        return `0`;
    }
    
    
    const totalSize: number = fileSizesTemp.reduce((a: any, b: any)=> {return a + b})


    
    
    switch(true){
        case totalSize < 1000:
            const fileSizeKb = totalSize/1000;
            return `${fileSizeKb} kb`;
            break;
        case totalSize >= 1000000 &&  totalSize < 1000000000:
            const fileSizeMb = (totalSize/1000000).toFixed(2);
            return `${fileSizeMb} mb`;
        case totalSize >= 1000000000:
            const fileSiizeGb = (totalSize/1000000000).toFixed(3);
            return `${fileSiizeGb} Gb`
        default:
            return 
    }

}


export const CountFileSize =(arquivo: any)=>{

    const totalSize = arquivo.Size;

    switch(true){
        case totalSize < 1000:
            const fileSizeKb = totalSize/1000;
            return `${fileSizeKb} kb`;
            break;
        case totalSize >= 1000000 &&  totalSize < 1000000000:
            const fileSizeMb = (totalSize/1000000).toFixed(2);
            return `${fileSizeMb} mb`;
        case totalSize >= 1000000000:
            const fileSiizeGb = (totalSize/1000000000).toFixed(3);
            return `${fileSiizeGb} Gb`
        default:
            return 
    }
}

export const CountFileSizeNumber =(arquivo: number)=>{

  

    switch(true){
        case arquivo < 1000000:
            const fileSizeKb = arquivo/1000;
            return `${fileSizeKb} kb`;
            break;
        case arquivo >= 1000000 &&  arquivo < 1000000000:
            const fileSizeMb = (arquivo/1000000).toFixed(2);
            return `${fileSizeMb} mb`;
        case arquivo >= 1000000000:
            const fileSiizeGb = (arquivo/1000000000).toFixed(2);
            return `${fileSiizeGb} Gb`
        default:
            return 
    }
}


