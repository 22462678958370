import { typeApagarPasta, typeCriarPastas, typeEditarPasta, typePasta } from "../types/pastasTypes";

export const usePastasUtils =()=>{

    const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL;

    const getPastas = async(empresaid: string)=>{
        const url: any = `${baseUrl}/api/pastas/listar-pastas?empresaid=${empresaid}`
        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }  
    }

    const criarPasta =async(data: typeCriarPastas)=>{
        const url: any = `${baseUrl}/api/pastas/criar-pasta?token=${data.token}`    
        
        try {

            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            })

            const response = await res.json();
            return response;
            
        } catch (error) {
            console.error(error);
            return null;
        }
    
    }



    const editarPasta = async(data: typeEditarPasta)=>{
        const url = `${baseUrl}/api/pastas/editar-pasta?token=${data.token}`;

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type':'application/json'
                },
                body: JSON.stringify(data.body)
            });
            const response = await res.json();
            return response;

        } catch (error) {
            console.error(error);
            return null;
        }

    }


    const apagarPasta = async(data: typeApagarPasta)=>{
        const url = `${baseUrl}/api/pastas/apagar-pasta?token=${data.token}`;

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type':'application/json'
                },
                body: JSON.stringify(data.body)
            });
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    return{
        getPastas,
        criarPasta,
        editarPasta,
        apagarPasta
    }


 
}