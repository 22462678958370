import React from 'react'


type Props = {}

const LoadingPage = (props: Props) => {
  return (
    <div className='fixed scroll- top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center bg-white bg-opacity-90 z-50'>
      <div role="status">
        <div className='scale-animation w-40 h-40 flex justify-center items-center object-contain'>
          <img 
            src="https://portalglc.s3.sa-east-1.amazonaws.com/res/logo3.jpg" 
            className='w-18 h-18 h-auto rounded-full'
            alt="Loading Logo"
          />
        </div>
        <span className="sr-only dark:text-gray text-black">Aguarde</span>
      </div>
      <h3 className='text-orange-500 font-bold text-xl pt-20'>
        Aguarde 
      </h3>
      <i className="pi pi-spin pi-cog text-orange-500" style={{ fontSize: '2rem' }}></i>
    </div>
  )
}

export default LoadingPage
