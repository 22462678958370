import React from 'react'
import { BsX } from 'react-icons/bs'

const TermosRegistro = ({showTermsModal, showPrivacyModal, fechar}: any) => {
  return (
    <div>
        {showTermsModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 max-w-3xl">
                        <div className="flex justify-between items-center pb-3">
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Condições Legais</h3>
                            <button
                                className="text-black dark:text-white"
                               onClick={fechar}
                            >
                                <BsX className="text-2xl" />
                            </button>
                        </div>
                        <div className="p-4 text-sm text-gray-800 dark:text-gray-200">
                            <p><strong>Termos e Condições</strong></p>
                            <p>Bem-vindo ao nosso Portal GLC, dedicado a serviços de licitações e contratos. Ao acessar ou utilizar nossos serviços, você concorda com os seguintes termos e condições:</p>
                            <p><strong>1. Uso dos Serviços</strong></p>
                            <p>Você deve utilizar nossos serviços apenas para finalidades legais e de acordo com todos os regulamentos e leis aplicáveis.</p>
                            <p><strong>2. Cadastro e Segurança</strong></p>
                            <p>Para acessar determinados serviços, você precisará criar uma conta. Você é responsável por manter a confidencialidade das informações da sua conta e por todas as atividades que ocorram sob sua conta.</p>
                            <p><strong>3. Propriedade Intelectual</strong></p>
                            <p>Todo o conteúdo, incluindo textos, gráficos, logos, e softwares, são de nossa propriedade ou de nossos licenciadores e estão protegidos por leis de direitos autorais.</p>
                            <p><strong>4. Limitação de Responsabilidade</strong></p>
                            <p>Não seremos responsáveis por quaisquer danos diretos, indiretos, incidentais, ou consequenciais decorrentes do uso ou da incapacidade de usar nossos serviços.</p>
                            <p><strong>5. Alterações nos Termos</strong></p>
                            <p>Reservamo-nos o direito de modificar estes termos a qualquer momento. Quaisquer alterações serão notificadas em nosso site.</p>
                            <p>Última atualização: *28/07/2024</p>
                        </div>
                        <div className="flex justify-end pt-3">
                            <button
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                onClick={fechar}
                            >
                                Lido!
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showPrivacyModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 max-w-3xl">
                        <div className="flex justify-between items-center pb-3">
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Política de Privacidade</h3>
                            <button
                                className="text-black dark:text-white"
                                onClick={fechar}
                            >
                                <BsX className="text-2xl" />
                            </button>
                        </div>
                        <div className="p-4 text-sm text-gray-800 dark:text-gray-200">
                            <p><strong>Política de Privacidade</strong></p>
                            <p>Bem-vindo ao nosso Portal GLC. Estamos comprometidos em proteger sua privacidade. Esta política explica como coletamos, usamos e protegemos suas informações pessoais.</p>
                            <p><strong>1. Coleta de Informações</strong></p>
                            <p>Coletamos informações que você nos fornece diretamente, como quando você cria uma conta, envia um formulário ou se comunica conosco. Também coletamos informações automaticamente, como seu endereço IP e dados de uso.</p>
                            <p><strong>2. Uso das Informações</strong></p>
                            <p>Utilizamos suas informações para fornecer, manter e melhorar nossos serviços, para comunicar com você, e para proteger nossos direitos e os direitos de nossos usuários.</p>
                            <p><strong>3. Compartilhamento de Informações</strong></p>
                            <p>Não compartilhamos suas informações pessoais com terceiros, exceto conforme necessário para fornecer nossos serviços ou conforme exigido por lei.</p>
                            <p><strong>4. Segurança</strong></p>
                            <p>Adotamos medidas razoáveis para proteger suas informações pessoais contra perda, roubo e uso não autorizado.</p>
                            <p><strong>5. Alterações nesta Política</strong></p>
                            <p>Podemos atualizar esta política de privacidade periodicamente. Notificaremos você sobre quaisquer alterações publicando a nova política em nosso site.</p>
                            <p>Última atualização: *28/07/2024</p>
                        </div>
                        <div className="flex justify-end pt-3">
                            <button
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                onClick={fechar}
                            >
                                Lido!
                            </button>
                        </div>
                    </div>
                </div>
            )}
    </div>
  )
}

export default TermosRegistro