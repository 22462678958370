import { useEffect, useState } from 'react'



const Rejeições = ({solicitações: dataSolicitações}: any) => {


  const [solicitações, setSolicitações] = useState<[String]>(['']);
  const [userSolicitação, setUserSolicitação] = useState<[String]>(['']);

    //--------------

    useEffect(()=>{
        if(dataSolicitações){
            const arrSolicitações = dataSolicitações.empresa.Empresa_Rejeições;
            setSolicitações(arrSolicitações)
            setUserSolicitação(dataSolicitações.users)
        }
    },[dataSolicitações])


  //-------------- Tailwind -----------------
  const idleRow = 'bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600';
  //-------------- Tailwind -----------------
    
    

const primeiroAcesso =(e: any)=>{
    const userData: any  = userSolicitação.find((i: any)=>{
        return i._id ===e
    })
    const primeiroAceso = userData?.PrimeiroAcesso;
    return primeiroAceso as string
}

const retornarNome = (e: any)=>{
   const userData : any =  userSolicitação.find((i: any)=>{
       return i._id === e
    });
    const userNome = userData?.Nome;
    return userNome;
}

const retornarEmail =(e: any)=>{
    const userData: any =  userSolicitação.find((i: any)=>{
        return i._id === e
     });
 
     const userNome = userData?.Email;
     return userNome as string;
}

const formatarData = (e:string)=>{
    const date = new Date(e);
    let dia: any =date.getDate();
    let mes : any= (date.getMonth()+1);
    let ano = date.getFullYear();

    if(date.getDate() < 10){
      mes = `0` + mes;
    }
    if(date.getDate() < 10){
      dia = `0` + dia;
    }

    return   dia + '-'+ mes +'-' + date.getFullYear() + ' - ' + date.getHours() + ':'+date.getMinutes() + 'h' 
}

const dataLimite = (e: string)=>{
  const date: Date = new Date(e);
  const actualDate: Date = new Date();
  const limit = date.getTime() - actualDate.getTime();
  const finalDate = new Date(limit).getDay();

  return finalDate;

}

  return (
    <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <h3 className='sm:text-md font-semibold md:text-xl'> Solicitações rejeitadas </h3>
        <small> As solicitações rejeitadas são apagadas após 30 dias. </small>
            <div className="flex  flex-row justify-between pb-4 bg-white dark:bg-gray-900">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                    </div>
                    <input type="text" id="table-search" className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Buscar por data"/>
                </div>



            </div>

            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="p-4">
                            <div className="flex items-center">
                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <sup>Nome</sup>/<sub>email</sub>
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Solicitação
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Acesso
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Data
                        </th>
                        <th scope="col" className="px-6 py-3">
                            VENCIMENTO 
                        </th>
                    </tr>
                </thead>
                <tbody>
                    
                    

                    {
                        solicitações.map((solicitação: any)=>(
                        <tr key={solicitação._id} id={`mapid-${solicitação.AcessoUserId}`} className={`${idleRow}`} >
                            <td className="w-4 p-4" >
                                <div className="flex items-center">
                                    <input  type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"  />
                                    <label htmlFor="checkbox-table-search-2" className="sr-only">checkbox</label>
                                </div>
                            </td>
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                               <sup>{
                                    retornarNome(solicitação.AcessoUserId)
                               }</sup>/<sub className=' opacity-80'>{
                                retornarEmail(solicitação.AcessoUserId)
                           }</sub>
                            </th>
                            <td className="px-6 py-4">
                                {solicitação.TipoSolicitação}
                            </td>
                            <td className="px-6 py-4">
                                {solicitação.AcessoSolicitação}
                            </td>
                            <td className="px-6 py-4">
                                {formatarData(solicitação.DataSolicitação)}
                            </td>
                            <td className="px-6 py-4">
                                <label className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                  {dataLimite(solicitação.DataSolicitação as string) } dias</label>
                            </td>
                        </tr>



                        ))
                    }
                    
                </tbody>
            </table>
        </div>

        
    </div>
  )
}

export default Rejeições