import React from 'react'
import LoadingSmall from '../LoadingSmall'

export type TCaptacaoPortais = {
    usuarioNome?: string,
    img?: string,
    linkSistemaOrigem?: string
}

export type TProps ={
    processo?: boolean,
    portaisData?: TCaptacaoPortais,
    isLoading?: boolean,
    isFetching?: boolean
}

const CaptacaoPortais = ({ processo, portaisData, isLoading, isFetching }: TProps) => {

    const  getLogoPortal =(nomePortal: string) =>{

        switch (nomePortal) {
            case 'Comprasnet Federal 4.0':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1705695738266.png';
            case 'Licitação Caixa':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1706211658100.jpg';
            case 'Compras Maranhão':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1696899614392.png';
            case 'Compras.gov.br':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697322349719.png';
            case 'Comprasnet.gov.br':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697323062321.png';
            case 'Comprasnet.GO':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697323822453.png';
            case 'Portal de Compras Publicas':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697326377625.png';
            case 'PE Integrado':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697328963062.png';
            case 'Huma':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697330357461.png';
            case 'SEAD Licitação João Pessoa PB':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697542072307.png';
            case 'AMM LICITA (Licitar Digital)':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1710869886512.png';
            case 'Licitar Digital':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697655849620.PNG';
            case 'BLL Compras':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697659627838.png';
            case 'BNC Compras':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697660111736.png';
            case 'Licitações-e (Banco do Brasil)':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697660720171.png';
            case 'Leilo (Antigo Leilomaster)':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1699894950343.png';
            case 'Leilões Brasil':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697662019306.png';
            case 'Licitanet Licitações Eletrônicas LTDA':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697662361522.png';
            case 'compras.rs.gov.br':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697663438207.png';
            case 'Zoom':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1697751162694.png';
            case 'Central Atendimento (Pref. Mun. Senador Canedo GO)':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1698861485433.png';
            case 'Milan Leilões':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1699270476152.PNG';
            case 'M2A Tecnologia':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1699985115623.PNG';
            case 'Compras Maranhão':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1704458346609.png';
            case 'Portal de Compras':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1706622398419.png';
            case 'Portal BC':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1709063998549.png';
            case '1Doc':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1711128745588.png';
            case 'Portal Garaju':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1712174196358.png';
            case 'Vecchi Leiloes':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1714668927811.png';
            case 'SlicX':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1716238580642.png';
            case 'Bolsa Nacional De Compras - BNC':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1717694361726.PNG';
            case 'BBMNET':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1718111298034.PNG';
            case 'Slicx':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1722525571337.png';
            case 'Portal  de Compras SMARA':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1723207017926.jpg';
            case 'São Mateus do Maranhão':
                return 'https://antigo.portalglc.com/servidorapigrupors/logoPortais/1723495603769.png';
            case 'ECustomize Consultoria em Software S.A':
                return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAACVBMVEUAAAAxb7QVW6qwlqtrAAAAAXRSTlMAQObYZgAAAElJREFUeAFjYGBzYGBg4JoAJIKWMDAwrlgJFFqg5cAg1cjlwJDF1aTCsEJqwkqGJK6mJQxsjUCFIFmIOoYsESAh1cAANYWRgQEAc7oOp/RXXggAAAAASUVORK5CYII='
            default:
                return `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/portal.jpg`;

        }
    }



  return (

    <>
      {
      processo ? <div>
            <label htmlFor="">{portaisData?.usuarioNome}</label>
          <a href={portaisData?.linkSistemaOrigem} target="_blank" className='rounded-full content-cover flex justify-center items-center w-16 h-16'>
            <img src={portaisData?.usuarioNome ? getLogoPortal(portaisData.usuarioNome) : `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/portal.jpg`}  className='w-full h-full rounded-full' alt="" />
          </a>
      </div> :
        <a href={portaisData?.linkSistemaOrigem} target="_blank" className='px-1 bg-slate-100 rounded-lg flex items-center dark:bg-gray-800 cursor-pointer'>
          <div  className='flex flex-row justify-center'>
            
            {isLoading || isFetching ?

            <div className='w-8 h-8 rounded-full'>
                <LoadingSmall/>
            </div>
            
            :<img src={portaisData?.usuarioNome ? getLogoPortal(portaisData.usuarioNome) : `${process.env.REACT_APP_PRIVATE_API_URL_ARQUIVOS}/res/portal.jpg`}  alt="img" className='h-8 w-8 rounded-full' />
            }


            <div className="flex flex-row text-xs pt-2 cursor-pointer">
              
             { 
             isLoading || isFetching ?
                <small>...</small>
             :
                <label title={portaisData?.usuarioNome ? portaisData.usuarioNome:'Portal não informado'} className='text-xs font-semibold text-gray-600 mx-2 cursor-pointer' >{portaisData?.usuarioNome ? portaisData.usuarioNome.length >= 25 ? `${portaisData.usuarioNome.slice(0, 25)}...` : portaisData?.usuarioNome : 'Portal não informado!'} </label>
             }

            </div>
          </div>
        </a>
      }
    </>
  )
}

export default CaptacaoPortais