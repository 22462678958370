import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { BsEye, BsEyeSlash, BsPlus, BsX, BsXOctagonFill } from 'react-icons/bs'
import { toast } from 'react-toastify';
import { usePortaisUtils } from '../../Utils/ReactQuery/getPortais';
import LoadingPage from '../LoadingPage';
import AlertaAssinatura from '../AlertaAssinatura';
import { error } from 'console';

const NovoPortal = ({fechar, empresa}: any) => {

    const [showSenhaPortal, setShowSenhaPortal] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [concluido, setConcluido] = useState<boolean>(false);
    const [arquivo, setArquivo] = useState<string | HTMLImageElement>('');

    const [file, setFile] = useState<string>('');

    const [planos, setPlanos] = useState<boolean>(false);


    // //~MANUTENÇÃO~//
    // useEffect(()=>{
    //     console.log('arquivo: ', arquivo);
    //     console.log('Empresa: ', empresa)
    // },[arquivo, empresa])
    // //~MANUTENÇÃO~//

    // Mutação -----------------------
    const {criarPortal} = usePortaisUtils();
    const queryClient = useQueryClient();
    const createPortalMutation = useMutation({
        mutationFn: criarPortal,
        onMutate: async(config) =>{
            setLoading(true);
            // console.log('Mutação dos portais iniciada!');
        },
        onSuccess: async(response)=>{
            // console.log('Mutação concluida!', response);
            if(response.status === 1){
                toast.success('Portal cadastrado com sucesso!');
                queryClient.invalidateQueries({queryKey:["rqPortais"]});
                queryClient.refetchQueries({queryKey:["rqPortais"]});
                setArquivo('');
            }
            if(response.status === 3){
                toast.error('Erro');
            }    
            if(response.status === 4){
                toast.warn(response.msg)
                setPlanos(true)
            }        
        },
        onSettled: (response)=>{
            // console.log('Mutação finalizada');
            setLoading(false);

        },
        onError: async(error)=>{
            console.error(error.message);
            toast.error(error.message)
        }
    })

    // Mutação -----------------------
    const handleNovoPortal = (e: any)=>{
        e.preventDefault();
        setLoading(true);

        const formData = new FormData(e.currentTarget);
        formData.append('file', file);
        
        const empresaSelecionada = localStorage.getItem('empresaSelecionada')
        const token = localStorage.getItem("token");

        const data ={
            LogoPortal           :  arquivo as any,
            NomePortal           : formData.get('NomePortal')  as string,
            LinkPortal           : formData.get('LinkPortal')  as string,
            LoginPortal          : formData.get('LoginPortal') as string,
            SenhaPortal          : formData.get('SenhaPortal') as string,
            DataCadastroPortal   : formData.get('DataCadastroPortal')   as string,
            DataVencimentoPortal : formData.get('DataVencimentoPortal') as string,
            ValorPortal          : formData.get('ValorPortal') as string,
            Categoria            : formData.get('Categoria')   as string,   
            EmpresaPortal: empresaSelecionada as string   
        }

        
        createPortalMutation.mutate({empresaid: empresaSelecionada as string, token: token as string, data: data, Arquivo: formData})

        return;

    }

    const handleFilePreview = (e: any)=>{

        try {
            setArquivo(URL.createObjectURL(e.target.files[0])); 
            setFile(e.target.files[0])
        } catch (error) {
            throw error
        }
        
    }

    //Tailwind -----------------
    const inputField = "bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-1.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
    // Tailwind ----------------

    
    

    const empresaSelecionada = localStorage.getItem("empresaSelecionada")

    if(!empresaSelecionada || empresaSelecionada === null || empresaSelecionada=== undefined || empresaSelecionada.length <= 0){
        toast.warn('Selecione uma empresa!');
        return(
            <div>
                Selecione uma empresa para registar um processo!
            </div>
        )
    }

    const fecharAlerta=()=>{
        setPlanos(false)
    }

    if(planos){
        return(
            <>
            <AlertaAssinatura fechar ={fecharAlerta} tipo ={'portal'}/>
            </>
        )
    }

    if(loading){
        return(
            <div id="crud-modal"  aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-5xl max-h-full">

                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                     <LoadingPage/>
                    </div>
                </div>
            </div>
        )
    }

    

  return (
    <div id="crud-modal"  aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div className="relative p-4 w-full max-w-5xl max-h-full">

        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Criar novo portal
                </h3>

                <div className='text-3xl cursor-pointer opacity-80 hover:opacity-100 transition-opacity' onClick={fechar}>
                    <BsX/>
                </div>

            </div>

            <form className="p-4 md:p-5" onSubmit={handleNovoPortal}>
                
                <div className='flex flex-col mb-4'>
                    <div className='w-10 h-10  rounded-full flex justify-center items-center origin-center'>
                    {
                    arquivo ? <img src ={arquivo as string} className='w-8 h-8 p-0 m-0 text-center rounded-full origin-center'/> :  <span className='text-black text-3xl w-8 h-8 p-0 m-0 text-center bg-white  rounded-full origin-center border-2 border-black dark:border-gray-500 border-dashed ' title='Adiconar portal'>
                      <BsPlus/>
                    </span>
                    }
                    </div>
                    <label> Logo do portal </label>
                    <input className='rounded-lg p-0 w-fit'  type="file" onChange={handleFilePreview} accept='.png, .jpg'/>
                    <small > Somente arquivos com menos de 25 mb são permitidos</small>
                </div>

                <div className="grid gap-4 mb-4 grid-cols-2">

                

                    <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="NomePortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Nome do portal </label>
                        <input type="text" name="NomePortal" id="NomePortal" className={inputField} placeholder="Nome do portal" required={true}/>
                    </div>
                    
                    
                    <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="ValorPortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Valor do portal </label>
                        <input type="text" name="ValorPortal" id="ValorPortal" className={inputField} placeholder="Valor do portal" />
                    </div>
                    
                    <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="LinkPortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Link do portal </label>
                        <input type="text" name="LinkPortal" id="LinkPortal" className={inputField} placeholder="www.portal.com" />
                    </div>
                    
                    <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="LoginPortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Login do portal </label>
                        <input type="text" name="LoginPortal" id="LoginPortal" className={inputField} placeholder="Login do portal" />
                    </div>
                    
                    <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="Categoria" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Categoria</label>
                        <select name='Categoria' className={`${inputField}`}>
                            <option className={`${inputField}`} value="">Nenhuma</option>
                            <option className={`${inputField}`} value="Portal de compras">Portal De Compras</option>
                            <option className={`${inputField}`} value="Rede Social">Rede Social</option>
                            <option className={`${inputField}`} value="Email">Email</option>
                            <option className={`${inputField}`} value="Banco">Banco</option>                        
                        </select>
                    </div>
                    
                    <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="SenhaPortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Senha do portal </label>
                        <input type={ showSenhaPortal ? "text" : "password"} name="SenhaPortal" id="SenhaPortal" className={inputField} placeholder="******" />
                        <p className='text-xl p-0 mt-4 cursor-pointer' onClick={()=> setShowSenhaPortal(current => !current)}>
                            {showSenhaPortal ? <BsEye/> : <BsEyeSlash/>}
                        </p>
                    </div>

                    <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="DataCadastroPortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Data inicial do cadastro do portal </label>
                        <input type="datetime-local" name="DataCadastroPortal" id="DataCadastroPortal" className={inputField} placeholder="www.portal.com" />
                    </div>

                    <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="DataVencimentoPortal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Data final do portal </label>
                        <input type="datetime-local" name="DataVencimentoPortal" id="DataVencimentoPortal" className={inputField} placeholder="www.portal.com" />
                    </div>

                </div>
                
                <button type="submit" className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                    Criar portal
                </button>
            </form>
        </div>
    </div>
</div>
  )
}

export default NovoPortal