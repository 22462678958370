import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BsArrowLeft, BsArrowRight, BsCheck, BsDash, BsPlus, BsSlash, BsX } from 'react-icons/bs'
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import { usePortaisUtils } from '../../Utils/ReactQuery/getPortais';
import { subTypePortal } from '../../Utils/types/portaisTypes';
import LoadingSmall from '../LoadingSmall';



const NovoProcessoEtapaQuatro = ({etapaQuatroData, loading, processo, voltar, editar , alterarParteEditada, cancelar}: any) => {

    const {listarUsuarios} = useReactQueryEmpresas();

    const {getPortais} =usePortaisUtils();

    
    


    const token = localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada')
    const empresaPadrão = localStorage.getItem('empresaPadrão')
    const {data: usuariosData, isLoading} = useQuery({
        queryFn: ()=> listarUsuarios(token as string, empresaSelecionada as string, empresaPadrão as string),
        queryKey: ['rqListaUsuarios', empresaSelecionada]
    })

    // Empresas permitidas---------------------
    const {empresasPermitidas} = useReactQueryEmpresas();
    const {data: empresasData} = useQuery({
        queryFn: ()=> empresasPermitidas(token as string),
        queryKey: ['rqEmpresasPermitidas']
    })
// Empresas permitidas---------------------


    const [contatos, setContatos] = useState(['']);
    const [contatoSelecionado, setContatoSelecionado] = useState<any>('');
    const [ contatoNome, setContatoNome] = useState<string>('')
    const [ contatoEmpresa, setContatoEmpresa] = useState<string>('')
    const [ contatoDepartamento, setContatoDepartamento  ] = useState<string>('')
    const [ contatoCargoFuncao , setContatoCargoFuncao] = useState<string>('')
    const [ contatoEmail, setContatoEmail] = useState<string>('')
    const [ contatoTelefone, setContatoTelefone] = useState<string>('')
    const [ contatoStatus, setContatoStatus] = useState<string>('')
    

    const [selecionarEmpresa, setSelecionarEmpresa] = useState<string>('');
    const [selecionarUsuario, setSelecionarUsuario] = useState<string>('');
    const [portalSelecionado, setPortalSelecionado] = useState<string>('');

    const { data: portaisData, isLoading: portaisIsLoading, refetch } = useQuery({ queryFn: () => getPortais({empresaid: selecionarEmpresa as string}), queryKey: ['rqPortais', selecionarEmpresa] })



    // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    //     console.log('Empresa selecionada 2: ', selecionarEmpresa)
    // },[selecionarEmpresa]);

    // useEffect(()=>{
    //     if(processo && processo.contatos){
    //         const contatosAtuais = processo.contatos;
    //         setContatos(contatosAtuais)
    //     }
    // },[processo]);

    // useEffect(()=>{
    //     console.log('Usuários selecionado: ', selecionarUsuario)
    // },[selecionarUsuario])

    // useEffect(()=>{
    //     console.log('Usuários disponíveis: ', usuariosData)
    // },[usuariosData])
    // //~~MANUTENÇÃO~~//

    useLayoutEffect(()=>{
        if(editar){
            setSelecionarEmpresa(processo.empresaSelecionada)
        }
    },[])


    const checkPortal = (portalid: string)=>{
        const portalEncontrado =portaisData &&  portaisData.portais.find((i: any)=> {return i._id === portalid});

        if(portalEncontrado){
            return portalEncontrado.NomePortal
        }else
        return false
    }


    const handleInserirContato =(e: any)=>{
        e.preventDefault();


        if(!selecionarEmpresa){
            toast.error('É necessário selecionar uma empresa!');
            return
        }

        if(!selecionarUsuario){
            toast.error('É necessário selecionar um usuário!');
            return
        }
        
        const contatoTemp: any ={
            contatoNome,
            contatoTelefone,
            contatoCargoFuncao,
            contatoDepartamento,
            contatoEmail,
            contatoEmpresa
        }

        const checkIgual: any = contatos.find((i: any)=>{
            return i.contatoTelefone === contatoTelefone
        });

        console.log('Check igual: ', checkIgual)
        
        if(checkIgual && checkIgual >= 1){
            toast.error('Ese contato ja foi adicionado a este processo!')
            return;
        }

        setContatos([
            ...contatos,
            contatoTemp
        ])


        setContatoNome('');
        setContatoTelefone('');
        setContatoCargoFuncao ('');
        setContatoDepartamento('');
        setContatoEmail('');
        setContatoEmpresa('');
        
    }

    const handleRemoverContato = (e: any)=>{
        e.preventDefault();
        const contatosTemp = contatos.filter((i: any)=>{
            return i.contatoTelefone !== contatoSelecionado
        })
        setContatos(contatosTemp);
        return;
    }

    const handleEtapaAquatro =(e: any)=>{
        e.preventDefault();

        const formData = new FormData(e.currentTarget);

        const data ={
            empresaSelecionada : selecionarEmpresa || processo.empresaSelecionada,
            usuarioSelecionado : selecionarUsuario,
            portalSelecionado: portalSelecionado,
            contatos : contatos
        }



        etapaQuatroData(e, data, contatos, selecionarEmpresa, selecionarUsuario, portalSelecionado)

    }

        //Tailwind ----------------------
        const inputField = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
        const labelField ="block mb-2 text-sm font-medium text-gray-900 dark:text-white";
    
        const button = "cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit"
        const closeButton = "bg-red-500 cursor-pointer text-white inline-flex items-center bg-bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:bg-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit"

        const selectInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
        const optionInput ="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";

        //Tailwind ----------------------


  return (
    <div>
        <div id="defaultModal" tabIndex={-1} aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full max-h-screen">
       
       <div className="relative p-4 w-fit max-w-2xl h-full md:h-auto">
           
           <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 ">
               <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                {!editar && <label onClick={voltar}><BsArrowLeft className='text-3xl cursor-pointer'/> Voltar </label>}

                

                    <div>
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                           {editar ? `Editar processo`:`Registrar processo`}
                        </h3>
                        <label> Informações do proponente e contatos</label>
                    </div>

                    {
                    editar && 
                    <div className='flex flex-row gap-2'>                 
                        <label onClick={()=> alterarParteEditada('back')}>
                        <BsArrowLeft className='text-3xl cursor-pointer'/> Voltar 
                        </label>
                    </div>
                    }

               </div>

               <form onSubmit={handleEtapaAquatro}>
                   <div className="grid gap-4 mb-4 sm:grid-cols-2">
                        <div>
                            <label htmlFor="empresaSelecionada" className={`${labelField}`}>Empresa selecionada</label>
                                <select name="empresaSelecionada" id="objeto" className={`${selectInput}`} onChange={(e: any)=> setSelecionarEmpresa(e.target.value)}>

                                <option className={`${optionInput}`} value ='nenhuma'>Nenhuma empresa selecionada</option>
                        
                             
                                {
                                    empresasData && empresasData.empresas.map((empresa: any)=>(
                                        <option  value={empresa._id ? empresa._id: ''} className={`${optionInput}`} onSelect={(e: any)=> setSelecionarEmpresa(e.target.value)} selected={editar && empresa &&processo && empresa._id === processo.empresaSelecionada}>{empresa.CnpjEmpresa} ({empresa.NomeEmpresa }) </option>
                                    ))
                                }
                                
                            '</select>
                        </div>
                           
                        <div>
                            <label htmlFor="usuarioSelecionado" className={`${labelField}`}>Usuário responsável</label>
                                <select name="usuarioSelecionado" id="objeto" className={`${selectInput}`} onChange={(e: any)=> setSelecionarUsuario(e.target.value)}>
                                <option className={`${optionInput}`}  >Usuario selecionado</option>
                                {
                                    usuariosData && usuariosData.usuarios.map((usuario: any, index: number)=>(
                                        <option key={index} className={`${optionInput}`} 
                                        selected={editar && processo.usuarioSelecionado === usuario._id ?true:false} value={usuario._id}
                                        >{usuario.Nome} ({usuario.CpfCnpj})</option>
                                    ))

                                }
                            </select>
                        </div>   
                        
                        <div>
                            <label htmlFor="portalSelecionado" className={`${labelField}`}>Portal selecionado</label>
                                <select name="portalSelecionado" onChange={(e)=> setPortalSelecionado(e.target.value)} className={`${selectInput}`} value={portalSelecionado}>
                                <option className={`${optionInput}`}  > Nenhum portal seleconado</option>

                                {
                                     portaisData && selecionarEmpresa && portaisData.portais.map((portal: subTypePortal)=>(
                                        <option value={portal._id} selected={editar && processo && portaisData && portal._id === processo.portalSelecionado}> {portal.NomePortal}</option>
                                    ))
                                }

                            </select>
                        </div>   

                    
                    </div>

                        <input type="checkbox" className='contatoCb' id="contatoCb" />
                        <label htmlFor="contatoCb" className='cursor-pointer opacity-75 hover:opacity-100 w-fit'> Contatos do processo </label>
                    <div className='contatosWrapper'>
                        <div className='contatosContainer'>
                        
                            <div className="grid gap-4 mb-4 sm:grid-cols-2 border-t-2border-white  border-t sm:mb-5 dark:border-gray-600">
                        
                                <div>
                                    <label className={`${labelField}`}> Nome do contato </label>
                                    <input name='contatoNome' className={`${inputField}`} type="text" id="contatoNome" onChange={(e)=> setContatoNome(e.target.value)} value={contatoNome}/>
                                </div>
                                <div>
                                    <label className={`${labelField}`}> Empresa do contato </label>
                                    <input name='contatoEmpresa' className={`${inputField}`} type="text" id="contatoEmpresa"  onChange={(e)=> setContatoEmpresa(e.target.value)} value={contatoEmpresa}/>
                                </div>
                        
                                <div>
                                    <label className={`${labelField}`}> Departamento do contato </label>
                                    <input name='contatoDepartamento' className={`${inputField}`} type="text" id="contatoDepartamento" onChange={(e)=> setContatoDepartamento(e.target.value)} value={contatoDepartamento}/>
                                </div>
                        
                                <div>
                                    <label className={`${labelField}`}> Função do contato </label>
                                    <input name='contatoCargoFuncao' className={`${inputField}`} type="text" id="contatoCargoFuncao" onChange={(e)=> setContatoCargoFuncao( e.target.value)} value={contatoCargoFuncao}/>
                                </div>
                        
                                <div>
                                    <label className={`${labelField}`}> Telefone do contato </label>
                                    <input name='contatoTelefone' className={`${inputField}`} type="text" id="contatoTelefone" onChange={(e)=> setContatoTelefone(e.target.value)} value ={contatoTelefone}/>
                                </div>
                                <div>
                                    <label className={`${labelField}`}> Email do contato </label>
                                    <input name='contatoEmail' className={`${inputField}`} type="text" id="contatoEmail" onChange={(e)=> setContatoEmail( e.target.value)} value ={contatoEmail}/>
                                </div>
                        
                                <div>
                                    <label className={`${labelField}`}> Status do contato </label>
                                    <select name="contatoStatus" id="objeto" className={`${selectInput}`}
                                    onChange={(e)=> setContatoStatus(e.target.value)} value={contatoStatus}
                                    >
                                    <option className={`${optionInput}`} >Ativo</option>
                                    <option className={`${optionInput}`} >Inativo</option>
                                    </select>
                                </div>
                            </div>
                            <div className='flex flex-row justify-between w-fuull'>
                                <button className='flex flex-row rounded-xl p-1 bg-blue-500' onClick={handleInserirContato}> <BsPlus/> Adicionar contato </button>
                        
                                <button className='flex flex-row rounded-xl p-1 bg-red-500' onClick={handleRemoverContato}> <BsDash/> Remover contato selecionado</button>
                        
                            </div>
                            <div className='mb-2'>
                                <label> Contatos anexados: </label>
                                <select className={`${selectInput}`} onChange={(e)=> setContatoSelecionado(e.target.value)}>
                                    <option className={`${optionInput} flex flex-row justify-between`}>Nenhum contato selecionado </option>
                                    {
                                        contatos.map((contato: any, index: number)=>(
                                            <option key={index} value={contato.contatoTelefone} className={`${optionInput} flex flex-row justify-between`}> {contato.contatoTelefone} ({contato.contatoNome})</option>
                                        ))
                                    }
                                </select>
                            </div>
                        
                        
                        </div>
                    </div>

                    <div className='flex flex-row w-full justify-between mt-4'>

                    <button type='button' className="closeButton" onClick={cancelar}> 
                        <BsX className='text-2xl ml-2'/>
                        Cancelar
                        </button>
                    {loading ? <button disabled = {loading}><LoadingSmall message = {"Aguarde"}/></button> : <button type='submit' className="confirmButton"> Salvar informações <BsArrowRight className='text-2xl ml-2'/></button>}
                       
                   </div>
               </form>
           </div>
       </div>
   </div>

    </div>
  )
}

export default NovoProcessoEtapaQuatro