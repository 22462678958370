
import axios from "axios";
import { TArquivosUsuario, typeUserUpdate } from "../types/userType";
import { useState } from "react";
import { TRegistarEmpresa } from "../types/cadastroTypes";


export const useReactQueryUsers = ()=>{

    const [fileUpload, setFileUpload] = useState<string | number>('')

    const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL

    type TLogIn ={
        CpfCnpj: string, 
        Senha:   string, 
        Acesso:  string,
        Afiliação?: string
    }

    const logIn =async(data: TLogIn) =>{

       try {

        const url = `${baseUrl}/api/auth/session`;
        const res= await fetch(url, {
            method: 'POST',
            headers:{
                'content-type': 'application/json'
            },
            body: JSON.stringify({...data})
        });

        const response = await res.json();
        return response;

       } catch (error) {
            console.error(error);
            return;
       }
    }

    // Mnater no query params por enquanto
    const getUser =async(tokenid: String)=>{

        
        try {
            const url = `${baseUrl}/api/auth/getUser?token=${tokenid}`;
            const res= await fetch(url);
            const response = await res.json();
        return response;
        } catch (error) {
            console.error(error);
            return;
        }
    }

    const logOut =async({config = {token: ''}} ={}) =>{

        const url = `${baseUrl}/api/auth/sessionLogOut?token=${config.token}`
        try {
            const res =  await fetch(url);
            const response = res.json();
            return response;
        } catch (error) {
            console.error(error);
            return;
        }
    }

    const cadastrar = async(data: any)=>{
        const url = `${baseUrl}/api/cadastro/registrar`

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            })

            const response = await res.json();
            return response;
    
        } catch (error) {
            console.error(error);
            return;
        }


    }

    
    const alterarUsuario = async(data: typeUserUpdate)=>{
        const url = `${baseUrl}/api/atualizar-perfil?token=${data.tokenid}`;
        
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    'content-type':'application/json'
                },
                body: JSON.stringify(data.data)
            });

            const response = await res.json();
            return response;
            
        } catch (error) {
            console.error(error);
            return;
        }
    }


    type typeUsuarioData ={
        Nome?: string,
        Foto?: HTMLFormElement | any
    }

    type typeAlterarUsuario={
        token: string,
        data: typeUsuarioData
    }

    const alterarAvatarDoUsuario = async(data: typeAlterarUsuario)=>{
        const url = `${baseUrl}/api/avatar-usuario?token=${data.token}`;

        try {

            const res: any = await axios({
                url: url,
                method: 'POST',
                headers:{
                    'Content-Type': 'multipart/form-data'
                },
                data: data.data.Foto,
                onUploadProgress: (p: any)=>{
                    const completedUpload = p.loaded/p.total ;
                    setFileUpload(completedUpload)
                }
                });

            const response = await res.data;
            return response;
            
        } catch (error) {
            console.error(error);
            return null;
        }

    }

    const getOrganogramaInfo =async(empresaid: string)=>{
        const url = `${baseUrl}/api/cadastro/user-organograma?empresaid=${empresaid}`   
    
        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
            
        }
    }

    const getUserInfo = async(token: string)=>{
        const url = `${baseUrl}/api/cadastro/user-info?token=${token}`;

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
            
        }
    }


    type TSalvarArquivoUser={
        token: string,
        empresaid?: string,
        resumo:TArquivosUsuario,
        Arquivo: HTMLFormElement | any
    }

    const salvarArquivoUser = async(data: TSalvarArquivoUser)=>{
        const url = `${baseUrl}/api/usuario/salvar-arquivo?token=${data.token}&empresaid=${data.empresaid}`;

        try{
        
            const res: any = await axios({
            url: url,
            params:{
                resumo: {
                    NomeDoArquivo: data.resumo.NomeDoArquivo,
                    Descrição: data.resumo.Descrição,
                    Userid: data.resumo.Userid,
                    Size: data.resumo.Size,
                    empresaid: data.resumo.empresaid,
                    dataInicio:    data.resumo.dataInicio,
                    dataVencimento:data.resumo.dataVencimento,
                    Categoria:     data.resumo.categoria,
                    valor:         data.resumo.valor,
                    arquivoPath:   data.resumo.arquivoPath,
                    processoId:    data.resumo.processoId,
                    LinkAws:       data.resumo.LinkAws,
                    mimetype:      data.resumo.mimetype,
                    exttype:       data.resumo.exttype
                }

            },
            method: 'POST',
            headers:{
                'Content-Type': 'multipart/form-data'
            },
            data: data.Arquivo,
            onUploadProgress: (p: any)=>{
                const completedUpload = p.loaded/p.total ;
                setFileUpload(completedUpload)
            }
            });
    
            const response = await res.data;
    
           return response;
    
            }
            catch(err){
                console.error(err);
                return null
    
            }

    }


    const getUserArquivos =async(token: string, userid: string, empresaid: string)=>{

        const url = `${baseUrl}/api/usuario/get-arquivos?token=${token}&userid=${userid}&empresaid=${empresaid}`;

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
            
        }
    }


    type TApagarUserArquivo = {
        token: string,
        arquivoid: string,
        empresaid: string
    }
    
    const apagarUserArquivo =async(data: TApagarUserArquivo)=>{

        const url = `${baseUrl}/api/usuario/apagar-arquivo?token=${data.token}&arquivoid=${data.arquivoid}&empresaid=${data.empresaid}`;

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;
            
        }
    }

    type TCriarAcesso ={
        data?: TRegistarEmpresa,
        action: 'novo' | 'solicitar',
        CpfCnpj?: string,
        Cnpj?: string
    }

    const criarNovoAcesso = async(data: TCriarAcesso)=>{

        const url = `${baseUrl}/api/cadastro/criar-acesso?action=${data.action}&CpfCnpj=${data.CpfCnpj}&Cnpj=${data.Cnpj}`

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers:{
                    "content-type": "application/json"
                },
                body: JSON.stringify(data.data)
            })

            const response = await res.json();
            return response;
    
        } catch (error) {
            console.error(error);
            return;
        }

    }


    return{
        logIn,
        getUser,
        logOut,
        cadastrar,
        alterarUsuario,
        alterarAvatarDoUsuario,
        getOrganogramaInfo,
        getUserInfo,
        salvarArquivoUser,
        getUserArquivos,
        apagarUserArquivo,
        criarNovoAcesso
    }
}