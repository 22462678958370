import React, { useLayoutEffect, useState } from 'react'
import { BsBook, BsBookFill, BsCheckSquare, BsClipboard2PlusFill, BsClipboardPlus, BsClock, BsFlag, BsHandThumbsDown, BsHandThumbsUp, BsTrophy, BsTrophyFill, BsTruck } from 'react-icons/bs'
import { CgSandClock } from "react-icons/cg";
import { FaCalendarTimes, FaCheckSquare, FaClock, FaEye, FaFlag, FaHourglassHalf, FaMoneyBill, FaRegMoneyBillAlt, FaTools } from 'react-icons/fa';
import { TbCalendarCancel } from 'react-icons/tb';
import { RiSearch2Fill, RiSearchEyeFill, RiSearchEyeLine } from 'react-icons/ri';
import { IoIosCloseCircle, IoIosCloseCircleOutline, IoMdThumbsDown, IoMdThumbsUp } from "react-icons/io";
import { GoGraph } from "react-icons/go";
import { SituaçãoInterna } from '../../Utils/types/PortalTypes';
import { LiaToolsSolid } from "react-icons/lia";
import { useQuery } from '@tanstack/react-query';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';
import { FaTruckArrowRight } from "react-icons/fa6";
import { MdOutlineTimeline } from 'react-icons/md';
import { BiSearchAlt } from 'react-icons/bi';
import { FaFileAlt } from "react-icons/fa";

const Filtros = ({ alterarFiltro, FiltroSelecionado, processos }: any) => {
    const empresaSelecionada = localStorage.getItem("empresaSelecionada");
    const token = localStorage.getItem('token');
    const { getProcessosInfo } = useReactQueryProcessos()

    const { data: processosInfoData, isLoading: proocessosInfoLoading } = useQuery({
        queryFn: () => getProcessosInfo(empresaSelecionada as string, token as string),
        queryKey: ["rqPrcessosInfo"]
    });


    const checkLength = (situacaoInterna: SituaçãoInterna) => {
        if (processosInfoData) {

            if (situacaoInterna === SituaçãoInterna.RESUMO) {
                return processosInfoData.processos.length
            }
            const length = processosInfoData.processos.filter((i: any) => {
                return i.situacaoInterna === situacaoInterna
            })?.length;
            return length;
        }
        return 0;
    }


    let filtroAtual = '';
    

    useLayoutEffect(()=>{
        const filtroSalvo = localStorage.getItem(`${empresaSelecionada}-filtro`);
        if(filtroSalvo){
            filtroAtual = filtroSalvo;
        }else{
            filtroAtual = localStorage.getItem('cnaePrincipal') as string;
        }
    },[])
    
    
    

    const { getCaptacaoAutomatica } = useReactQueryCaptacao()
    const { data: captacaoData, isLoading: captacaoIsLoading, refetch, isSuccess } = useQuery({
        queryFn: () => getCaptacaoAutomatica({ descrição: filtroAtual as string, pagina: 1, tamanhoPagina: 2000 }),
        queryKey: ["rqCaptacaoData", filtroAtual,]
    })



    // Tailwind Custom CSS
   
    const filterGrid = 'flex flex-col w-full gap-2';
   
    



    return (
        <div className="w-full h-fit pb-3">
            <div className='flex flex-row w-full justify-around pl-1 pr-1 gap-2'>

                <div className={`${filterGrid}`}>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.CAPTACAO ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.CAPTACAO)}>
                        <BsClipboard2PlusFill className='icon-filter' />
                        <label className="labelFilter">Captação</label>
                        <span className={`${`badgeFilter`} pulse-animation-filter text-orange-500 mr-2`}> {captacaoData ? captacaoData.total : 0}</span>
                    </div>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.RESUMO ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.RESUMO)}>
                        <BiSearchAlt className='icon-filter' />
                        <label className="labelFilter">Análise</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.REGISTRAR)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.DESAPROVADO ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.DESAPROVADO)}>
                        <IoMdThumbsDown className='icon-filter' />
                        <label className="labelFilter">Desaprovados</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.DESAPROVADO)}</span>
                    </div>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.APROVADO ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.APROVADO)}>
                        <IoMdThumbsUp className='icon-filter' />
                        <label className="labelFilter">Aprovados</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.APROVADO)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.FAZENDO ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.FAZENDO)}>
                        <FaHourglassHalf className='icon-filter' />
                        <label className="labelFilter">Fazendo</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.FAZENDO)}</span>
                    </div>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.FEITO ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.FEITO)}>
                        <FaCheckSquare className='icon-filter' />
                        <label className="labelFilter">Feito</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.FEITO)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.REVOGADA ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.REVOGADA)}>
                        <FaCalendarTimes className='icon-filter' />
                        <label className="labelFilter">Revogado</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.REVOGADA)}</span>
                    </div>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.ORÇAMENTO ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.ORÇAMENTO)}>
                        <FaMoneyBill className='icon-filter' />
                        <label className="labelFilter">Orçamento</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.ORÇAMENTO)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.SESSÕES_DO_DIA ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.SESSÕES_DO_DIA)}>
                        <FaClock className='icon-filter' />
                        <label className="labelFilter">Sessões do dia</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.SESSÕES_DO_DIA)}</span>
                    </div>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.ACOMPANHAMENTO ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.ACOMPANHAMENTO)}>
                        <FaEye className='icon-filter mr-1' />
                        <label className="labelFilter">Acompanhamento</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.ACOMPANHAMENTO)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.PERDIDA ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.PERDIDA)}>
                        <IoIosCloseCircle className='icon-filter' />
                        <label className="labelFilter">Perdida</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.PERDIDA)}</span>
                    </div>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.GANHA ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.GANHA)}>
                        <BsTrophyFill className='icon-filter' />
                        <label className="labelFilter">Ganha</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.GANHA)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.ENTREGANDO ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.ENTREGANDO)}>
                        <FaTruckArrowRight className='icon-filter' />
                        <label className="labelFilter">Entregando</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.ENTREGANDO)}</span>
                    </div>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.OCORRÊNCIA ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.OCORRÊNCIA)}>
                        <FaTools className='icon-filter' />
                        <label className="labelFilter">Ocorrência</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.OCORRÊNCIA)}</span>
                    </div>
                </div>

                <div className={`${filterGrid}`}>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.EXECUÇÃO ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.EXECUÇÃO)}>
                        <MdOutlineTimeline className='icon-filter' />
                        <label className="labelFilter">Execução</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.EXECUÇÃO)}</span>
                    </div>
                    <div className={`idleFilterTab ${FiltroSelecionado === SituaçãoInterna.ENCERRADO ? "selectedFilterTab" : ''}`} onClick={() => alterarFiltro(SituaçãoInterna.ENCERRADO)}>
                        <FaFlag className='icon-filter' />
                        <label className="labelFilter">Encerrado</label>
                        <span className={`badgeFilter`}>{checkLength(SituaçãoInterna.ENCERRADO)}</span>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Filtros;
