import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { DisplayMiddle } from '../../Utils/types/PortalTypes';
import {
  BsChat,
  BsChatFill,
  BsPerson,
  BsPersonFill,
  BsPlusCircle,
  BsSearch,
  BsStar,
  BsStarFill,
} from 'react-icons/bs';
import { CategoriasPadrão, TCategoriaPastas } from '../../Utils/DevUtils/CategoriasFake';
import PastasProcessoCatgorias from './PastasProcessoCatgorias';
import { useQuery } from '@tanstack/react-query';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import { TbLayoutGridAdd } from "react-icons/tb";
import FerramentasConfig from '../FerramentasComponents/FerramentasConfig';
import { PiCaretDownThin, PiCaretUpThin } from "react-icons/pi";

import { Dropdown } from 'primereact/dropdown';
import { useInView } from 'react-intersection-observer';
import { RxCaretLeft, RxCaretRight } from 'react-icons/rx';
import { scrollFunction } from '../../Utils/ScrollSlider';



const FiltroGlobal = ({ filtroGlobal, changeFiltroGlobal, alterarFiltroImportante, importante }: any) => {



  const [categoria, setCategoria] = useState<TCategoriaPastas>({ value: 'Documentos', label: 'Documentos' });
  const [abrirListaCategorias, setAbrirListaCategorias] = useState<boolean>(false);

  const [buscarFiltro, setBuscarFiltro] = useState<string>('');

  const [categorias, setCategorias] = useState<any | undefined>(undefined);

  const handleCategoria = (categoria: TCategoriaPastas) => {
    console.log('Categoria: ', categoria)
    setCategoria(categoria);
    changeFiltroGlobal(categoria.value)
  }


  const [abrirCategorias, setAbrirCategorias] = useState<boolean>(false);
  const fechar = () => {
    setAbrirCategorias(false);
    setFerramentasConfiig(false)
  }
  const [ferramentasConfig, setFerramentasConfiig] = useState<boolean>(false)
  const token = localStorage.getItem('token');
  const empresaSelecionada = localStorage.getItem('empresaSelecionada');
  const { configCategoria, getCategorias } = useReactQueryEmpresas()

  const { data: categoriasData, refetch } = useQuery({
    queryFn: () => getCategorias(token as string, empresaSelecionada as string),
    queryKey: ['rqCategorias', empresaSelecionada]
  })

  useEffect(() => {
    if (filtroGlobal === DisplayMiddle.RESUMO) {
      setAbrirListaCategorias(false);
      setCategoria({ value: 'Documentos', label: 'Documentos' })

    }
    if (categoriasData) {
      const tempCategorias = categoriasData.categorias
      setCategorias(tempCategorias)
    }
  }, [filtroGlobal])

  useLayoutEffect(() => {

    if (categoriasData && buscarFiltro.length <= 0) {
      const tempCategorias = categoriasData.categorias
      setCategorias(tempCategorias)
    }

  }, [buscarFiltro])

  const handleSearch = (e: any) => {


    setBuscarFiltro(e.target.value);
    if (buscarFiltro || buscarFiltro.length > 1) {
      const filteredData: [TCategoriaPastas] = categorias.filter((item: TCategoriaPastas) => {

        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(buscarFiltro.toLowerCase());
      });
      setCategorias(filteredData);
    } else {
      setCategorias(categoriasData.categorias);
    }
  };



  const [ref1, inView1] = useInView()
  const [ref2, inView2] = useInView()
  const scrollRef = useRef<HTMLElement | any>(null)

  return (
    <div className='bg-white border-r border-l'>
      <div className='gap-2 pb-2'>
        <div className="w-full flex flex-row h-fit gap-2">
          {
            abrirCategorias &&
            <PastasProcessoCatgorias novaCat={false} fechar={fechar} categoriasData={categoriasData} />
          }
          {
            ferramentasConfig &&
            <FerramentasConfig fechar={fechar} categoriasData={categoriasData} />
          }
      
      
          <div className="flex flex-row w-28  gap-2 pl-3 mr-4">
            <label
              className={`mt-1 cursor-pointer opacity-75 hover:opacity-100`}
              onClick={alterarFiltroImportante}
            >
              {importante ? <BsStarFill className="text-xl text-gray-500" /> : <BsStar className="text-xl text-gray-500" />}
            </label>
            <label
              className="cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center"
              onClick={() => changeFiltroGlobal(DisplayMiddle.CONTATO)}
            >
              {filtroGlobal === DisplayMiddle.CONTATO ? (
                <BsPersonFill className="text-xl text-gray-500" />
              ) : (
                <BsPerson className="text-xl text-gray-500" />
              )}
            </label>
            <label
              className="cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center"
              onClick={() => changeFiltroGlobal(DisplayMiddle.CHAT)}
            >
              {filtroGlobal === DisplayMiddle.CHAT ? (
                <BsChatFill className="text-xl text-gray-500" />
              ) : (
                <BsChat className="text-xl text-gray-500" />
              )}
            </label>
            <label
              onClick={() => setFerramentasConfiig(current => !current)}
              className="cursor-pointer opacity-80 hover:opacity-100 transition-opacity flex flex-row items-center">
              <TbLayoutGridAdd className="text-xl text-gray-500" />
            </label>
          </div>
          <div className='flex flex-row w-fit'>
            <label
              className={filtroGlobal === DisplayMiddle.RESUMO ? `selectedTab max-w-36` : `idleTab max-w-36`}
              onClick={() => changeFiltroGlobal(DisplayMiddle.RESUMO)}
            >
              Resumo
            </label>
      
            <div className='relative flex flex-row justify-between '>
              <label
                onClick={() => changeFiltroGlobal(categoria.value)}
                className={
                  filtroGlobal !== DisplayMiddle.RESUMO &&
                    filtroGlobal !== DisplayMiddle.CONTATO &&
                    filtroGlobal !== DisplayMiddle.CHAT
                    ? 'selectedTab relative'
                    : 'idleTab relative'
                }> <span className='capitalize'>{filtroGlobal === DisplayMiddle.RESUMO ? categoria.label : filtroGlobal}</span>  <span onClick={() => setAbrirListaCategorias(current => !current)}>{abrirListaCategorias ? <PiCaretUpThin className='mt-1 font-semibold opacity-75 cursor-pointer' /> : <PiCaretDownThin className='mt-1 font-semibold opacity-75 cursor-pointer' />}</span></label>
              <div className='rounded-lg absolute top-[100%] right-0 z-50 w-full'>
                {
                  abrirListaCategorias &&
                  <div className='flex flex-col gap-2 min-h-14 max-h-64 min-w-44 overflow-x-hidden overflow-y-auto rounded-lg bg-white chart shadow-xl mt-2'>
                    <label className='w-full inline-flex max-h-11 outline-none border rounded-lgflex flex-row gap-1 sticky top-0 bg-white z-50 mx-2 rounded-lg'>
                      <span className='mt-2'>
                        <BsSearch className='text-sm mt-2 ml-2 opacity-55' />
                      </span>
                      <input type="text" className='border-none bg-none outline-none focus:ring-0 max-w-fit placeholder:opacity-55 text-xsm opacity-70' onChange={handleSearch} placeholder='Buscar' />
                    </label>
                    <label className='border-b border-opacity-75 hover:text-white text-sm cursor-pointer opacity-75 hover:opacity-100 hover:bg-orange-500 w-full px-5' onClick={() => { handleCategoria({ value: 'Documentos', label: 'Documentos' }) }}>
                      Documentos
                    </label>
                    {
                      categorias && categorias.sort((a: any, b: any) => a.label.localeCompare(b.label)).map((catgoriaMap: TCategoriaPastas) => (
                        <label className='selectTabOption' onClick={() => { handleCategoria(catgoriaMap) }}>
                          {catgoriaMap.label}
                        </label>
                      ))
                    }
                  </div>
                }
              </div>
            </div>
          </div>
          {
            !inView1 &&
            <label className='text-xl mt-1 opacity-75 w-fit cursor-pointer hover:opacity-100' onClick={() => scrollFunction('left', scrollRef)}>
              <RxCaretLeft />
            </label>
          }
            <div className='relative flex flex-row w-fit justify-start gap-2 overflow-x-auto no-scrollbar ' ref={scrollRef}>
            <div ref={ref1}></div>
      
              {
                categoriasData && categoriasData.filtros.map((categoria: TCategoriaPastas) => (
                  <label
                    className={filtroGlobal === categoria.label ? `selectedTab w-fit` : `idleTab w-fit`}
                    onClick={() => changeFiltroGlobal(categoria.label)}
                  >
                    {categoria.label.length >= 10
                      ? `${categoria.label.slice(0, 15)}...` : categoria.label}
                  </label>
                ))
              }
              <div ref={ref2}></div>
      
          </div>
          {
              !inView2 &&
              <label className='text-xl mt-1 opacity-75 w-fit cursor-pointer hover:opacity-100' onClick={() => scrollFunction('right', scrollRef)}>
                <RxCaretRight />
              </label>
            }
          {
            categoriasData && categoriasData.filtros.length < 10 ?
              <label
                className="border w-fit opacity-75 cursor-pointer hover:opacity-100 text-gray-500 transition-opacity border-gray-500 border-dashed rounded-2xl shadow hover:shadow-md flex flex-row px-2 gap-1"
                onClick={() => setAbrirCategorias(current => !current)}
              >
                <BsPlusCircle className="mt-1 " />
                Nova categoria
              </label>
              :
              <label
                className="border w-fit opacity-75 cursor-pointer hover:opacity-100 transition-opacity border-gray-700 border-dashed rounded-lg flex flex-row px-2 gap-1 "
                onClick={() => setAbrirCategorias(current => !current)}
              >
                <BsPlusCircle className="mt-1" />
              </label>
          }
      
        </div>
      </div>
        <hr className='border-gray-200 dark:border-gray-700 mx-8' />
    </div>
  );
};

export default FiltroGlobal;
