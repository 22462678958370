import { useQuery } from '@tanstack/react-query';
import React, { ReactElement, Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useReactQueryUsers } from '../Utils/ReactQuery/getUser';



const PublicRoute = () => {
    const {getUser} = useReactQueryUsers()

    const token = localStorage.getItem("token");
    const {data, isLoading, isError, isSuccess} = useQuery({queryFn: ()=> getUser(token as string), queryKey: ['rqUser']});

    return (
            token && data && data.status === 1 ? <Navigate to={'/portal/bem-vindo'}/> : <Outlet/> 
    )
    
  
}

export default PublicRoute