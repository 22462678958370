export const scrollFunction = (direction: 'left' | 'right', scrollRef: any) => {


    switch (true) {
      case direction === 'right':
        scrollRef.current?.scrollBy({
          left: scrollRef.current.clientWidth,
          top: 0,
          behavior: 'smooth',
        })
        break;
      case direction === 'left':
        scrollRef.current?.scrollBy({
          left: -scrollRef.current.clientWidth,
          top: 0,
          behavior: 'smooth',
        })

    }

  }