import React, { useEffect, useState } from 'react'
import { IMaskInput } from 'react-imask';
import { useRecoverSenha } from '../../Utils/RecuperarSenha/useRecoverSenha';
import AlertaCadastro from '../Alertas';
import styles from './RecuperarSenhaForm.module.css'

type Props = {}

const RecuperarSenhaForm = (props: Props) => {

  const {verifyEmail, verifyToken : checarToken, mudarSenha} = useRecoverSenha();

  const [email, setEmail] = useState<String>('');
  const [token, setToken] = useState<String[]>(['1', '2', '3', '4', '5', '6']);
  const [tokenString, setTokenString] = useState<String>();
  const [senha, setSenha] = useState< String>('');
  const [repetirSenha, setRepetirSenha] = useState<String>('');

  const [emailVerify, setEmailVerify] = useState<Boolean>(false);
  const [verifyToken, setVerifyToken] = useState<Boolean>(false);

  const [emailError, setEmailError] = useState<Boolean>(false);
  const [tokenError, setTokenError] = useState<Boolean>(false);
  const [erroSenha, setErroSenha] = useState(false);
  const [erroSenhaMsg, setErroSenhaMsg] = useState<String>('');
  const [sucess, setSucess] = useState<Boolean>(false);
  const [errorMsg, setErrorMsg] = useState<String>('');
  const [loading, setLoading] = useState<Boolean>(false);

  const tokenInput = `w-12 h-12 bg-gray-800 dark:bg-white text-black font-bold text-xl placeholder:font-bold rounded-lg text-center`;

  const handlEmailVerify =async(e: any)=>{
      e.preventDefault();
      setLoading(true);
      const data = {
        email
      }
   
      const res = await verifyEmail(data);
      if(res.status === 1){
        setEmailVerify(true);
        setEmailError(false);
        setLoading(false);
        return;
      }else{
        setEmailError(true);
        setErrorMsg(res.msg);
        setLoading(false);
        return;
      }

  }

  const changeTokenValue =(index: any, value: string)=>{

      let newArr = [...token]
      newArr[index] = value;
      setToken(newArr);
      
  }
  


  const handleTokenVerify =async(e: any)=>{
    e.preventDefault();
    setLoading(true);

    const cleanArr = token.filter((i: any)=> {return i !== ''});
    const concatArr = token.join("");
    setTokenString(concatArr)
    const res = await checarToken({config:{email: email as string, token: concatArr as string}});
    if(res.status === 1){
      setVerifyToken(true);
      setTokenError(false);
      setLoading(false)
      return
    }else{
      setTokenError(true);
      setErrorMsg(res.msg);
      setLoading(false);
      return;
    }
  }

  const close =()=>{
    setEmailError(false);
    setErrorMsg('');
  }

  const verifySenha =(e: any)=>{
    e.preventDefault();
    if(senha !== repetirSenha){
      setErroSenha(true);
      setErroSenhaMsg('As senhas não são iguais!')
    }else
    if(senha.length < 8 || repetirSenha.length < 8){
      setErroSenha(true);
      setErroSenhaMsg('A senhA deve ter mais de 8 dígitos!')
    }else
    setErroSenha(false);
    return;
  }

  const handleSendPassword=async(e: any)=>{
    e.preventDefault();
  
    setLoading(true);
    const res = await mudarSenha({config: {email: email as string , novaSenha: senha as string, token: tokenString as string}});
    if(res.status === 3 || !res.status){
      setErroSenha(true);
      setErrorMsg(res.msg)
      setLoading(false);
      return;
    }
    setErroSenha(false);
    setLoading(false);
    setSucess(true)
  }

  if(sucess){
    return(
      <section className="bg-white dark:bg-gray-900">
          <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
              <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white"> Senha alterada com sucesso! </h1>
              <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400"> Volte para a tela de login e utilize a nova senha, se já estiver logado você será redirecionado para a página principal.</p>
              <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
                
                  <a href="/" className="py-3 px-5 sm:ms-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-70">
                      Voltar
                  </a>  
              </div>
          </div>
      </section>

    )
  }

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a className="flex items-center justify-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white">
              <img className="w-42 h-auto" src="../logooficial.webp" alt="logo" />
                  
          </a>
          <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
              <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Recuperar senha
              </h2>
              <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" onSubmit={handleSendPassword} >


                  <div>
                      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">E-mail associado a conta</label>
                      <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={emailVerify ? email as string : "email@exemplo.com"} required={true} onChange ={(e)=> setEmail(e.target.value)} readOnly={emailVerify ? true : false} />
                      {
                        emailError && <AlertaCadastro message={errorMsg} state ={'error'} fechar ={close} />
                      }
                  </div>

                  {
                    loading && !emailVerify ?       
                    <button disabled= {true}  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">  
                    <div role="status" className='flex flex-row gap-5 m-0 p-0 justify-center items-center'>
                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
          
                    
                    </div>
                    
                     </button>
                    :
                    <button onClick={handlEmailVerify}  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"> {emailVerify? 'Reenviar código' : 'Enviar código'} </button>
                  }

                  { emailVerify && !verifyToken &&
                  <>
                  <div>
                  <label htmlFor="token" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Verifique o token envado no email </label>
                  
                  <div className={`flex flex-row gap-2 justify-center items-center`}>
                    <IMaskInput  mask={'0'} type="text" name="" id="" className={`${styles.tokenInput} ${tokenInput}`} placeholder={'_'} onAccept ={(value: string, mask: any)=> {changeTokenValue(0, value as string)}}/>
                    <IMaskInput mask={'0'} type="text" name="" id="" className={`${styles.tokenInput} ${tokenInput}`} placeholder={'_'} onAccept ={(value: string, mask: any)=> {changeTokenValue(1, value as string)}}/>
                    <IMaskInput mask={'0'} type="text" name="" id="" className={`${styles.tokenInput} ${tokenInput}`} placeholder={'_'} onAccept ={(value: string, mask: any)=> {changeTokenValue(2, value as string)}}/>
                    <IMaskInput mask={'0'} type="text" name="" id="" className={`${styles.tokenInput} ${tokenInput}`} placeholder={'_'} onAccept ={(value: string, mask: any)=> {changeTokenValue(3, value as string)}}/>
                    <IMaskInput mask={'0'} type="text" name="" id="" className={`${styles.tokenInput} ${tokenInput}`} placeholder={'_'} onAccept ={(value: string, mask: any)=> {changeTokenValue(4, value as string)}}/>
                    <IMaskInput mask={'0'} type="text" name="" id="" className={`${styles.tokenInput} ${tokenInput}`} placeholder={'_'} onAccept ={(value: string, mask: any)=> {changeTokenValue(5, value as string)}}/>
  
                  </div>

                  </div>

                  {
                    tokenError && <p className='text-red-500 text-sm'> {errorMsg}</p>
                  }

                  <p className='cursor-pointer opacity-80 font-semi-bold hover:opacity-100'> Enviar novamente </p>
                  

                  {
                    loading ?       
                    <button disabled= {true}  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">  
                    <div role="status">
                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span className="sr-only">Aguarde...</span>
                    </div>
                     </button>
                    :
                    <button onClick={handleTokenVerify}  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"> Verificar código </button>
                  }
                  </>                    
                  }

                  {
                  verifyToken &&
                  <>
                  <div>
                      <label htmlFor ="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">nova senha</label>
                      <input onChange={(e)=> setSenha(e.target.value)} onBlur ={verifySenha}  type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required={true}/>

                    
                  </div>

                  <div>
                      <label htmlFor ="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirme a senha</label>
                      <input onChange={(e)=>setRepetirSenha(e.target.value)} onBlur ={verifySenha} type="password" name="confirm-password" id="confirm-password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required={true}/>
                  </div>

                  {
                    erroSenha && <p className='text-red-500 text-sm'> {erroSenhaMsg}</p>
                  }

                  {
                    loading && verifyToken ?       
                    <button disabled= {true}  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">  
                    <div role="status">
                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    </div>
                     </button>
                    :
                    !erroSenha ? 
                    < button onClick={handleSendPassword}  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"> Alterar senha </button>
                    :
                    <button type='submit'  disabled={true} className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"> Alterar senha </button>
                  }
                    
                  </>
                  }
              </form>
          </div>
      </div>
  </section>
  )
}

export default RecuperarSenhaForm