import { cnpj } from "cpf-cnpj-validator"
import { json } from "stream/consumers";



export const getCnpjData = async(Cnpj: string) =>{

    const checkCnpj = cnpj.isValid(Cnpj);
    if(!checkCnpj){
        return null
    }

   
   
    const reg = /[\/\.-]/g;
    const sanitizedCnpj = Cnpj.replace(reg, "");
    try {
        const res = await fetch(`https://brasilapi.com.br/api/cnpj/v1/${sanitizedCnpj}`);
        if(!res || res.status === 404){
            console.error('Erro no fetch!')
            return {
                status: 3,
                msg: 'Nnenhma empresa encontrada'
            };
        }


        const cnpjData = res.json();
        return cnpjData;
    } catch (error) {
        console.error(error);
        return null;
        
    }


}