import { useState, useEffect, useLayoutEffect } from 'react';
import { TCaptacaoCard } from '../../Utils/DevUtils/FakeCaptacao';
import CaptacaoItem from './CaptacaoItem';
import { BsQuestionCircle, BsPlus, BsArrowRight, BsSearch, BsExclamationCircle, BsX } from 'react-icons/bs';
import CaptacaoArquivos from './CaptacaoArquivos';
import CaptacaoItems from './CaptacaoItems';
import { cnpj } from 'cpf-cnpj-validator';
import NovoProcesso from '../ProcessosComponents/NovoProcesso';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TempresaData } from '../../Utils/types/cadastroTypes';
import { useUserContext } from '../../Context/useUserContext';
import { toast } from 'react-toastify';
import { RegistrarProcesso, TItensData, typeProcessoData } from '../../Utils/types/PortalTypes';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import LoadingSmall from '../LoadingSmall';
import { LuCheckCheck } from "react-icons/lu";
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';
import CaptacaoPortais, { TCaptacaoPortais } from './CaptacaoPortais';
import { arquivosFake } from '../../Utils/DevUtils/FakeProcessos';
import { FaCheckDouble } from "react-icons/fa6";
import { checkAnexado } from '../../Utils/checkAnexado';



const CaptacaoCard = ({ item, fechar, processoPncp }: any) => {
    const [itemSelecionado, setItemSelecionado] = useState<any>('');
    const [abiriItem, setAbrirItem] = useState<boolean>(false);
    const [arquivosUrl, setArquivosUrl] = useState<string>('');
    const [itensUrl, setItensUrl] = useState<string>('');
    const [anexarNoPortal, setAnexarNoPortal] = useState<boolean>(false);
    const [anexarDireto, setAnexarDireto] = useState<boolean>(false)
    const [orgaoInfo, setOrgaoInfo] = useState<any>(null);
    const [mostrarObjetoCompleto, setMostrarObjetoCompleto] = useState<boolean>(false)
    const [duplicação, setDuplicação] = useState<boolean>(false);
    const [itemDuplicado, setItemDuplicado] = useState<any>();

    const [fonte, setFonte] = useState<string>('')

    const [empresaResponssavel, setEmpresaResponsavel] = useState<any>('');

    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false)

    const [usuarioNome, setUsuarioNome] = useState<string>('')
    const [linkSistemaOrigem, setLinkistemaOrigem] = useState<string>('')


    const [userContext] = useUserContext();


    const token = localStorage.getItem('token');
    const { empresasPermitidas } = useReactQueryEmpresas();
    const { data: empresasData } = useQuery({
        queryFn: () => empresasPermitidas(token as string),
        queryKey: ['rqEmpresasPermitidas']
    })


    const handletemDuplicado = (item: TCaptacaoCard) => {
        setItemDuplicado(item);
        setDuplicação(true)
    }




    enum SelecionarItem {
        ARQUIVO = 'Arquivos',
        ITEM = 'Items',
        RESUMO = 'resumo',
        ANEXAR = 'anexar',
        ANEXARDIRETO = 'anexardireto',
        ANEXARDIRETOEMPRESA = 'anexardiretoempresa'
    }

    const [captacaoArea, setCaptacaoArea] = useState<SelecionarItem>(SelecionarItem.RESUMO);

    const fecharItem = () => {
        setAbrirItem(false);
        setItemSelecionado('');
        setAnexarNoPortal(false);
        setAnexarDireto(false);
        setDuplicação(false)
    };


    const formUrl = `https://pncp.gov.br/pncp-api/v1/orgaos/${item.orgao_cnpj}/compras/${item.ano}/${item.numero_sequencial}`;


    const { getCaptacaoPortais, getCaptacaoItens } = useReactQueryCaptacao();

    const { data: portaisData, isLoading, isFetching } = useQuery({
        queryKey: [`${item.id}-rqCaptacaoPortal`, item],
        queryFn: () => getCaptacaoPortais(formUrl),
        retry: true,
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 3000)

    })

    const { data: itensData, isLoading: isLoadingItens, refetch, isError: itensIsError } = useQuery({
        queryKey: ['rqItensTotal', itensUrl],
        queryFn: () => getCaptacaoItens(itensUrl),
        staleTime: Infinity,

    })


    const selecionarItem = (itemAtivo: TCaptacaoCard, action: SelecionarItem) => {

        const arquivosUrl = `https://pncp.gov.br/api/pncp/v1/orgaos/${itemAtivo.orgao_cnpj}/compras/${itemAtivo.ano}/${itemAtivo.numero_sequencial}/arquivos`;
        setArquivosUrl(arquivosUrl);

        const itensUrl = `https://pncp.gov.br/api/pncp/v1/orgaos/${itemAtivo.orgao_cnpj}/compras/${itemAtivo.ano}/${itemAtivo.numero_sequencial}/itens`;

        refetch()

        const portalUrl = `https://pncp.gov.br/api/pncp/v1/orgaos/${itemAtivo.orgao_cnpj}/compras/${itemAtivo.ano}/${itemAtivo.numero_sequencial}`

        const data = {
            item: itemAtivo,
            LinkArquivos: arquivosUrl,
            LinkItens: itensUrl,
            portalUrl: portalUrl
        };


        setItensUrl(itensUrl);
        setItemSelecionado(data);


        const empresaPadrão = localStorage.getItem('empresaPadrão');

        switch (action) {
            case SelecionarItem.ARQUIVO:
                return setCaptacaoArea(SelecionarItem.ARQUIVO);
            case SelecionarItem.ITEM:
                return setCaptacaoArea(SelecionarItem.ITEM);
            case SelecionarItem.RESUMO:
                return setCaptacaoArea(SelecionarItem.RESUMO);
            case SelecionarItem.ANEXAR:
                return setAnexarNoPortal(true);
            case SelecionarItem.ANEXARDIRETO:
                setDuplicação(false)
                setAnexarDireto(true)
                return
            case SelecionarItem.ANEXARDIRETOEMPRESA:
                handleEnviarProcesso(empresaPadrão as string, itensUrl, arquivosUrl);
                return
        }
    };

    const resgatarValorTotal = async () => {

        try {
            const res = await fetch(itensUrl);
            const response = await res.json();

            const valorTotal = response.flatMap((i: any) => {
                if (!isNaN(i.valorTotal)) {
                    return i.valorTotal
                }

            }).reduce((sum: any, i: any) => {
                return sum + i
            }).toFixed(2)


            if (!valorTotal || valorTotal === 0) {
                setVte('Sigiloso!');
                return;
            }


            setVte(`R$: ${valorTotal}`)
            return

        } catch (error) {
            return
        }

    }


    useEffect(() => {
        if (item.orgao_cnpj) {
            fetch(`https://brasilapi.com.br/api/cnpj/v1/${item.orgao_cnpj}`)
                .then(response => response.json())
                .then(data => {
                    setOrgaoInfo(data);

                })
                .catch(error => console.error('Erro ao buscar informações do órgão:', error));
        }

        resgatarValorTotal()

    }, [item.orgao_cnpj]);

    const formatCep = (cep: string) => {
        return cep ? cep.replace(/(\d{5})(\d{3})/, "$1-$2") : '';
    };

    const formatTelefone = (telefone: string) => {
        return telefone ? telefone.replace(/(\d{2})(\d{4,5})(\d{4})/, "($1) $2-$3") : '';
    };

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        const date = new Date(dateString);
        return date.toLocaleDateString('pt-BR', options);
    };

    // Função para alternar a exibição do objeto completo
    const toggleObjetoCompleto = () => {
        setMostrarObjetoCompleto(!mostrarObjetoCompleto);
    };


    const pncpLink = (linkPncp: any) => {
        const pncpUrl: string = linkPncp.replace('/compras', 'https://pncp.gov.br/app/editais');
        return pncpUrl;
    }

    // Envio diretoo mutation --------------------------------

    const queryClient = useQueryClient();
    const { registrarProcesso, anexarProcessoDireto } = useReactQueryProcessos();
    const empresaSelecionada = localStorage.getItem('empresaSelecionada');

    const registrarProcessoMutation = useMutation({
        mutationFn: anexarProcessoDireto,
        onMutate: async (config) => {
            // console.log('Mutação inicida!');
            setLoading(true)
        },
        onSuccess: async (response) => {
            // console.log('Mutação bem sucedida!', response);

            queryClient.invalidateQueries({ queryKey: ['rqProcessos'] })
            queryClient.fetchQuery({ queryKey: ['rqProcessos'] })
            queryClient.refetchQueries({ queryKey: ['rqProcessos'] })

            queryClient.invalidateQueries({ queryKey: ['rqProcessosPncp'] })
            queryClient.fetchQuery({ queryKey: ['rqPrcessosInfo'] })
            queryClient.refetchQueries({ queryKey: ['rqPrcessosInfo'] })

            queryClient.invalidateQueries({ queryKey: ['rqProcessosPncp'] })
            queryClient.fetchQuery({ queryKey: ['rqProcessosPncp'] })
            queryClient.refetchQueries({ queryKey: ['rqProcessosPncp'] })

            if (response.status === 1) {
                toast.success(response.msg);
                setSucesso(true);
            }
            if (response.status === 4) {
                toast.warn(response.msg)

            }
            if (response.status === 3) {
                toast.error(response.msg);
            }
        },
        onError: async (error, variables, context) => {
            console.error(error.message);
            toast.error(error.message);

        },
        onSettled: async (data) => {
            // console.log('Mutação concluída!');
            setLoading(false)
        }
    })


    // Envio diretoo mutation --------------------------------

    const [Vte, setVte] = useState('')

    const numeroDoProcesso = () => {
        const numeroDoProcesso = `${item.numero_sequencial.toString()}/${item.ano.toString()}`
        return numeroDoProcesso;
    }

    const findNumero = () => {
        const numeroApenas = item.title.replaceAll(/[^\d.-/]/g, '');
        return numeroApenas
    }


    const getPortalData = () => {
        try {

            setUsuarioNome(portaisData.usuarioNome)
            setLinkistemaOrigem(portaisData.linkSistemaOrigem)


        } catch (error) {
            setUsuarioNome('');
            setLinkistemaOrigem('')

        }
    }

    const handleEnviarProcesso = (empresaSelecionada: string, itemUlr = '', docUrl = '') => {

        if (checkAnexado(item.id, processoPncp) && !itemDuplicado) {
            setItemDuplicado(item);
            setDuplicação(true);
            return;
        }

        const dataItens: [TItensData] = itensData ? itensData.flatMap((i: any) => {
            return {
                Numero: i.numeroItem || '',
                Descrição: i.descricao,
                Quantidade: i.quantidade,
                ValorTotalEstimado: i.valorTotal || 'Sigiloso',
                ValorUnitarioEstimado: i.valorUnitarioEstimado || 'Sigiloso',
            }
        }) : ['']

        let usuarioTemp = ''
        let linkSistemaOrigemTemp = ''

        if (portaisData && portaisData.usuarioNome) {
            usuarioTemp = portaisData.usuarioNome
        }
        if (portaisData && portaisData.linkSistemaOrigem) {
            linkSistemaOrigemTemp = portaisData.linkSistemaOrigem
        }

        const data: typeProcessoData = {
            usuarioNome: usuarioTemp,
            linkSistemaOrigem: linkSistemaOrigemTemp,
            link_pncp: pncpLink(item.item_url),
            id_pncp: item.id,
            orgaopublicoLicitante: item.orgao_nome,
            CnpjLicitante: item.orgao_cnpj,
            empresaSelecionada: empresaSelecionada,
            enderecoLicitante: orgaoInfo ? orgaoInfo.logradouro : '',
            cidadeLicitante: orgaoInfo ? orgaoInfo.municipio : '',
            cepLicitante: orgaoInfo ? orgaoInfo.cep : '',
            foneLicitante: orgaoInfo ? orgaoInfo.ddd_telefone_1 : '',
            emailLicitante: orgaoInfo ? orgaoInfo.email : '',
            modalidade: item.modalidade_licitacao_nome,
            numero: findNumero(),
            processo: numeroDoProcesso(),
            tipo: item.tipo_nome,
            objeto: item.description,
            valorestimado: Vte,
            situacaoInterna: 'Registrar',
            DataHoraPublicacao: item.data_publicacao_pncp,
            DataHoraAberturaLicitacao: item.data_fim_vigencia,
            LinkArquivosPncp: arquivosUrl || docUrl,
            LinkItensPncp: itensUrl || itemUlr,
            importante: false,
            DataHoraAdjucacao: '',
            DataHoraLimiteEsclarecimento: '',
            DataHoraImpugnacao: '',
            DataHoraContraProposta: '',
            DataHoraContinuacaoLicitacao: '',
            DataHoraIntencaoRecurso: '',
            DataHoraRecurso: '',
            DataHoraLimiteContrarrazao: '',
            DataHoraLimiteJulgamentoRecurso: '',
            DataHoraHomologacao: '',
            DataHoraAdjudicação: '',
            DataHoraDiligencia: '',
            DataHoraApresentação: '',
            DataMandatoS: '',
            exclusividade: '',
            LocalsessaoPublica: '',
            logoLicitante: '',
            portalSelecionado: '',
            usuarioSelecionado: '',
            srp: '',
            siteLicitante: '',
            file: ''

        }



        registrarProcessoMutation.mutate({
            token: token as string,
            data: data,
            empresaSelecionada: empresaSelecionada,
            contatos: {},
            empresaid: empresaSelecionada,
            File: false,
            Arquivo: '',
            tipo: 'direto',
            itens: dataItens

        });

        fecharItem();

    }


    const empresaPadrão = localStorage.getItem("empresaPadrão");







    // TAILWIND --------------------------------------------------------------------
    const button = 'cursor-pointer text-white inline-flex items-center bg-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 h-fit';
    const activeTab = 'border-b-2 border-orange-400 text-orange-400 font-semibold inline-flex p-2 transition ease-in-out duration-300';
    const inactiveTab = 'text-gray-600 hover:text-orange-600 cursor-pointer transition ease-in-out duration-300 inline-flex p-2';
    const tooltip = 'absolute bg-gray-800 text-white text-xs rounded-lg py-2 px-3 right-0 bottom-full mb-2 shadow-lg';
    const selectInput = "bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3";
    const optionInput = "bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3";
    const cardContainer = "w-full h-fit bg-white rounded-lg border shadow-md flex flex-col p-5 transition transform hover:shadow-lg hover:scale-[102%] transition-scale  ease-in-out duration-300";


    // TAILWIND --------------------------------------------------------------------


    return (
        <div className='flex flex-col p-5  ' onClick={(e) => e.stopPropagation()}>

            {
                anexarDireto &&
                <div id="popup-modal" tabIndex={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">

                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-5 flex flex-col gap-5">

                            <div className='w-full flex justify-end'>
                                <BsX className='text-3xl' onClick={fecharItem} />
                            </div>

                            <div className='text-5xl w-full flex justify-center items-center'>
                                <BsExclamationCircle />
                            </div>

                            <h3> Selecione a empresa responssável pelo processo: </h3>

                            <select className={selectInput} onChange={(e) => setEmpresaResponsavel(e.target.value)} value={empresaResponssavel}>
                                <option value=""> Selecioone uma empresa</option>
                                {
                                    empresasData && empresasData.empresas.filter((empresa: TempresaData) => { return empresa._id !== empresaPadrão }).map((empresa: TempresaData) => (
                                        <option className={optionInput} value={empresa._id}>{empresa.NomeEmpresa} - ({empresa.CnpjEmpresa})</option>
                                    ))
                                }

                            </select>


                            <div className='w-full flex' onClick={() => handleEnviarProcesso(empresaResponssavel)}>

                                {
                                    empresaResponssavel ?
                                        <button className={button} disabled={loading || !empresaResponssavel || empresaResponssavel === ""}>
                                            {loading ? <LoadingSmall message={'Salvando processo!'} /> : <>Enviar para análise
                                                <BsArrowRight className='ml-5 text-xl' /></>}
                                        </button>
                                        :
                                        <label > Selecione a empresa responsável pelo processo para enviar para a análse</label>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                // Alterado para pegar somente as empresas, não aceitar a assessoria no select @matheusgosk8 - ac_dev_3.3
            }

            {
                duplicação &&
                <div id="popup-modal" tabIndex={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">


                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-5  flex flex-col gap-5">
                            <h3>Este processo já foi encaminhado para a análise!</h3>
                            <small>Oque deseja fazer?</small>

                            <div className='flex flex-col gap-4'>
                                <button className='capLimparButton' onClick={fecharItem}> Cancelar</button>

                                <button className='confirmButton' onClick={() =>
                                    userContext && userContext.User.AcessoAtual === 'Empresa' ?
                                        selecionarItem(itemDuplicado, SelecionarItem.ANEXARDIRETOEMPRESA)
                                        :
                                        selecionarItem(itemDuplicado, SelecionarItem.ANEXARDIRETO)
                                }>
                                    Enviar processo duplicado para análise
                                </button>

                                <button className='confirmButton' onClick={() => selecionarItem(itemDuplicado, SelecionarItem.ANEXAR)}> Verificar informações</button>

                            </div>

                        </div>

                    </div>
                </div>
            }


            <div >
                {abiriItem &&
                    <div className='fadeIn'>
                        <CaptacaoItem item={itemSelecionado} fechar={fecharItem} />
                    </div>}
                {anexarNoPortal &&
                    <NovoProcesso fechar={fecharItem} captacaoPncp={itemSelecionado} />
                }


            </div>

            <div className={cardContainer}>
                {
                    checkAnexado(item.id, processoPncp) &&
                    <span className='text-xl text-blue-500'><LuCheckCheck /></span>
                }
                <div className='flex justify-between items-start mb-4'>


                    <div>
                        <label className={captacaoArea === SelecionarItem.RESUMO ? activeTab : inactiveTab} onClick={() => selecionarItem(item, SelecionarItem.RESUMO)}>Resumo</label>
                        <label className={captacaoArea === SelecionarItem.ARQUIVO ? activeTab : inactiveTab} onClick={() => selecionarItem(item, SelecionarItem.ARQUIVO)}>Documentos</label>
                        <label className={captacaoArea === SelecionarItem.ITEM ? activeTab : inactiveTab} onClick={() => selecionarItem(item, SelecionarItem.ITEM)}>Itens</label>
                    </div>
                    <div className='text-right'>
                        <div className='flex flex-row gap-5'>
                            <div>
                                <label className='text-sm text-gray-500'>Fonte: <a target='_blank' href={pncpLink(item.item_url)} className='font-semibold text-blue-500 underline cursor-pointer'>PNCP</a></label>
                                <div className='text-xs text-gray-500 mt-1'>Última Atualização: <span className='font-semibold'>{formatDate(item.data_atualizacao_pncp)}</span></div>
                            </div>
                            <CaptacaoPortais portaisData={portaisData as TCaptacaoPortais} isLoading={isLoading} isFetching={isFetching} />
                        </div>
                    </div>
                </div>



                {captacaoArea === SelecionarItem.RESUMO && (
                    <div className="  text-gray-700 dark:text-gray-300">
                        <div className="flex flex-col gap-1">
                            <div className="font-bold text-base md:text-xl text-green-600 inline-block">
                                {item.modalidade_licitacao_nome + ' - ' + item.unidade_nome + " - " + findNumero()}
                            </div>
                            <div className='gap-4 flex flex-col'>
                                <div className='font-semibold text-sm'>
                                    {item.description}
                                </div>

                            </div>

                        </div>


                    </div>
                )}

                {captacaoArea === SelecionarItem.ARQUIVO && (
                    <div className='overflow-y-auto chart fadeIn mt-4'>
                        <CaptacaoArquivos item={item} url={arquivosUrl} />
                    </div>
                )}
                {captacaoArea === SelecionarItem.ITEM && (
                    <div className='overflow-y-auto chart fadeIn mt-4'>
                        <CaptacaoItems item={item} url={itensUrl} />
                    </div>
                )}

                <div className='flex flex-col md:flex-row justify-between items-center mt-6 gap-4'>
                    <div className='flex flex-row gap-4'>

                        {userContext && userContext.User.AcessoAtual === 'Empresa' ? (
                            <button className="capEnviarButton" onClick={() => selecionarItem(item, SelecionarItem.ANEXARDIRETOEMPRESA)}>
                                Enviar para análise
                                <BsArrowRight className='text-lg' />
                            </button>
                        ) : (
                            <button className="capEnviarButton" onClick={() => selecionarItem(item, SelecionarItem.ANEXARDIRETO)}>
                                Enviar para análise
                                <BsArrowRight className='text-lg' />
                            </button>
                        )}
                    </div>

                    <div className='flex flex-row gap-5'>
                        <div className=''>
                            <label className='text-sm opacity85'>
                                Data da abertura:
                                {formatDate(item.data_fim_vigencia)}
                            </label>
                        </div>
                        <div className='relative group'>
                            <span className='text-gray-500 hover:text-gray-700 cursor-pointer transition ease-in-out duration-300'>
                                <BsQuestionCircle />
                            </span>
                            <div className={`${tooltip} hidden group-hover:block`}>
                                Esses são dados de captação automática. Para dar andamento no processo licitatório, envie para análise.
                            </div>
                        </div>
                    </div>


                </div>
            </div>




        </div>
    );
}

export default CaptacaoCard;
