import React, { useState } from 'react'
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { BsExclamationCircle, BsPlus, BsX } from 'react-icons/bs';
import { FaUnlink } from 'react-icons/fa';
import { useAdminUtils } from '../../Utils/ReactQuery/getAdminUtils';
import { toast } from 'react-toastify';
import LoadingSmall from '../LoadingSmall';


const AssessoriaGerenciarAssessorias = () => {


    const [desvincular, setDesvincular] = useState<boolean>(false)
    const [empresaSelecionada, setEmpresaSelecionada] = useState<any>('')


    const fecharDesvincular = () => {
        setDesvincular(false)
    }

    const handleDesvincular = (empresa: any) => {
        setEmpresaSelecionada(empresa);
        setDesvincular(true)
    }

    //Mutação --------------------------------------------

    const [loading, setLoading] = useState<boolean>(false);


    const queryClient = useQueryClient()
    const { revogarAssessoria } = useAdminUtils()
    const revogarAssessoriaMutation = useMutation({
        mutationFn: revogarAssessoria,
        onMutate: async (data) => {
            setLoading(true)

        },
        onSuccess: async (response) => {


            queryClient.invalidateQueries({ queryKey: ['rqEmpresasPermitidas'] })
            queryClient.refetchQueries({ queryKey: ['rqEmpresasPermitidas'] })

            if (response.status === 1) {
                toast.success(response.msg)
            }
            if (response.status === 3) {
                toast.error(response.msg)
            }

        },
        onError: async (err) => {
            console.error(err.message);
            toast.error('Erro na solicitação!');
        },
        onSettled: async (data) => {
            setLoading(false);
            setDesvincular(false);
            setEmpresaSelecionada('')
        }
    })



    //Mutação --------------------------------------------


    const { empresasPermitidas } = useReactQueryEmpresas();
    const token = localStorage.getItem('token');
    const { data: empresasDisponiveis, isLoading: empresasDisponiveisLoading } = useQuery({
        queryFn: () => empresasPermitidas(token as string),
        queryKey: ['rqEmpresasPermitidas'],
        staleTime: 1000 * 60 * 2,
    });


    if (empresasDisponiveis) {
        console.log('Empresas disponiveis: ', empresasDisponiveis)
    }


    return (


        <div className="relative overflow-x-auto shadow-xl sm:rounded-lg bg-white dark:bg-gray-900 p-5 rounded-xl max-w-4xl">

            {
                desvincular &&
                <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 " >
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 flex flex-col p-10 gap-5" >

                            <div className='w-full flex justify-center items-center'>
                                <BsExclamationCircle className='text-5xl' />
                            </div>

                            <h3>
                                Deseja desvincular a assessooria da empresa <span className='font-semibold'>{empresaSelecionada.NomeEmpresa}</span>?
                            </h3>

                            <small>
                                Você perderá toddo oo acesso a pastas e processos desta empresa, para reetmar o acesso sera necesário que a empresa responsa a uma nova solicitação de assessoria.
                            </small>

                            <div className='w-full flex flex-row justify-between'>

                                <label onClick={loading ? () => { } : fecharDesvincular} className={loading ? "text-white inline-flex items-center cursor-pointer bg-gray-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600  dark:focus:ring-gray-800" : "text-white inline-flex items-center cursor-pointer bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-red-700 dark:focus:ring-red-800"}>
                                    <BsX className='text-xl' />
                                    Cancelar
                                </label>

                                {

                                    loading ?

                                        <button className="text-white inline-flex items-center bg-green-500 focus:ring-4  font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-400 dark:hover:bg-green-500 " disabled={true}>
                                            <LoadingSmall message={'Revogando acesso...'} />
                                        </button>
                                        :

                                        <button className="text-white inline-flex items-center bg-green-500 focus:ring-4  font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-400 dark:hover:bg-green-500 " onClick={() => revogarAssessoriaMutation.mutate({ token: token as string, empresaid: empresaSelecionada._id, tipo: 'assessoria' })}>
                                            <FaUnlink className='mr-2' />
                                            Desvincular
                                        </button>
                                }

                            </div>

                        </div>
                    </div>
                </div>
            }

            <h3 className="text-lg  mb-2 font-semibold">Empresas assessoradas</h3>
            <small className="block mb-4 font-sans text-gray-600 dark:text-gray-300">Estas são as empresas que você assessor, ao selecionar a opção de "Desvincular" o seu acesso a empresa seleconada erá desvinculado, sendo necessário envar ooutra solicitação para acessar novamente a empresa.</small>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 font-sans">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                    <tr>

                        <th scope="col" className="px-6 py-3">
                            Nome da empresa

                        </th>
                        <th scope="col" className="px-6 py-3">
                            CNPJ da empresa
                        </th>

                        <th scope="col" className="px-6 py-3">
                            Opções
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {

                        empresasDisponiveis && empresasDisponiveis.empresas.filter((i: any) => { return i.CnpjEmpresa !== empresasDisponiveis.AcessoAtual.Afiliação }).map((empresa: any) => (
                            <tr>
                                <td className="px-6 py-4 flex flex-row">
                                    <div className='w-12 h-12 rounded-full object-contain flex justify-center items-center mr-2'>
                                        {

                                            empresa.LogoEmpresa ?
                                            <img src={empresa.LogoEmpresa} className='w-8 h-8 rounded-full' />
                                            :
                                            <label>
                                                {
                                                    empresa.NomeEmpresa.slice(0, 1)
                                                }
                                            </label>
                                        }

                                    </div>
                                    <span className='mt-4 font-black'>{empresa.NomeEmpresa}</span>
                                </td>

                                <td className="px-6 py-4">
                                    {empresa.CnpjEmpresa}
                                </td>


                                <td className="px-6 py-4">
                                    <label className='opacity-75 hover:opacity-100 cursor-pointer font-normal text-blue-700 hover:underline' onClick={() => handleDesvincular(empresa)}>
                                        Desvincular
                                    </label>
                                </td>
                            </tr>
                        ))

                    }
                </tbody>
            </table>



        </div>
    )
}

export default AssessoriaGerenciarAssessorias