import React, { useLayoutEffect, useState } from 'react';
import SideNavWrapper from '../Components/PortalComponents/SideNavWrapper';
import BannerNetwork from '../Components/NetWorkComponents/BannerNetwork';
import Organograma from '../Components/NetWorkComponents/Organograma';
import CaptacaoPopInit from '../Components/CaptacaoAutomatica/CaptacaoPopInit';


const BemVindo = () => {

  
    return (
        <div className="flex ">
            <SideNavWrapper />
            <div className="w-full ">
                <BannerNetwork />
                 
                <Organograma />
                
            </div>
        </div>
    );
};

export default BemVindo;