import React, { useEffect, useState } from 'react';
import { DisplayMiddle } from '../../Utils/types/PortalTypes';
import { formatarData } from '../../Utils/formatarData';
import { BsDownload, BsEye, BsEyeSlash, BsPencil, BsTrash } from 'react-icons/bs';
import { FaFolderOpen } from 'react-icons/fa';

import moment from 'moment';
import AbrirPastaProcesso from './AbrirPastaProcesso';
import NovaPastaProcesso from './NovaPastaProcesso';
import ApagarPastaArquivoProcesso from './ApagarPastaArquivoProcesso';
import { useQuery } from '@tanstack/react-query';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import ArquivoModal from '../ArquivosComponents.tsx/ArquivoModal';
import { toast } from 'react-toastify';
import CaptacaoArquivosProcesso from '../CaptacaoAutomatica/CaptacaoArquivosProcesso';
import { converterValor } from '../../Utils/ConverterValor';
import EditarArquivo from '../ArquivosComponents.tsx/EditarArquivo';
import NovoArquivoProcesso from './NovoArquivoProcesso';

const ProcessosPastas = ({ filtro, processo, changeMiddleDisplay }: any) => {
  const [pastas, setPastas] = useState<any>([]);
  const [pastaSelecionada, setPastaSelecionada] = useState<any>('');
  const [arquivoSelecionado, setArquivoSelecionado] = useState<any>('');
  const [arquivosPasta, setArquivosPasta] = useState<any>([]);

  const [novaPasta, setNovaPasta] = useState<boolean>(false);
  const [pastaEditar, setPastaEditar] = useState<boolean>(false);
  const [apagar, setApagar] = useState<boolean>(false);
  const [tipoApagado, setTipoApagado] = useState<string>('');
  const [abrirArquivo, setAbrirArquivo] = useState<boolean>(false);

  // Expanded state for expandable rows
  const [expandedPastaId, setExpandedPastaId] = useState(null);

  const token = localStorage.getItem('token');
  const { listarPastasProcessos } = useReactQueryProcessos();

  const { data: pastaArquivoData, isLoading } = useQuery({
    queryKey: ['rqPastasProcesso', processo._id, filtro],
    queryFn: () =>
      listarPastasProcessos({
        processoid: processo._id as string,
        token: token as string,
      }),
  });

  const abrirArquivoModal = (arquivo: any) => {
    setArquivoSelecionado(arquivo);
    setAbrirArquivo(true);
  };

  const checkStatus = (dataVencimento: string, status: string) => {
    const dataAtual = moment();

    if (!dataVencimento && (status === 'Nenhum' || !status)) {
      return <span className="text-green-500"> --- </span>;
    }

    const dataFinal = moment(dataVencimento);
    const dif = dataFinal.diff(dataAtual, 'days');

    switch (true) {
      case status === 'Deferido':
        return <span className="text-green-500"> Deferido </span>;
      case status === 'Indeferido':
        return <span className="text-red-500"> Indeferido </span>;
      case dif === 0 && (status === 'Nenhum' || !status):
        return <span className="text-yellow-500"> Vence hoje</span>;
      case dif < 90 && dif > 0 && (status === 'Nenhum' || !status):
        return <span className="text-yellow-500"> Vence em {dif} dias</span>;
      case dif > 0 && (status === 'Nenhum' || !status):
        return <span className="text-green-500"> Vigente</span>;
      case dif < 0 && (status === 'Nenhum' || !status):
        return <span className="text-red-500"> Vencido </span>;
      case status === 'entregue':
        return <span className="text-green-500"> Entregue </span>;
      case status === 'distratado':
        return <span className="text-red-500"> Distratado </span>;
      case status === 'encerrado':
        return <span className="text-red-500"> Encerrado </span>;
      default:
        return <span className="text-gray-500"> --- </span>;
    }
  };

  const abrirPasta = (pasta: any) => {
    setExpandedPastaId((prevId) => (prevId === pasta._id ? null : pasta._id));
  };

  const editarPasta = (pasta: any) => {
    setPastaSelecionada(pasta);
    setPastaEditar(true);
  };

  const findLinkArquivo = (arquivo: any) => {
    switch (true) {
      case arquivo.oldDescartmed === true && arquivo.arquivoPath !== undefined:
        return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/${arquivo.arquivoPath.replaceAll(
          /\\/g,
          '/'
        )}`;
      case arquivo.LinkAws !== undefined:
        return arquivo.LinkAws as string;
      case arquivo.arquivoPath !== undefined:
        const link = `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivo.arquivoPath.replaceAll(
          /\\/g,
          '/'
        )}`;
        return link;
      default:
        return '#';
    }
  };

  const ajustarCategoria = (categoria: string) => {
    switch (categoria) {
      case 'os':
        return "Ordem Serviço'ᴮ";
      case 'act':
        return 'Atestado de capacidade técnica';
      case 'objeto':
        return 'Proposta readequada';
      case 'arp':
        return 'Ata registros preços';
      default:
        return categoria || '';
    }
  };

  const [editandoArquivo, setEditandoArquivo] = useState<boolean>(false)
  const editarArquivo = (arquivo: any) => {

    setArquivoSelecionado(arquivo);
    setEditandoArquivo(true)
  }


  const apagarPastaArquivo = (data: any, tipo: any) => {
    if (tipo === 'arquivo') {
      setArquivoSelecionado(data);
      setTipoApagado('arquivo');
      setApagar(true);
    } else {
      setTipoApagado('pasta');
      setPastaSelecionada(data);
      setApagar(true);
    }
  };

  const fechar = () => {
    setNovaPasta(false);
    setPastaEditar(false);
    setApagar(false);
    setPastaSelecionada('');
    setEditandoArquivo(false)
  };

  const fecharArquivo = () => {
    setAbrirArquivo(false);
    setArquivoSelecionado('');
  };

  useEffect(() => {
    if (pastaArquivoData) {
      if (filtro === DisplayMiddle.ESCLARECIMENTO) {
        const pastaSelecionada = pastaArquivoData.pastas.filter((i: any) => {
          if (
            (i.categoria === 'Pedido Esclarecimento' ||
              i.categoria === 'Resposta Esclarecimento' ||
              i.categoria === 'Esclarecimento') &&
            i.processoId === processo._id
          ) {
            return i;
          }
        });
        setPastas(pastaSelecionada);
        return;
      }

      if (filtro === DisplayMiddle.IMPUGNAÇÃO) {
        const pastaSelecionada2 = pastaArquivoData.pastas.filter((i: any) => {
          if (
            (i.categoria === 'Pedido Impugnação' ||
              i.categoria === 'Resposta Impugnação' ||
              i.categoria === 'impugnação') &&
            i.processoId === processo._id
          ) {
            return i;
          }
        });
        setPastas(pastaSelecionada2);
        return;
      }

      if (filtro === DisplayMiddle.DILIGENCIA) {
        const pastaSelecionada = pastaArquivoData.pastas.filter((i: any) => {
          if (
            (i.categoria === 'solicitação Diligência' ||
              i.categoria === 'Resposta Diligência' ||
              i.categoria === 'Diligência') &&
            i.processoId === processo._id
          ) {
            return i;
          }
        });
        setPastas(pastaSelecionada);
        return;
      }

      if (filtro === DisplayMiddle.MANDATOS) {
        const pastaSelecionada = pastaArquivoData.pastas.filter((i: any) => {
          if (
            (i.categoria === 'Pedido Mandato.s' ||
              i.categoria === 'Resposta Mandato.s' ||
              i.categoria === 'Mandatos') &&
            i.processoId === processo._id
          ) {
            return i;
          }
        });
        setPastas(pastaSelecionada);
        return;
      }
      if (filtro === DisplayMiddle.CONTRATOS) {
        const pastaSelecionada = pastaArquivoData.pastas.filter((i: any) => {
          if (i.categoria === 'Contrato' && !i.pastaid) {
            return i;
          }
        });

        const findPastaComArquivos = pastaArquivoData.pastas.filter((i: any) => {
          if (i.processoId === processo._id && i.pastaid) {
            return i;
          }
        });
        setArquivosPasta(findPastaComArquivos);

        setPastas(pastaSelecionada);
        return;
      }

      if (filtro === DisplayMiddle.OS) {
        const pastaSelecionada = pastaArquivoData.pastas.filter((i: any) => {
          if (i.categoria === "Ordem Serviço'ᴮ" && !i.pastaid) {
            return i;
          }
        });
        setPastas(pastaSelecionada);
        return;
      }
      if (filtro === DisplayMiddle.EMPENHO) {
        const pastaSelecionada = pastaArquivoData.pastas.filter((i: any) => {
          if (i.categoria === 'Empenho' && !i.pastaid) {
            return i;
          }
        });
        setPastas(pastaSelecionada);
        return;
      }

      if (filtro === DisplayMiddle.PUBLICAÇÃO) {
        const pastaSelecionada = pastaArquivoData.pastas.filter((i: any) => {
          if (
            (i.categoria === 'Publicação Aviso' ||
              i.categoria === 'Licitação' ||
              i.categoria === 'Edital/Anexos' ||
              i.categoria === 'Termo Referência' ||
              i.categoria === 'Recibo Edital' ||
              i.categoria === 'Retificação' ||
              i.categoria === 'Rerratificação' ||
              i.categoria === 'Adiamento' ||
              i.categoria === 'Cancelamento' ||
              i.categoria === 'Revogado' ||
              i.categoria === 'Suspenso' ||
              i.categoria === 'Fracassado' ||
              i.categoria === 'Deserto') &&
            i.processoId === processo._id
          ) {
            return i;
          }
        });
        setPastas(pastaSelecionada);
        return;
      }

      if (filtro === DisplayMiddle.DOCUMENTOS && pastaArquivoData) {
        const findPastaComArquivos = pastaArquivoData.pastas.filter((i: any) => {
          if (i.processoId === processo._id && i.pastaid) {
            return i;
          }
        });
        setArquivosPasta(findPastaComArquivos);

        const findPasta = pastaArquivoData.pastas.filter((i: any) => {
          if (i.processoId === processo._id && !i.pastaid) {
            return i;
          }
        });
        setPastas(findPasta);
      } else {
        const pastaSelecionada = pastaArquivoData.pastas.filter((i: any) => {
          if (i.categoria === filtro && i.processoId === processo._id) {
            return i;
          }
        });
        setPastas(pastaSelecionada);
      }
    }
  }, [pastaArquivoData, filtro]);

  const dowloadFile = (e: any, arquivo: any, fileName: string) => {
    e.preventDefault();

    const nomeValido = `${fileName.replaceAll(/\s/g, '_')}`;

    const fileUrl: any = arquivo.LinkAws;
    // const fileUrl: any = findLinkArquivo(arquivo);

    try {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${nomeValido}`;

          document.body.appendChild(link);

          link.click();

          link.parentNode?.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
    } catch (error) {
      console.error(error);
      toast.error('Erro no dowload!');
      return;
    }
  };

  const hanldeAlterarFiltro = (filtro: DisplayMiddle | string) => {
    changeMiddleDisplay(filtro)
  }


  // Tailwind -----------------------------------------

  // Tailwind -


  // Define column classes for consistent widths
  const colDescricao = 'w-64 min-w-64 max-w-64';
  const colAcao = 'w-20 text-center';
  const colCategoria = 'w-32 text-center break-words';
  const colEmissao = 'w-28 text-center';
  const colVencimento = 'w-28 text-center';
  const colValor = 'w-28 text-center';
  const colStatus = 'w-28 text-center';

  return (
    <div className="w-full mt-1 h-auto bg-gray-60 ">
      {novaPasta && <NovaPastaProcesso fechar={fechar} processo={processo} />}
      {pastaEditar && (
        <NovaPastaProcesso
          fechar={fechar}
          processo={processo}
          pasta={pastaSelecionada}
          editar={true}
        />
      )}
      {apagar && (
        <ApagarPastaArquivoProcesso
          fechar={fechar}
          pasta={pastaSelecionada}
          arquivo={arquivoSelecionado}
          tipo={tipoApagado}
        />
      )}
      {abrirArquivo && (
        <ArquivoModal
          arquivo={arquivoSelecionado}
          fechar={fecharArquivo}
          processo={processo}
        />
      )}

      {
        editandoArquivo &&
        <NovoArquivoProcesso editar={true} arquivo={arquivoSelecionado} fechar={fechar} processo={processo} />
      }





      {filtro !== DisplayMiddle.RESUMO  &&(
        <div className="relative overflow-x-auto bg-white">

          <div className='bg-white'>
            
            <label className='text-xsm '> <span className='font-semibold'>{processo.orgaopublicoLicitante}</span> | <span>{processo.modalidade}</span> | <span></span>{processo.numero
            }</label>
            <hr className="border-gray-200 my-2 " />
          </div>


          <table className="w-full table-fixed text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
              <tr className="py-0">
                <th scope="col" className={colDescricao}>
                  Descrição
                </th>
                <th scope="col" className={colAcao}>
                  Ação
                </th>
                <th scope="col " className={colCategoria}>
                  Categoria
                </th>
                <th scope="col" className={colEmissao}>
                  Emissão
                </th>
                <th scope="col" className={colVencimento}>
                  Vencimento
                </th>
                <th scope="col" className={colValor}>
                  Valor
                </th>
                <th scope="col" className={colStatus}>
                  Status
                </th>
               
              </tr>
            </thead>
            <tbody>
              {filtro === DisplayMiddle.DOCUMENTOS && (
                <CaptacaoArquivosProcesso
                  url={processo.LinkArquivosPncp}
                  processo={processo}
                />
              )}
              {pastas &&
                pastas.map((pasta: any) => (
                  <React.Fragment key={pasta._id}>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td
                        scope="row"
                        className={`${colDescricao} font-medium text-gray-700 whitespace-nowrap dark:text-white`}
                      >
                        {pasta.nomePasta && (
                          <label className="cursor-pointer w-fit">
                            {pasta.nomePasta.length > 60
                              ? `${pasta.nomePasta.replaceAll('_', ' ').slice(0, 60)}...`
                              : pasta.nomePasta.replaceAll('_', ' ')}
                          </label>
                        )}

                        {pasta.nomeDoArquivo && (
                          <>
                            {
                              pasta.mimetype === "application/pdf" || !pasta.mimetype ?

                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={findLinkArquivo(pasta)}
                                  className="cursor-pointer w-fit"
                                >
                                  {pasta.nomeDoArquivo.length > 60
                                    ? `${pasta.nomeDoArquivo.replaceAll('_', ' ').slice(0, 60)}...`
                                    : pasta.nomeDoArquivo.replaceAll('_', ' ')}
                                </a>
                                :
                                <label
                                  className="cursor-pointer w-fit"
                                >
                                  {pasta.nomeDoArquivo.length > 30
                                    ? `${pasta.nomeDoArquivo.replaceAll('_', ' ').slice(0, 30)}...`
                                    : pasta.nomeDoArquivo.replaceAll('_', ' ')}
                                </label>
                            }
                          </>
                        )}
                      </td>

                      <td className={`${colAcao} flex flex-row justify-center w-full gap-1`}>
                        {pasta.nomeDoArquivo ? (
                          <>
                            {
                              pasta.mimetype === "application/pdf" || !pasta.mimetype ?
                                <BsEye
                                  onClick={() => abrirArquivoModal(pasta)}
                                  className={"cursor-pointer opacity-85 hover:opacity-100"}
                                  title="Abrir arquivo"
                                />
                                :
                                <BsEyeSlash
                                  className={"cursor-pointer  hover:opacity-100"}
                                  title="Modelo de arquvo não pode ser aberto pelo portal"
                                />
                            }
                          </>
                        ) : (

                          <FaFolderOpen
                            className={`cursor-pointer opacity-80 hover:opacity-100 ${expandedPastaId === pasta._id ? 'text-blue-500' : ''
                              }`}
                            title="Abrir pasta"
                            onClick={() => abrirPasta(pasta)}
                          />

                        )}

                        {pasta.nomeDoArquivo ? (
                          <>
                            <BsPencil
                              className="cursor-pointer opacity-80 hover:opacity-100"
                              title="Editar"
                              onClick={() => editarArquivo(pasta)}
                            />
                            <BsDownload
                              className="cursor-pointer opacity-80 hover:opacity-100"
                              title="Baixar"
                              onClick={(e) => dowloadFile(e, pasta, pasta.nomeDoArquivo)}
                            /></>
                        ) : (
                          <BsPencil
                            className="cursor-pointer opacity-80 hover:opacity-100"
                            title="Editar"
                            onClick={() => editarPasta(pasta)}
                          />
                        )}

                        {pasta.nomeDoArquivo ? (
                          <BsTrash
                            className="cursor-pointer opacity-80 hover:opacity-100"
                            title="Apagar"
                            onClick={() => apagarPastaArquivo(pasta, 'arquivo')}
                          />
                        ) : (
                          <BsTrash
                            className="cursor-pointer opacity-80 hover:opacity-100"
                            title="Apagar"
                            onClick={() => apagarPastaArquivo(pasta, 'pasta')}
                          />
                        )}
                      </td>

                      <td className={colCategoria}>
                        {ajustarCategoria(pasta.categoria) 
                          ? ajustarCategoria(pasta.categoria).length > 12 
                          ? `${ajustarCategoria(pasta.categoria).toUpperCase().slice(0, 12)}...`:ajustarCategoria(pasta.categoria).toUpperCase() 
                          : ''}
                      </td>
                      <td className={colEmissao}>
                        {pasta.dataInicio
                          ? formatarData(pasta.dataInicio)
                          : 'Sine Die'}
                      </td>
                      <td className={colVencimento}>
                        {pasta.dataVencimento
                          ? formatarData(pasta.dataVencimento)
                          : 'Sine Die'}
                      </td>
                      <td className={colValor}>{converterValor(pasta.valor)}</td>

                      <td className={`${colStatus}  text-black dark:text-gray-300 gap-4`}>
                        {checkStatus(pasta.dataVencimento, pasta.status)}
                      </td>
                      
                    </tr>
                    {expandedPastaId === pasta._id && (
                      <tr>
                        <td colSpan={7} className="bg-gray-50 dark:bg-gray-800">
                          <AbrirPastaProcesso
                            pasta={pasta}
                            processo={processo}
                            arquivosPasta={arquivosPasta}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ProcessosPastas;
