import React, { useEffect, useLayoutEffect, useState } from 'react'
import { getCnpjData } from '../../Utils/cnpjAutocomplete';
import { BsArrowLeft, BsArrowRight, BsCheck, BsPlus, BsSearch, BsX } from 'react-icons/bs';
import LoadingPage from '../LoadingPage';
import AlterarAvatar from '../PerfilComponents/AlterarAvatar';
import { SituaçãoInterna } from '../../Utils/types/PortalTypes';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import DisplayProcessos from '../ProcessosComponents/DisplayProcessos';
import LoadingSmall from '../LoadingSmall';
import { useInView } from 'react-intersection-observer';



const BuscarProcessos = ({ cancelar, selecionarPortal, globalFilter}: any) => {



  const [loadingBusca, setLoadingBusca] = useState<boolean>(false);
  const [processosEncontrados, setProcessosEncontrados] = useState<any>(['']);
  const [textoBusca, setTextoBusca] = useState<string>('');
  const [situaçãoInterna, setSitaçãoInterna] = useState<SituaçãoInterna>(SituaçãoInterna.RESUMO);

  const [pageParam, setPageParam] = useState(1);


    //RQ E MUTATION ----------------------------------
    const  {buscarProcessos} = useReactQueryProcessos();
    const token = localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada');

    const queryClient = useQueryClient()
    
    const {data: processosData, isLoading, refetch} = useQuery({queryFn: ()=>buscarProcessos({pageParam, empresaid: empresaSelecionada as string, token: token as string, body: {
      texto: textoBusca as string, situacaoInterna: situaçãoInterna
    }}), queryKey: ['rqBuscarProcessos'] ,
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false
    });


    useEffect(()=>{
      if(processosData){
        setProcessosEncontrados(processosData.processos)

      }
    },[processosData])
    //RQ E MUTATION ----------------------------------

    const {ref, inView} = useInView();
  
    // //~~MANUTENÇÃO~~//
      useEffect(()=>{
        if(inView){
          setPageParam(pageParam => pageParam + 1)
          refetch();
        }
        
      },[inView])

    if(loadingBusca){
        return(
        <div id="defaultModal" tabIndex={-1} aria-hidden="true" className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">
            
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
                
                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                    <LoadingPage/>
                </div>
            </div>
        </div>
        )
    }

    //Tailwind ----------------------
    const inputField = '"bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"';
    const labelField ="block mb-2 text-sm font-medium text-gray-900 dark:text-white";

    const button = "cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit"
    const closeButton = "bg-red-500 cursor-pointer text-white inline-flex items-center bg-bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:bg-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit"
    
    //Tailwind ----------------------

    const handleBusca =(e: any)=>{
        e.preventDefault();

        const formData = new FormData(e.currentTarget);


        const dadosBusca ={
          text: formData.get("text"),
          situaçãoInterna: formData.get("situacaoInterna"),

        }

        const filteredDados =Object.fromEntries( Object.entries(dadosBusca).filter(([key, value])=> value !== "" &&  value !== null ));

        refetch();
        
        return;

    }


    const handlePagination = () =>{

    }



  return (
    <div>

        <div id="defaultModal"  aria-hidden="true" className="flex flex-col overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  items-center w-full md:inset-0 h-modal md:h-full">


            <div className="relative p-4 w-full max-w-7xl h-full md:h-auto">
                
                <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                

                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                

                <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                       Buscar processo
                    </h3>
    
                    <small> Realiza uma busca completa em todos os processos registrados na empresa</small>
                    <br />
                </div>



               </div>

                    <form onSubmit={handleBusca}>
          

                        <div className="grid gap-4 mb-4 sm:grid-cols-2">
                            
                          

                          <div>
                            <label className={labelField}>Cnpj licicitante, numero, processo ou orgão público licitante</label>
                              <label htmlFor="text" className={`${inputField} flex flex-row max-h-9 `}>
                                <BsSearch className='mt-0 mr-2'/>
                                <input type="text" name="text" id="text" className={`p-0 m-0 focus-visible:border-none border-none focus:ring-0 dark:bg-transparent bg-transparent w-full`} onChange={(e)=> setTextoBusca(e.target.value)}/>
                              </label>
                          </div>
                          
                          
                          <div>
                            <label htmlFor="situacaoInterna" className={`${labelField}`}>Situação Interna </label>

                            <select name="situacaoInterna" id="situacaoInterna" className={`${inputField}`} onChange={(e)=> setSitaçãoInterna(e.target.value as SituaçãoInterna)} >
                              <option className={`${inputField}`} value=""> ... </option>
                                <option className={`${inputField}`} value={SituaçãoInterna.REGISTRAR}>Registrar</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.DIÁRIO}>Diário</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.DESAPROVADO}>Desaprovado </option>
                                <option className={`${inputField}`} value={SituaçãoInterna.APROVADO}>Aprovado</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.FAZENDO}>Fazendo</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.FEITO}>Feito</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.REVOGADA}>Revogada</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.ORÇAMENTO}>Orçamento</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.SESSÕES_DO_DIA} >Sessões do Dia </option>
                                <option className={`${inputField}`} value={SituaçãoInterna.ACOMPANHAMENTO} >Acompanhamanto </option>
                                <option className={`${inputField}`} value={SituaçãoInterna.GANHA}>Ganha</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.ENTREGANDO}>Entregando</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.OCORRÊNCIA}>Ocorrência</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.EXECUÇÃO}>Execução</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.ENCERRADO}>Encerrado</option>
                                <option className={`${inputField}`} value={SituaçãoInterna.PERDIDA}>Perdida</option>
                            </select>
                        </div>
   

                        </div>

                        <div className='flex flex-row w-full justify-between'>
    
                          <button type='submit' className={`${button}`}> <BsSearch className='text-2xl mr-2' />Buscar processo</button>                       
                          <label className={`${closeButton}`} onClick={cancelar}> Cancelar <BsX className='text-2xl ml-2'/></label>
                           
                        </div>

                        <div className=' w-full p-4 min-h-60 max-h-72vv'>
                          <label className={labelField} htmlFor="">Processos encontardos: </label>
                            <div className='w-full h-96 bg-gray-100 dark:bg-gray-600 overflow-y-auto rounded-md autofill:bg-transparent dark:autofill:bg-transparent'>
                              
                              {
                                isLoading ? <LoadingPage/> :
                                processosData ?
                                <div>
                                  <DisplayProcessos selecionarPortal ={selecionarPortal}  processos ={processosEncontrados} globalFilter={'resumo'} busca={true}/>
                      
                                </div>
                                
                                :
                                <></>
                              }
                              
                            </div>

                        </div>

                    </form>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default BuscarProcessos