import React, { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import LoadingPage from '../LoadingPage';
import EmpresaFormEtapaUm from './EmpresaFormEtapaum';


const NovaEmpresa = ({ fecharRegistro, acesso }: any) => {

  console.log('Acesso: ', acesso);

  const [loading, setLoading] = useState<boolean>(false);
  const [sucesso, setSucesso] = useState<boolean>(false);

  // const {cadastrar} = useReactQueryEmpresas()
  const { cadastrar } = useReactQueryUsers()

  //MUTAÇÃO ------------------------------
  const queryClient = useQueryClient();

  const novaEmpresaMutation = useMutation({
    mutationFn: cadastrar,
    onMutate: (config) => {
      setLoading(true);
      // console.log('New empresa mutation iniciada!');
    },
    onSuccess: async (response) => {
      const res = response;

      if (response.status === 1) {
        toast.success(response.msg);
       
        localStorage.setItem('token', response.token);
        localStorage.setItem('empresaSelecionada', response.createdEmpresa._id);
        localStorage.setItem('empresaPadrão', response.createdEmpresa._id);
        window.location.href = '/portal/bem-vindo';
        setLoading(false);
        
        // setSucesso(true)
      }
      if (response.status === 3) {
        setLoading(false);
        toast.error(response.msg);
      }

    },
    onError: async (error) => {
      setLoading(false);
      console.error('Erro: ', error);
      toast.error(error.message);
      ;
    },
    onSettled: async (data) => {
      setLoading(false);
      // console.log('Mutação concluída!: ');
    }
  })

  //MUTAÇÃO ------------------------------

  const handleRegistrarEmpresa = (data: any) => {
    novaEmpresaMutation.mutate(data);
  }

  return (
    <div className='min-h-screen w-full '>

      <div className={sucesso ? 'opacity-50 bg-black' : ''}>
        <EmpresaFormEtapaUm fechar={fecharRegistro} registrar={handleRegistrarEmpresa} acesso={acesso} />
      </div>

      {
        loading && <LoadingPage />
      }

      {
        sucesso &&
        <div className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full">
          <div className="relative p-4 w-full max-w-xl h-full md:h-auto ">
            <div className="relative opacity-100 p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 flex flex-col justify-center items-center gap-5">
              <h3> Empresa registrada com sucesso!</h3>
              <svg className="mx-auto mb-4 text-green-500 w-12 h-12 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
              <Link to={'/'} className="cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit"> Ir para a tela de login <BsArrowRight className='text-2xl ml-2' /></Link>
            </div>
          </div>
        </div>
      }


    </div>
  )
}

export default NovaEmpresa