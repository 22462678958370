export enum DisplayPastas{
    GRID ='grid',
    LIST ='list',
    DETAILS='details'
}

export enum DisplayArquivos{
    GRID ='grid',
    LIST ='list',
    DETAILS='details'
}

export type typePasta={
    _id?: string, 
    EmpresaAfiliada?: string,
    Cnpj?: string,
    NomePasta:  string,
    DataEmissao:     Date|string,
    DataVencimento:  Date|string,
    SineDie:  boolean,
    LinkOEA: string ,
    Valor:  string ,
    Categoria: string,
    CreatedAt?: string, 
    UpdatedAt?: string
}


export type typeCriarPastas ={
    empresaid: string,
    token: string,
    pasta: typePasta
}

export type typeEditarPasta={
    token: string,
    body: {
        pastaid: string,
        empresaid: string,
        pasta: typePasta
    }
}

export type typeApagarPasta ={    
    token: string,
    body:{
        empresaid: string,
        pastaid: string
    }  
}

export enum ModoAbrirArquivo {
    EDITAR= 'editar',
    VISUALIZAR ='visualiar'

}