import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { BsArrowRight, BsX } from 'react-icons/bs'
import { useGetAcessos } from '../../Utils/ReactQuery/getAcessos'
import { IMaskInput } from 'react-imask'
import { toast } from 'react-toastify'
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser'


const SolicitarAcesso = ({ usuario, fechar }: any) => {



    const { buscarEmpresa, enviarSolicitação, assessoriaSolicitação } = useGetAcessos();


    const [cnpj, setCnpj] = useState<string>('');
    const [cnpjDaBusca, setCnpjDaBusca] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false);
    const [solEnviada, setSolEnviada] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('')


    const { data, isLoading, isFetching } = useQuery({
        queryFn: () => buscarEmpresa({ cnpj: cnpj }),
        queryKey: ["rqBuscarEmpresa", cnpjDaBusca]
    });

    const handleBuscarEmpresa = (e: any) => {
        e.preventDefault();
        setCnpjDaBusca(cnpj);
    }

    

    const {criarNovoAcesso} = useReactQueryUsers();
    const novoAcessoMutation = useMutation({
    mutationFn: criarNovoAcesso,
    onMutate: (config) => {
      setLoading(true);
      // console.log('New empresa mutation iniciada!');
    },
    onSuccess: async (response) => {
      const res = response;

      if (response.status === 1) {
        toast.success(response.msg);
       
        // localStorage.setItem('token', response.token);
        // localStorage.setItem('empresaSelecionada', response.createdEmpresa._id);
        // localStorage.setItem('empresaPadrão', response.createdEmpresa._id);
        window.location.href = '/';
        setLoading(false);
        
        // setSucesso(true)
      }

      if(response.status === 4){
        toast.success(response.msg);
        setLoading(false)
      }
      if (response.status === 3) {
        setLoading(false);
        toast.error(response.msg);
      }

    },
    onError: async (error) => {
      setLoading(false);
      console.error('Erro: ', error);
      toast.error(error.message);
      ;
    },
    onSettled: async (data) => {
      setLoading(false);
      // console.log('Mutação concluída!: ');
    }
  })

    
    const handleEnviarSolictação =()=>{
        const data ={
            Cnpj: cnpj,
            CpfCnpj:  usuario.cpfCnpj
        }
        novoAcessoMutation.mutate({action: 'solicitar', CpfCnpj: usuario.cpfCnpj, Cnpj: cnpj})
    }


    return (
        <div className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full ">



            <div className="relative p-4 w-full max-w-xl h-full md:h-auto ">
                

                <div className="relative opacity-100 p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5 flex flex-col justify-center items-center gap-5">
                    <div className='flex flex-row justify-between'>
                        <h3> Este usuário já possuí conta, informe o Cnpj de uma empresa existente para solictar acesso como colaborador.</h3>
                        <label className='w-fit h-fit cursor-pointer p-0' onClick={fechar}> <BsX className='text-xl'/> </label>
                    </div>





                    <div>
                        <form className="w-full max-w-md flex flex-col items-center">
                            <label htmlFor="" className="text-center mb-2">Informe o CNPJ da empresa para solicitar o acesso.</label>

                            <div className="w-full mt-2 flex flex-col gap-4 items-center">
                                <IMaskInput
                                    mask="00.000.000/0000-00"
                                    type="text"
                                    name="CnpjEmpresa"
                                    id="floating_cnpjEmpresa"
                                    className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                    placeholder="Buscar empresa"
                                    required
                                    onAccept={(value, mask) => setCnpj(value)}
                                />
                                <button
                                    onClick={handleBuscarEmpresa}
                                    className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                >
                                    Buscar
                                </button>
                            </div>
                        </form>

                        <div className="w-full max-w-md">
                            {
                                data && data.empresa ?
                                    <div className='flex flex-col gap-4 bg-gray-100 shadow-lg dark:bg-gray-600 rounded-md text-xl p-4 items-center'>
                                        <h3 className='text-center'>Informações da empresa</h3>
                                        <div className='flex flex-col items-center'>
                                            <div className='w-16 h-16 rounded-full bg-green-500 text-white flex items-center justify-center text-2xl'>
                                                {
                                                    data.empresa.LogoEmpresa ?
                                                        <img className='w-16 h-16 rounded-full object-cover' src={data.empresa.LogoEmpresa} alt="Empresa" />
                                                        :
                                                        <span>{data.empresa.NomeEmpresa.slice(0, 2)}</span>
                                                }
                                            </div>
                                            <div className='flex flex-col items-center mt-4'>
                                                <label>Nome: {data.empresa.NomeEmpresa}</label>
                                                <label>CNPJ: {data.empresa.CnpjEmpresa}</label>
                                            </div>
                                        </div>
                                        {
                                            solEnviada ?
                                                <div className='font-bold text-center'>
                                                    Solicitação enviada com sucesso, aguarde a resposta.
                                                </div>
                                                :
                                                error ?
                                                    <div className='flex flex-col gap-2 text-center'>
                                                        A solicitação não foi enviada!
                                                        <label className='text-red-500'>{errorMsg}</label>
                                                        <button
                                                            onClick={() => setError(false)}
                                                            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                                        >
                                                            Tentar novamente
                                                        </button>
                                                    </div>
                                                    :
                                                    <button
                                                        
                                                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                                        disabled={loading}
                                                        onClick={handleEnviarSolictação}
                                                    >
                                                        {loading ? `Aguarde ...` : 'Enviar solicitação'}
                                                    </button>
                                        }
                                    </div>
                                    :
                                    data && data.status === 3 && cnpj ?
                                        <div className='text-center'>
                                            <label>Empresa não encontrada, por favor verifique o CNPJ informado!</label>
                                        </div>
                                        :
                                        null
                            }
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default SolicitarAcesso