import React, { useEffect, useRef, useState } from 'react';
import { BsArrowRight, BsEye, BsEyeSlash, BsSearch, BsX } from 'react-icons/bs';
import { IMaskInput } from 'react-imask';
import { useReactQueryUsers } from '../../Utils/ReactQuery/getUser';
import { useQuery } from '@tanstack/react-query';
import { checkCpfCnpj } from '../../Utils/checkCpfCnpj';
import { NivelHierarquicoRegistro, RegistroEmpresa } from '../../Utils/types/cadastroTypes';
import TermosRegistro from './TermosRegistro';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';

const ColaboradorFormEtapaUm = ({ registrar, fechar, empresas }: any) => {
    const [cpfCnpj, setCpfCnpj] = useState<string>('');
    const [cpfCnpjVerify, setCpfCnpjVerify] = useState<Boolean | any>(true);
    const [passWordError, setPasswordError] = useState<Boolean>(false);
    const [checkPassword, setCheckPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [repetirSenha, setRepetirSenha] = useState<string>('');
    const [senha, setSenha] = useState<string>('')
    const [passwordErrorrMesg, setPasswordErrorMsg] = useState<string>('');

    const [cnpjBuscar, setCnpjBuscar] = useState<string>('');
    const [empresaAfiliada, setEmpresaAfiliada] = useState<any>(null);
    const [buscarEmpresaid, setBuscarEmpresaId] = useState<string>('')
    const [usuariosError, setUsuariosError] = useState<boolean>(true);
    const [foneEmpresa, setFoneEmpresa] = useState<string>('')

    const [condiçõesLegais, setCondiçõesLegais] = useState<boolean>(false);
    const [politicaPrivacidade, setPoliticaPrivacidade] = useState<boolean>(false);
    const [termoAceito, setTermoAceito] = useState<boolean>(false)


    const { getOrganogramaInfo } = useReactQueryUsers();
    const { data: organogramaData, refetch } = useQuery({
        queryFn: () => getOrganogramaInfo(buscarEmpresaid || ''),
        queryKey: ["rqOrganogramaUsers", buscarEmpresaid],

    });

    const usuariosRef = useRef<HTMLInputElement | any>(null)

    // //~~ANUUTENÇÃO~~//
    // if(organogramaData){
    //     console.log('Organograma data: ', organogramaData)
    // }

    // useEffect(()=>{
    //     console.log('Cnpj buscado: ', cnpjBuscar)
    // },[cnpjBuscar])
    // //~~ANUUTENÇÃO~~//


    const checkPass = (e: any) => {
        e.preventDefault();
        if (senha.length > 0 && repetirSenha.length < 8) {
            setPasswordError(true);
            setPasswordErrorMsg('A senha deve conter mais de 8 dígtos');
            return;
        }

        if (repetirSenha.length >= 1 && repetirSenha !== senha) {
            setPasswordError(true);
            setPasswordErrorMsg('As senhas digitadas não coincidem!');
            return;
        }
        setPasswordError(false);
    };


    const handleInputChange = () => {
        setCnpjBuscar(cnpjBuscar);

        if (cnpjBuscar.length === 18 && checkCpfCnpj(cnpjBuscar)) {
            try {
                const empresaTemp = empresas.find((i: any) => i.CnpjEmpresa === cnpjBuscar);
                if (empresaTemp) {
                    setEmpresaAfiliada(empresaTemp);
                    setBuscarEmpresaId(empresaTemp._id)
                    setUsuariosError(false)
                } else {
                    setEmpresaAfiliada(null);
                    setUsuariosError(true)
                }
            } catch (error) {
                console.error("Erro ao buscar empresa:", error);
                setEmpresaAfiliada(null);

                setUsuariosError(true)

            }
        } else {
            setEmpresaAfiliada(null); // Limpa caso o valor não seja completo
        }
    };

    const handleEtapa = (e: any) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);

        const dadosEtapa = {
            Nome: formData.get('Nome'),
            Email: formData.get('Email'),
            Senha: formData.get('Senha'),
            CpfCnpj: formData.get('CpfCnpj'),
            Acesso: 'colab',
            Cadastro: 'Colaborador',
            CargoFuncao: formData.get('CargoFuncao'),
            NivelHierarquico: indexNivelHierarquico(formData.get('NivelHierarquico') as NivelHierarquicoRegistro),
            SuperiorImediato: formData.get('SuperiorImediato'),
            Afiliação: empresaAfiliada ? empresaAfiliada.CnpjEmpresa : '',
            Telefone: formData.get('Telefone')
        };

        registrar(dadosEtapa);
    };

    const indexNivelHierarquico = (hierarquia: NivelHierarquicoRegistro) => {
        switch (hierarquia) {
            case NivelHierarquicoRegistro.ASSESSOR:
                return { tipo: 'Assessor', nivel: 1 };
            case NivelHierarquicoRegistro.PRESIDENTE:
                return { tipo: 'Presidente', nivel: 2 };
            case NivelHierarquicoRegistro.VICE_PRESIDENTE:
                return { tipo: 'Vice-Presidente', nivel: 3 };
            case NivelHierarquicoRegistro.DIRETOR:
                return { tipo: 'Diretor', nivel: 4 };
            case NivelHierarquicoRegistro.GERENTE:
                return { tipo: 'Gerente', nivel: 5 };
            case NivelHierarquicoRegistro.SUPERVISOR:
                return { tipo: 'Supervisor', nivel: 6 };
            case NivelHierarquicoRegistro.COODERNADOR:
                return { tipo: 'Coordenador', nivel: 7 };
            case NivelHierarquicoRegistro.TÉCNICO:
                return { tipo: 'Técnico', nivel: 8 };
            case NivelHierarquicoRegistro.ANALISTA:
                return { tipo: 'Analista', nivel: 9 };
            case NivelHierarquicoRegistro.ASSISTENTE:
                return { tipo: 'Assistente', nivel: 10 };
            case NivelHierarquicoRegistro.AUXILIAR:
                return { tipo: 'Auxiliar', nivel: 11 };
            case NivelHierarquicoRegistro.ESTAGIÁRIO:
                return { tipo: 'Estagiário', nivel: 12 };
            case NivelHierarquicoRegistro.TRAINEE:
                return { tipo: 'Trainee', nivel: 13 };
            case NivelHierarquicoRegistro.JOVEM_APRENDIZ:
                return { tipo: 'Jovem Aprendiz', nivel: 14 };
            default:
                return { tipo: '', nivel: 0 };
        }
    };


    const fecharTermoModal = () => {
        setPoliticaPrivacidade(false);
        setCondiçõesLegais(false);
    }



    return (
        <div className="flex flex-col md:flex-row bg-gradient-to-br from-slate-50 to-white h-screen overflow-hidden">
            {politicaPrivacidade && (
                <TermosRegistro
                    showTermsModal={false}
                    showPrivacyModal={true}
                    fechar={fecharTermoModal}
                />
            )}

            {condiçõesLegais && (
                <TermosRegistro
                    showTermsModal={true}
                    showPrivacyModal={false}
                    fechar={fecharTermoModal}
                />
            )}

            <div className="bg-gradient-to-br from-slate-50 to-white dark:bg-gray-900 w-full flex justify-center items-center overflow-auto">
                <div className="relative bg-white flex flex-col lg:flex-row h-full w-full">
                    {/* Metade Esquerda - Imagem */}
                    <div className="w-1/2 hidden lg:flex relative">
                        <img
                            src="./design.png"
                            alt="Descrição da imagem"
                            className="w-full h-full object-cover rounded-l-lg"
                        />

                        {
                            empresaAfiliada &&
                            <div className='absolute top-[25%] left-[25%]  bg-white rounded-lg flex flex-col gap-2 justify-center items-center p-5'>

                                <h3 >Enviar solicitação de cadastro na emrpesa:</h3>

                                <div className='w-24 h-24 flex justify-center items-center rounded-full my-5 object-contain border '>
                                    {
                                        empresaAfiliada.LogoEmpresa ?
                                            <img src={empresaAfiliada.LogoEmpresa} className='w-full h-full' /> :
                                            <span className='rounded-full border-1 text-3xl'> {empresaAfiliada.NomeEmpresa.slice(0, 1)}</span>
                                    }
                                </div>

                                <label> {empresaAfiliada.NomeEmpresa}</label>
                                <label> {empresaAfiliada.CnpjEmpresa}</label>

                            </div>
                        }


                    </div>

                    {/* Metade Direita - Formulário */}
                    <div className="w-full lg:w-1/2 p-8 flex flex-col justify-center overflow-auto">
                        <div className="flex mb-4 items-center">
                            <button
                                type="button"
                                onClick={fechar}
                                className="text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
                            >
                                <IoIosArrowRoundBack className="w-6 h-6" />
                            </button>
                            <h3 className="text-gray-500 dark:text-gray-400 text-xl pl-36 font-semibold">
                                Cadastrar como Colaborador
                            </h3>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-md pl-6 font-medium text-orange-500 dark:text-gray-200">
                                Insira os dados da empresa solicitada
                            </h3>
                            <hr className="mx-4 mt-2 border-gray-300 dark:border-gray-600" />
                        </div>

                        <form onSubmit={handleEtapa} className="space-y-6">

                            {/* Buscar CNPJ */}
                            <div className="flex flex-col  relative mb-4">
                                {/* Container para o rótulo e a mensagem de erro */}
                                <div className="flex items-center ">

                                    {usuariosError && cnpjBuscar.length === 18 && (
                                        <p id="buscar-cnpj-error" className="text-xs text-orange-500 ml-2">
                                            CNPJ não encontrado!
                                        </p>
                                    )}
                                </div>
                                <IMaskInput
                                    mask="00.000.000/0000-00"
                                    onAccept={(value) => setCnpjBuscar(value)}
                                    onBlur={handleInputChange}
                                    className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pl-4 pr-4 py-2.5"
                                    placeholder="Digite o CNPJ da empresa que você é colaborador"
                                    id="buscarCNPJ"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <h3 className="text-md pl-6 font-medium text-orange-500 dark:text-orange-500">
                                    Insira os dados do colaborador
                                </h3>
                                <hr className="mx-4 mt-2 border-gray-300 dark:border-gray-600" />
                            </div>


                            <div className="flex flex-col md:flex-row md:gap-4">

                                <div className="flex flex-col flex-1 relative mb-4 ">
                                    {/* Container para o rótulo e a mensagem de erro */}
                                    <div className="flex items-center absolute top-[100%]">

                                        {cpfCnpj && cpfCnpj.length > 2 && !cpfCnpjVerify && (
                                            <p id="cpf-cnpj-error" className="text-sm text-red-500 ml-2 ">
                                                *CPF/CNPJ Inválido!
                                            </p>
                                        )}
                                    </div>
                                    <IMaskInput
                                        mask={[
                                            { mask: '000.000.000-00', maxLength: 11 }, // CPF
                                            { mask: '00.000.000/0000-00', maxLength: 14 } // CNPJ
                                        ]}
                                        type="text"
                                        name="CpfCnpj"
                                        className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pl-4 pr-4 py-2.5"
                                        placeholder="Digite seu CPF ou CNPJ"
                                        required
                                        value={cpfCnpj}
                                        onAccept={(value) => setCpfCnpj(value)}
                                        onBlur={() => setCpfCnpjVerify(checkCpfCnpj(cpfCnpj))}
                                    />

                                </div>


                                <div className="flex flex-col flex-1 relative mb-4">
                                    <input
                                        type="text"
                                        name="Nome"
                                        id="Nome"
                                        aria-describedby={/* nomeError ? "nome-error" : */ undefined}
                                        className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pl-4 pr-4 py-2.5"
                                        placeholder="Digite o nome do colaborador"
                                        required
                                    />
                                </div>
                            </div>


                            {/* Telefone e E-mail */}
                            <div className="flex md:flex-row md:gap-4">

                                {/* E-mail */}
                                <div className="flex flex-col flex-1 relative mb-4">
                                    {/* Container para o rótulo e a mensagem de erro */}

                                    <input
                                        type="email"
                                        name="Email"
                                        id="Email"
                                        aria-describedby={/* emailError ? "email-error" : */ undefined}
                                        className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pl-4 pr-4 py-2.5"
                                        placeholder="Digite o email do colaborador"
                                        required

                                    />
                                </div>


                                <div className="flex flex-col flex-1 relative mb-4">
                                    <IMaskInput
                                        mask="(00) 0000-0000"
                                        placeholder="Digite o telefone do colaborador"
                                        type="text"
                                        name="Telefone"
                                        id="Telefone"
                                        onAccept={(value) => setFoneEmpresa(value)}
                                        value={foneEmpresa}
                                        className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pl-4 pr-4 py-2.5"
                                    />
                                </div>

                            </div>

                            {/* Senha e Confirmar Senha */}
                            <div className="flex flex-col md:flex-row md:gap-4">
                                {/* Senha */}
                                <div className="flex flex-col flex-1 relative mb-4">
                                    {/* Container para o rótulo e a mensagem de erro */}
                                    <div className="flex items-center absolute top-[100%]">

                                        {passWordError && (
                                            <p id="senha-error" className="text-xs text-orange-500 ml-2">
                                                {passwordErrorrMesg}
                                            </p>
                                        )}
                                    </div>
                                    <div className="relative">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            name="Senha"
                                            id="Senha"
                                            className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pr-10 pl-4 py-2.5"
                                            placeholder="Digite sua senha"
                                            required
                                            onBlur={checkPass}
                                            onChange={(e) => setSenha(e.target.value)}
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                            onClick={() => setShowPassword((current) => !current)}
                                        >
                                            {showPassword ? (
                                                <BsEyeSlash className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                            ) : (
                                                <BsEye className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                            )}
                                        </div>
                                    </div>

                                </div>

                                {/* Confirmar Senha */}
                                <div className="flex flex-col flex-1 relative mb-4">
                             
                                    <div className="relative">
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name="repetirSenha"
                                            id="repetirSenha"
                                            aria-describedby={passWordError ? 'confirmar-senha-error' : undefined}
                                            className="border border-gray-300 text-gray-500 text-sm rounded-2xl placeholder:text-gray-400 focus:ring-green-300 focus:border-green-300 block w-full pr-10 pl-4 py-2.5"
                                            placeholder="Confirme sua senha"
                                            required
                                            onBlur={checkPass}
                                            onChange={(e) => setRepetirSenha(e.target.value)}
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                            onClick={() => setShowConfirmPassword((current) => !current)}
                                        >
                                            {showConfirmPassword ? (
                                                <BsEyeSlash className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                            ) : (
                                                <BsEye className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                                            )}
                                        </div>
                                    </div>
                             
                                </div>
                            </div>

                            <hr className="mx-4 mt-2 border-gray-300 dark:border-gray-600" />

                            {/* Termos e Condições */}
                            <div className="flex justify-between items-center pl-4">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="terms"
                                        id="terms"
                                        className="mr-2 h-4 w-4 text-green-500 bg-transparent border-gray-300 rounded focus:ring-green-300"
                                        onChange={(e) => setTermoAceito(e.target.checked)}
                                    />

                                    <label htmlFor="terms" className="text-xs font-medium text-gray-500 dark:text-gray-300">
                                        Li e aceito as{' '}
                                        <span
                                            className="underline text-orange-400 cursor-pointer opacity-80 transition-opacity hover:opacity-100"
                                            onClick={() => setCondiçõesLegais(true)}
                                        >
                                            Condições Legais
                                        </span>{' '}
                                        e a{' '}
                                        <span
                                            className="underline text-orange-400 cursor-pointer opacity-80 transition-opacity hover:opacity-100"
                                            onClick={() => setPoliticaPrivacidade(true)}
                                        >
                                            Política de Privacidade
                                        </span>{' '}
                                        do Portal GLC
                                    </label>
                                </div>
                                <div className="flex justify-end pr-10">
                                    <button type="submit" className="confirmButton flex items-center">
                                        Enviar solicitaão <BsArrowRight className="text-xl ml-2" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );




};

export default ColaboradorFormEtapaUm;
