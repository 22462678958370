import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { useUserContext } from '../../Context/useUserContext';
import { usePortaisUtils } from '../../Utils/ReactQuery/getPortais';
import { subTypePortal } from '../../Utils/types/portaisTypes';
import LoadingPage from '../LoadingPage';
import NovoPortal from '../PortaisComponents/NovoPortal';
import PortalObj from '../PortaisComponents/PortalObj';

const PortalPortais = ({ empresas, portalDefinido, portalDoProcesso }: any) => {
  const { getPortais } = usePortaisUtils();
  const [userContext] = useUserContext();
  const [empresaSelecionada, setEmpresaSelecionada] = useState<any>(localStorage.getItem('empresaSelecionada') || null);

  useLayoutEffect(() => {
    setEmpresaSelecionada(localStorage.getItem('empresaSelecionada'))
  }, [empresaSelecionada]);

  const { data: portaisData, isLoading } = useQuery({
    queryKey: ["rqPortais", userContext],
    queryFn: () => getPortais({empresaid: empresaSelecionada as string, tipo: 'individual'}),
    staleTime: Infinity
  });

  const [portalSelecionado, setPortalSelecionado] = useState<subTypePortal | null>();
  const [criarPortal, setCriarPortal] = useState<boolean>(false);

  useEffect(() => {
    if (portalDefinido) {
      setPortalSelecionado(portalDefinido)
    }
  }, [portalDefinido]);

  const changePortal = async (portalid: string) => {
    if (portaisData) {
      const portal = await portaisData.portais.find((i: any) => i._id === portalid);
      setPortalSelecionado(portal as subTypePortal);
    }
  };

  const fechar = (e: any) => {
    e.preventDefault();
    setCriarPortal(false);
    setPortalSelecionado(null);
    portalDoProcesso('');
  };

  const findLogoPortal = (portalSelecionado: any) => {
    try {
      if (portalSelecionado.length <= 28) {
        return `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/logoPortais/${portalSelecionado}`;
      } else {
        return portalSelecionado;
      }
    } catch (error) {
      return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT26JBePenirPP31FfiGWn4yOmLn1bMsLgHGA&s';
    }
  };

  const findLogoPortalDescartmed = (portalSelecionado: any)=>{
    try {
      return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/logoPortais/${portalSelecionado}`
      
    } catch(error) {
      return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT26JBePenirPP31FfiGWn4yOmLn1bMsLgHGA&s';

    }
  }

  if (isLoading) {
    return <LoadingPage />;
  }


  return (
    <div className="border-1 border-gray-300 bg-white border-solid h-fit w-full rounded-lg dark:bg-gray-800 p-1 -mt-5 max-h-screen overflow-y-hidden">
      <div className="relative overflow-x-auto rounded-lg dark:bg-gray-800 p-1" >
  
                <div className="flex flex-row gap-3 overflow-y-hidden overflow-x-auto px-6 py-1 rounded-md mt-2 ">


                  {portaisData.portais && portaisData.portais.map((portal: any) => (
                    <div key={portal._id} onClick={() => setPortalSelecionado(portal)}>
                      <div className="cursor-pointer hover:scale-110 transition-transform w-8 h-8 rounded-full">

                      { 
                        portal.EmpresaPortal === "65aace13036becbc9a90ab6c" ?
                        <img src={findLogoPortalDescartmed(portal.LogoPortal)} alt="Portal" className="w-8 h-8 rounded-full" />
                        :
                        <img src={findLogoPortal(portal.LogoPortal)} alt="Portal" className="w-8 h-8 rounded-full" />
                      }

                      </div>
                    </div>
                  ))}


                  <div
                    className="flex justify-center items-center w-8 h-8 rounded-full border-2 border-dashed border-gray-500 cursor-pointer hover:scale-110 transition-transform"
                    onClick={() => setCriarPortal(current => !current)}
                  >
                    <BsPlus className="text-gray-700" />
                  </div>
                </div>

      </div>

      {criarPortal && (
        <NovoPortal fechar={fechar} empresa={empresaSelecionada ? empresaSelecionada : userContext.EmpresaPadrão} />
      )}

      {portalSelecionado && (
        <div id="crud-modal" aria-hidden="true" className="fixed inset-0 z-50 flex justify-center items-center overflow-y-hidden overflow-x-hidden h-full max-h-full">
          <div className="relative p-4 w-full max-w-5xl max-h-screen overflow-y-hidden">
            <PortalObj portalSelecionado={portalSelecionado} criarPortal={criarPortal} fechar={fechar} />
          </div>
        </div>
      )}
    </div>
  );
}

export default PortalPortais;
