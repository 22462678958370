 export type typeCadastroState={
    empresaComplete: number,
    userComplete : number,
    pagamentoComplete: number,
    Empresa_Adm?: boolean | string,
    Empresa_Solicitações?: boolean | string,
    Empresa_Visualizar?: boolean | string,
    Empresa_PastasArquivos?: boolean | string,

}

export type TempresaData={
_id               ?: string,
CnpjEmpresa        : string,    
NomeEmpresa        : string,
Departamentos      : string,
Cargos             : string,
RazãoSocial        ?: string,
NomeFantasia       ?: string,
LogoEmpresa        ?: string,
Nire               ?: string,
NaturezaJuridica   ?: string,
MEI                ?: boolean,
PorteEmpresa       ?: string,
AtividadeEconômicaP?: string,
AtividadeEconômicaS?: string,
CapitalSocial      ?: string,
SituaçãoFinanceira ?: 'Positivo'| 'Negativo',
InscriçãoMunicipal ?: string,
InscriçãoEstadual  ?: string,
Site               ?: string,
Cep                ?: string,
Fone               ?: string,
Complemento        ?: string,
Endereço           ?: string,
DataAbertura       ?: string,
}

type TusuarioData  = {
    Email          :  string,
    Senha          :  string,
    Nome           :  string,
    Foto:             string,
    CpfCnpj        :  string,
    Inativado      ?:  string,
    Departamento   :  string,
    Cargo          :  string,
    Cadastro       :  'Empresa' | 'Colaborador',
}

export type TRegistarEmpresa = TempresaData & TusuarioData


// export type TRegistroEmpresa ={

// }

export enum RegistroEmpresa{
    PRESIDENTE = 'Presidente',
    VICEPRESIDENTE ='Vice Presidente',
    GERAL                      = "Geral",
    ADMINISTRATIVO             = "Administrativo",
    ATENDIMENTO                = "Atendimento",
    FINANCEIRO                 = "Financeiro",
    CONTAS_Á_RECEBER           = "Contas á Receber",
    CONTAR_Á_PAGAR             = "Contar á Pagar",
    CONCILIAÇÃO_BANCÁRIA       = "Conciliação Bancária",
    COBRANÇA                   = "Cobrança",
    FATURAMENTO                = "Faturamento",
    COMERCIAL                  = "Comercial ",
    COMPRAS                    = "Compras",
    VENDAS                     = "Vendas",
    MARKETING                  = "Marketing",
    PROPAGANDA                 = "Propaganda",
    ALMOXARIFADO               = "Almoxarifado",
    LICITAÇÕES_E_CONTRATOS     = "Licitações e Contratos ",
    LICITAÇÕES                 = "Licitações ",
    CONTRATOS                  = "Contratos",
    CAPTAÇÃO                   = "Captação",
    PROPOSTA                   = "Proposta",
    HABILITAÇÃO                = "Habilitação",
    REGISTRO                   = "Registro",
    RECURSOS_ADMINISTRATIVO    = "Recursos Administrativo",
    OPERADOR                   = "Operador",
    JURÍDICO_E_COMPLIANCE      = "Jurídico e Compliance",
    JURÍDICO                   = "Jurídico",
    COMPLIANCE                 = "Compliance",
    CONTÁBIL                   = "Contábil ",
    FISCAL                     = "Fiscal",
    AUDITORIA                  = "Auditoria",
    RECEITAS                   = "Receitas",
    REPRESENTAÇÃO              = "Representação",
    SEGURO                     = "Seguro",
    CONTROLLER                 = "Controller",
    MANUTENÇÃO                 = "Manutenção ",
    PRODUÇÃO                   = "Produção",
    SINISTRO                   = "Sinistro",
    QUALIDADE                  = "Qualidade",
    SEGURANÇA                  = "Segurança",
    DESENVOLVIMENTO            = "Desenvolvimento",
    PESQUISA                   = "Pesquisa",
    TECNOLOGIA_DA_INFORMAÇÃO   = "Tecnologia da Informação",
    RECURSOS_HUMANOS           = "Recursos Humanos",
    RECRUTAMENTO               = "Recrutamento",
    TREINAMENTO                = "Treinamento",
    OPERAÇÕES                  = "Operações",
    LOGÍSTICA                  = "Logística",
    GESTÃO_DE_PESSOAS          = "Gestão de Pessoas",
    CREDENCIAMENTO             = "Credenciamento",
    EXPEDIÇÃO                  = "Expedição",
    CONSELHO                   = "Conselho",
    ORÇAMENTISTA               = "Orçamentista",
    RELAÇÕES                   = "Relações",
    CUSTOS                     = "Custos",
    GESTÃO                     = "Gestão",
    PROJETOS                   = "Projetos",
    ESTOQUE                    = "Estoque",
    ENTREGA                    = "Entrega ",
    PATRIMÔNIO                 = "Patrimônio",
    CREDITO                    = "Credito",
    TESOURARIA                 = "Tesouraria",
    INOVAÇÃO                   = "Inovação",
    ARQUIVOS                   = "Arquivos",
    PRODUTOS                   = "Produtos",
    TÉCNICO                    = "Técnico",
    PROTOCOLO                  = "Protocolo",
    IMPRENSA                   = "Imprensa",
    AGENDAMENTO                = "Agendamento"
}


export enum NivelHierarquicoRegistro {
    PRESIDENTE         ='Presidente',
    VICE_PRESIDENTE    ='Vice_Presidente',
    DIRETOR            ='Diretor',
    GERENTE            ='Gerente',
    SUPERVISOR         ='Supervisor',
    COODERNADOR        ='Coodernador',
    TÉCNICO            ='Técnico',
    ANALISTA           ='Analista',
    ASSISTENTE         ='Assistente',
    AUXILIAR           ='Auxiliar',
    ESTAGIÁRIO         ='Estagiário',
    TRAINEE            ='Trainee',
    JOVEM_APRENDIZ     ='Jovem_Aprendiz',
    ASSESSOR           ='Assessor', 

}
