import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useAdminUtils } from '../../Utils/ReactQuery/getAdminUtils';
import LoadingPage from '../LoadingPage';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';



const AlterandoPerm = ({usuario, close}: any) => {

    const {configUsuario} = useAdminUtils();

    const [loading, setLoading] = useState<Boolean>(false);

    const{selecionarEmpresa} = useReactQueryEmpresas();
    const empresaPadrão = localStorage.getItem('empresaPadrão');
    const token = localStorage.getItem('token');

    const {data: empresaData, isLoading: empresaIsLoading} = useQuery({
      queryFn: ()=> selecionarEmpresa({empresaid: empresaPadrão as string, token: token as string}),
      queryKey: ['rqEmpresaInfo']
    })


    //--- Mutaação ---------------------
    const queryClient = useQueryClient();
    const configUserMutation = useMutation({
        mutationFn: configUsuario,
        onMutate: async(config)=>{
            // console.log('Mutação iniciada!');
            setLoading(true);
            
        },
        onSuccess: async(response)=>{
            // console.log('Mutação bem sucedida! ', response);
            queryClient.invalidateQueries({queryKey: ["rqEmpresasAdm"]})
            queryClient.invalidateQueries({queryKey: ["rqListaUsuarios"]})
            queryClient.fetchQuery({queryKey: ["rqEmpresasAdm"]})
            queryClient.fetchQuery({queryKey: ["rqListaUsuarios"]})
            queryClient.refetchQueries({queryKey: ["rqEmpresasAdm"]})
            queryClient.refetchQueries({queryKey: ["rqListaUsuarios"]})

            if(response.status === 1){
                toast.success('Usuário alterado com sucsso!');
                return;
            }else if(response.status === 3){
                toast.error(response.msg);
                return;
            }

        },
        onSettled: async(response)=>{
            // console.log('Mutação concluída!');
            setLoading(false);
            close();
        }

    })


    // ---------------------------------


    const handleConfiUser =(e: any)=> {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const Permissão = formData.get('Permissão');

        const token = localStorage.getItem('token');
        configUserMutation.mutate({token:token as string ,data:{
            action: 'Permissão',
            empresaid: empresaData.empresa._id,
            useridAlterado: usuario._id,
            Permissão: Permissão as string
        }})

        return;
    }

    if(loading){
        return(
            <div  className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex flex-col justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                
                <div className="relative p-4 w-full max-w-2xl max-h-full ">
                    
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            {<LoadingPage/>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

  return (
    
    <div  className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex flex-col justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                
                <div className="relative p-4 w-full max-w-2xl max-h-full ">
                    
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Alterando permissão
                            </h3>

                            <button onClick={close} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>

                        <div className="p-4 md:p-5 space-y-4">
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Alterando as permissões do usuário, esta alteração configura se o usuário selecionado poderé visualizar ou alterar arquivos e pastas da empresa.
   
                            </p>
                            <h3 className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Alterando a permissão de acesso do usuário: <span>{usuario.Nome} </span>  
                            </h3>

                            <form className='flex flex-col gap-10' onSubmit={handleConfiUser}>
                                <select name="Permissão" className='w-fit rounded-lg dark:text-gray-400 p-2 m-0 dark:bg-gray-700 text-black bg-gray-50 cursor-pointer'>
                                    <option disabled ={usuario.Permissão ==='Administrador' ? true : false} value="Administrador">Administrador{usuario.Permissão ==='Administrador' && '(Atual)' } </option>
                                    <option disabled ={usuario.Permissão === 'Visualizar' ? true : false} value="Visualizar">Visualizar      {usuario.Permissão === 'Visualizar' && '(Atual)'} </option>
                                    <option disabled ={usuario.Permissão === 'Editar' ? true : false} value="Editar">Editar              {usuario.Permissão === 'Editar' && '(Atual)'} </option>
                                </select>

                                <button type ='submit' className='confirmButton w-fit '> Alterar permissão </button>

                            </form>
                            
                        </div>

                       
                    </div>
                </div>
            </div>

  )
}

export default AlterandoPerm