import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { cnpj } from 'cpf-cnpj-validator'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BsPencil, BsPlus, BsX } from 'react-icons/bs'
import { IMaskInput } from 'react-imask'
import { toast } from 'react-toastify'
import { checkCpfCnpj } from '../../Utils/checkCpfCnpj'
import { formatarData } from '../../Utils/formatarData'
import { usePastasUtils } from '../../Utils/ReactQuery/getPastasUtils'
import { typePasta } from '../../Utils/types/pastasTypes'
import LoadingPage from '../LoadingPage'
import AlertaAssinatura from '../AlertaAssinatura'
import { UserContext } from '../../Context/UserContext'
import { useUserContext } from '../../Context/useUserContext'
import { CategoriasPadrão, TCategoriaPastas } from '../../Utils/DevUtils/CategoriasFake'
import PastasProcessoCatgorias from '../ProcessosComponents/PastasProcessoCatgorias'
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas'



const NovaPasta = ({ fechar, e }: any) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [empresaError, setEmpresaError] = useState<boolean>(false)


    const [planos, setPlanos] = useState<boolean>(false)

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            fechar()
        }
    };

    const [userCntext] = useUserContext()

    const [categorias, setCategorias] = useState<TCategoriaPastas[]>(CategoriasPadrão);

    const [categoriaModal, setCategoriaModal] = useState<boolean>(false)
    const fecharModal = () => {
        setCategoriaModal(false)
    }

   

    const handleSaveCategoria = (e: any) => {
        e.preventDefault();
        console.log('Categoria', categorias)
    };

    // Mutação -------------------------------------------


    const { criarPasta } = usePastasUtils();
    const queryClient = useQueryClient();
    const empresaid = localStorage.getItem('empresaSelecionada');
    const token = localStorage.getItem('token')

    // Categrias customizadas

    const {getCategoriasEmpresa} = useReactQueryEmpresas();

    const {data: categriasData, isLoading: catIsLoading} = useQuery({
        queryFn: ()=> getCategoriasEmpresa(token as string, empresaid as string),
        queryKey: ['rqCategoriasEmpresa']
    })


    if(categriasData){
        console.log('Categoras data: ', categriasData)
    }

    useLayoutEffect(() => {
        if (!empresaid || empresaid.length <= 0) {
            setError(true)
            setEmpresaError(true)
            toast.error('Selecione a empresa ao qual a pasta pertencerá!');
        } else {
            setError(false)
            setEmpresaError(false)
        }
    }, [empresaid])

    const criarPastaMutation = useMutation({
        mutationFn: criarPasta,
        onMutate: async (config) => {
            setLoading(true);
            // console.log('Mutação iniciada!');

        },
        onSuccess: async (response) => {
            // console.log("Mutação bem sucedida!", response);
            if (response.status === 1) {
                toast.success(response.msg);
                setSucesso(true);
                setNomePasta('')
                setDataEmissao('')
                setDataVencimento('')
                setSineDie(false)
                setLinkOEA('')
                setValor('')
                setCategoria('')

                queryClient.invalidateQueries({ queryKey: ["rqPastas"] });
                queryClient.fetchQuery({ queryKey: ["rqPastas"] });
                queryClient.refetchQueries({ queryKey: ["rqPastas"] });

            }

            if (response.status === 4) {
                toast.warn(response.msg);
                setPlanos(true)
            }

            if (response.status === 3) {
                toast.error(response.msg);
                setError(true);

            }

        },
        onError: async (err) => {
            console.error(err.message);
            toast.error('Erro ao tentar realizar a criação da pasta!')
        },
        onSettled: async (reesponse) => {
            // console.log('Mutação bem sucedida');
            setLoading(false);

        }

    })



    // ----------------------------------------------------

    const [cnpjEmpresa, setCnpjEmpresa] = useState<string>('');
    const [NomePasta, setNomePasta] = useState<string>('');
    const [DataEmissao, setDataEmissao] = useState<any>('');
    const [DataVencimento, setDataVencimento] = useState<string>('');
    const [SineDie, setSineDie] = useState<boolean>(false);
    const [LinkOEA, setLinkOEA] = useState<string>('');
    const [Valor, setValor] = useState<string>('');
    const [Categoria, setCategoria] = useState<string>('');


    const handleNovaPasta = (e: any) => {
        e.preventDefault();

        if (empresaError) {
            return;
        }

        if (!Categoria) {
            toast.error('Informe uma categoria para a pasta criada!');
            return;
        }
        setError(false);

        const formData = new FormData(e.currentTarget);

        const data: typePasta = {
            Categoria: Categoria as string,
            DataEmissao: formData.get("DataEmissao") as unknown as Date,
            DataVencimento: SineDie ? '' : DataVencimento,
            LinkOEA: formData.get("LinkOEA") as string,
            NomePasta: formData.get("NomePasta") as string,
            SineDie: SineDie,
            Valor: formData.get("Valor") as string,
        }

        criarPastaMutation.mutate({ empresaid: empresaid as string, token: token as string, pasta: data })
        return;
    }

    const fecharAlerta = () => {
        setPlanos(false)
    }

    if (planos) {
        return (
            <>
                <AlertaAssinatura fechar={fecharAlerta} tipo={'pasta'} />
            </>
        )
    }

    if (loading) {
        return (
            <div id="crud-modal" tabIndex={-1} aria-hidden="true" className="overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 ">
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <LoadingPage />
                    </div>
                </div>
            </div>
        )
    }
    if (sucesso) {
        return (
            <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Pasta criada com sucesso!</h3>

                            <div className='flex flex-row justify-between'>
                                <button onClick={fechar} data-modal-hide="popup-modal" type="button" className="closeButton">Fechar</button>
                                <button onClick={() => setSucesso(false)} data-modal-hide="popup-modal" type="button" className="confirmButton"> Criar outa pasta</button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        )
    }


    // Tailwind -------------------------------------------------
    const selectInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";
    const optionInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500";


    // Tailwind -------------------------------------------------


    return (
        <div id="crud-modal" tabIndex={-1} className="overflow-y-auto overflow-x-hidden bg-black bg-opacity-30 fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
            onClick={handleOverlayClick}>


            <div className="relative p-4 w-full max-w-md max-h-full">
                {
                    categoriaModal
                    &&
                    <PastasProcessoCatgorias novaCat={true} fechar={fecharModal} pasta ={true}
                    />
                }
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    <form className="p-4 md:p-5" onSubmit={handleNovaPasta}>
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome da pasta</label>
                                <input type="text" name="NomePasta" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder='Nome da pasta' value={NomePasta} onChange={(e) => setNomePasta(e.target.value)} required={true} />

                                {error && NomePasta.length <= 0 && <p className='text-red-500 text-sm'> Campo vazio </p>}
                            </div>


                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Link OEA</label>
                                <input type="text" name="LinkOEA" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={'www.linkOEA.com'} value={LinkOEA} onChange={(e) => setLinkOEA(e.target.value)} />
                            </div>



                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Valor</label>
                                <input type="number" name="Valor" step={"0.01"} id="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder='R$ 000,00' onChange={(e) => setValor(e.target.value)} value={Valor} />
                            </div>

                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">SineDie</label>

                                <label htmlFor="sineDieTrue" className='mr-2'> Sim</label>
                                <input type="radio" name="SineDie" id='sineDieTrue' defaultChecked={SineDie} className='mr-2' onChange={(e) => { if (e.target.checked) { setSineDie(true) } }} />

                                <label htmlFor="sineDieFalse" className='mr-2'> Não </label>
                                <input type="radio" name="SineDie" id='sineDieFalse' onChange={(e) => { if (e.target.checked) { setSineDie(false) } }} />

                            </div>

                            <div className="col-span-2 sm:col-span-1">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data de emissão </label>
                                <input type="datetime-local" name="DataEmissao" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" onChange={(e) => setDataEmissao(e.target.value)} />
                            </div>

                            {
                                SineDie ?
                                    <div className="col-span-2 sm:col-span-1">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data de vencimento </label>
                                        <span>SineDie</span>
                                    </div>

                                    :
                                    <div className="col-span-2 sm:col-span-1">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data de vencimento </label>
                                        <input type="datetime-local" name="DataVencimento" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" onChange={(e) => setDataVencimento(e.target.value)} />
                                    </div>}

                            <div>
                                <div className='flex flexrow justify-between'>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Categoria </label>

                                    <label className='text-sm flex font-medium flex-row text-blue-600 opacity-60 hover:opacity-100 hover:underline' onClick={() => setCategoriaModal(prev => !prev)}>
                                        <BsPlus className='mt-1' />Adicionar</label>

                                </div>
                                <select name="categoria" id="categota" className={`${selectInput}`} onChange={(e) => setCategoria(e.target.value)}>
                                    <option value=""> Selecione uma categoria</option>
                                    <option className={`${optionInput}`} value='Representante' >Representante </option>
                                    <option className={`${optionInput}`} value='Credenciamento'>Credenciamento</option>
                                    <option className={`${optionInput}`} value='ME/EPP+'       >ME/EPP+       </option>
                                    <option className={`${optionInput}`} value='Diligência(s)' >Diligência(s) </option>
                                    <option className={`${optionInput}`} value='Proposta'      >Proposta      </option>
                                    <option className={`${optionInput}`} value='H.J.'          >H.J.          </option>
                                    <option className={`${optionInput}`} value='R.F. e T.'     >R.F. e T.     </option>
                                    <option className={`${optionInput}`} value='Q.E.F.'        >Q.E.F.        </option>
                                    <option className={`${optionInput}`} value='Declarações'   >Declarações   </option>
                                    <option className={`${optionInput}`} value='Doc. Bancário' >Doc. Bancário </option>
                                    <option className={`${optionInput}`} value="Outros">Outros     </option>
                                    {
                                        categriasData && categriasData.categorias.map((categoria: TCategoriaPastas)=>(
                                            <option key={categoria._id} value={categoria.label}>{categoria.label}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            


                        </div>


                        <div className='flex flex-row justify-between'>
                            <button type="button" onClick={fechar} className="closeButton">
                                <BsX className="text-xl" />
                                Cancelar
                            </button>
                            {
                                !empresaError ?
                                    <button type="submit" className="confirmButton">
                                        <BsPlus className='text-xl' />
                                        Criar pasta
                                    </button>
                                    :
                                    <button disabled={true} className="confirmButton">
                                        <BsPlus className='text-xl' />
                                        Criar pasta
                                    </button>
                            }
                        </div>



                    </form>
                </div>
            </div>
        </div>
    )
}

export default NovaPasta