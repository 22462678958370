import { useQuery } from '@tanstack/react-query'
import React from 'react'
import LoadingPage from '../Components/LoadingPage'
import ListarPastas from '../Components/PastasComponents/ListarPastas'
import PastasSideNav from '../Components/PastasComponents/PastasSideNav'
import SelecionarEmpresa from '../Components/SelecionarEmpresa'
import { useUserContext } from '../Context/useUserContext'
import { usePastasUtils } from '../Utils/ReactQuery/getPastasUtils'
import ErrorPage from './ErrorPage'
import SideNavWrapper from '../Components/PortalComponents/SideNavWrapper';

type Props = {}

const Pastas = ({empresaData}: any) => {


    const [userContext] = useUserContext();
    const {getPastas} = usePastasUtils();

    const empresaid = localStorage.getItem('empresaSelecionada');
    const {data: pastasData, isLoading: isLoadingPastas, isError: isErrorPastas} = useQuery({queryFn: ()=> getPastas(empresaid as string), queryKey: ["rqPastas"], staleTime: 6000 * 3});


    
  return (
    <div className='w-full min-h-screen  grid grid-cols-[200px_minmax(200px,_1fr)]'>

        
        
      { pastasData ?
       <>
        <div>
          <SideNavWrapper />
           {/* <PastasSideNav pastas ={pastasData.pastas && pastasData.pastas} arquivos = {pastasData.arquivos && pastasData.arquivos} />  */}
        </div>

        <div className='fixed top-0 left-0 w-full dark:bg-gray-600 bg-gray-100 z-40 pl-18'>
              <SelecionarEmpresa/>
            </div>
        <div className='w-10/12 h-screen dark:bg-gray-800 shadow-xl rounded-lg mt-24'>

           <ListarPastas pastas ={pastasData.pastas && pastasData.pastas} arquivos = {pastasData.arquivos && pastasData.arquivos} empresas = {empresaData} empresaPadrão = {userContext.EmpresaPadrão}/> 
        </div>
        </>
        :
            isLoadingPastas ? <LoadingPage/> : <ErrorPage message={'Erro ao carregar pastas!'}/>
        }

        
        

    </div>
  )
}

export default Pastas