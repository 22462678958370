import React from 'react'
import { TCaptacaoCard } from '../../Utils/DevUtils/FakeCaptacao'
import { BsCaretDown, BsX } from 'react-icons/bs';



const CaptacaoItem = ({item, fechar}: any) => {


    //TAILWIND --------------------------------------------------------------------
    const button = 'cursor-pointer text-white inline-flex items-center bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 h-fit';
    const closeButton = 'bg-red-500 cursor-pointer text-white inline-flex items-center bg-bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:bg-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-800 h-fit';
    //TAILWIND --------------------------------------------------------------------


  return (

        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
            <div className="relative rounded-lg shadow dark:bg-gray-800 sm:p-5 max-w-7xl">
                <div className='w-full h-96 dark:bg-gray-700  bg-white rounded-lg shadow-md flex flex-col justify-between items-center gap-4 p-2 relative text-lg'>
                
                    <div className='absolute right-0 pr-3'>
                        <span className='text-3xl opacity-75 cursor-pointer hover:opacity-100 w-fit' onClick={fechar}><BsX/> </span>
                        <label className='inline-flex'>
                             Fonte: <span className='font-semibold '> PNCP</span>
                        </label>
                
                    </div>

                    <div className='flex flex-col gap-2'>
                        <div> <span className='font-semibold'>Orgão</span>: {item.Orgão} </div>
                        <div><span className='font-semibold'> Edital</span>: {item.Edital} </div>
                        <div><span className='font-semibold'> Modalidade</span>: {item.Modalidade} </div>
                        <div><span className='font-semibold'>Ultima atualização</span>: {item.ultimaAtualização}  </div>
                        <div><span className='font-semibold'>Cidade/UF</span>: {item.CidadeUF} </div>
                        <div><span className='font-semibold'>Objetos </span>: {item.Objetos.length > 30 ? `${item.Objetos.slice(0.30)} ... [Expandir]` : item.Objetos} </div>
                    </div>

                    <div className='w-full'>
                        <label className='inline-flex shadow-md border-opacity-45 p-2 cursor-pointer opacity-85 hover:opacity-100'> Ver mais <span><BsCaretDown className='mt-2'/></span></label>

                        <div className='itemCaptacaoWrapper pl-2'>
                            <div className='itemCaptacaoContainer'>
                                <div className='w-full bg-gray-100'>
                                    <label className='font-semibold'> Arquivos </label>
                                </div>
                                <div>
                                    <label className='font-semibold'> Items </label>
                                </div>
                            </div>
                        </div>
                    </div>

                


      
                </div>
            </div>
        </div>

  )
}

export default CaptacaoItem