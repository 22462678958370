import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react'
import { BsCheck, BsDownload, BsFilePlus, BsPencil, BsPlus, BsTrash, BsX } from 'react-icons/bs'
import { FaEye } from 'react-icons/fa'
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { toast } from 'react-toastify';


const ContatosProcessos = ({processo}: any) => {


    const [novoContato, setNovoContato] = useState<boolean>(false);
    const [editarContato, setEditarContato] = useState<boolean>(false);
    const [apagarContato, setApagarContato] = useState<boolean>(false);
    const [contatoSelecionado, setContatoSelecionado] = useState<string>('');
    const [contatoApagar, setContatoApagar] = useState<any>('');


    const [contatoNome        , setContatoNome        ] = useState<string>('');
    const [contatoEmpresa     , setContatoEmpresa     ] = useState<string>('');
    const [contatoDepartamento, setContatoDepartamento] = useState<string>('');
    const [contatoCargoFuncao , setContatoCargoFuncao ] = useState<string>('');
    const [contatoEmail       , setContatoEmail       ] = useState<string>('');
    const [contatoTelefone    , setContatoTelefone    ] = useState<string>('');
    const [contatoStatus      , setContatoStatus      ] = useState<string>('');


    //-- MUTAÇÃO -------------------------------------------


    const token = localStorage.getItem('token');
    const queryClient = useQueryClient();
    const {processoContatos} = useReactQueryProcessos();

    const contatosMutation = useMutation({
        mutationFn:  processoContatos,
        onMutate: async(config)=>{
            // console.log('Mutação iniciada!')
        },
        onSuccess: async(response)=>{

            // console.log('Mutação bem sucedida:', response);
            queryClient.invalidateQueries({queryKey: ["rqProcessos"]});
            queryClient.fetchQuery({queryKey: ["rqProcessos"]});
            queryClient.refetchQueries({queryKey: ["rqProcessos"]});

            if(response.status === 1){
                toast.success(response.msg);
                fechar()
            }else{
                toast.error(response.msg);
                fechar()
            }

        },
        onError: (err)=>{
            console.error(err.message);
            toast.error('Erro interno !');
            return;
        },
        onSettled: async(data)=>{
            // console.log('Mutação concluída!')
            setContatoSelecionado('')
        }
    })
    //-- MUTAÇÃO -------------------------------------------




    const apagarContatoStart =(contato: any)=>{
        setContatoSelecionado(contato._id);
        setContatoApagar(contato)
        setApagarContato(true);

    }
    const editarContatoStart =(contatoid: string)=>{
        setContatoSelecionado(contatoid);
        setEditarContato(true);

    }


    const fechar =()=>{
        setApagarContato(false);
        setEditarContato(false);
        setContatoSelecionado('')
    }




    const handleContato = (e: any, contato: any ,action: string)=>{
        e.preventDefault();


        if(action === 'editar'){
            const contatoData = {
                contatoNome : contatoNome.length >=1 ? contatoNome : contato.contatoNome,
                contatoEmpresa : contatoEmpresa.length >=1 ? contatoEmpresa : contato.contatoEmpresa,
                contatoDepartamento: contatoDepartamento.length >= 1 ? contatoDepartamento : contato,
                contatoCargoFuncao: contatoCargoFuncao.length >=1 ? contatoCargoFuncao : contato.contatoCargoFuncao,
                contatoEmail : contatoEmail.length >=1 ? contatoEmail : contato.contatoEmail,
                contatoTelefone: contatoTelefone.length >=1 ? contatoTelefone : contato.contatoTelefone,
                contatoStatus: contatoStatus.length >=1 ? contatoStatus : contato.contatoStatus,
            }
    
            contatosMutation.mutate({
                token: token as string,
                processoid: processo._id,
                action: 'editar',
                contato: contatoData
            })
            return;
           
        }

        if(action === 'novo'){
            const contatoData = {
                contatoNome,
                contatoEmpresa,
                contatoDepartamento,
                contatoCargoFuncao,
                contatoEmail,
                contatoTelefone,
                contatoStatus,
            }
    
            contatosMutation.mutate({
                token: token as string,
                processoid: processo._id,
                action: 'novo',
                contato: contatoData
            })
            return;
        }

        if(action === 'apagar'){
            contatosMutation.mutate({
                token: token as string,
                action: 'apagar',
                processoid: processo._id,
                 contato: contato
            })
        }

    }




  return (
    <div>
        <div className='mt-2' >
            <span className='font-semibold cursor-pointer opacity-85 hover:opacity-100 transition-opacity' 
            onClick={()=> setNovoContato(current => !current)}> + Adicionar contato </span>
        </div>

        {
        apagarContato &&
        <div id="popup-modal" tabIndex={1} className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    

                <label className='text-xl ml-2 mt-3 font-semibold'> Apagando o contato: {contatoApagar.contatoNome} ({contatoApagar.contatoTelefone})</label>

                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Deseja realizar esta operação?</h3>
                        <p>
                            Todos os dados deste contato serão apagados permanentemente.
                        </p>
                        <button data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center" onClick={(e)=> handleContato(e, contatoApagar, 'apagar')}>
                            Apagar 
                        </button>
                        <button data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={fechar}> Cancelar</button>
                    </div>
                </div>
            </div>
        </div>}


        <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Nome
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Opções
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Empresa
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Departamento
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Cargo/função
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Telefone
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            processo && processo.contatos.map((contato: any)=>(
                                <tr key={contato.contatoTelefone} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">

                                <th scope="row" className="px-6 py-4 font-medium text-gray-900
                                underline underline-offset-2 whitespace-nowrap dark:text-white">
                                    { editarContato && contato.contatoTelefone === contatoSelecionado? <input className='bg-transparent border-0 border-b-2 m-0 p-0' type="text" placeholder={contato.contatoNome} onChange={(e)=> setContatoNome(e.target.value)}/> : contato.contatoNome}
                                </th>

                                {
                                editarContato && contato.contatoTelefone === contatoSelecionado ? 
                                <td className="px-6 py-4 flex flex-row gap-2">
                                <BsCheck className='text-xl cursor-pointer opacity-80 hover:opacity-100' title='Aceitar' onClick={(e)=> handleContato(e, contato, 'editar')}/>
                                <BsX onClick={()=> setEditarContato(false)}     className='text-xl cursor-pointer opacity-80 hover:opacity-100' title='Cancelar' />
                                </td>
                                :
                                <td className="px-6 py-4 flex flex-row gap-2">
                                    
                                    <BsPencil className='cursor-pointer opacity-80 hover:opacity-100' title='Editar' onClick={()=> editarContatoStart(contato.contatoTelefone)} />
                                    <BsTrash  className='cursor-pointer opacity-80 hover:opacity-100' title='Apagar' onClick={()=> apagarContatoStart(contato)}/>

                                </td>
                                
                                }
                              
                                <td className="px-6 py-4">
                                    { editarContato && contato.contatoTelefone === contatoSelecionado ? <input className='bg-transparent border-0 border-b-2 m-0 p-0' type="text" placeholder={contato.contatoEmpresa} onChange={(e)=> setContatoEmpresa(e.target.value)}/> : contato.contatoEmpresa}
                                </td>
                                <td className="px-6 py-4">
                                    { editarContato && contato.contatoTelefone === contatoSelecionado ? <input className='bg-transparent border-0 border-b-2 m-0 p-0' type="text" placeholder={contato.contatoDepartamento} onChange={(e)=> setContatoDepartamento(e.target.value)}/> : contato.contatoDepartamento}
                                </td>
                                <td className="px-6 py-4">
                                    { editarContato && contato.contatoTelefone === contatoSelecionado ? <input className='bg-transparent border-0 border-b-2 m-0 p-0' type="text" placeholder={contato.contatoCargoFuncao} onChange={(e)=> setContatoCargoFuncao(e.target.value)}/> : contato.contatoCargoFuncao}
                                </td>

                                <td className="px-6 py-4">
                                    { editarContato && contato.contatoTelefone === contatoSelecionado ? <input className='bg-transparent border-0 border-b-2 m-0 p-0' type="text" placeholder={contato.contatoEmail} onChange={(e)=> setContatoEmail(e.target.value)}/> : contato.contatoEmail}
                                </td>

                                <td className="px-6 py-4  text-black dark:text-gray-300  gap-4">
                                    { editarContato && contato.contatoTelefone === contatoSelecionado ? <input className='bg-transparent border-0 border-b-2 m-0 p-0' type="text" placeholder={contato.contatoTelefone} onChange={(e)=> setContatoTelefone(e.target.value)}/> : contato.contatoTelefone}
                                </td>
                                

                                {editarContato && contatoSelecionado=== contato.contatoTelefone?

                                <div>
                                    <select name="contatoStatus" className=' p-0 rounded-xl dark:bg-gray-700 text-white cursor-pointer'
                                    onChange={(e)=> setContatoStatus(e.target.value)} value={contatoStatus}
                                    >
                                        <option value="ativo"   selected={contato.contatoStatus === 'ativo' ? true : false} className=' p-0 rounded-xl cursor-pointer'>Ativo</option>
                                        <option value="inativo" selected={contato.contatoStatus === 'inativo' ? true : false} className=' p-0 rounded-xl cursor-pointer'>Inativo</option>
                                    </select>
                                </div>
                                :
                                <td className="px-6 py-4  text-black dark:text-gray-300  gap-4">
                                     {contato.contatoStatus}
                                    
                                </td>
                                }


                                </tr>
                            ))
                        }
                         
                         {
                           novoContato && 
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <td className="px-6 py-4">
                                    <input required={true}  type="text" name="contatoNome"  className='bg-transparent border-0 border-b-2 m-0 p-0 text-sm' placeholder='Nome do contato ' onChange={(e)=> setContatoNome(e.target.value)} value={contatoNome}/>
                                </td>
                                
                                <td className="px-6 py-4 flex flex-row gap-2">
                                    <BsCheck className='text-xl cursor-pointer opacity-80 hover:opacity-100' title='Aceitar' onClick={(e)=> handleContato(e, '', 'novo')}/>
                                    <BsX onClick={()=> setNovoContato(false)}     className='text-xl cursor-pointer opacity-80 hover:opacity-100' title='Cancelar' />
                                </td>
                              
                                

                                <td className="px-6 py-4">
                                    <input required={true}  type="text" name="contatoEmpresa" placeholder='Empresa do contato' className="bg-transparent border-0 border-b-2 m-0 p-0 text-sm" onChange={(e)=> setContatoEmpresa(e.target.value)} value={contatoEmpresa}/> 
                                </td>
                                
                                <td className="px-6 py-4">
                                    <input required={true}  type="text" name="contatoDepartamento" placeholder='Departamento do contato' className="bg-transparent border-0 border-b-2 m-0 p-0 text-sm" onChange={(e)=> setContatoDepartamento(e.target.value)} value={contatoDepartamento}/> 
                                </td>
                                
                                <td className="px-6 py-4">
                                    <input required={true}  type="text" name="contatoCargoFuncao" placeholder='Cargo/função do contato' className="bg-transparent border-0 border-b-2 m-0 p-0 text-sm" onChange={(e)=> setContatoCargoFuncao(e.target.value)} value={contatoCargoFuncao}/> 
                                </td>
                                
                                <td className="px-6 py-4">
                                    <input required ={true} type="text" name="contatoEmail" placeholder='Email do contato' className="bg-transparent border-0 border-b-2 m-0 p-0 text-sm" onChange={(e)=> setContatoEmail(e.target.value)} value={contatoEmail}/> 
                                </td>
                               
                                <td className="px-6 py-4">
                                    <input required ={true} type="text" name="contatoTelefone" placeholder='Nº de telefone do contato' className="bg-transparent border-0 border-b-2 m-0 p-0 text-sm" onChange={(e)=> setContatoTelefone(e.target.value)} value={contatoTelefone}/> 
                                </td>

                                <td className="px-6 py-4">
                                    <div>
                                        <select required ={true} name="contatoStatus" className=' p-0 rounded-xl text-sm'
                                        onChange={(e)=> setContatoStatus(e.target.value)} value={contatoStatus}
                                        >
                                            <option value="ativo" className='text-sm p-0 rounded-xl'>Ativo</option>
                                            <option value="ativo" className='text-sm p-0 rounded-xl'>Inativo</option>
                                        </select>
                                    </div>
                                </td>


                                
                            </tr>
                            }

                        
                    </tbody>
                </table>
            </div>
    </div>

    
  )
}

export default ContatosProcessos