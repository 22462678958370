

export interface IPagamentosAssinatura{
    _id: string,
    empresaid: string,
    Stripe_id:  string,
    Email:      string,
    Nome:       string,
    Fatura_pdf?: string,
    Plano:      'Básico' | 'Intermediário' | 'Avançado',
    Valor?:      number,
    Created_At?: Date | string,
}

export interface IPagamentosUsuario extends IPagamentosAssinatura{
    CustomerId: string
}

export const fakeUserPagamento: IPagamentosUsuario ={
    _id: '1', 
    empresaid:'6696ce643473dab84e5a90de',
    Stripe_id: 'cs_test_a1aLlwyVhSgOulpx3GK7PBtQjWANDZ15aukZiLygAbyMop9cKPaAE8GgEg',
    Email: 'hosea@gmail.com',
    Nome: 'Hosea',
    Plano: 'Básico',
    CustomerId: 'cus_Qkbu3i6O51T7oH'

}

export const fakePagamentos: IPagamentosAssinatura[] =[
    {
        _id: '1', 
        empresaid:'6696ce643473dab84e5a90de',
        Stripe_id: 'cs_test_a1aLlwyVhSgOulpx3GK7PBtQjWANDZ15aukZiLygAbyMop9cKPaAE8GgEg',
        Email: 'hosea@gmail.com',
        Nome: 'Hosea',
        Fatura_pdf: 'https://pay.stripe.com/invoice/acct_1PqgC4P3NChn1rO8/test_YWNjdF8xUHFnQzRQM05DaG4xck84LF9Ra0d6dk93QThYc1FqWEIyOFNNRW00V2RjSjMyZFdXLDExNTM5NDUxNA020044fQoqF0/pdf?s=ap',
        Plano: 'Básico',
        Valor: 35300,
        Created_At: new Date().toISOString()
    },
    {
        _id: '2', 
        empresaid:'6696ce643473dab84e5a90de',
        Stripe_id: 'cs_test_a1aLlwyVhSgOulpx3GK7PBtQjWANDZ15aukZiLygAbyMop9cKPaAE8GgEg',
        Email: 'arthur@gmail.com',
        Nome: 'Arthur Morgan',
        Fatura_pdf: 'https://pay.stripe.com/invoice/acct_1PqgC4P3NChn1rO8/test_YWNjdF8xUHFnQzRQM05DaG4xck84LF9Ra0d6dk93QThYc1FqWEIyOFNNRW00V2RjSjMyZFdXLDExNTM5NDUxNA020044fQoqF0/pdf?s=ap',
        Plano: 'Básico',
        Valor: 35300,
        Created_At: new Date().toISOString()
    },
]


