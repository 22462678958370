import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { EventHandler, useLayoutEffect, useState } from "react";
import { FaTrashCan } from "react-icons/fa6";
import { useReactQueryCaptacao } from "../../Utils/ReactQuery/getCaptacaoUtils";
import { TItensData } from "../../Utils/types/PortalTypes";
import { toast } from "react-toastify";
import LoadingSmall from "../LoadingSmall";
import { BsCheck, BsPencil, BsTrash, BsX } from "react-icons/bs";
import { InputTextarea } from "primereact/inputtextarea";
import { converterValor } from "../../Utils/ConverterValor";

const ItensProcessos = ({ processo, itensData }: any) => {


  const [loading, setLoading] = useState<boolean>(false);
  const [sucesso, setSucesso] = useState<boolean>(false);
  const [numero, setNumero] = useState<number | string>('')
  const [descrição, setDescrição] = useState<string | string>('')
  const [quantidade, setQuantidade] = useState<number | string | null>()
  const [marca, setMarca] = useState<string | string>('')
  const [valorUnitarioEstimado, setValorUnitarioEstimado] = useState<number | string | null>()
  const [valorTotalEstimado, setValorTotalEstimado] = useState<number | string | null>('')


  const [editItenmId, setEditItemId] = useState<string>('');
  const [apagandoItemId, setApagandoItemId] = useState<string>('');
  // Estado para os dados dos itens

  const token = localStorage.getItem('token');
  const { createProcessoItens, apagarCaptacaoItens, editarCaptacaoItens } = useReactQueryCaptacao();
  const queryyClient = useQueryClient()

  const criarItemMutaton = useMutation({
    mutationFn: createProcessoItens,
    onMutate: async (data) => {
      setLoading(true)
    },
    onSuccess: async (response) => {

      if (response.status === 1) {
        toast.success(response.msg);
        setSucesso(true)
        clear()
      }

      if (response.status === 3) {
        toast.error(response.msg)
      }

      queryyClient.invalidateQueries({ queryKey: [`rqPrcessoItens-${processo._id}`] })
      queryyClient.refetchQueries({ queryKey: [`rqPrcessoItens-${processo._id}`] })

    },
    onError: async (error) => {
      toast.error(error.message)
    },
    onSettled: async (data) => {
      setLoading(false)
    }
  })

  const editarItemMutation = useMutation({
    mutationFn: editarCaptacaoItens,
    onMutate: async (data) => {
      setLoading(true)
    },
    onSuccess: async (response) => {

      if (response.status === 1) {
        toast.success(response.msg);
        setSucesso(true)

      }

      if (response.status === 3) {
        toast.error(response.msg)
      }

      queryyClient.invalidateQueries({ queryKey: [`rqPrcessoItens-${processo._id}`] })
      queryyClient.refetchQueries({ queryKey: [`rqPrcessoItens-${processo._id}`] })

    },
    onError: async (error) => {
      toast.error(error.message)
    },
    onSettled: async (data) => {
      setLoading(false)
    }
  })

  const deletarItemMutation = useMutation({
    mutationFn: apagarCaptacaoItens,
    onMutate: async (data) => {
      setLoading(true)
    },
    onSuccess: async (response) => {

      if (response.status === 1) {
        toast.success(response.msg);

      }

      if (response.status === 3) {
        toast.error(response.msg)
      }

      queryyClient.invalidateQueries({ queryKey: [`rqPrcessoItens-${processo._id}`] })
      queryyClient.refetchQueries({ queryKey: [`rqPrcessoItens-${processo._id}`] })

    },
    onError: async (error) => {
      toast.error(error.message)
    },
    onSettled: async (data) => {
      setLoading(false)
      clear()
    }
  })


  const handleOpenNewItem = ()=>{
    setEditItemId('');
    setApagandoItemId('')
    setShowForm(true)
  }


  // Estado para controlar a exibição do formulário
  const [showForm, setShowForm] = useState(false);

  // Estado para os valores dos inputs do novo item

  const handlleNovoIten = (e: any) => {

    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const Numero = formData.get("numeroItem") as string;
    const Descrição = formData.get("descricao") as string;
    const Quantidade = formData.get("quantidade") as string;
    const Marca = formData.get("marca") as string;
    const ValorUnitarioEstimado = formData.get("valorUnitario") as string;
    const ValorTotalEstimado = formData.get("valorTotal") as string;


    const novoItem: TItensData = {
      processoId: processo._id,
      Numero: itensData.itens ? itensData.itens.length + 1 : 1,
      Descrição,
      Quantidade,
      MarcaModelo: Marca,
      ValorUnitarioEstimado,
      ValorTotalEstimado,
    }


    criarItemMutaton.mutate({ processoId: processo._id, token: token as string, data: novoItem })


  }

  useLayoutEffect(() => {
    if (sucesso) {

      setTimeout(() => {
        setSucesso(false);
        clear();
      }, 3000)

    }
  }, [sucesso])


  const getNumero = (): number => {

    if (itensData) {
      const numeroAtual = itensData.iitens.length + 1;
      setNumero(numeroAtual)
      return numeroAtual
    } else {
      setNumero(1)
      return 1
    }

  }

  const getValorTotal = (): number => {



    if (quantidade && valorUnitarioEstimado) {
      const valorTotal = quantidade as number * +valorUnitarioEstimado as number;
      setValorTotalEstimado(valorTotal);

      return valorTotal

    } else {
      return 0
    }
  }


  const handleEditarItem = (item: any) => {


    if (!quantidade && valorTotalEstimado) {
      setValorTotalEstimado(item.Quantidade * +valorTotalEstimado)
    }
    if (quantidade && !valorTotalEstimado) {
      setValorTotalEstimado(+quantidade * item.ValorTotalEstimado)
    }

    const data = {
      Numero: numero,
      Descrição: descrição,
      Quantidade: quantidade,
      MarcaModelo: marca,
      ValorUnitarioEstimado: valorUnitarioEstimado,
      ValorTotalEstimado: valorTotalEstimado,
    }


    const fiteredObj = Object.fromEntries(Object.entries(data).filter(([key, value]) => {
      if (value !== "" && value !== 0) {
        return value
      }
    }));

    editarItemMutation.mutate({ token: token as string, itemid: editItenmId, data: fiteredObj })

  }

  const handleApagarItem = () => {
    deletarItemMutation.mutate({ token: token as string, itemid: apagandoItemId })
  }


  const clear = () => {
    setNumero('')
    setDescrição('')
    setQuantidade('')
    setMarca('')
    setValorUnitarioEstimado('')
    setValorTotalEstimado('')
    setEditItemId('')
    setApagandoItemId('')
  }


  const handleValueChange = (e: any) => {
    const input = e.target.value;
    setValorUnitarioEstimado(input === '' ? null : Number(input))
  }

  const handleQuantChange = (e: any) => {
    const input = e.target.value;
    setQuantidade(input === '' ? null : Number(input))
  }

  const handleTotalnputChange = (e: any)=>{
    const input = e.target.value;
    setValorTotalEstimado(input === '' ? null : Number(input))
  }




  return (
    <div>
      <div className='mb-12'>
        <div className="relative">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>

                <th scope="col" className="px-6 py-3 text-center">Numero</th>
                <th scope="col" className="px-6 py-3 text-center">Descrição</th>
                <th scope="col" className="px-6 py-3 text-center">Quantidade</th>
                <th scope="col" className="px-6 py-3 text-center">Marca/Modelo</th>
                <th scope="col" className="px-6 py-3 text-center"> Valor unitario estimado</th>
                <th scope="col" className="px-6 py-3 text-center">Valor total estimado</th>
                <th scope="col" className="px-6 py-3 text-center">Ações</th>

              </tr>
            </thead>
            <tbody>

              {
                itensData && itensData.itens.map((item: TItensData, index: number) => (

                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={item._id}>


                    <td className="px-6 py-4 text-center">
                      {item.Numero}
                    </td>
                    <td className="px-6 py-4 ">
                      {
                        editItenmId === item._id ?
                          <InputTextarea autoResize={true} rows={1} placeholder={item.Descrição} name="descricao" className="bg-white labelInput" value={descrição} onChange={(e) => setDescrição(e.target.value)} />
                          : item.Descrição
                      }
                    </td>
                    <td className="px-6 py-4 text-center">
                      {
                        editItenmId === item._id ?
                          <input
                            type="number"
                            name="quantidade"
                            placeholder={item.Quantidade}
                            value={quantidade === null ? '' : Number(quantidade)}
                            onChange={handleQuantChange}
                            className="bg-white labelInput"
                            onBlur={getValorTotal}
                          />
                          :
                          item.Quantidade
                      }
                    </td>

                    
                    <td className="px-6 py-4 text-center">
                      {
                        editItenmId === item._id ?
                          <input type="text" name="marca"
                          className="inputField"
                          value={marca}
                          onChange={(e)=> setMarca(e.target.value)}
                          placeholder={item.MarcaModelo}
                          />
                        :
                      
                      item.MarcaModelo
                      
                      }
                    </td>


                    <td className="px-6 py-4 text-center">
                      {
                        editItenmId === item._id ?
                          <input
                            type="number"
                            step={"0.01"}
                            name="valorUnitario"
                            placeholder={item.ValorUnitarioEstimado as string}
                            value={valorUnitarioEstimado === null ? '' : valorUnitarioEstimado}
                            onChange={handleValueChange}
                            className="bg-white labelInput"
                            onBlur={getValorTotal}

                          />
                          :
                          item.ValorUnitarioEstimado === 0 ? 'Sigiloso' : item.ValorUnitarioEstimado ? item.ValorUnitarioEstimado.toLocaleString("pt-br", { style: "currency", currency: 'BRL' }) : item.ValorUnitarioEstimado
                      }
                    </td>
                    <td className="px-6 py-4 text-center">

                      {
                        editItenmId === item._id ?
                          <input
                            type="number"
                            step={"0.01"}
                            name="valorTotal"
                            placeholder={item.ValorTotalEstimado as string}
                            className="bg-white labelInput"
                            value={valorTotalEstimado === null ? '' : valorTotalEstimado}
                            onChange={handleTotalnputChange}
                          />
                          :
                          item.ValorTotalEstimado === 0 || item.ValorTotalEstimado === 'Sigiloso' ? 'Sigiloso' : item.ValorTotalEstimado ? converterValor(item.ValorTotalEstimado) : item.ValorTotalEstimado
                      }
                    </td>

                    {editItenmId === item._id && loading ?
                      <td className="flex flex-row text-black justify-center items-center gap-2">
                        <LoadingSmall />
                      </td>
                      :

                      editItenmId === item._id && sucesso ?

                        <td className="flex flex-row text-black justify-center items-center gap-2">
                          <BsCheck />
                        </td>

                        :
                        <td className="flex flex-row h-full text-black justify-center items-center gap-2 pt-4">

                          {

                            apagandoItemId === item._id ?


                              <>
                                <BsCheck className="cursor-pointer opacity-100 hover:opacity-100" onClick={handleApagarItem} />
                                <BsX className="cursor-pointer opacity-100 hover:opacity-100"
                                  onClick={clear}
                                />
                              </>
                              :

                              apagandoItemId === item._id && loading ?
                                <LoadingSmall />
                                :
                                <BsTrash className="cursor-pointer opacity-100 hover:opacity-100" onClick={() => setApagandoItemId(item._id as string)} />
                          }



                          {
                            editItenmId === item._id ?
                              <>
                                <BsCheck className="cursor-pointer opacity-100 hover:opacity-100" onClick={() => handleEditarItem(item)} />
                                <BsX className="cursor-pointer opacity-100 hover:opacity-100" onClick={clear} />
                              </>
                              :
                              <BsPencil className="cursor-pointer opacity-100 hover:opacity-100" onClick={() => editItenmId.includes(item._id as string) ? setEditItemId('') : setEditItemId(item._id as string)} />
                          }


                        </td>}


                  </tr>

                ))
              }



              <tr className="bg-white border-b">
                <td colSpan={7} className="px-6 py-3 text-center">
                  <button
                    onClick={handleOpenNewItem}
                    className="flex items-center justify-center w-full text-orange-500 hover:text-orange-700 focus:outline-none"
                  >
                    <span className="text-2xl">+</span>
                  </button>
                </td>


              </tr>


              {showForm && (
                <tr className="bg-gray-100">
                  <td colSpan={7} className="px-6 py-4 text-center">
                    <div className="flex flex-row justify-between">

                      <div className="col-span-1 md:col-span-6 flex justify-end space-x-3 ">

                      </div>
                    </div>
                    <form className="grid grid-cols-1 md:grid-cols-5 gap-4" onSubmit={handlleNovoIten}>

                      <label className="w-full flex flex-row justify-center items-center labelInput text-center">
                        {itensData.itens ? itensData.itens.length + 1 : 1}
                      </label>

                      <InputTextarea autoResize={true} rows={1} placeholder="Descrição" name="descricao" className="bg-white labelInput" value={descrição} onChange={(e) => setDescrição(e.target.value)} />

                      <input
                        type="number"
                        name="quantidade"
                        placeholder="Quantidade"
                        value={quantidade === null ? '' : Number(quantidade)}
                        onChange={handleQuantChange}
                        className="bg-white labelInput"
                        onBlur={getValorTotal}
                      />

                      <input
                        type="text"
                        name="marca"
                        placeholder="Marca/Modelo"
                        value={marca}
                        onChange={(e) => setMarca(e.target.value)}
                        className="bg-white labelInput"
                      />
                      <input
                        type="number"
                        step={"0.01"}
                        name="valorUnitario"
                        placeholder="Valor Unitário "
                        value={valorTotalEstimado === null ? '' : Number(valorUnitarioEstimado)}
                        onChange={handleValueChange}
                        className="bg-white labelInput"
                        onBlur={getValorTotal}
                      />

                      <input
                        type="number"
                        step={"0.01"}
                        name="valorTotal"
                        placeholder="Valor Total"
                        className="bg-white labelInput"
                        value={valorTotalEstimado === null ? '': Number(valorTotalEstimado)}
                        onChange={handleTotalnputChange}
                      />

                      <div className="col-span-1 md:col-span-6 flex justify-between space-x-3 ">


                        <label className="pb-2 font-semibold text-lg">Inserir novo Item</label>

                        <div className="flex flex-row gap-4">
                          {!loading && !sucesso &&
                            <button
                              onClick={() => setShowForm(false)}
                              className="closeButton"
                            >
                              Cancelar
                            </button>}
                          <button
                            type="submit"
                            className="confirmButton"
                            disabled={loading || sucesso ? true : false}
                          >
                            {loading ? <LoadingSmall /> : sucesso ? <BsCheck /> : 'Salvar'}
                          </button>
                        </div>
                      </div>
                    </form>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ItensProcessos;
