import axios from "axios"
import { TArquivosPortais, subTypePortal, typeUpdatePortal } from "../types/portaisTypes"
import { useState } from "react"

export const usePortaisUtils =()=>{

    const [fileUpload ,setFileUpload] = useState<string | number>('');

    const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL


    type typePortal = {
        LogoPortal            : string,
        EmpresaPortal         : string,
        LinkPortal            : string,
        NomePortal            : string,
        LoginPortal           : string,
        SenhaPortal           : string,
        DataCadastroPortal    : string,
        DataVencimentoPortal  : string,
        ValorPortal           : string
    }



    type typeCreatePortal ={
        token: string,
        empresaid: string,
        data?: subTypePortal,
        Arquivo?: HTMLFormElement | any
    }

    

    type TGetPortal ={
        token?: string,
        empresaid: string,
        tipo?: 'individual' | null

    }

    

    const getPortais = async(data: TGetPortal)=>{
        const url = `${baseUrl}/api/portais?empresaid=${data.empresaid}&tipo=${data.tipo}`;

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;

        } catch (error) {
            console.error(error);
            return error;
        }
    }

   

    const criarPortal = async(data: typeCreatePortal)=>{
        const url = `${baseUrl}/api/portais/cadastrar?empresaid=${data.empresaid}&token=${data.token}`;

        try {

            const res: any = await axios({
                url: url,
                method: 'POST',
                headers:{
                    'Content-Type': 'multipart/form-data'
                },
                params:{
                    data:{
                        LogoPortal: data.data?.LogoPortal,
                        EmpresaPortal: data.data?.EmpresaPortal,
                        LinkPortal: data.data?.LinkPortal,
                        NomePortal: data.data?.NomePortal,
                        LoginPortal: data.data?.LoginPortal,
                        SenhaPortal: data.data?.SenhaPortal,
                        DataCadastroPortal: data.data?.DataCadastroPortal,
                        DataVencimentoPortal: data.data?.DataVencimentoPortal,
                        ValorPortal: data.data?.ValorPortal,
                        Categoria: data.data?.Categoria         
                    }
                },
                data: data.Arquivo,
                onUploadProgress: (p: any)=>{
                    const completedUpload = p.loaded/p.total ;
                    setFileUpload(completedUpload)
                }
                });

            const response = await res.data;
            return response;
            
        } catch (error) {
            console.error(error);
            return null;
        }

      
    }


    const updatePortal =async(data: typeUpdatePortal)=>{
        const url = `${baseUrl}/api/portais/atualizar?portalid=${data.portalid}`;
        
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data.data)
            });
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return null;            
        }

    }


    type typeApagarPortal ={
        token: string, 
        portalid: string,
        empresaid: string
    }

    const removerPortal = async(data: typeApagarPortal)=>{
        const url = `${baseUrl}/api/portais/portal/excluir?portalid=${data.portalid}&token=${data.token}&empresaid=${data.empresaid}`;

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;

        } catch (error) {
            console.error(error);
            return null;
        }
    }

    type typeAlterarLogoPortais ={
        token: string, 
        portalid: string,
        empresaid: string,
        Arquivo: HTMLFormElement | any
    }

    const alterarLogoPoprtal = async(data: typeAlterarLogoPortais)=>{
        const url = `${baseUrl}/api/portais/alterar-logo?portalid=${data.portalid}&token=${data.token}&empresaid=${data.empresaid}`;

        try{
            const res: any = await axios({
                url: url,
                method: 'POST',
                headers:{
                    'Content-Type': 'multipart/form-data'
                },
                data: data.Arquivo,
                onUploadProgress: (p: any)=>{
                    const completedUpload = p.loaded/p.total ;
                    setFileUpload(completedUpload)
                }
                });

            const response = await res.data;
            return response;
            
        } catch (error) {
            console.error(error);
            return null;
        }


    }


    type TSalvarArquivoPortal ={
        token: string,
        portalid: string,
        empresaid?: string,
        resumo:TArquivosPortais,
        Arquivo: HTMLFormElement | any
    }
    const salvarArquivoPortal = async(data: TSalvarArquivoPortal)=>{
        const url = `${baseUrl}/api/portais/salvar-arquivo?token=${data.token}&portalid=${data.portalid}&empresaid=${data.empresaid}`;

        try{
        
            const res: any = await axios({
            url: url,
            params:{
                resumo: {
                    NomeDoArquivo: data.resumo.NomeDoArquivo,
                    Descrição: data.resumo.Descrição,
                    Categoria: data.resumo.Categoria,
                    Portalid: data.resumo.Portalid,
                    Size: data.resumo.Size,
                    empresaid: data.empresaid
                }

            },
            method: 'POST',
            headers:{
                'Content-Type': 'multipart/form-data'
            },
            data: data.Arquivo,
            onUploadProgress: (p: any)=>{
                const completedUpload = p.loaded/p.total ;
                setFileUpload(completedUpload)
            }
            });
    
            const response = await res.data;
    
           return response;
    
            }
            catch(err){
                console.error(err);
                return null
    
            }

    }


    const getArquivosPortais = async(token: string, portalid: string) =>{
        const url = `${baseUrl}/api/portais/arquivos/get-arquivos?token=${token}&portalid=${portalid}`;

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;

        } catch (error) {
            console.error(error);
            return null;
        }
    }


    type TAapagarArquivo={
        token: string,
        arquivoid: string,
        portalid?: string,
        empresaid: string
    }


    const apagarArquivosPortal = async(data: TAapagarArquivo)=>{
        const url = `${baseUrl}/api/portais/arquivos/apagar-arquivos?token=${data.token}&arquivoid=${data.arquivoid}&empresaid=${data.empresaid}`;

        try {
            const res = await fetch(url);
            const response = await res.json();
            return response;

        } catch (error) {
            console.error(error);
            return null;
        }
    }

    return{
        getPortais,
        criarPortal,
        updatePortal,
        removerPortal,
        alterarLogoPoprtal,
        salvarArquivoPortal,
        getArquivosPortais,
        apagarArquivosPortal
    }

}