import React from 'react'
import Solicitações from './Solicitações'
import Funcionarios from './Funcionarios'
import Rejeições from './Rejeições'
import { AdminTab } from '../../Utils/types/adminTypes'
import { Link } from 'react-router-dom'



const RhEstrategico = ({activeTab, solicitações, usuarios, empresaData}: any) => {
    

    return (
        
        <div className='grid justify-start grid-cols-[250px_auto]'>
            <div className='w-full'>
                <div className='fixed'>
                    <label >RH Estratégico / <span className='capitalize'>{activeTab}  </span></label>
                    <div className='flex flex-col pt-5 gap-1'>
                        <Link to="/portal/admin?area=solicitações&secao=rhestrategico" className={activeTab === AdminTab.SOLICITAÇÕES ? `opacity-80 hover:opacity-100 cursor-pointer font-semibold`:`opacity-80 hover:opacity-100 cursor-pointer` } >Solicitações</Link>
                        <Link to="/portal/admin?area=funcionários&secao=rhestrategico"className={activeTab === AdminTab.FUNCIONÁRIOS ? `opacity-80 hover:opacity-100 cursor-pointer font-semibold`:`opacity-80 hover:opacity-100 cursor-pointer` } >Funcionários</Link>
                        <Link to="/portal/admin?area=rejeições&secao=rhestrategico" className={activeTab === AdminTab.REJEIÇÕES ? `opacity-80 hover:opacity-100 cursor-pointer font-semibold`:`opacity-80 hover:opacity-100 cursor-pointer` } >Rejeições</Link>
                    </div>
                </div>
            </div>
            {
                activeTab === AdminTab.SOLICITAÇÕES &&
            <div>
                <Solicitações solicitações={solicitações}  />
            </div>
            }

            {
                activeTab === AdminTab.FUNCIONÁRIOS &&
                <div> <Funcionarios usuarios={usuarios} empresaData={empresaData && empresaData} /> </div>}

            {
                activeTab === AdminTab.REJEIÇÕES &&
                <div> <Rejeições solicitações={solicitações} /> </div>}
        </div>
    )
}

export default RhEstrategico