import React, { useState, useEffect, useRef } from 'react';
import { CategoriasPadrão, TCategoriaPastas } from '../../Utils/DevUtils/CategoriasFake';
import { BsCaretDown, BsExclamationCircle, BsQuestionCircle, BsX } from 'react-icons/bs';
import { toast } from 'react-toastify';
import LoadingSmall from '../LoadingSmall';
import { useReactQueryEmpresas } from '../../Utils/ReactQuery/getEmpresas';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import FerramentasConfig from '../FerramentasComponents/FerramentasConfig';
import { useUserContext } from '../../Context/useUserContext';


const PastasProcessoCatgorias = ({ novaCat, fechar, categoriasData, pasta }: any) => {

    const [userContext] = useUserContext();
    const empresaPadrão = localStorage.getItem('empresaPadrão')
    const [abrirRecomendadas, setAbrirRecomendadas] = useState<boolean>(false);



    // //~~MANUTENÇÃO~~//
    // if (categoriasData) {
    //     console.log('Cateegorias data: ', categoriasData)
    // }

    // if (userContext) {
    //     console.log('User Context: ', userContext)
    // }
    // //~~MANUTENÇÃO~~//

    const [alertaAssessoria, setAlertaAssessoria] = useState<boolean>(false)

    const [categoria, setCategoria] = useState<string>('');
    const [inputType, setInputType] = useState<'lista' | 'criar' | 'bloquear'>('lista');
    const [error, setError] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    const [loading, setLoadding] = useState<boolean>(false);

    const [abrirFiltrosConfig, setAbrirFiltrosConfig] = useState<boolean>(false);
    const fechaFiltroConfig = () => {
        setAbrirFiltrosConfig(false)
    }

    // Mutação -------------------------------
    const { configCategoria } = useReactQueryEmpresas()
    const token = localStorage.getItem('token');
    const empresaSelecionada = localStorage.getItem('empresaSelecionada');
    const queryClient = useQueryClient();

    const configCategoriaMutation = useMutation({
        mutationFn: configCategoria,
        onMutate: async (data) => {

            setLoadding(true)
        },
        onSuccess: async (response) => {

            queryClient.invalidateQueries({ queryKey: ['rqCategorias'] });
            queryClient.refetchQueries({ queryKey: ['rqCategorias'] })
            queryClient.invalidateQueries({ queryKey: ['rqCategoriasEmpresa'] });
            queryClient.refetchQueries({ queryKey: ['rqCategoriasEmpresa'] })

            if (response.status === 1) {
                toast.success(response.msg)
            }

            if (response.status === 3) {
                toast.error(response.msg)
            }
        },
        onError: async (error) => {
            console.error(error.message);
            toast.error(error.message)

        },
        onSettled: async (data) => {
            setLoadding(false);
            fechar()
        }
    })

    // Mutação -------------------------------

    useEffect(() => {
        if (novaCat) {
            setInputType('criar');
        }

        if (categoriasData && categoriasData.filtros.length >= 6) {
            setInputType('bloquear')
        }
    }, [novaCat, categoriasData])

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                fechar();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);


    const handleAnexarCategoria = (e: React.FormEvent) => {
        e.preventDefault();

        if (!categoria || categoria.length === 0) {
            toast.error('Crie ou selecione uma categoria para anexar nos atalhos!');
            return
        }

        const novaCategoria: TCategoriaPastas = {
            label: categoria,
            value: categoria,
        };

        setCategoria('');
        setInputType('lista');
        setError('');

        if(pasta){
            configCategoriaMutation.mutate({ action: 'pastaEmpresa', categoria: novaCategoria, empresaid: empresaSelecionada as string, token: token as string })
            return
        }

        if (inputType === 'criar' && novaCat) {
            configCategoriaMutation.mutate({ action: 'nova', categoria: novaCategoria, empresaid: empresaSelecionada as string, token: token as string })
            return
        }

        if (inputType === 'lista' && !novaCat) {
            configCategoriaMutation.mutate({ action: 'anexar', categoria: novaCategoria, empresaid: empresaSelecionada as string, token: token as string })
            return
        }

        if (inputType === 'criar' && !novaCat) {
            configCategoriaMutation.mutate({ action: 'nova-anexar', categoria: novaCategoria, empresaid: empresaSelecionada as string, token: token as string })
            return
        }

    };

    const handleInTextInput = () => {
        setCategoria('');
        setInputType('criar');
        setError('');
    };

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (e.target === e.currentTarget) {
            fechar()
        }
    };

    if (inputType === 'bloquear') {
        return (
            <div
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50"
                onClick={handleOverlayClick}
            >
                {abrirFiltrosConfig && (
                    <FerramentasConfig
                        fechar={fechaFiltroConfig}
                        categoriasData={categoriasData}
                    />
                )}
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                        <div className="flex flex-col items-center text-center">
                            <BsExclamationCircle className="text-6xl text-orange-400 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-500 mb-2">
                                O limite de filtros adicionados aos favoritos é de 6 categorias!
                            </h3>
                            <p className="mb-4 text-gray-500">
                                Remova filtros favoritados para adicionar outros no lugar.
                            </p>
                            <button
                                className="text-blue-500 hover:underline mb-4"
                                onClick={() => setAbrirFiltrosConfig((current) => !current)}
                            >
                                Gerenciar ferramenta de filtros
                            </button>
                            <button
                                type="button"
                                className="closeButton"
                                onClick={fechar}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50"
            onClick={handleOverlayClick}
        >




            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white dark:bg-gray-700 rounded-lg shadow-lg">

                    <button
                        onClick={fechar}
                        className="absolute top-3 right-3 text-gray-500 hover:bg-gray-200 rounded-lg hover:text-gray-700 dark:text-gray-300 dark:hover:text-white"
                        aria-label="Fechar modal"
                    >
                        <BsX size={24} />
                    </button>

                    <form className="p-6 text-center" onSubmit={handleAnexarCategoria}>
                        <h3 className="mb-4 text-lg font-semibold text-gray-700 dark:text-gray-200">
                            Anexar Filtro de Categoria
                        </h3>
                        <p className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                            Adicione um atalho para filtrar pastas ou arquivos do processo através da sua categoria.
                        </p>
                        {
                            empresaPadrão === empresaSelecionada && userContext && userContext.User.AcessoAtual === 'Assessoria' &&
                            <div >
                                <small className='text-red-500'>Acessando como assessoria, os resultados dos filtros podem não funcionar como o esperado</small>


                                <div className='relative w-full flex flex-row justify-end '>
                                    <BsQuestionCircle className='cursor-pointer hover:opacity-100 opacity-75 items-end' onClick={() => setAlertaAssessoria(current => !current)} />

                                    {
                                        alertaAssessoria &&
                                        <small className='absolute top-0 left-[100%] bg-white rounded-lg p-4 shadow-lg min-w-72 text-md max-w-md'>Atualemnte  você está acessando uma conta como assessoria, as categorias de pastas e arquivos são criadas pelas empresas, ainda será possível anexar filtros, mas é possível que a empresa assessorada não possua a mesma categoria em seus arquivos e pastas.</small>}
                                </div>
                            </div>}

                        {!novaCat && <div>
                            <div className="mb-4">
                                <label htmlFor="categoria" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Categoria
                                </label>
                                <select
                                    name="categoria"
                                    id="categoria"
                                    className="w-full p-2 border border-gray-300 rounded dark:bg-gray-600 dark:text-white"
                                    value={categoria}
                                    onChange={(e) => setCategoria(e.target.value)}
                                    onFocus={() => setInputType('lista')}
                                >
                                    <option value="">Selecione uma categoria</option>
                                    {categoriasData && categoriasData.categorias.sort((a: any, b: any) => a.label.localeCompare(b.label)).map((i: TCategoriaPastas) => (
                                        <option key={i.value} value={i.value}>
                                            {i.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="text-sm text-gray-500 dark:text-gray-400">
                                    Ou{' '}
                                    <span className="cursor-pointer font-semibold text-blue-500" onClick={handleInTextInput}>
                                        crie e anexe uma nova categoria
                                    </span>
                                </label>
                            </div>
                        </div>}

                        {inputType === 'criar' && (
                            <div className="mb-4 flex flex-col gap-4">
                                <label htmlFor="nova-categoria" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Nova Categoria
                                </label>
                                <input
                                    type="text"
                                    id="nova-categoria"
                                    className="w-full p-2 border border-gray-300 rounded dark:bg-gray-600 dark:text-white"
                                    value={categoria}
                                    onChange={(e) => setCategoria(e.target.value)}
                                    ref={inputRef}
                                />

                                <label className=' mb-2 border-opacity-75  rounded-md text-blue-600 cursor-pointer'>
                                    <span className='inline-flex' onClick={() => setAbrirRecomendadas(current => !current)}>Categorias recomendadas {<BsCaretDown className={abrirRecomendadas ? 'mt-1' : '-rotate-90 mt-1'} />}</span>

                                    {
                                        abrirRecomendadas &&
                                        <select className='w-full p-2 border border-gray-300 rounded dark:bg-gray-600 dark:text-white text-black' onChange={(e) => setCategoria(e.target.value)}>
                                            <option className='optionInput'> Categorias recomendadas</option>
                                            {CategoriasPadrão.map((categoria: TCategoriaPastas) => (
                                                <option value={categoria.label}>{categoria.label}</option>
                                            ))}
                                        </select>}

                                </label>
                            </div>
                        )}




                        {error && <p className="mb-4 text-sm text-red-500">{error}</p>}

                        <div className="flex flex-row justify-between">
                            <button
                                type="button"
                                className="closeButton"
                                onClick={fechar}
                            >
                                Cancelar
                            </button>
                            {
                                categoria &&
                                <button
                                    type="submit"
                                    className="confirmButton"
                                >
                                    {loading ? <LoadingSmall message={'Carregando ...'} /> : 'Anexar Categoria'}
                                </button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PastasProcessoCatgorias;
