export const useRecoverSenha =()=>{

    const baseUrl = process.env.REACT_APP_PRIVATE_API_URL

    const verifyEmail = async(email :any)=>{
        
        const url = `${baseUrl}/api/verificaemail`;

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    "content-type": "application/json"
                },
                body:JSON.stringify(email)
            });

            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return;
        }
    }

   const verifyToken =async({config = {email: '', token : ''}} ={})=>{
    const baseUrl = process.env.REACT_APP_PRIVATE_API_URL;
        const url = `${baseUrl}/api/validar-token`;
        
        try {
            const res = await fetch(url,{
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(config)
            })
            const response = await res.json();
            return response;

        } catch (error) {
            console.error(error);
            return;
        }
    }

    const mudarSenha = async({config ={email: '', novaSenha: '', token: ''}}={})=>{
        const baseUrl = process.env.REACT_APP_PRIVATE_API_URL;
        const url = `${baseUrl}/api/redefinir-senha`; 
        try {
            const res = await fetch(url,{
                method: 'POST',
                headers:{
                    'content-type': 'application/json'
                },
                body: JSON.stringify(config)
            })
            const response = await res.json();
            return response;
        } catch (error) {
            console.error(error);
            return;
        }
    }

    return{
        verifyEmail,
        verifyToken,
        mudarSenha
    }

}