import React from 'react'

type Props = {}

const AcessosHelp = ({close}: any) => {
  return (
    <div className='w-full h-screen fixed z-20'>

            <div id="static-modal" data-modal-backdrop="static" tabIndex={-1}  className=" overflow-y-auto overflow-x-hidden z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Ajuda sobre os tipos de acesso do portal
                            </h3>
                            <button onClick={close} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                As opções apresentadas referem-se aos tipos de acessos disponíveis, definidos no registro da plataforma.
                            </p>
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                É possivel criar um acesso em cada categoria de uso presente na plataforma, ao criar um novo acesso em uma conta institucional (cadastro via instituição) será possível criar outra empresa ou solicitar a associação de outra empresa padrão bastando informar o Cnpj da empresa ao qual sera realizada a solicitação de acesso.
                            </p>
                      
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Contas resgistradas como colaborador somente terão acesso a associação de empresas já existente durente a criação do novo acesso.
                            </p>

                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Ao <span className="font-semibold"> associar a nova forma de acsso a uma conta já existente</span> será gerado uma solicitação de acesso á empresa informada, é possível solicitar acesso a mesma empresa que o usuário já estava associado em outro cadastro, porém será realizada uma nova solicitação ao administrador ou dono da empresa.
                            </p>

                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                Caso o usuário opte por <span className='font-semibold'> criar uma nova empresa para o novo acesso, não será possível acessar dados da empresa do cadastro anterior, para isto será realizado um nova solicitação.</span>
                            </p>
                 
                        </div>
        
                    </div>
                </div>
            </div>
    </div>
     

  )
}

export default AcessosHelp