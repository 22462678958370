import axios from "axios";
import { typeAlterarEmpresa } from "../types/empresaTypes";
import { useState } from "react";
import { TRegistarEmpresa } from "../types/cadastroTypes";
import { TCategoriaPastas } from "../DevUtils/CategoriasFake";

export const useReactQueryEmpresas = () => {
  const baseUrl: any = process.env.REACT_APP_PRIVATE_API_URL;

  const getEmpresas = async () => {
    const url = `${process.env.REACT_APP_PRIVATE_API_URL}/api/empresas/listar-todas-empresas`;
    try {
      const res = await fetch(url);
      const data = await res.json();
      return data;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const cadastrar = async (data: TRegistarEmpresa) => {
    const url = `${process.env.REACT_APP_PRIVATE_API_URL}/api/cadastro/registrar`;

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const listarEmpresas = async (empresasId: [String]) => {
    const url = `${process.env.REACT_APP_PRIVATE_API_URL}/api/empresas/listar-empresas`;

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ empresasId: empresasId }),
      });
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const alterarEmpresa = async (data: typeAlterarEmpresa) => {
    const url = `${baseUrl}/api/empresas/alterar-empresa?token=${data.token}&&empresaId=${data.empresaId}`;

    // console.log(url)
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data.data),
      });
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const empresasPermitidas = async (token: string) => {
    const url = `${baseUrl}/api/empresas/empresas-disponiveis?token=${token}`;

    try {
      const res = await fetch(url);
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  type typeSelecionarEmpresa = {
    token: string;
    empresaid: string;
  };

  const selecionarEmpresa = async (data: typeSelecionarEmpresa) => {
    const url = `${baseUrl}/api/empresas/listar-empresa?token=${data.token}&&empresaid=${data.empresaid}`;

    try {
      const res = await fetch(url);
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const listarUsuarios = async (
    token: string,
    empresaid: string,
    empresaidAssessoria: string
  ) => {
    const url = `${baseUrl}/api/empresas/listar-usuarios?token=${token}&&empresaid=${empresaid}&empresaidAssessoria=${empresaidAssessoria}`;

    try {
      const res = await fetch(url);
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  type typeAlterarLogoEmpresa = {
    token: string;
    empresaid: string;
    Arquivo: HTMLFormElement | any;
  };

  const [fileUpload, setFileUpload] = useState<number | string>("");

  const alterarLogoEmpresa = async (data: typeAlterarLogoEmpresa) => {
    const url = `${baseUrl}/api/empresas/alterar-logo?token=${data.token}&&empresaid=${data.empresaid}`;

    try {
      const res: any = await axios({
        url: url,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: data.Arquivo,
        onUploadProgress: (p: any) => {
          const completedUpload = p.loaded / p.total;
          setFileUpload(completedUpload);
        },
      });

      const response = await res.data;
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getArmazenamentoEmpresa = async (token: string, empresaid: string) => {
    const url = `${baseUrl}/api/empresas/get-size?token=${token}&empresaid=${empresaid}`;

    try {
      const res = await fetch(url);
      const response = res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  type TCategoria = {
    token: string;
    empresaid: string;
    action:
      | "nova"
      | "nova-anexar"
      | "anexar"
      | "pastaEmpresa"
      | "remover-filtro"
      | "remover-categoria";
    categoria: TCategoriaPastas;
  };

  const configCategoria = async (data: TCategoria) => {
    const url = `${baseUrl}/api/usuario/config-categoria?token=${data.token}&empresaid=${data.empresaid}&action=${data.action}`;

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data.categoria),
      });

      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getCategorias = async (token: string, empresaid: string,) => {
    const url = `${baseUrl}/api/usuario/get-categoria?token=${token}&empresaid=${empresaid}`;

    try {
      const res = await fetch(url);
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const verifcarEmpresa = async (empresaid: string, token: string) => {
    const url = `${baseUrl}/api/empresas/verificar-empresa?token=${token}&empresaid=${empresaid}`;

    try {
      const res = await fetch(url);
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };


  const getCategoriasEmpresa = async (token: string, empresaid: string,) => {
    const url = `${baseUrl}/api/usuario/get-categoria?token=${token}&empresaid=${empresaid}&pastaEmpresa=true`;

    try {
      const res = await fetch(url);
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return {
    getEmpresas,
    cadastrar,
    listarEmpresas,
    alterarEmpresa,
    empresasPermitidas,
    selecionarEmpresa,
    listarUsuarios,
    alterarLogoEmpresa,
    getArmazenamentoEmpresa,
    configCategoria,
    getCategorias,
    verifcarEmpresa,
    getCategoriasEmpresa
  };
};
