import React, { useEffect } from 'react';
import { ProgressBar } from 'primereact/progressbar';

const LoadingUploadArquivo = ({ value }: any) => {

  useEffect(() => {
    // Prompt confirmation when reload page is triggered
    window.onbeforeunload = () => { return "" };
    // Unmount the window.onbeforeunload event
    return () => { window.onbeforeunload = null };
  }, []);

  return (
    <div className="fixed inset-0 flex flex-col backdrop-blur-sm items-center justify-center bg-white bg-opacity-80 z-50">
      <ProgressBar color='#ff5a1f' value={Math.ceil(value * 100)} style={{ height: '15px', width: '50%' }} className="mb-4" />

      <p className="text-gray-700 text-lg">Carregando...</p>
    </div>
  );
};

export default LoadingUploadArquivo;
