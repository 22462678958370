import React, { useEffect, useState } from 'react'
import { BsDownload, BsEye, BsPencil, BsTrash } from 'react-icons/bs';
import { SlShareAlt } from 'react-icons/sl';
import { formatarData } from '../../Utils/formatarData';

import { useQuery } from '@tanstack/react-query';
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';


const CaptacaoArquivos = ({item, url}: any) => {


    const [arquivos, setArquivos] = useState<any>(['']);


    // console.log(url)

    const {getCaptacaoArquivos} = useReactQueryCaptacao()
    const {data: captacaoArquivosData, isLoading} = useQuery({
        queryFn: ()=> getCaptacaoArquivos(url as string),
        queryKey: ['rqCaptcaoArquivos',url]
    })


  return (
    <div>
        
        <div className="relative overflow-y-auto h-full chart">
            {
            arquivos ?
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3 ">
                            Nome do arquivo
                        </th>
                        <th scope="col" className="px-6 py-3 text-center">
                            Descrição
                        </th>
                        <th scope="col" className="px-6 py-3 text-center">
                            Data do arquivo
                        </th>
                        <th scope="col" className="px-6 py-3 text-center">
                            Download
                        </th>
                    </tr>
                </thead>
                <tbody>
                   
                    {
                       captacaoArquivosData && captacaoArquivosData.map((arquivo: any)=>(
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  
                        <td className="px-6 py-4">
                            {arquivo.titulo}
                        </td>
                        <td className="px-6 py-4 text-center">
                            {arquivo.tipoDocumentoDescricao}
                        </td>
    
                        <td className="px-6 py-4 text-center">
                            {formatarData(arquivo.dataPublicacaoPncp)}
                        </td>
                       
                        <td className="px-6 py-4 text-center inline-flex text-black dark:text-gray-300  gap-4">
                            <a href={arquivo.url} target='_blank'>
                                <BsDownload  className='cursor-pointer opacity-80 hover:opacity-100'/>
                            </a>                   
                        </td>
                    </tr>
                        ))
                    }
                    
                </tbody>
            </table>
            :
            <label> Nenhum arquivo encontrado</label>
            }
        </div>

    </div>
  )
}

export default CaptacaoArquivos