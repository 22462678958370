import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { BsQuestionCircle } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import AcessosHelp from '../Components/AcessosComponents/AcessosHelp'
import GerenciarAcessos from '../Components/AcessosComponents/GerenciarAcessos'

import { useGetAcessos } from '../Utils/ReactQuery/getAcessos'
import { useReactQueryUsers } from '../Utils/ReactQuery/getUser'


type Props = {}

const Acessos = ({user, empresaData}: any) => {

    const {listarAcessos} = useGetAcessos();

    const {data, isLoading} = useQuery({
        queryFn: ()=> listarAcessos(user.CpfCnpj),
        queryKey: ["rqAcessos", user]
    })

    const [acessoHelp, setAcessoHelp] = useState<Boolean>(false);

   

    const close =(e: any)=>{
        e.preventDefault()
        setAcessoHelp(false);
    }
   

  return (
    <div className='w-full h-screen mt-20 flex flex-col justify-center items-center'>
        {
            acessoHelp && <AcessosHelp close={close}/>
        }
        <div className='w-fit p-5 min-h-40 bg-white shadow dark:bg-gray-700 rounded-md'>
            <div>
                <h3 className='text-xl text-gray-800 dark:text-white'> Acessos disponíveis para <span className='font-bold'>{user.Nome}</span></h3>
            </div>

            <div className=''>
                 <GerenciarAcessos acessos={data ? data.Acessos : ''}/> 
            </div>

            <div>
            <Link to={'/portal/acessos/criar-acesso'}> Criar outro tipo de acesso</Link>
            <span className='cursor-pointer opacity-80 hover:opacity-100 transition-opacity' onClick={()=> setAcessoHelp(current => !current)}> <BsQuestionCircle/> </span>
                   
            </div>

        </div>
    </div>
  )
}

export default Acessos