import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { useUserContext } from '../../Context/useUserContext';
import { usePortaisUtils } from '../../Utils/ReactQuery/getPortais';
import { subTypePortal } from '../../Utils/types/portaisTypes';
import LoadingPage from '../LoadingPage';
import EmpresasPortal from '../PortalComponents/EmpresasPortal';
import NovoPortal from './NovoPortal';
import PortalObj from './PortalObj';
import SelecionarEmpresa from '../SelecionarEmpresa';


const PortaisCenter = ({ empresas }: any) => {
  const { getPortais } = usePortaisUtils();
  const [userContext] = useUserContext();

  const [empresaSelecionada, setEmpresaSelecionada] = useState<any>(localStorage.getItem('empresaSelecionada') || null);

  useLayoutEffect(() => {
    setEmpresaSelecionada(localStorage.getItem('empresaSelecionada'));
  }, [empresaSelecionada])

  const { data: portaisData, isLoading } = useQuery({
    queryKey: ["rqPortais", userContext],
    queryFn: () => getPortais({empresaid: empresaSelecionada as string}), staleTime: 0
  }
  );

  const [portalSelecionado, setPortalSelecionado] = useState<subTypePortal | null>();
  const [criarPortal, setCriarPortal] = useState<boolean>(false)

  const changePortal = async (portalid: string) => {
    if (portaisData) {
      const portal = await portaisData.portais.find((i: any) => {
        return i._id === portalid
      });
      setPortalSelecionado(portal as subTypePortal)

    }
  }

  //-----------------------------------------------------
  //Salvando state na url
  const { portalid } = useParams();

  useLayoutEffect(() => {
    if (portaisData && portalid !== '0') {
      changePortal(portalid as string)
    }
  })


  //-----------------------------------------------------


  const fechar = (e: any) => {
    e.preventDefault();
    setCriarPortal(false);
  }

  // //~MANUTENÇÃO~//

  // useEffect(()=>{
  //     console.log('Emresa do novo portal: ', empresaSelecionada)
  // },[empresaSelecionada])

  // useEffect(()=>{
  //   console.log('Empresas do portal: ', empresas)
  // },[empresas])

  // //~MANUTENÇÃO~//

  if (isLoading) {
    return (
      <LoadingPage />
    )
  }

  const findLogoPortal = (portalSelecionado: any) => {
    try {
      switch (true) {
        case portalSelecionado.length <= 28:
          return `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/logoPortais/${portalSelecionado}`
          break;
        case portalSelecionado && portalSelecionado.length > 20:
          return portalSelecionado;
      }
    } catch (error) {
      return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT26JBePenirPP31FfiGWn4yOmLn1bMsLgHGA&s'
    }
  }



  return (
    <div className={`border-1 border-black border-solid min-h-dvh w-full m-5 mt-24  rounded-lg bg-gray-100 dark:bg-gray-700`}>

      <div className='fixed w-full left-0 top-0 pl-18 z-40 '>
        <SelecionarEmpresa />
      </div>

      {
        criarPortal && <NovoPortal fechar={fechar} empresa={empresaSelecionada ? empresaSelecionada : userContext.EmpresaPadrão} />
      }


      <div className='w-full  shadow-2xl flex flex-row gap-5 overflow-y-hidden overflow-x-auto mt-10 px-4 rounded-sm '>

        <div>
          <div className='cursor-pointer hover:scale-110 transition-transform '  >
            <div onClick={() => setCriarPortal(current => !current)} className='w-10 h-10  rounded-full border-2 border-black dark:border-gray-00 border-dashed flex justify-center items-center origin-center '>
              <span className='text-black text-3xl w-8 h-8 p-0 m-0 text-center bg-white  rounded-full origin-center' title='Adiconar portal'>
                <BsPlus />
              </span>
            </div>
          </div>
        </div>
        {
          portaisData.portais && portaisData.portais.map((portal: any) => (
            <Link to={`/portal/portais/${portal._id}`} key={portal._id} >
              <div className='cursor-pointer hover:scale-110 transition-transform'  >
                <img src={findLogoPortal(portal.LogoPortal)} alt="Portal" className='w-10 h-10 rounded-full ' id={portal._id} />
              </div>
            </Link>
          ))
        }

      </div>

      {
        portalSelecionado && <PortalObj portalSelecionado={portalSelecionado} criarPortal={criarPortal} />
      }

    </div>
  )
}

export default PortaisCenter