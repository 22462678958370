import React, { useEffect, useRef, useState } from 'react'
import { BsPlus, BsX } from 'react-icons/bs'
import ErrorPage from '../../Pages/ErrorPage'
import LoadingPage from '../LoadingPage'
import styles from './Arquivos.module.css'
import { MdOutlineSpeakerNotes, MdOutlineSpeakerNotesOff, MdOutlineEditNote } from "react-icons/md";
import { TbSendOff, TbSend } from "react-icons/tb";
import { InputTextarea } from 'primereact/inputtextarea';


import { PiNavigationArrow } from 'react-icons/pi'
import DisplayChat from '../ProcessosChat/DisplayChat'


const ArquivoModal = ({ arquivo, fechar, processo }: any) => {

    const [criandoNota, setCriandoNota] = useState<boolean>(true);
    const [inputText, setInputText] = useState<string>('');

    const [inputChecked, setInputChecked] = useState(true)

    const childRef = useRef<any>();

    const handleSendtext = (e: any)=>{
        // console.log('Input text: ', inputText)
        e.preventDefault();
        childRef.current.enviarComentarioDoParente(e, inputText)
        setInputText('')
    }


    // //~~MANUTENÇÃO~~//
    // useEffect(()=>{
    // console.log('nput text: ', inputText)
    // },[inputText])
    // //~~MANUTENÇÃO~~//

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                fechar();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);
    


    const findArquivoLink = (arquivo: any) => {

        switch (true) {
            case arquivo.oldDescartmed === true && arquivo.arquivoPath !== undefined:
                return `${process.env.REACT_APP_PRIVATE_DESCARTMED_API_URL_ANTIGO}/${arquivo.arquivoPath.replaceAll(/\\/g, "/")}`
            case arquivo.arquivoPath !== undefined && !arquivo.LinkAws:
                const link0: string = `${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/${arquivo.arquivoPath}`;
                // console.log('Link: ', link0)
                return link0;
                break
            case arquivo.LinkAws && !arquivo.arquivoPath:
                // console.log('Prrimeira!!!!')
                return arquivo.LinkAws
            case arquivo.LinkAws2 && !arquivo.LinkAws && !arquivo.LinkAws2.includes('https'):
                const link: string = '${process.env.REACT_APP_PRIVATE_API_URL_ANTIGO}/' + arquivo.LinkAws2.replaceAll(" ", "%20").replaceAll(/\\/g, "/");
                // console.log('Link: ',link)
                return link
            case arquivo.LinkAws2 && !arquivo.LinkAws && arquivo.LinkAws2.length > 20:
                const link2: string = arquivo.LinkAws2.replaceAll(" ", "%20").replaceAll(/\\/g, "/")
                return link2;
        }
    }


    return (
        <div id="crud-modal" tabIndex={-1} className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0  h-screen bg-black">


            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 h-full w-full  grid grid-cols-[minmax(65%,_1fr)_auto] ">
                <div className=' w-full min-h-screen  bg-gray-600'>
                    <div className='bg-white w-full h-12 flex flex-row justify-between items-center'>

                        <div className='bg-white'>
                            <label className='ml-4'> <span className='font-semibold'>{processo.orgaopublicoLicitante}</span> | <span>{processo.modalidade}</span> | <span></span>{processo.numero
                            }</label>
                        </div>

                        <label className='text-3xl mr-5 cursor-pointer opacity-75 hover:opacity-100' onClick={fechar}><BsX /></label>
                    </div>

                    <div className='max-w-5xl h-full w-full'>
                        {
                            arquivo.LinkAws || arquivo.LinkAws2 || arquivo.arquivoPath ? <iframe src={findArquivoLink(arquivo)}
                                width="100%"
                                height="85%"
                                style={{ border: 'none' }}
                                onError={
                                    () => <ErrorPage message={'Erro ao carregar o documento'} />
                                }
                                onLoad={
                                    () => <LoadingPage />
                                }

                            ></iframe>

                                :
                                <LoadingPage />
                        }


                    </div>


                </div>


                <div className='h-full min-w-24 w-full px-2 bg-black resize-x '>

                    <input type="checkbox" className='modalSideNavCb' id="modalSideNavCb" checked={inputChecked}/>

                    <div className='w-full flex flex-row justify-center items-center icons gap-5'>

                        <label htmlFor='modalSideNavCb' className='w-fit flex justify-center items-center mt-5 labels  gap-5' onClick={()=> setInputChecked(current => !current)}>
                            <MdOutlineSpeakerNotes className='text-white text-3xl cursor-pointer openIcon' title='Comentários'/>
                            <MdOutlineSpeakerNotesOff className='text-white text-3xl cursor-pointer closeIcon' title='Fechar comentários'/>

                        </label>


                        {
                            criandoNota ?

                                <>
                                    <TbSendOff className='text-white text-3xl cursor-pointer  mt-4 closeIcon' onClick={() => setCriandoNota(current => !current)} title='Cancelar'/>
                                    {/* <textarea className='bg-transparent border-dashed focus:ring-0 outline-none rounded-lg h-11 text-white  mt-4 closeIcon' onChange={(e)=> setInputText(e.target.value)}></textarea> */}
                                    <InputTextarea  className='bg-transparent border-dashed focus:ring-0 outline-none rounded-lg h-11 text-white  mt-4 closeIcon' autoResize value={inputText} onChange={(e) => setInputText(e.target.value)} rows={1} cols={30} />
                                    

                                    <TbSend className='text-white text-3xl cursor-pointer rotate-45 mt-4 closeIcon' onClick={handleSendtext} />
                                </>
                     



                                :
                                <MdOutlineEditNote className='text-white text-3xl cursor-pointer  closeIcon mt-4' onClick={() => setCriandoNota(current => !current)} title='Anexar nota' />

                        }




                    </div>

                    <div className='modalSideNavWrapper'>

                        <div className='modalSideNavContainer'>
                            <div className='min-w-96 h-full max-h-full'>
                                <div className='h-full max-w-md w-full bg-black dark:bg-gray-700 p-4 relative overflow-y-auto'>
                                    <div className='h-full bg-gray-200 dark:bg-gray-600 rounded-xl '>
                                        <DisplayChat processo={processo} tipo='arquivo' ref={childRef}/>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>

            </div>


        </div>
    )
}

export default ArquivoModal