import React, { useState } from 'react'
import { BsX } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import LoadingSmall from '../LoadingSmall';
import { toast } from 'react-toastify';
import { useAdminUtils } from '../../Utils/ReactQuery/getAdminUtils';
import { useMutation, useQuery } from '@tanstack/react-query';


const AssessoriaPermissões = ({ empresa, fechar }: any) => {

  // //~~MANUTENÇÃO~~//

  // //~~MANUTENÇÃO~~//


  const token = localStorage.getItem('token');

  const [pastasPermissão,      setPastasPermissão] = useState<string>('')
  const [funcionariosPermissão,setFuncionariosPermissão] = useState<string>('')
  const [funcionariosPastas,   setFuncionariosPastas] = useState<string>('')

  // React query--------------
  const {getPermissões, configPermissões} = useAdminUtils()
  const {data: assessoriaPermData, isLoading, isError} = useQuery({
    queryFn: ()=> getPermissões(token as string, empresa._id as string),
    queryKey: ['rqPermissõesAssessooria']
  })


  const assessoriaPermissõesMutation = useMutation({
    mutationFn: configPermissões,
    onMutate: async(data)=>{
      setLoading(true)
    },
    onSuccess: async(response)=>{

      if(response.status === 1){
        toast.success(response.msg)
      }

      if(response.status === 3){
        toast.error(response.msg)
      }

    },
    onError: async(err)=>{

      console.error(err.message)
    },
    onSettled: async(data)=>{
      setLoading(false)
    }
    
  })



  // React query--------------
  

  const [loading, setLoading] = useState<boolean>(false);
  const [sucess, setSucess] = useState<boolean>(false);

  const hanldePermissão = (e: any) => {

    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const PastasPermissão = formData.get('PastasPermissão') as string;
    const FuncionariosPermissão = formData.get('FuncionariosPermissão')  as string;
    const FuncionariosPastas = formData.get('FuncionariosPastas') as string;

    const permissões = {
      PastasPermissão,
      FuncionariosPermissão,
      FuncionariosPastas, 
      Cnpj: empresa.CnpjEmpresa,
      Empresaid: empresa._id
    }

    assessoriaPermissõesMutation.mutate({token: token as string, data: permissões})


  }

  // Tailwind ----------------------------------------------------------------------
  const selectInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 cursor-pointer";
  const optionInput = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 cursor-pointer";
  // Tailwind ----------------------------------------------------------------------



  return (
    <div id="popup-modal" tabIndex={-1} className=" overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">

      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <form className="p-4 md:p-5 text-center" onSubmit={hanldePermissão}>


            <div className="flex justify-between items-center w-full">
              <p className="text-left font-semibold">{empresa.NomeEmpresa}</p>
              <BsX className="cursor-pointer opacity-80 hover:opacity-100 text-3xl" onClick={fechar} />
            </div>

            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>

            <h3 className="mb-3 text-lg font-normal text-gray-500 dark:text-gray-400">Permissões desta assessoria</h3>


            <div className="flex flex-col space-y-4 bg-gray-50 dark:bg-gray-900 p-4 rounded-md shadow-md">
              <div className="">
                <label className="block mb-2 ml-5 text-sm font-semibold text-gray-700 dark:text-gray-300 text-left">
                  Pastas da empresa
                </label>
                <div className="inline-block w-full max-w-xs">
                  <select
                    onChange={(e)=> setPastasPermissão(e.target.value)}
                    name="PastasPermissão"
                    id="categoria"
                    className={`${selectInput} hover:bg-gray-100 hover:text-blue-700`}
                  >
                    <option value="Nenhum" className={`${optionInput}`}>
                      Sem acesso
                    </option>
                    <option value="Visualizar" className={`${optionInput}`}>
                      Somente leitura
                    </option>
                    <option value="Alterar" className={`${optionInput}`}>
                      Modificar
                    </option>
                  </select>
                </div>
              </div>

              <div className="">
                <label className="block mb-2 ml-5 text-sm font-semibold text-gray-700 dark:text-gray-300 text-left">
                  Lista de funcionários
                </label>
                <div className="inline-block w-full max-w-xs">
                  <select
                  onChange={(e)=> setFuncionariosPermissão(e.target.value)}
                    name="FuncionariosPermissão"
                    id="FuncionariosPermissão"
                    className={`${selectInput} hover:bg-gray-100 hover:text-blue-700`}
                  >
                    <option value="Nenhum" className={`${optionInput}`}>
                      Sem acesso
                    </option>
                    <option value="Visualizar" className={`${optionInput}`}>
                      Somente leitura
                    </option>

                  </select>
                </div>
              </div>

              <div className="">
                <label className="block mb-2 ml-5 text-sm font-semibold text-gray-700 dark:text-gray-300 text-left">
                  Pastas dos funcionários
                </label>
                <div className="inline-block w-full max-w-xs">
                  <select
                    disabled={funcionariosPermissão === 'Nenhum' ? true: false}
                    onChange={(e)=> setFuncionariosPastas(e.target.value)}
                    name="FuncionariosPastas"
                    id="FuncionariosPastas"
                    className={`${selectInput} hover:bg-gray-100 hover:text-blue-700`}
                  >
                    <option value="Nenhum" className={`${optionInput}`} selected={funcionariosPermissão === 'Nenhum' ? true: false}>
                      Sem acesso
                    </option>
                    <option value="Visualizar" className={`${optionInput}`}>
                      Somente leitura
                    </option>
                    <option value="Alterar" className={`${optionInput}`}>
                      Modificar
                    </option>
                  </select>
                </div>
              </div>
            </div>


            <button
              data-modal-hide="popup-modal"
              type="submit"
              disabled={loading}
              className={`py-2.5 mt-4 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-green-500 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 shadow-md ${loading ? 'cursor-not-allowed opacity-50' : ''}`}>
              {loading ? (
                <LoadingSmall message="Aplicando permissões..." />
              ) : (
                'Aplicar Permissões'
              )}
            </button>

          </form>
        </div>
      </div>
    </div>
  )
}

export default AssessoriaPermissões