import React, { useEffect, useState } from 'react'
import { SlShareAlt } from 'react-icons/sl';
import { formatarData } from '../../Utils/formatarData';
import { useReactQueryCaptacao } from '../../Utils/ReactQuery/getCaptacaoUtils';
import { useQuery } from '@tanstack/react-query';
import { FaExclamationTriangle } from 'react-icons/fa';
import { BsLockFill } from 'react-icons/bs';


const CaptacaoItens = ({item, url, total}: any) => {


    const [itens, setItens] = useState<any>(['']);

    const {getCaptacaoItens} = useReactQueryCaptacao();

    const {data: itensData, isLoading} = useQuery({
        queryFn: ()=> getCaptacaoItens(url as string),
        queryKey: ['rqCaptacaoArquivos', url]
    })

    if(itensData && itensData.error && itensData.status === 404){
        return(
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <td colSpan={7} className=" pl-4 w-full text-start text-gray-500 py-4">
                <div className='flex gap-2 flex-col'>
                    
                    <label className='inline-flex'><FaExclamationTriangle className='mt-0.5 mr-2' />Ocorreu um erro ao tentar se comunicar com o servidor do PNCP. </label>
                    <span> Ainda é possível adicionar arquivos!</span>
                    
                    <span>{itensData.message}</span>
                </div>
            </td>
        </tr>

        )
    }
    
    if(itensData && itensData.error && itensData.status === 500){
        return(
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <td colSpan={7} className=" pl-4 w-full text-start text-gray-500 py-4">
                <div className='flex gap-2 flex-col'>
                    
                    <label className='inline-flex'><FaExclamationTriangle className='mt-0.5 mr-2' />Ocorreu um erro ao tentar se comunicar com o servidor do PNCP. Aparentemente, há uma instabilidade no próprio servidor do PNCP. Por favor, tente novamente mais tarde.</label>
                    <span> Ainda é possível adicionar arquivos!</span>
                    
                    <span>{itensData.message}</span>
                </div>
            </td>
        </tr>

        )
    }

    if(itensData && total){
        total(itensData)
    }

  return (
    <div className='mb-12'>
        <div className="relative">
        <label htmlFor="">
            <BsLockFill size={10}/>
        </label>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-center">
                                Numero
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Descrição
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Quantidade
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Valor unitario estimado 
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Valor total estimado
                            </th>
                    

                        </tr>
                    </thead>
                    <tbody>
                       
                        {
                           itensData && itensData.map((item: any, index: number)=>(
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                           
                            <td className="px-6 py-4 text-center">
                                {item.numeroItem}
                            </td>
                           
                            <td className="px-6 py-4 text-center">
                                {item.descricao}
                            </td>
                            <td className="px-6 py-4 text-center">
                                {item.quantidade}
                            </td>
                            
                            <td className="px-6 py-4 text-center">
                                {item.valorUnitarioEstimado === 0 ? 'Sigiloso' : item.valorUnitarioEstimado ? item.valorUnitarioEstimado.toLocaleString("pt-br", {style: "currency", currency: 'BRL'}) : item.valorUnitarioEstimado}
                            </td>
                            
                            <td className="px-6 py-4 text-center">
                                {item.valorTotal === 0 ? 'Sigiloso' : item.valorTotal ? item.valorTotal.toLocaleString("pt-br", {style: "currency", currency: 'BRL'}): item.valorTotal}
                            </td>

                        </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

    </div>
  )
}


export default CaptacaoItens