const BannerNetwork = () => {
    return (
        <div
            className={`relative flex items-center justify-between p-6 bg-gradient-to-r from-green-100 to-green-200 rounded-lg transition-all duration-300`}
            style={{
                marginLeft: '69px',
                marginTop: '70px',
                marginRight: '5px',
                paddingRight: '5px',
                height: '100px',
            }}
        >
            <div className="absolute top-4 left-4">
                <h1 className="text-2xl font-bold text-gray-500 dark:text-gray-400 dark:group-hover:text-white">Bem-Vindo(a)!</h1>
            </div>
            <button className="absolute bottom-2 right-2 flex items-center justify-center w-8 h-8 bg-gray-500 text-white dark:bg-gray-600 dark:hover:bg-gray-700 rounded-full shadow hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500">
                <svg className="flex-shrink-0 w-4 h-4 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M256.005,220.786c-35.892,0-64.989,29.35-64.989,65.558c0,36.226,29.097,65.558,64.989,65.558
		c35.892,0,64.978-29.332,64.978-65.558C320.983,250.135,291.896,220.786,256.005,220.786z"/>
                    <path d="M496.774,135.592c-9.344-9.461-22.509-15.416-36.886-15.416h-56.404c-4.546,0.018-8.784-2.702-10.736-7.093
		l-16.898-37.826c-8.358-18.741-26.936-30.931-47.557-30.931H183.706c-20.611,0-39.198,12.19-47.575,30.931l-16.88,37.826
		c-1.952,4.391-6.199,7.111-10.736,7.093H52.112c-14.368,0-27.56,5.954-36.922,15.416C5.828,145.026,0,158.21,0,172.569v242.73
		c0,14.368,5.828,27.543,15.19,36.976c9.361,9.462,22.554,15.416,36.922,15.398h224.55h183.227
		c14.377,0.018,27.542-5.937,36.886-15.398c9.407-9.433,15.226-22.608,15.226-36.976v-242.73
		C512,158.21,506.181,145.008,496.774,135.592z M339.463,370.334c-21.28,21.515-50.892,34.89-83.458,34.89
		c-32.585,0-62.187-13.374-83.468-34.89c-21.316-21.46-34.528-51.262-34.509-83.991c-0.018-32.729,13.193-62.504,34.509-84
		c21.28-21.498,50.874-34.889,83.468-34.862c32.566-0.027,62.178,13.364,83.458,34.862c21.316,21.497,34.528,51.271,34.5,84
		C373.99,319.072,360.779,348.874,339.463,370.334z M461.967,214.542h-35.793V178.74h35.793V214.542z"/>
                </svg>
            </button>
        </div>
    );
};

export default BannerNetwork;
