import React from 'react'
import DisplayChat from './DisplayChat'

const ProcessosChat = ({processo}: any) => {
  return (
    <div className=' w-full min-h-72 h-72 dark:bg-gray-700 p-4 relative overflow-y-auto'>
      
          <DisplayChat processo ={processo} tipo ='processo'/>
       
    </div>
  )
}

export default ProcessosChat