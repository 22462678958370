import React from 'react'
import { BsArrowRight, BsDot } from 'react-icons/bs';

type Props = {}

const PlanosCard = ({handleAssinatura}: any) => {


    //Tailwind ---------------------------------------
    const plano = 'border border-opacity-80 shadow-lg max-w-lg p-5 rounded-lg relative';
    //Tailwind ---------------------------------------

    return (
        <div>
            <div className='w-full gap-3 grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 justify-between'>

                <div className={`${plano}`}>


                    <div className='absolute right-2 top-0' onClick={() => handleAssinatura('basico')}>
                        <label htmlFor="" className='inline-flex opacity-80 hover:opacity-100 cursor-pointer transition-opacity'><BsArrowRight className='mt-1 mr-1' /> <span> Assinar plano </span></label>
                    </div>

                    <label className='text-xl font-semibold p-2 mb-2'> Básico </label>
                    <div>

                        <div>
                            <p><span>Valor: </span> R$ 353,00 - (Assinatura mensal)</p>
                        </div>

                        <div className='ml-4'>
                            <p> <span> Armazenamento: </span> 5gb em arquivos.</p>
                            <p> <span> Benefícios: </span> </p>
                            <div className='ml-2 flex flex-col '>
                                <p className='inline-flex'> <BsDot /> Busca automática de edtais.</p>
                                <p className='inline-flex'> <BsDot /> Alertas personalizados.</p>
                                <p className='inline-flex'><BsDot />  Gerênciamento de processos de licitação</p> 
                                <p className='inline-flex'> <BsDot/> Relatório de contratos.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${plano}`}>
                    <div className='absolute right-2 top-0' onClick={() => handleAssinatura('intermediario')}>
                        <label htmlFor="" className='inline-flex opacity-80 hover:opacity-100 cursor-pointer transition-opacity'><BsArrowRight className='mt-1 mr-1' /> <span> Assinar plano </span></label>
                    </div>

                    <label className='text-xl font-semibold p-2 mb-2'> Intermediário </label>
                    <div>

                        <div>
                            <p><span>Valor: </span> R$ 706,00 - (Assinatura mensal)</p>
                        </div>

                        <div className='ml-4'>
                            <p> <span> Armazenamento: </span> 10gb em arquivos.</p>
                            <p> <span> Benefícios: </span> </p>
                            <div className='ml-2 flex flex-col '>
                                <p className='inline-flex'> <BsDot /> Busca automática de edtais.</p>
                                <p className='inline-flex'> <BsDot /> Alertas personalizados.</p>
                                <p className='inline-flex'><BsDot />  Gerênciamento de processos de licitação</p> 
                                <p className='inline-flex'> <BsDot/> Relatório de contratos.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${plano}`}>

                    <div className='absolute right-2 top-0' onClick={() => handleAssinatura('avançado')}>
                        <label htmlFor="" className='inline-flex opacity-80 hover:opacity-100 cursor-pointer transition-opacity'><BsArrowRight className='mt-1 mr-1' /> <span> Assinar plano </span></label>
                    </div>

                    <label className='text-xl font-semibold p-2 mb-2'> Avançado </label>
                    <div>

                        <div>
                            <p><span>Valor: </span> R$ 1412,00 - (Assinatura mensal)</p>
                        </div>

                        <div className='ml-4'>
                            <p> <span> Armazenamento: </span> 15gb em arquivos.</p>
                            <p> <span> Benefícios: </span> </p>
                            <div className='ml-2 flex flex-col '>
                                <p className='inline-flex'> <BsDot /> Busca automática de edtais.</p>
                                <p className='inline-flex'> <BsDot /> Alertas personalizados.</p>
                                <p className='inline-flex'><BsDot />  Gerênciamento de processos de licitação</p> 
                                <p className='inline-flex'> <BsDot/> Relatório de contratos.</p>
                            </div>
                        </div>
                    </div>


                </div>

            
            </div>

          
        </div>
    )

}

export default PlanosCard