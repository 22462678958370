import React, { useState } from 'react';
import CaptacaoCard from './CaptacaoCard';
import CaptacaoCalendar from './CaptacaoCalendar'; // Importe o novo componente
import { useQuery } from '@tanstack/react-query';
import { useReactQueryProcessos } from '../../Utils/ReactQuery/getProcessosUtils';
import { toast } from 'react-toastify';

const CaptacaoDisplay = ({ editais, permitirCalendario }: any) => {
  const empresaSelecionada = localStorage.getItem('empresaSelecionada');
  const token = localStorage.getItem('token');
  const { verificarProcessoPncp } = useReactQueryProcessos();

  const [visualizacao, setVisualizacao] = useState('lista');

  const filtroSalvado = localStorage.getItem(`${empresaSelecionada}-filtro`)
  const alternarVisualizacao = (modo: any) => {

    if (!permitirCalendario && !filtroSalvado) {
      toast.warn("Aplique e salve um filtro Primeiro!")
      return
    }

    setVisualizacao(modo);
  };



  const { data: pncpData, isLoading } = useQuery({
    queryFn: () => verificarProcessoPncp(token as string, empresaSelecionada as string),
    queryKey: ['rqProcessosPncp'],
  });

  return (
    <div>
      {editais.items.map((item: any, index: number) => (
        <CaptacaoCard item={item} processoPncp={pncpData} key={index} />
      ))}
    </div>
  );
};

export default CaptacaoDisplay;
